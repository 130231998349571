import backIcon from "../../../assets/icons/header/back_icon.svg";
import infoIcon from "../../../assets/icons/header/info.svg";
import question_mark from "../../../assets/icons/header/question_mark.svg";
import cart from "../../../assets/icons/redeem/cart.svg";
import search_icon from "../../../assets/icons/redeem/searchIcon.svg";

export const styles = (theme) => {
  return {
    toolbarMargin: {
      minHeight: "86px",
      [theme.breakpoints.down("641")]: {
        minHeight: "82px",
      },
      [theme.breakpoints.down("481")]: {
        minHeight: "64px",
      },
      [theme.breakpoints.down("421")]: {
        minHeight: "54px",
      },
      [theme.breakpoints.down("381")]: {
        minHeight: "50px",
      },
      [theme.breakpoints.down("361")]: {
        minHeight: '48px'
      },
      [theme.breakpoints.down("321")]: {
        minHeight: '43px'
      },
    },
    appbar: {
      // height: "auto",
      backgroundColor: theme.palette.primary.main,
    },
    backBtn: {
      width: "19px",
      height: "35px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      fontSize: "6px",
      [theme.breakpoints.down("641")]: {
        width: "17px",
        height: "30px",
      },
      [theme.breakpoints.down("481")]: {
        width: "12px",
        height: "22px",
      },
      [theme.breakpoints.down("381")]: {
        width: "9px",
        height: "16px",
      },
      [theme.breakpoints.down("321")]: {
        width: "8px",
        height: "15px",
      },
    },
    infobtn: {
      width: "33px",
      height: "33px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      [theme.breakpoints.down("481")]: {
        width: "24px",
        height: "24px",
      },
      [theme.breakpoints.down("381")]: {
        width: "18px",
        height: "18px",
      },
      [theme.breakpoints.down("321")]: {
        width: "17px",
        height: "17px",
      },
    },
    mainHeader: {
      color: theme.palette.typography.texts.headerTextColor,
      fontSize: "20px",
      [theme.breakpoints.down("641")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
    },
    offerHeader: {
      position: "relative",
      left: "20px",
    },
    toolbar: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    toolbar_bill_summary: {
      width: "100%",
      justifyContent: "center",
      alignItems: 'center',
      display: "flex",
    },
    typography: {
      padding: 0,
      color: theme.palette.typography.texts.color,
      "& .MuiListItem-root": {
        paddingTop: "0px",
        paddingBottom: "0px",
      },
      "& .MuiTypography-body1": {
        fontSize: "16px",
        [theme.breakpoints.down("641")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("481")]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down("421")]: {
          fontSize: "11.5px",
        },
        [theme.breakpoints.down("401")]: {
          fontSize: "11px",
        },
        [theme.breakpoints.down("381")]: {
          fontSize: "10.5px",
        },
        [theme.breakpoints.down("361")]: {
          fontSize: "10px",
        },
      },
      "& .MuiListItemIcon-root": {
        paddingRight: "10px",

        minWidth: "19px",
        [theme.breakpoints.down("641")]: {
          minWidth: "17px",
        },
        [theme.breakpoints.down("481")]: {
          minWidth: "12px",
        },
        [theme.breakpoints.down("381")]: {
          minWidth: "9px",
        },
        [theme.breakpoints.down("321")]: {
          minWidth: "8px",
        },
      },
      "& .MuiSvgIcon-root": {
        fill: theme.palette.primary.main,
        width: "19px",
        height: "35px",
        [theme.breakpoints.down("641")]: {
          width: "17px",
          height: "30px",
        },
        [theme.breakpoints.down("481")]: {
          width: "12px",
          height: "22px",
        },
        [theme.breakpoints.down("381")]: {
          width: "9px",
          height: "16px",
        },
        [theme.breakpoints.down("321")]: {
          width: "8px",
          height: "15px",
        },
      },
    },
    backBtnIconCss: {
      backgroundColor: theme.palette.header.backIconColor,
      " -webkit-mask-image": `url(..${backIcon})`,
      "mask-image": `url(..${backIcon})`,
      mask: `url(..${backIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(..${backIcon}) no-repeat center / contain`,
    },
    backBtnOfferIconCss: {
      backgroundColor: theme.palette.header.backIconColor,
      " -webkit-mask-image": `url(..${backIcon})`,
      "mask-image": `url(..${backIcon})`,
      mask: `url(..${backIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(..${backIcon}) no-repeat center / contain`,
    },
    infoIconCss: {
      backgroundColor: theme.palette.header.backIconColor,
      " -webkit-mask-image": `url(.${infoIcon})`,
      "mask-image": `url(.${infoIcon})`,
      mask: `url(.${infoIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${infoIcon}) no-repeat center / contain`,
    },
    questionMarkIconCss: {
      backgroundColor: theme.palette.header.backIconColor,
      " -webkit-mask-image": `url(.${question_mark})`,
      "mask-image": `url(.${question_mark})`,
      mask: `url(.${question_mark}) no-repeat center / contain`,
      "-webkit-mask": `url(.${question_mark}) no-repeat center / contain`,
    },
    p0: {
      padding: 0,
    },
    locicon: {
      width: 36,
      height: 36,
      [theme.breakpoints.down("641")]: {
        width: 34,
        height: 34,
      },
      [theme.breakpoints.down("481")]: {
        width: 26,
        height: 26,
      },
      [theme.breakpoints.down("421")]: {
        width: 24,
        height: 24,
      },
      [theme.breakpoints.down("401")]: {
        width: 24,
        height: 24,
      },
      [theme.breakpoints.down("381")]: {
        width: 20,
        height: 20,
      },
      [theme.breakpoints.down("361")]: {
        width: 20,
        height: 20,
      },
      [theme.breakpoints.down("321")]: {
        width: 18,
        height: 18,
      },
    },
    toolbar_three: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cartbtn: {
      width: "33px",
      height: "30px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      [theme.breakpoints.down("481")]: {
        width: "25px",
        height: "23px",
      },
      [theme.breakpoints.down("381")]: {
        width: "19px",
        height: "17px",
      },
      [theme.breakpoints.down("321")]: {
        width: "17px",
        height: "15px",
      },
    },
    emptyDiv: {
      marginRight: "1.4rem",
    },
    countClass: {
      color: theme.palette.primary.main,
      position: "absolute",
      border: "1px solid #ffffff",
      fontSize: "1.20rem",
      fontWeight: "600",
      borderRadius: "50%",
      "-webkit-border-top-left-radius": "50%",
      "-webkit-border-top-right-radius": "50%",
      "-webkit-border-bottom-right-radius": "50%",
      "-webkit-border-bottom-left-radius": "50%",
      "-moz-border-radius-topleft": "50%",
      "-moz-border-radius-topright": "50%",
      "-moz-border-radius-bottomright": "50%",
      "-moz-border-radius-bottomleft": "50%",
      borderTopLeftRadius: "50%",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
      borderBottomLeftRadius: "50%",
      "-webkit-border-radius": "50%",
      "-moz-border-radius": "50%",
      "-khtml-border-radius": "50%",
      backgroundColor: theme.palette.cards.backgroundColor,
      transform: "translate(10px, -11px)",
      height: "24px",
      width: "24px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        height: "22px",
        width: "22px",
      },
      [theme.breakpoints.down(641)]: {
        fontSize: "12.5px",
        height: "18px",
        width: "18px",
        transform: "translate(9px, -10px)",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "10.5px",
        height: "14px",
        width: "14px",
        transform: "translate(7px, -8px)",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "10.2px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "9.9px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "9.5px",
        height: "12px",
        width: "13px",
        transform: "translate(6px, -7px)",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "9px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
        height: "12px",
        width: "12px",
        transform: "translate(5px, -6px)",
      },
    },
    cartIconCss: {
      backgroundColor: theme.palette.dashBoardheadText.color,
      " -webkit-mask-image": `url(..${cart})`,
      "mask-image": `url(..${cart})`,
      mask: `url(..${cart}) no-repeat center / contain`,
      "-webkit-mask": `url(..${cart}) no-repeat center / contain`,
    },
    searchIconCss: {
      backgroundColor: theme.palette.cards.backgroundColor,
      " -webkit-mask-image": `url(..${search_icon})`,
      "mask-image": `url(..${search_icon})`,
      mask: `url(..${search_icon}) no-repeat center / contain`,
      "-webkit-mask": `url(..${search_icon}) no-repeat center / contain`,
    },
    searchIcon: {
      position: "relative",
      zIndex: "10",
      display: "block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      width: 31,
      height: 33,
      [theme.breakpoints.down("641")]: {
        width: 29,
        height: 31,
      },
      [theme.breakpoints.down("481")]: {
        width: 23,
        height: 25,
      },
      [theme.breakpoints.down("421")]: {
        width: 21,
        height: 23,
      },
      [theme.breakpoints.down("401")]: {
        width: 21,
        height: 23,
      },
      [theme.breakpoints.down("381")]: {
        width: 17,
        height: 19,
      },
      [theme.breakpoints.down("361")]: {
        width: 17,
        height: 19,
      },
      [theme.breakpoints.down("321")]: {
        width: 15,
        height: 17,
      },
    },
    icon_panel: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1px",
      width: "66px",
      [theme.breakpoints.down("641")]: {
        width: '64px',
      },
      [theme.breakpoints.down("481")]: {
        width: '62px',
      },
      [theme.breakpoints.down("421")]: {
        width: '60px',
      },
      [theme.breakpoints.down("401")]: {
        width: '58px',
      },
      [theme.breakpoints.down("381")]: {
        width: '56px',
      },
      [theme.breakpoints.down("361")]: {
        width: '51px',
      },
      [theme.breakpoints.down("321")]: {
        width: '51px',
      },
    },
    regular: {
      minHeight: "86px",
      padding: "11px 30px 11px 30px",
      [theme.breakpoints.down("641")]: {
        padding: "9px 26px 9px 26px",
        minHeight: "82px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "7px 20px 7px 20px",
        minHeight: "64px",
      },
      [theme.breakpoints.down("421")]: {
        minHeight: "54px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "0px 15px 0px 15px",
        minHeight: "50px",
      },
      [theme.breakpoints.down("361")]: {
        minHeight: '48px'
      },
      [theme.breakpoints.down("321")]: {
        padding: "0px 13px 0px 13px",
        minHeight: '43px'
      },
    }
  };
};
