import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import BookWrapper from "./Component/BookWrapper";
import AlertMessage from '../../../common/AlertMessage/AlertMessage';
import { getStorageItem, setStorageItem, removeStorageItem } from '../../../common/localStorage';
import { showLoader } from '../../../App/redux/action';
import { connect } from 'react-redux';

class BookVisitContainer extends Component {
	componentDidMount() {
	}

	render() {
		return (
			<Fragment>
				<AlertMessage />
				<BookWrapper openLoader={this.props.showLoader} user={JSON.parse(getStorageItem("user"))} {...this.props}/>
			</Fragment>
		);
	}
}

const mapDispatchToProps = {
	showLoader,
};

const mapStateToProps = (state) => {
	return {
		customerResponse: state.reducerRegistration.registerCustomerResponse,
		isAuthenticated: state.reducerOtpverification.isAuthenticated
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookVisitContainer));
