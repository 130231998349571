import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { Prompt } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const styles = (theme) => {
  return {
    button: {
      ...theme.palette.typography.doneButtons,
      width: "auto !important",
    },
    cnlbutton: {
      ...theme.palette.typography.cnclButtons,
      width: "auto !important",
    },
    dialogModal: {
      "& .MuiDialog-paperWidthMd": {
        width: "100%",
      },
      "& .MuiDialog-paper": {
        margin: "15px",
      },
    },
    common_font: {
      fontSize: "20px",
      textAlign: "center",
      lineHeight: "1.5",
      [theme.breakpoints.down(641)]: {
        fontSize: "18px",
        lineHeight: "1.44",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "16px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "15.5px",
        lineHeight: "1.4",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "14.5px",
        lineHeight: "1.3",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "12px",
      },
    },
    content: {
      padding: "8px 10px",
    },
    MuiDialogActions: {
      justifyContent: "center",
    },
  };
};

class UnsavedChangesWarning extends React.Component {
  state = {
    isDirty: false,
    lastLocation: null,
    confirmedNavigation: false,
    modalVisible: false,
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isDirty) {
      return {
        isDirty: true,
      };
    }
    return { isDirty: false };
  }

  // componentDidUpdate() {
  //   if (this.state.when) {
  //     window.onbeforeunload = () => true;
  //   } else {
  //     window.onbeforeunload = null;
  //   }
  // }

  handleClose = () => {
    this.setState({
      isDirty: false,
    });
  };

  showModal = (location) =>
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });
  closeModal = async (yes) => {
    await this.setState({
      modalVisible: false,
    });

    if (yes === 1) {
      const { navigate } = this.props;
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true,
          },
          () => {
            // Navigate to the previous blocked location with your navigate function
            navigate(lastLocation.pathname);
          }
        );
      }
    }
  };
  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation } = this.state;
    if (!confirmedNavigation && this.props.when) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };

  handleConfirmNavigationClick = () => this.closeModal(1);

  render() {
    const { isDirty, modalVisible, confirmedNavigation } = this.state;
    const { classes, when } = this.props;
    const CustomModal = ({ open, handleClose, handleNo, handleYes }) => {
      return (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          maxWidth="md"
          className={classes.dialogModal}
        >
          <DialogContent className={classes.content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={classes.common_font}
            >
              {"Are you sure you want to discard the changes?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.MuiDialogActions}>
            <Button onClick={handleNo} className={classes.button}>
              No
            </Button>
            <Button onClick={handleYes} className={classes.cnlbutton}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      );
    };

    if (isDirty) {
      return (
        <CustomModal
          open={isDirty}
          handleNo={this.props.handleNo}
          handleYes={this.props.handleYes}
          handleClose={this.props.handleNo}
        />
      );
    } else {
      return (
        <React.Fragment>
          <Prompt when={when} message={this.handleBlockedNavigation} />
          <CustomModal
            open={modalVisible}
            handleNo={this.closeModal}
            handleYes={this.handleConfirmNavigationClick}
            handleClose={this.closeModal}
          />
        </React.Fragment>
      );
    }
  }
}

export default withStyles(styles, { withTheme: true })(UnsavedChangesWarning);
