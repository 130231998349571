import React, { Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import classnames from "classnames";
import home from "../../assets/icons/footer/home.png";
import circle_tick from "../../assets/icons/submit_bill/green_tick.svg";
import { Typography, Button } from "@material-ui/core";
import moment from "moment";
import { getStorageItem } from "../../common/localStorage";
import { primaryOrange, green, errorRed } from "../../components/UI/Theme";
import pdfIcon from "../../assets/icons/submit_bill/pdf.svg";

const styles = (theme) => {
  return {
    wrapper: {
      margin: "0 auto",
      padding: 0,
      display: "block",
      maxWidth: "600px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    bill_process_block: {
      padding: "2px 0px 0px 0px",
      display: "block",
      width: "100%",
      boxShadow: "0px 0px 10px #dad4d4",
      backgroundColor: theme.palette.cards.backgroundColor,
      borderRadius: "6px",
    },
    process: {
      display: "block",
      width: "100%",
      padding: "0px 1px",
      height: "6px",
      [theme.breakpoints.down(481)]: {
        height: "4px",
      },
      [theme.breakpoints.down(361)]: {
        height: "3px",
      },
    },
    process_cnt: {
      display: "flex",
      alignItems: "center",
      padding: "12px 12px 0px 12px",
    },
    imageContainer: {
      marginRight: "30px",
      display: "inline-block",
      width: "132px",
      height: "132px",
      overflow: "hidden",
      verticalAlign: "top",
      [theme.breakpoints.down(641)]: {
        marginRight: "20px",
        width: "112px",
        height: "112px",
        verticalAlign: "top",
      },
      [theme.breakpoints.down(481)]: {
        marginRight: "10px",
        width: "100px",
        height: "100px",
        verticalAlign: "top",
      },
      [theme.breakpoints.down(381)]: {
        marginRight: "10px",
        width: "83px",
        height: "83px",
        verticalAlign: "top",
      },
      [theme.breakpoints.down(361)]: {
        marginRight: "10px",
        width: "83px",
        height: "83px",
        verticalAlign: "top",
      },
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      objectPosition: "center",
      // backgroundColor: theme.palette.img.backgroundColor,
    },
    cnt: {
      position: "relative",
      display: "inline-block",
      verticalAlign: "super",
      width: "calc(100% - 162px)",
      [theme.breakpoints.down(641)]: {
        width: "calc(100% - 132px)",
      },
      [theme.breakpoints.down(481)]: {
        width: "calc(100% - 110px)",
      },
      [theme.breakpoints.down(361)]: {
        width: "calc(100% - 93px)",
      },
      [theme.breakpoints.down(361)]: {
        width: "calc(100% - 93px)",
      },
      [theme.breakpoints.down(321)]: {
        width: "calc(100% - 93px)",
      },
    },
    tick_icon: {
      position: "absolute",
      height: "28px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      width: "28px",
      top: "3px",
      right: "3px",
      [theme.breakpoints.down(481)]: {
        height: "16px",
        width: "16px",
        top: "2px",
      },
      [theme.breakpoints.down(361)]: {
        height: "14px",
        width: "14px",
        top: "1px",
        right: 0,
      },
      [theme.breakpoints.down(321)]: {
        top: "0px",
      },
    },
    common_font: {
      fontSize: "20px",
      lineHeight: "1.5",

      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "1.44",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
        lineHeight: "1.36",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        lineHeight: "1.36",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
        lineHeight: "1.36",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.4",
      },
    },
    status: {
      margin: "0px 0px 16px 0px",
      display: "block",
      width: "90%",
      fontWeight: "500",
      [theme.breakpoints.down("sm")]: {
        margin: "0px 0px 12px 0px",
      },
      [theme.breakpoints.down("xs")]: {
        margin: "0px 0px 8px 0px",
      },
      [theme.breakpoints.down(361)]: {
        margin: "0px 0px 3px 0px",
      },
    },
    common_font_color: {
      color: "#454545",
    },
    secondary_font_color: {
      color: theme.palette.typography.texts.color,
      fontWeight: "bold",
    },
    secondary_font: {
      fontSize: "18px",
      lineHeight: "1.67",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "1.64",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        lineHeight: "1.33",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
        lineHeight: "1.33",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.4",
      },
    },
    label_font: {
      fontSize: "18px",
      lineHeight: "1.67",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "1.64",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        lineHeight: "1.33",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
        lineHeight: "1.33",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.4",
      },
    },
    bill_listed: {
      margin: "10px 0px 0px 0px",
      "& ul": {
        margin: 0,
        padding: "0px",
        display: "block",
        width: "100%",
        listStyle: "none",
        "& li": {
          margin: 0,
          display: "block",
          width: "100%",
          float: "none",
          borderBottom: "1px solid #d9d9d9",
          padding: `20px ${theme.spacing(2)}px`,
          [theme.breakpoints.down("sm")]: {
            padding: "10px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "8px",
          },
          "& $common": {
            margin: 0,
            padding: "0px 0px 0px 10px",
            display: "block",
            float: "left",
          },
          "& $one": {
            width: "40%",
            fontWeight: "400",
            position: "relative",
            display: "block",
            float: "left",
            "& span": {
              position: "absolute",
              display: "inline-block",
              top: "0px",
              right: "0",
            },
          },
          "& $two": {
            width: "60%",
            padding: `0px 0px 0px ${theme.spacing(2)}px`,
            fontWeight: "500",
            display: "block",
            float: "left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
      },
    },
    btnContainer: {
      width: "100%",
      padding: "20px",
      [theme.breakpoints.down("xs")]: {
        padding: "16px",
      },
    },
    doneBtn: {
      ...theme.palette.typography.doneButtons,
      marginBottom: 0,
      fontSize: "18px",
      lineHeight: "18px",
      height: "92px",

      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "14px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(361)]: {
        height: "46px",
        fontSize: "12px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    first_child: {
      borderTop: "1px solid #d9d9d9",
    },
    clear_float: {
      clear: "both",
    },
    common: {},
    one: {},
    two: {},
    fileName: {
      width: "100%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontSize: "18px",
      lineHeight: "2",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "2",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "1.71",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
        lineHeight: "1.7",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        lineHeight: "1.33",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.6",
      },
    },
    orangeTickCss: {
      backgroundColor: theme.palette.tick.pending,
      " -webkit-mask-image": `url(.${circle_tick})`,
      "mask-image": `url(.${circle_tick})`,
      mask: `url(.${circle_tick}) no-repeat center / contain`,
      "-webkit-mask": `url(.${circle_tick}) no-repeat center / contain`,
    },
    greenTickCss: {
      backgroundColor: theme.palette.tick.green,
      " -webkit-mask-image": `url(.${circle_tick})`,
      "mask-image": `url(.${circle_tick})`,
      mask: `url(.${circle_tick}) no-repeat center / contain`,
      "-webkit-mask": `url(.${circle_tick}) no-repeat center / contain`,
    },
    pdfIconCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${pdfIcon})`,
      "mask-image": `url(.${pdfIcon})`,
      mask: `url(.${pdfIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${pdfIcon}) no-repeat center / contain`,
    },
    pdfCss: {
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      height: "40%",
      width: "40%",
    },
    pdfContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "30px",
      width: "132px",
      height: "132px",
      overflow: "hidden",
      verticalAlign: "top",
      [theme.breakpoints.down(641)]: {
        marginRight: "20px",
        width: "112px",
        height: "112px",
        verticalAlign: "top",
      },
      [theme.breakpoints.down(481)]: {
        marginRight: "10px",
        width: "100px",
        height: "100px",
        verticalAlign: "top",
      },
      [theme.breakpoints.down(381)]: {
        marginRight: "10px",
        width: "83px",
        height: "83px",
        verticalAlign: "top",
      },
    },
  };
};

function getExtension(filename) {
  if (filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }
}

function isPDF(filename) {
  var ext = getExtension(filename);
  if (ext.toLowerCase() === "pdf") {
    return true;
  }
  return false;
}

const BillDetailsDialog = (props) => {
  const { classes, status } = props;
  const currencySymbol = getStorageItem("currencyCode")
    ? getStorageItem("currencyCode")
    : "₹";

  const listItems = [
    { label: "Brand", value: props.brand ? props.brand : "-" },
    {
      label: props.order ? "Points Redeemed" : "Points Earned",
      value: props.pointsEarned
        ? `${props.order ? "-" : "+"}${
          parseFloat(props.pointsEarned) % 1 !== 0
              ? parseFloat(props.pointsEarned).toFixed(2)
              : Math.floor(props.pointsEarned)
        } ${
            getStorageItem("pointShortName")
              ? getStorageItem("pointShortName")
              : "P"
          }`
        : "-",
    },
    {
      label: props.order ? "Order Number" : "Bill Number",
      value: props.billNumber ? props.billNumber : "-",
    },
    {
      label: props.order ? "Order Date" : "Bill Date",
      value: props.billDate
        ? moment(props.billDate, "YYYY-MM-DD").format("DD / MM / YYYY")
        : "-",
    },
    {
      label: props.order ? "Order Amount" : "Bill Amount",
      value: props.billAmount
        ? `${currencySymbol} ${
            parseFloat(props.billAmount) % 1 !== 0
              ? parseFloat(props.billAmount).toFixed(2)
              : Math.floor(props.billAmount)
          }`
        : "-",
    },
  ];

  return (
    <div className={classes.wrapper}>
      <div className={classes.bill_process_block}>
        <div
          className={classes.process}
          style={{
            backgroundColor:
              status === "Processing"
                ? primaryOrange
                : status === "Processed"
                ? green
                : (status === "Rejected" || status === "Cancelled") && errorRed,
          }}
        />
        <div className={classes.process_cnt}>
          {props.fileName && isPDF(props.fileName && props.fileName) ? (
            <div className={classes.pdfContainer}>
              <span className={classes.pdfIconCss + " " + classes.pdfCss} />
            </div>
          ) : (
            <div className={classes.imageContainer}>
              {props.billImage.length > 1 ? (
                <img
                  src={decodeURIComponent(
                    props.billImages && props.billImages[0]
                  )}
                  className={classes.img}
                  alt="Bill"
                />
              ) : (
                <img
                  src={decodeURIComponent(
                    props.billImages && props.billImages[0]
                  )}
                  className={classes.img}
                  alt="Bill"
                />
              )}
            </div>
          )}
          <div className={classes.cnt}>
            <Typography
              style={{
                color:
                  status === "Processing"
                    ? primaryOrange
                    : status === "Processed"
                    ? green
                    : (status === "Rejected" || status === "Cancelled") &&
                      errorRed,
              }}
              className={classes.status + " " + classes.common_font}
            >
              {status === "Processing"
                ? "Bill submitted for manual processing."
                : status === "Processed"
                ? "Bill processed."
                : (status === "Rejected" || status === "Cancelled") &&
                  `Bill ${status}.`}{" "}
            </Typography>
            <span
              className={classnames({
                [classes.tick_icon]: true,
                [classes.orangeTickCss]: props.status === "Processing",
                [classes.greenTickCss]: props.status === "Processed",
              })}
            />
            {Array.isArray(props.fileName) ? (
              <Typography
                className={classes.common_font_color + " " + classes.fileName}
              >
                {props.fileName[0]}
              </Typography>
            ) : (
              <Typography
                className={classes.fileName + " " + classes.common_font_color}
              >
                {props.fileName}
              </Typography>
            )}
            <Typography className={classes.secondary_font}>
              {props.status !== "Pending" && props.pointsEarned ? (
                <Fragment>
                  <span className={classes.common_font_color}>
                    You have {props.order ? "redeemed" : "earned"}{" "}
                  </span>
                  <span className={classes.secondary_font_color}>{`${
                    props.order ? "-" : "+"
                  }${
                    parseFloat(props.pointsEarned) % 1 !== 0
                    ? parseFloat(props.pointsEarned).toFixed(2)
                    : Math.floor(props.pointsEarned)
                  } ${
                    getStorageItem("pointShortName")
                      ? getStorageItem("pointShortName")
                      : "P"
                  }`}</span>
                </Fragment>
              ) : null}
            </Typography>
          </div>
        </div>
        <div className={classes.bill_listed}>
          <ul>
            <li className={classes.first_child}>
              <div
                className={
                  classes.common + " " + classes.label_font + " " + classes.one
                }
              >
                Location <span>:</span>
              </div>
              <div
                className={
                  classes.common + " " + classes.label_font + " " + classes.two
                }
              >
                {/* {props.merchant_name ? props.merchant_name : null }{props.location ? `, ${props.location}` : ''} */}
                {props.location ? props.location : "-"}
              </div>
              <div className={classes.clear_float} />
            </li>
            {listItems.map((item) => (
              <li key={item.label}>
                <div
                  className={
                    classes.common +
                    " " +
                    classes.label_font +
                    " " +
                    classes.one
                  }
                >
                  {item.label} <span>:</span>
                </div>
                <div
                  className={
                    classes.common +
                    " " +
                    classes.label_font +
                    " " +
                    classes.two
                  }
                >
                  {item.value}
                </div>
                <div className={classes.clear_float} />
              </li>
            ))}
          </ul>
        </div>
        <div className={classes.btnContainer}>
          <Button
            disableRipple
            color="primary"
            variant="contained"
            className={classes.doneBtn}
            onClick={props.okBtnHandler}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(BillDetailsDialog);

BillDetailsDialog.defaultProps = {
  okBtnHandler: () => console.log("Ok button clicked"),
  fileName: "",
  brand: "",
  pointsEarned: "",
  status: "Pending",
  billNumber: "",
  billDate: "",
  billAmount: "",
  billImage: home,
  location: "",
  order: "",
};
