import React from "react";
import { withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import { searchStyles } from "./SearchStyles";
import { InputAdornment, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
const Search = (props) => {
  const { classes } = props;
  const {
    onSearchTextChange,
    searchText,
    className,
    paddingBottom,
    placeholder,
    searchIcon,
    autoFocus,
  } = props;

  return (
    <div className={classes.searchWrap + " " + classes[paddingBottom]}>
      {/* <div> */}
      {searchIcon && (
        <span className={classes.searchIconCss + " " + classes.searchIcon} />
      )}
      {/* </div> */}
      <form onSubmit={props.searchCall} action=".">
        <TextField
          className={classes.searchBox + " " + classes[className]}
          onChange={onSearchTextChange}
          id="outlined-search"
          autoComplete="off"
          label=""
          autoFocus={autoFocus}
          placeholder={placeholder}
          type="search"
          variant="outlined"
          value={searchText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchText ? (
                  <IconButton aria-label="clear" onClick={props.resetSearch}>
                    <ClearIcon />
                  </IconButton>
                ) : (
                  <div></div>
                )}
              </InputAdornment>
            ),
          }}
        />
      </form>
    </div>
  );
};

export default withStyles(searchStyles, { withTheme: true })(Search);

Search.defaultProps = {
  className: "",
  paddingBottom: "",
  searchIcon: true,
  placeholder: "Search Product",
  autoFocus: false,
};
