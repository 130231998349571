import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { Component } from 'react';
import checkicon from "../../../../assets/icons/book_visit/check.png";
import mall_logo from "../../../../assets/icons/splash/mall_logo_bottom.png";
import { styles } from "./BookConfirmStyles";

class BookVisitConfirm extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};

		this.setOK = this.setOK.bind(this);
	}

	componentDidMount() {
	}

	setOK() {
		if (this.props.user) {
			this.props.history.push("/dashboard");
		} else {
			this.props.history.push("/login");
		}
	}

	render() {
		const { classes } = this.props;
		// const getMobileNumber = () => {
		// 	const numbers = this.props.data.mobile_number
		// 	&& this.props.data.mobile_number.split("")
		// 	? this.props.data.mobile_number.split("").map((num, i) => {
		// 		if (i < 2 || i > 7) {
		// 			return num;
		// 		} else {
		// 			return "*";
		// 		}
		// 	}) : [];
		// 	return numbers.join("");
		// }

		// const isLoginUser = this.props.user.mobile_number == this.props.data.mobile_number;

		return (
			<div style={{ display: "flex", alignItems: "center", height: "100%", backgroundColor: '#fff' }}>
				<Grid container>
					<Grid item xs={12} style={{ position: "fixed", top: 15, width: "100%" }}> 
						<div style={{ textAlign: "center" }}>
							<img className={classes.icons1} alt="" src={mall_logo}/>
						</div>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.startwrapper}>
								<img className={classes.icons} alt="" src={checkicon}/>
								<Typography variant="h1" className={classes.heading}>
								Your Booking is Confirmed!!
								</Typography>
								<p className={classes.heading1}>Booking ID: {this.props.data.booking_id}</p>

								{/* {!this.props.data.isInMall
								&& <div className={classes.subheading1} style={{ lineHeight: 1.7 }}>
									<span style={{ color: "#7e7e7e" }}>Congratulations! You have successfully booked a visit to the mall on </span>
								<span>{moment(this.props.data.visit_date, "YYYY-MM-DD").format("Do MMM YYYY")}</span> <span style={{ color: "#7e7e7e" }}>between</span> <span> {this.props.data.slot} </span>
									<span style={{ color: "#7e7e7e" }}>for {this.props.data.number_of_visitors_booked} people.</span>
								</div>}
								{this.props.data.isInMall
								&& <div className={classes.subheading2} style={{ lineHeight: 1.7 }}>
									<span style={{ color: "#7e7e7e" }}>Congratulations! You have successfully booked a visit to the mall </span>
									<strong>right now </strong>
									<span style={{ color: "#7e7e7e" }}>for {this.props.data.number_of_visitors_booked} people.</span>
								</div>
								}

								<Typography variant="h3" className={classes.subHeading}>
									We've also sent you an SMS on +91 {getMobileNumber()} with
									your booking details.
								</Typography> */}
								{<Typography variant="h3" className={classes.subHeading}>
								Congratulations! Your visit confirmation details have been sent to you over SMS or WhatsApp
								</Typography>}
								{<Typography variant="h3" className={classes.subHeading}>
									Thank you for your visit! 
								</Typography>}

						</div>
					</Grid>
					<Grid item xs={12} className={classes.buttonContainer}>
					<div >
					<Button
						variant="contained"
						color="primary"
						className={classes.completeHere}
						type="submit"
						onClick={this.setOK}
					>
						 {this.props.user ? "OK" : "Want rewards for your shopping?"}
					</Button>
				</div>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(BookVisitConfirm);
