import * as React from "react";
import { styles } from "./SliderStyles";
import { withStyles } from "@material-ui/core";

const { Component, Fragment } = React;

class Handle extends Component {
  static defaultProps = {
    disabled: false
  };

  state = {
    mouseOver: false
  };

  onMouseEnter = () => {
    this.setState({ mouseOver: true });
  };

  onMouseLeave = () => {
    this.setState({ mouseOver: false });
  };

  render() {
    const {
      domain: [min, max],
      handle: { id, value, percent },
      disabled,
      getHandleProps,
      unitSide,
      unit,
    } = this.props;
    const { classes } = this.props;

    return (
      <Fragment>
        {!disabled ? (
          <div
            className={classes.tooltipWrapper}
            style={{
              left: `${percent}%`,
            }}
          >
            <div className={classes.tooltip}>
              <span className={classes.tooltiptext}>
                {unitSide === "left" ? unit ? `${unit} ${value}` : value : unit ? `${value} ${unit}` : value}
              </span>
            </div>
          </div>
        ) : null}
        <div
          role="slider"
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          style={{
            left: `${percent}%`,
          }}
          className={classes.tooltipSlider}
          {...getHandleProps(id, {
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave
          })}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Handle);