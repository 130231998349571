import type from "./actionTypes";

let INITIAL_STATE = {
  offersList: [],
  pageNum: 1,
  offerDetails: {},
  noRecords: "",
  hasMore: false,
};

const reducerHandler = (action, state = INITIAL_STATE) => {
  const reducerObject = {
    [type.GET_MERCHANT_OFFERS]: () => ({
      ...state,
      offersList: [...state.offersList, ...action.payLoad.offers],
      pageNum: action.payLoad.page_num,
    }),
    [type.GET_OFFERS_DETAILS]: () => ({
      ...state,
      offerDetails: action.payLoad,
    }),
    [type.CLEAR_OFFERS_DEATILS]: () => ({
      ...state,
      offerDetails: {},
    }),
    [type.CLEAR_OFFERS]: () => ({
      ...state,
      offersList: [],
      pageNum: 1,
      noRecords: "",
      hasMore: false,
    }),
    [type.GET_NEXT_PAGE]: () => ({
      ...state,
      pageNum: action.payLoad,
    }),
    [type.RESET_OFFERS]: () => ({
      ...state,
      offersList: [],
      pageNum: 1,
      offerDetails: {},
      noRecords: "",
      hasMore: false,
    }),
  };
  return (reducerObject[action.type] && reducerObject[action.type]()) || state;
};

const reducerOffers = (action, state) => reducerHandler(state, action);
export default reducerOffers;
