import {
  Button,
  FormControl,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Card,
  Dialog,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { withStyles } from "@material-ui/styles";
import { apiData } from "../../../../../common/common-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { showLoader } from "../../../../../App/redux/action";
import { getStorageItem } from "../../../../../common/localStorage";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { openAlertBox } from "../../../../../common/AlertMessage/AlertMessage";
import { stockAPI } from "../../../../../common/axiosInstance";
import BillImage from "../../../../../components/BillImage/MallImage";
import layout from "../../../../../assets/icons/more/Mall_Map_Default_Image.png";
import down_arrow from "../../../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import { styles } from "./StoreStyles";

const CustomInput = withStyles((theme) => ({
  root: {
    width: "96%",
    position: "relative",
    fontSize: 20,
    padding: "25px 24px 0px 13px",
    textAlign: "left",
    [theme.breakpoints.down(641)]: {
      fontSize: 21,
      padding: "20px 24px 0px 13px",
    },
    [theme.breakpoints.down(481)]: {
      fontSize: 14,
      padding: "11px 24px 0px 13px",
    },
    [theme.breakpoints.down(421)]: {
      fontSize: 14,
      padding: "11px 24px 0px 13px",
    },
    [theme.breakpoints.down(401)]: {
      fontSize: 13,
      padding: "12px 24px 0px 13px",
    },
    [theme.breakpoints.down(381)]: {
      fontSize: 12,
      padding: "9px 24px 0px 13px",
    },
    [theme.breakpoints.down(361)]: {
      fontSize: 12,
      padding: "8px 24px 0px 13px",
    },
    [theme.breakpoints.down(321)]: {
      fontSize: 10,
      padding: "6px 24px 0px 13px",
    },
  },
}))(InputBase);

const ItemTitle = ({ title, classes }) => {
  return (
    <div className={classes.shoptitle}>
      <div style={{ display: "table-cell", verticalAlign: "middle" }}>
        {title}
      </div>
    </div>
  );
};

const ItemContent = ({ name, classes }) => (
  <div className={classes.itemwrapper}>
    <div className={classes.itemiconwrapper}>
      <ArrowRightIcon className={classes.itemicon} />
    </div>
    <div className={classes.itemcontent}>{name}</div>
  </div>
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "50%",
    },
  },
};

const mapDispatchToProps = {
  showLoader,
};

class MallMap extends Component {
  state = {
    selectedlocation: 0,
    selectedfloor: 0,
    malllayout: null,
    floors: [],
    posx: null,
    posy: null,
    open: false,
  };

  componentDidMount() {
    const { locations, floors, selectedFloor } = this.props;
    this.setState(
      {
        selectedlocation: locations[0] ? locations[0].id : 0,
        selectedfloor: selectedFloor
          ? selectedFloor
          : floors[0]
          ? floors[0].floor_id
          : 0,
        floors,
      },
      () => {
        this.getMallLayout();
      }
    );
  }

  getMallLayout() {
    const data = new FormData();
    data.append("merchant_id", apiData.merchant_id);
    data.append("platform", apiData.platform);
    data.append("location_id", this.state.selectedlocation);
    data.append("floor_id", this.state.selectedfloor);
    if (this.state.selectedlocation && this.state.selectedfloor) {
      this.props.showLoader(true);
      stockAPI(data, "POST", "/getMallLayout")
        .then((response) => {
          this.props.showLoader(false);
          if (response) {
            this.setState(
              {
                malllayout: null,
              },
              () => {
                this.setState({
                  malllayout: response,
                });
              }
            );
          }
        })
        .catch((err) => {
          openAlertBox(err && err.message, "error");
        });
    }
  }

  getFloorsAndCategories() {
    const data = new FormData();
    data.append("merchant_id", apiData.merchant_id);
    data.append("platform", apiData.platform);
    data.append("location_id", this.state.selectedlocation);
    data.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
    if (this.state.selectedlocation) {
      stockAPI(data, "POST", "/getStoreLocatorFloorCatDetails")
        .then((response) => {
          if (response) {
            this.setState(
              {
                floors: response.floors || [],
                selectedfloor: response.floors[0]
                  ? response.floors[0].floor_id
                  : 0,
              },
              () => {
                this.getMallLayout();
              }
            );
          }
        })
        .catch((err) => {
          openAlertBox(err && err.message, "error");
        });
    }
  }

  prevFloor = () => {
    const { selectedfloor, floors } = this.state;
    const index = floors.findIndex(
      (f, i) => f.floor_id === parseInt(selectedfloor)
    );
    const sf = floors.find((f, i) => i === index - 1);
    this.setState(
      {
        selectedfloor: sf ? sf.floor_id : "",
      },
      () => {
        this.getMallLayout();
      }
    );
  };

  nextFloor = () => {
    const { selectedfloor, floors } = this.state;
    const index = floors.findIndex(
      (f, i) => f.floor_id === parseInt(selectedfloor)
    );
    const sf = floors.find((f, i) => i === index + 1);
    this.setState(
      {
        selectedfloor: sf ? sf.floor_id : "",
      },
      () => {
        this.getMallLayout();
      }
    );
  };

  zoomChange = (x, y) => {
    this.setState({
      posx: x,
      posy: y,
    });
  };

  componentWillUnmount() {
    this.setState({
      open: false,
      imageSrc: "",
    });
  }

  render() {
    const { classes, locations } = this.props;
    const { selectedlocation, selectedfloor, malllayout, floors } = this.state;
    const arr = malllayout ? malllayout.categories || [] : [];
    const leftside =
      arr.length > 1
        ? arr.filter((ele, i) => i < parseInt(arr.length / 2))
        : [...arr];
    const rightside =
      arr.length > 1
        ? arr.filter((ele, i) => i >= parseInt(arr.length / 2))
        : [];
    const disableprevfloor =
      floors.length === 0 ||
      floors.find((f, i) => i === 0 && f.floor_id === parseInt(selectedfloor))
        ? true
        : false;
    const disablenextfloor =
      floors.length === 0 ||
      floors.find(
        (f, i) =>
          i === floors.length - 1 && f.floor_id === parseInt(selectedfloor)
      )
        ? true
        : false;
    return (
      <div style={{ height: "100%", background: "#f5f5f5" }}>
        <div>
          <div className={classes.cardpadding}>
            <div className={classes.select_box}>
              <FormControl fullWidth className={classes.formControl}>
                <Select
                  value={selectedlocation}
                  onChange={(e) =>
                    this.setState(
                      {
                        selectedlocation: e.target.value,
                        selectedfloor: 0,
                        malllayout: {},
                        floors: [],
                      },
                      () => {
                        this.getFloorsAndCategories();
                      }
                    )
                  }
                  input={<CustomInput />}
                  IconComponent={() => (
                    <img
                      className={classes.down_icon}
                      src={down_arrow}
                      alt=""
                    />
                  )}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value={0}>
                    Select location
                  </MenuItem>
                  {locations.map((loc) => (
                    <MenuItem key={loc.id} value={loc.id}>
                      {loc.location_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.cardpadding1}>
            <Grid container spacing={1}>
              <Grid item xs={2} sm={2}>
                <Button
                  className={classes.arrowbtn}
                  onClick={this.prevFloor}
                  disabled={disableprevfloor}
                >
                  <ChevronLeftIcon className={classes.arrowicon} />
                </Button>
              </Grid>
              <Grid item xs={8} sm={8}>
                <div className={classes.select_box}>
                  <FormControl fullWidth className={classes.formControl}>
                    <Select
                      value={selectedfloor}
                      onChange={(e) => {
                        this.setState(
                          {
                            selectedfloor: e.target.value,
                            malllayout: {},
                          },
                          () => {
                            this.getMallLayout();
                          }
                        );
                      }}
                      input={<CustomInput />}
                      IconComponent={() => (
                        <img
                          className={classes.down_icon}
                          src={down_arrow}
                          alt=""
                        />
                      )}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled value={0}>
                        Select floor
                      </MenuItem>
                      {floors.map((f) => (
                        <MenuItem key={f.floor_id} value={f.floor_id}>
                          {f.floor_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Button
                  className={classes.arrowbtn}
                  onClick={this.nextFloor}
                  disabled={disablenextfloor}
                >
                  <ChevronRightIcon className={classes.arrowicon} />
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.mallwrapper}>
          <div>
            {malllayout && malllayout.floor_image ? (
              <img
                src={malllayout.floor_image}
                alt={"Floor layout"}
                className={classes.malllayout}
                onClick={() =>
                  this.setState({
                    open: true,
                    imageSrc: malllayout.floor_image,
                  })
                }
              />
            ) : (
              <img
                src={layout}
                alt={"Floor layout"}
                className={classes.malllayout}
                onClick={() =>
                  this.setState({
                    open: true,
                    imageSrc: layout,
                  })
                }
              />
            )}
          </div>
          <div className={classes.cardpadding} style={{ paddingTop: 0 }}>
            {arr.length > 0 && (
              <Card className={classes.mallpadding}>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "4vw",
                    marginBottom: "1.5vh",
                  }}
                >
                  {malllayout ? malllayout.floor_name : ""}
                </div>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={6}>
                    {leftside.map((el) => (
                      <div key={el.category_name}>
                        <ItemTitle title={el.category_name} classes={classes} />
                        {el.brands.map((item) => (
                          <ItemContent
                            name={item.brand_name}
                            classes={classes}
                          />
                        ))}
                      </div>
                    ))}
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    {rightside.map((el) => (
                      <div key={el.category_name}>
                        <ItemTitle title={el.category_name} classes={classes} />
                        {el.brands.map((item) => (
                          <ItemContent
                            name={item.brand_name}
                            classes={classes}
                          />
                        ))}
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </Card>
            )}
          </div>
        </div>
        <Dialog
          open={this.state.open}
          //   keepMounted
          onClose={() =>
            this.setState({
              open: false,
              imageSrc: "",
            })
          }
          maxWidth="md"
          className={classes.dialogModal}
        >
          <BillImage
            multi={false}
            open={this.state.open}
            imgSrc={this.state.imageSrc || layout}
            okBtnHandler={() =>
              this.setState({
                open: false,
                imageSrc: "",
              })
            }
          />
        </Dialog>
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(MallMap));
