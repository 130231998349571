import { primaryOrange } from "../../../../components/UI/Theme";

export const styles = (theme) => {
  return {
    top: {
      backgroundColor: theme.palette.common.globalBackground,
      display: "block",
      textAlign: "center",
      height: "auto",
      paddingBottom: "152px",
      [theme.breakpoints.down("641")]: {
        paddingBottom: "140px",
      },
      [theme.breakpoints.down("610")]: {
        paddingBottom: "136px",
      },
      [theme.breakpoints.down("481")]: {
        paddingBottom: "96px",
      },
      [theme.breakpoints.down("381")]: {
        paddingBottom: "76px",
      },
      [theme.breakpoints.down("321")]: {
        paddingBottom: "66px",
      },
    },
    top1: {
      backgroundColor: theme.palette.common.globalBackground,
      display: "block",
      textAlign: "center",
      height: "auto",
      paddingBottom: "86px",
      [theme.breakpoints.down("641")]: {
        paddingBottom: "82px",
      },
      [theme.breakpoints.down("481")]: {
        paddingBottom: "64px",
      },
      [theme.breakpoints.down("421")]: {
        paddingBottom: "54px",
      },
      [theme.breakpoints.down("381")]: {
        paddingBottom: "50px",
      },
      [theme.breakpoints.down("361")]: {
        paddingBottom: "48px",
      },
      [theme.breakpoints.down("321")]: {
        paddingBottom: "43px",
      },
    },
    pageContainer: {
      display: "block",
      textAlign: "center",
      height: "100%",
      padding: "30px",
      [theme.breakpoints.down("610")]: {
        padding: "28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
    },
    pageItem: {
      margin: "0 auto",
      width: "100%",
      display: "block",
      maxWidth: "660px",
      justifyContent: "center",
    },
    main_page_item: {
      height: "calc(100% - 64px)",
    },

    tabContainer: {
      margin: "0 auto",
      marginBottom: theme.spacing(3),
      width: "100%",
      "& .MuiTab-root": {
        maxWidth: "660px",
        minHeight: "92px",
        [theme.breakpoints.down(641)]: {
          minHeight: "80px",
        },
        [theme.breakpoints.down(481)]: {
          minHeight: "54px",
        },
        [theme.breakpoints.down(381)]: {
          minHeight: "46px",
        },
        [theme.breakpoints.down(321)]: {
          minHeight: "40px",
        },
      },
      "& .MuiTabs-root": {
        minHeight: "92px",

        [theme.breakpoints.down(641)]: {
          minHeight: "80px",
        },
        [theme.breakpoints.down(481)]: {
          minHeight: "54px",
        },
        [theme.breakpoints.down(381)]: {
          minHeight: "46px",
        },
        [theme.breakpoints.down(321)]: {
          minHeight: "40px",
        },
      },
    },
    overiteTab: {
      width: "100% !important",
    },
    tab: {
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textTransform: "none",
      textAlign: "center",
      padding: theme.spacing(1),
      opacity: 1,
      fontSize: "22px",
      lineHeight: "18px",
      color: "#000000",
      width: "50%",
      height: "100%",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    selected: {
      background: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
    root: {
      height: "100%",
      maxWidth: "660px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      margin: "0 auto",
    },
    doneBtn: {
      ...theme.palette.typography.doneButtons,
      width: "100%",
      height: "92px",
      fontSize: "22px",
      lineHeight: "18px",
      marginBottom: "0px !important",
      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "16px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "14px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
        fontSize: "12.5px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    info_box: {
      width: "100%",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textTransform: "none",
      textAlign: "center",
      marginBottom: theme.spacing(3),
      "& $main_title": {
        fontWeight: "600",
        color: theme.palette.typography.texts.color,
        fontSize: "22px",
        lineHeight: "1.5",
        [theme.breakpoints.down(641)]: {
          fontSize: "18px",
          lineHeight: "1.44",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "16px",
          lineHeight: "1.5",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "15.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "15px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "14.5px",
          lineHeight: "1.29",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "12px",
          lineHeight: "1.33",
        },
      },
      "& $subtitle": {
        fontSize: "18px",
        lineHeight: "1.67",
        color: theme.palette.typography.texts.subHeading,
        [theme.breakpoints.down(641)]: {
          fontSize: "16px",
          lineHeight: "1.66",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "14px",
          lineHeight: "1.43",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "13.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "12.5px",
          lineHeight: "1.25",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "10px",
          lineHeight: "1.35",
        },
      },
      "& $subtitle_learn": {
        fontStyle: "italic",
        color: primaryOrange,
        cursor: "pointer",
        fontSize: "16px",
        lineHeight: "1.67",
        [theme.breakpoints.down(641)]: {
          fontSize: "14px",
          lineHeight: "1.66",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "12px",
          lineHeight: "1.43",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "11.5px",
          lineHeight: "1.25",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "11px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "10.5px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "10px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "8px",
          lineHeight: "1.35",
        },
      },
    },
    main_title: {},
    subtitle: {},
    subtitle_learn: {},
    dialogModal: {
      "& .MuiDialog-paperWidthMd": {
        width: "100%",
      },
      "& .MuiDialog-paper": {
        margin: "15px",
      },
    },
    brLTB: {
      "& .MuiTab-root": {
        borderBottomLeftRadius: "4px",
        borderTopLeftRadius: "4px",
      },
    },
    brRTB: {
      "& .MuiTab-root": {
        borderBottomRightRadius: "4px",
        borderTopRightRadius: "4px",
      },
    },
    lastIndicator: {
      [theme.breakpoints.down(641)]: {
        width: "220px !important",
      },
      [theme.breakpoints.down(491)]: {
        width: "167px !important",
      },
      [theme.breakpoints.down(381)]: {
        width: "168px !important",
      },
      [theme.breakpoints.down(361)]: {
        width: "160px !important",
      },
      [theme.breakpoints.down(321)]: {
        width: "142px !important",
      },
      [theme.breakpoints.down(316)]: {
        width: "auto !important",
      },
    },
    firstIndicator: {
      marginLeft: "4px !important",
      [theme.breakpoints.down(641)]: {
        width: "220px !important",
      },
      [theme.breakpoints.down(491)]: {
        width: "167px !important",
      },
      [theme.breakpoints.down(381)]: {
        width: "168px !important",
      },
      [theme.breakpoints.down(361)]: {
        width: "160px !important",
      },
      [theme.breakpoints.down(321)]: {
        width: "142px !important",
      },
      [theme.breakpoints.down(316)]: {
        width: "auto !important",
      },
    },
    plusBtnContainer: {
      paddingRight: "30px",
      [theme.breakpoints.down("610")]: {
        paddingRight: "28px",
      },
      [theme.breakpoints.down(481)]: {
        paddingRight: "21px",
      },
      [theme.breakpoints.down(381)]: {
        paddingRight: "15px",
      },
      [theme.breakpoints.down(321)]: {
        paddingRight: "13px",
      },
    },
    btnContainer: {
      margin: "0px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "152px",
      padding: "30px",
      position: "fixed",
      width: "100%",
      bottom: "86px",
      [theme.breakpoints.down(641)]: {
        height: "140px",
        bottom: "82px",
      },
      [theme.breakpoints.down(610)]: {
        height: "136px",
        padding: "28px",
      },
      [theme.breakpoints.down(481)]: {
        height: "96px",
        padding: "21px",
        bottom: "64px",
      },
      [theme.breakpoints.down(421)]: {
        bottom: "54px",
      },
      [theme.breakpoints.down(381)]: {
        height: "76px",
        padding: "15px",
        bottom: "50px",
      },
      [theme.breakpoints.down(361)]: {
        bottom: "48px",
      },
      [theme.breakpoints.down(321)]: {
        height: "66px",
        padding: "13px",
        bottom: "43px",
      },
    },
    // allFilesContainer: {
    // 	marginBottom: '30px'
    // },
    active: {
      visibility: "visible",
    },
    hidden: {
      visibility: "hidden",
    },
    mTop_10: {
      marginTop: "10px",
    },
    rm_pd: {
      paddingBottom: "0 !important",
    },
  };
};
