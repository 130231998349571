import { getStorageItem } from "./localStorage";
export const WHATSAPP_LINK = "https://wa.me/91";
export const WHATSAPP_SHARING_LINK = "https://api.whatsapp.com/send";
export const headerScreenLabels = {
  dashBoard: "dashBoard",
  myMembership: "myMembership",
  submitBill: "submitBill",
  billSummary: "billSummary",
  redeem: "redeem",
  myCart: "myCart",
  orderSummary: "orderSummary",
  transactions: "transactions",
  offers: "offers",
  more: "more",
  aboutUs: "aboutUs",
  contactUs: "contactUs",
  storeLocator: "storeLocator",
  showmall: "showmall",
  termsOfUse: "termsOfUse",
  howitworks: "howitworks",
  faqs: "faqs",
  feedback: "feedback",
  myprofile: "myprofile",
  editprofile: "editprofile",
  billUploadProgress: "billUploadProgress",
  billUploadGuidelines: "billUploadGuidelines",
  notifications: "notifications",
};

const { REACT_APP_BASE_URL, NODE_ENV } = process.env;
// console.warn(REACT_APP_BASE_URL);
console.warn(NODE_ENV);
export const apiData = {baseUrl: NODE_ENV === "development" ? "https://stage-api.technovatechnologys.com" : REACT_APP_BASE_URL,
  merchant_id: 10071, //10068
  platform: 4,
  appVersion: 4.0,
  login_through: 2,
  customer_id: getStorageItem("user") && JSON.parse(getStorageItem("user")).id,
  // mobile_number: getStorageItem('user') && JSON.parse(getStorageItem('user')).mobile_number
};
// export const appData = {
// 	pointShortName: getStorageItem('pointShortName') ? getStorageItem('pointShortName') : 'P',
// 	currencyCode: getStorageItem('currencyCode') ? getStorageItem('currencyCode') : '₹'
// }
