import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import Header from "../../../components/Layout/Header/HeaderSubmitBill";
import { headerScreenLabels, apiData } from "../../../common/common-types";
import MyRoute from "../../../hoc/MyRoute";
import OfferDetails from "./components/OfferDetails";
import { withStyles } from "@material-ui/core";
import { getOfferDetails, clearOfferDetails } from "../Offers/redux/actions";
import { getStorageItem } from "../../../common/localStorage";
import { showLoader } from "../../../App/redux/action";

const mapStateToProps = (state) => {
  return {
    offerDetails: state.reducerOffers.offerDetails,
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
  };
};

const mapDispatchToProps = {
  getOfferDetails,
  showLoader,
  clearOfferDetails,
};

const styles = (theme) => {
  return {
    root: {
      width: "100%",
      height: "100%",
      margin: "0 auto",
      backgroundColor: theme.palette.common.globalBackground,
      // overflow: "hidden",
      // overflowY: "auto",
    },
  };
};
class OfferDetailsContainer extends Component {
  componentDidMount = async () => {
    const { getOfferDetails, showLoader } = this.props;

    const offer_id = window.location.pathname.split("/")[2];
    if (getStorageItem("user")) {
      showLoader(true);
      const bodyobj = new URLSearchParams();
      bodyobj.append("offer_id", parseInt(offer_id));
      bodyobj.append("merchant_id", apiData.merchant_id);
      bodyobj.append("customer_id", JSON.parse(getStorageItem("user")).id);
      bodyobj.append("platform", apiData.platform);

      await getOfferDetails(bodyobj);
      showLoader(false);
    }
  };

  componentWillUnmount() {
    const { clearOfferDetails } = this.props;
    clearOfferDetails();
  }
  render() {
    const { classes, offerDetails } = this.props;
    let offerDetailsProps = {
      offerDetails,
    };
    return (
      <Fragment>
        {/* <Header
          screenLabel={headerScreenLabels.offers}
          backBtn={true}
          heading="Offers Details"
        />
        <AlertMessage />
        <MyRoute
          fullHeight={true}
          billUploadStatus={this.props.uploadingStatus}
        > */}
        <div className={classes.root}>
          <OfferDetails {...offerDetailsProps} />
        </div>
        {/* </MyRoute> */}
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(OfferDetailsContainer));
