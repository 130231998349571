export const styles = (theme) => {
  return {
    root: {
      margin: "0px auto",
      padding: "0px",
      height: "140px",
      display: "block",
      width: "660px",
      position: "relative",
      backgroundColor: theme.palette.common.globalBackground,
      [theme.breakpoints.down(641)]: {
        width: "100%",
        height: "160px",
      },
      [theme.breakpoints.down(481)]: {
        width: "100%",
        height: "156px",
      },
      [theme.breakpoints.down(421)]: {
        width: "100%",
        height: "152px",
      },
      [theme.breakpoints.down(381)]: {
        width: "100%",
        height: "137px",
      },
      [theme.breakpoints.down(361)]: {
        width: "100%",
        height: "134px",
      },
      [theme.breakpoints.down(321)]: {
        width: "100%",
        height: "118px",
      },
      "& .MuiListItemIcon-root": {
        minWidth: "26px   !important ",
        [theme.breakpoints.between(380, 480)]: {
          marginTop: "9px",
        },
        [theme.breakpoints.down(370)]: {
          marginTop: "8px",
        },
        [theme.breakpoints.down(361)]: {
          marginTop: "6px",
        },
      },
    },
    common_padding: {
      padding: "30px",
      [theme.breakpoints.down("610")]: {
        padding: "28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
    },
    offer_details_container: {
      position: "relative",
      marginTop: "-65px",
      borderRadius: "4px",
      [theme.breakpoints.down(421)]: {
        marginTop: "-55px",
      },
      [theme.breakpoints.down(381)]: {
        marginTop: "-50px",
      },
      [theme.breakpoints.down(321)]: {
        marginTop: "-45px",
      },
    },
    content: {
      position: "relative",
      marginBottom: "5px",
      paddingBottom: "0",
      marginTop:'40px',
      [theme.breakpoints.down(421)]: {
        paddingBottom: "0",
      },
      [theme.breakpoints.down(381)]: {
        paddingBottom: "0",
      },
      [theme.breakpoints.down(321)]: {
        paddingBottom: "0",
      },
    },
    tab_container: {
      position: "relative",
      paddingTop: "0",
      paddingBottom: 0,
      [theme.breakpoints.down(421)]: {
        paddingTop: "0",
      },
      [theme.breakpoints.down(381)]: {
        paddingTop: "0",
      },
      [theme.breakpoints.down(321)]: {
        paddingTop: "0",
      },
    },
    pd_tb: {
      paddingTop: "30px",
      paddingBottom: "30px",
      [theme.breakpoints.down("610")]: {
        paddingTop: "28px",
        paddingBottom: "28px",
      },
      [theme.breakpoints.down("481")]: {
        paddingTop: "21px",
        paddingBottom: "21px",
      },
      [theme.breakpoints.down("381")]: {
        paddingTop: "15px",
        paddingBottom: "15px",
      },
      [theme.breakpoints.down("321")]: {
        paddingTop: "13px",
        paddingBottom: "13px",
      },
    },
    pageItem: {
      width: "100%",
      display: "block",
      justifyContent: "center",
      marginBottom: "15px",
      "& .MuiSvgIcon-root": {
        fill: theme.palette.primary.main,
        fontSize: "10px",
        paddingBottom: "0 !important",
        paddingTop: "0 !important",
      },
    },
    listItem: {
      "& .MuiListItem-root": {
        paddingBottom: "2px !important",
        paddingTop: "2px !important",
        display: "-webkit-inline-box !important",
        width: "92% !important",
        textAlign: "justify",
      },
      "& .MuiTypography-root": {
        padding: "0px",
        display: "block",
        width: "100%",
        fontSize: "18px",
        userSelect: "none",
        fontWeight: "500",
        color: theme.palette.typography.texts.color,
        lineHeight: "2",
        wordBreak: "break-all",
        [theme.breakpoints.down(641)]: {
          fontSize: "16px",
          lineHeight: "2",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "14px",
          lineHeight: "1.5",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "13.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "12.5px",
          lineHeight: "1.33",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "10px",
        },
      },
    },
    tab: {
      textTransform: "none",
      textAlign: "center",
      padding: theme.spacing(1),
      opacity: 1,
      color: "#000000",
      width: "50%",
      maxWidth: "50%",
      display: "block",
      float: "left",
      height: "92px",
      fontSize: "22px",
      lineHeight: "1.8",
      [theme.breakpoints.down("641")]: {
        fontSize: "18px",
        lineHeight: "1.78",
        height: "80px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
        lineHeight: "1.71",
        height: "54px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        lineHeight: "1.5",
        height: "46px",
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
        lineHeight: "1.33",
        height: "40px",
      },
    },
    selected: {
      background: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
    lastIndicator: {
      width: "137px !important",
    },
    firstIndicator: {
      width: "137px !important",
      marginLeft: "4px !important",
    },
    brLTB: {
      "& .MuiTab-root": {
        borderBottomLeftRadius: "4px",
        borderTopLeftRadius: "4px",
      },
    },
    brRTB: {
      "& .MuiTab-root": {
        borderBottomRightRadius: "4px",
        borderTopRightRadius: "4px",
      },
    },
    tabContainer: {
      margin: "0 auto",
      width: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("641")]: {
        marginTop: "12px",
        width: "100%",
      },
      [theme.breakpoints.down("461")]: {
        marginTop: "12px",
        width: "100%",
      },
      [theme.breakpoints.down("381")]: {
        marginTop: "12px",
        width: "100%",
      },
      [theme.breakpoints.down("321")]: {
        marginTop: "12px",
        width: "100%",
      },
    },
    descriptionWrapper: {
      margin: "0px",
      marginBottom: "15px",
      display: "block",
      width: "100%",
      fontSize: "24px",
      lineHeight: "2",
      fontWeight: "400",
      color: theme.palette.typography.texts.color,
      "& span": {
        padding: "0px",
        display: "block",
        width: "100%",
        userSelect: "none",
        fontSize: "20px",
        fontWeight: "500",
        color: theme.palette.typography.texts.color,
        lineHeight: "2",
        [theme.breakpoints.down(641)]: {
          fontSize: "18px",
          lineHeight: "2",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "16px",
          lineHeight: "1.33",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "15.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "15px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "14.5px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "12px",
        },
      },
      "& ol": {
        marginTop: "5px",
        marginBottom: 0,
      },
      "& p": {
        margin: 0,
      },
    },

    descriptionClass: {
      textAlign: "justify",
      marginTop: "7px",
      marginBottom: "0px",
      userSelect: "none",
      width: "100%",
      fontSize: "18px",
      lineHeight: "1.5",
      fontWeight: "500",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "1.56",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        lineHeight: 1.33,
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.33",
      },
    },
    brand: {
      fontFamily: "Montserrat",
      fontSize: "22px",
      lineHeight: "1.25",
      userSelect: "none",
      marginBottom: "2px",
      width: "100%",
      fontWeight: "normal",
      overflow: "hidden",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      [theme.breakpoints.down(641)]: {
        fontSize: "20px",
        lineHeight: "1.4",
        // height: "21px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "18px",
        // width: "175px",
        lineHeight: "1.3",
        // height: "19px",
      },
      [theme.breakpoints.down(430)]: {
        fontSize: "16px",
        // height: "17px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "15.5px",
        // height: "15px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "15px",
        // height: "15px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "14.5px",
        // height: "15px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "14px",
        // height: "15px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "12px",
        // height: "13px",
        lineHeight: "1.23",
      },
    },
    title: {
      fontFamily: "Montserrat",
      fontSize: "20px",
      lineHeight: "1.25",
      userSelect: "none",
      width: "100%",
      fontWeight: "normal",
      overflow: "hidden",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "1.4",
        // height: "21px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        // width: "175px",
        lineHeight: "1.3",
        // height: "19px",
      },
      [theme.breakpoints.down(430)]: {
        fontSize: "14px",
        // height: "17px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
        // height: "15px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
        // height: "15px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        // height: "15px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
        // height: "15px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        // height: "13px",
        lineHeight: "1.23",
      },
    },
    sub_title: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      userSelect: "none",
      lineHeight: "1.4",
      width: "100%",
      margin: "5px 0",
      fontWeight: "500",
      overflow: "hidden",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      [theme.breakpoints.down(641)]: {
        fontSize: "22px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "20px",
        lineHeight: "1.4",
      },
      [theme.breakpoints.down(430)]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "17.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "17px",
        lineHeight: "1.2",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "16.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "14px",
        lineHeight: "1.33",
      },
    },
    coupon: {
      margin: "24px 0px 25px 0px",
      padding: "0px",
      display: "block",
      width: "100%",
      position: "relative",
      height: "160px",

      [theme.breakpoints.down(641)]: {
        margin: "15px 0px 25px 0px",
      },
      [theme.breakpoints.down(481)]: {
        margin: "8px 0px 17px 0px",
      },
      [theme.breakpoints.down(381)]: {
        margin: "5px 0px 14px 0px",
      },
      [theme.breakpoints.down(321)]: {
        margin: "5px 0px 12px 0px",
      },
    },
    coupons_box: {
      padding: "10px 10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      float: "right",
      borderRadius: "4px",
      border: "dashed 2px #a7da2b",
      cursor: "pointer",
      width: "fit-content",
      height: "100%",
      [theme.breakpoints.down(421)]: {
        padding: "5px 7px",
      },
      // [theme.breakpoints.down(381)]: {
      //   padding: "5px 7px",
      // },
      [theme.breakpoints.down(321)]: {
        padding: "4px 6px",
      },
    },
    coupons: {
      color: theme.palette.typography.texts.color,
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "600",
      // padding: "3px 0px",
      [theme.breakpoints.down("641")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "17.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "16.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "16px",
      },
    },
    date_value: {
      color: theme.palette.typography.texts.sellingPriceColor,
      userSelect: "none",
    },
    date_title: {
      color: theme.palette.typography.texts.color,
      fontFamily: "Montserrat",
      userSelect: "none",
      fontWeight: "500",
      overflow: "hidden",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontSize: "20px",
    },
    common_fontSize: {
      fontSize: "18px",
      lineHeight: "1.5",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "1.56",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        lineHeight: "1.33",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.33",
      },
    },
    // image_container: {
    //   height: "160px",
    // },
    date: {
      display: "flex",
      flexDirection: "column",
      marginTop: "2px",
    },
    mB_6: {
      marginBottom: "6px",
    },
    mB_10: {
      marginBottom: "10px",
    },
    noSelect: {
      "-webkit-touch-callout": "none",
      "-webkit-user-select": "none",
      "-khtml-user-select": "none",
      "-moz-user-select": "none",
      "-ms-user-select": "none",
      userSelect: "none",
      " -webkit-tap-highlight-color": "transparent",
    },
    mT_20: {
      marginTop: "17px",
      [theme.breakpoints.down(321)]: {
        marginTop: "20px",
      },
    },
  };
};
