import React, { Component } from "react";
import CMicon from "../../assets/icons/comingSoon/Empty_Cart.svg";
import CMBGicon from "../../assets/icons/comingSoon/coming-soon-bg.svg";
import { Typography, Grid, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { color } from "../UI/Theme";

const styles = (theme) => {
  return {
    coming_soon: {
      textAlign: "center",
      color: color,
      fontSize: "30px",
      [theme.breakpoints.down("641")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
    },
    coming_soon_img: {
      width: "100%",
      height: "100%",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      objectPosition: "center",
      objectFit: "contain",
    },
    bgCss: {
      background: `url(.${CMBGicon}) no-repeat center / contain`,
      display: "flex",
      justifyContent: "center",
      position: "relative",
      left: "-10px",
      width: "100%",
      height: "560px",
      [theme.breakpoints.down(481)]: {
        height: "360px",
      },
      [theme.breakpoints.down(421)]: {
        height: "340px",
      },
      [theme.breakpoints.down(321)]: {
        height: "260px",
      },
    },
    iconCss: {
      width: "40%",
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${CMicon})`,
      "mask-image": `url(.${CMicon})`,
      mask: `url(.${CMicon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${CMicon}) no-repeat center / contain`,
    },
    emptyCart: {
      display: "block",
      fontFamily: "Montserrat",
      textAlign: "center",
      margin: "10px",
      fontSize: "16px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "12px",
      },
    },
    button: {
      ...theme.palette.typography.doneButtons,
      minHeight: "92px",
      width: "auto",
      fontFamily: "Montserrat",
      margin: "18px auto",
      [theme.breakpoints.down(641)]: {
        minHeight: "80px",
      },
      [theme.breakpoints.down(481)]: {
        minHeight: "54px",
        fontSize: "16px",
      },
      [theme.breakpoints.down(381)]: {
        minHeight: "46px",
        fontSize: "14px",
      },
      [theme.breakpoints.down(321)]: {
        minHeight: "40px",
      },
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    cartEmpty: {
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      width: "660px",
      borderRadius: "6px",
      overflow: "hidden",
      // boxShadow: "0px 0px 10px #e9e9e9",
      position: "relative",
      backgroundColor: "transparent",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        margin: "0px 0px",
        // height: "4.5rem",
      },
      [theme.breakpoints.between(559, 610)]: {
        width: "100%",
      },
    },
  };
};

class CartIsEmpty extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflowX: "hidden",
          // overflowY: "auto",
        }}
      >
        <div className={classes.bgCss}>
          <span className={classes.coming_soon_img + " " + classes.iconCss} />
        </div>
        <Grid className={classes.cartEmpty}>
          <Typography className={classes.emptyCart}>
            Oops! Your cart is empty!
          </Typography>
          <Typography className={classes.emptyCart}>
            Looks like you haven't added anything to your cart yet.
          </Typography>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => this.props.history.push("/redeem")}
          >
            Continue Shopping
          </Button>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CartIsEmpty);
