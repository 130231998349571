import { lightgreen, whiteColor, lightGrey } from '../../../../components/UI/Theme';

export const styles = (theme) => {
	return {
		startwrapper: {
			objectFit: "contain",
			backgroundColor: theme.palette.cards.backgroundColor,
			maxWidth: "660px",
			padding: "30px",
			margin: "0 auto",
			textAlign: "center",
			[theme.breakpoints.down(641)]: {
			  padding: "28px",
			  // maxWidth: "588px",
			},
			[theme.breakpoints.down(481)]: {
			  padding: "21px",
			  // maxWidth: "440px",
			},
			[theme.breakpoints.down(381)]: {
			  padding: "15px",
			  // maxWidth: "330px",
			},
			[theme.breakpoints.down(321)]: {
			  padding: "13px",
			  // maxWidth: "294px",
			},
		},
		heading: {
				fontSize: "32px",
				fontWeight: "bold",
				fontStretch: "normal",
				fontStyle: "normal",
				letterSpacing: "normal",
				textAlign: "center",
				color: theme.palette.typography.texts.color,
				marginTop: "0px",
				lineHeight: 1.6,
				padding: "0px 40px",
				marginBottom: "18px",
				[theme.breakpoints.down(641)]: {
					fontSize: "28px",
					lineHeight: 1.6,
					padding: "0px 40px",
					marginBottom: "18px",
					marginTop: "0px",
				},
				[theme.breakpoints.down(481)]: {
					fontSize: "18px",
					marginTop: "0px",
					lineHeight: 1.6,
					padding: "0px 40px",
					marginBottom: "12px"
				},
				[theme.breakpoints.down(421)]: {
					fontSize: "17.5px",
					lineHeight: 1.6,
					marginTop: "0px",
					padding: "0px 26px",
					marginBottom: "12px"
				},
				[theme.breakpoints.down(401)]: {
					fontSize: "17px",
					marginTop: "0px",
					lineHeight: 1.6,
					padding: "0px 20px",
					marginBottom: "12px"
				},
				[theme.breakpoints.down(381)]: {
					fontSize: "16.5px",
					marginTop: "0px",
					lineHeight: 1.6,
					padding: "0px 20px",
					marginBottom: "12px"
				},
				[theme.breakpoints.down(361)]: {
					fontSize: "16px",
					marginTop: "0px",
					lineHeight: 1.6,
					padding: "0px 18px",
					marginBottom: "7px"
				},
				[theme.breakpoints.down(321)]: {
					fontSize: 15,
					marginTop: "00px",
					lineHeight: 1.6,
					padding: "0px 9px",
					marginBottom: "7px"
				},
		},
    subHeading: {
			height: "53px",
			width: "100%",
			fontSize: "18px",
			fontWeight: "normal",
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: "1.64",
			letterSpacing: "normal",
			textAlign: "center",
			color: lightGrey,
			margin: "24px 0px 0px 0px",
			[theme.breakpoints.down(641)]: {
					height: "54px",
					lineHeight: "1.64",
					fontSize: "16px",
					margin: "20px 0px 0px 0px",
			},
			[theme.breakpoints.down(481)]: {
					height: "41px",
					lineHeight: "1.5",
					fontSize: "14px",
					margin: "14px 0px 0px 0px",
			},
			[theme.breakpoints.down(421)]: {
					fontSize: "13px",
			},
			[theme.breakpoints.down(401)]: {
					fontSize: "12px",
			},
			[theme.breakpoints.down(381)]: {
					height: "31px",
					lineHeight: "1.5",
					fontSize: "12px",
					margin: "12px 0px 0px 0px",
			},
			[theme.breakpoints.down(361)]: {
					fontSize: "11px",
			},
			[theme.breakpoints.down(321)]: {
					height: "27px",
					fontSize: "10px",
					lineHeight: "1.6",
					margin: "10px 0px 0px 0px",
			},
		},
		icons: {
			width: "260px",
			height: "150px",
			marginRight: "50px",
			"mask-size ": "cover",
			objectFit: "contain",
			[theme.breakpoints.down(641)]: {
				width: "260px",
				height: "150px",
				marginRight: "50px"
			},
			[theme.breakpoints.down(481)]: {
			  width: "190px",
				height: "120px",
				marginRight: "40px"
			},
			[theme.breakpoints.down(421)]: {
				width: "180px",
				height: "120px",
				marginRight: "37px"
			},
			[theme.breakpoints.down(401)]: {
				width: "170px",
				height: "110px",
				marginRight: "34px"
			},
			[theme.breakpoints.down(381)]: {
				width: "160px",
				height: "90px",
				marginRight: "31px"
			},
			[theme.breakpoints.down(361)]: {
				width: "150px",
				height: "80px",
				marginRight: "28px"
			},
			[theme.breakpoints.down(321)]: {
			  width: "130px",
				height: "70px",
				marginRight: "23px"
			},
		},
		btnicons: {
			width: "42px",
			marginRight: "18px",
			[theme.breakpoints.down(641)]: {
				width: "37px",
				marginRight: "18px"
			},
			[theme.breakpoints.down(481)]: {
				width: "26px",
				marginRight: "16px"
			},
			[theme.breakpoints.down(421)]: {
				width: "26px",
				marginRight: "15px"
			},
			[theme.breakpoints.down(401)]: {
				width: "26px",
				marginRight: "15px"
			},
			[theme.breakpoints.down(381)]: {
				width: "26px",
				marginRight: "15px"
			},
			[theme.breakpoints.down(361)]: {
			  width: "24px",
				marginRight: "15px"
			},
			[theme.breakpoints.down(321)]: {
			  width: "22px",
				marginRight: "13px"
			},
		},
		completeHere: {
				...theme.palette.typography.doneButtons,
				width: "100%",
				height: "92px",
				fontSize: "22px",
				lineHeight: "18px",
				marginTop: "30px",
				marginBottom: "0px",
				[theme.breakpoints.down(641)]: {
					height: "80px",
					fontSize: "16px",
					lineHeight: "16px",
					marginTop: "24px",
				},
				[theme.breakpoints.down(481)]: {
					height: "54px",
					fontSize: "14px",
					lineHeight: "12px",
					marginTop: "20px",
				},
				[theme.breakpoints.down(421)]: {
					fontSize: "13.5px",
				},
				[theme.breakpoints.down(401)]: {
					fontSize: "13px",
				},
				[theme.breakpoints.down(381)]: {
					height: "46px",
					fontSize: "12.5px",
					lineHeight: "9px",
					marginTop: "14px",
				},
				[theme.breakpoints.down(361)]: {
					fontSize: "12px",
				},
				[theme.breakpoints.down(321)]: {
					height: "40px",
					fontSize: "10px",
					lineHeight: "8px",
					marginTop: "12px",
				},
		},
		bookwhatsapp: {
				...theme.palette.typography.doneButtons,
				width: "100%",
				height: "92px",
				fontSize: "22px",
				lineHeight: "18px",
				marginTop: "30px",
				marginBottom: "0px",
				backgroundColor: lightgreen,
				color: whiteColor,
			  "&:hover": {
					backgroundColor: lightgreen,
					color: whiteColor,
        },
        "&:disabled": {
					opacity: "0.4 !important",
					backgroundColor: `${lightgreen} !important`,
					color: whiteColor,
        },
				[theme.breakpoints.down(641)]: {
					height: "80px",
					fontSize: "16px",
					lineHeight: "16px",
					marginTop: "24px",
				},
				[theme.breakpoints.down(481)]: {
					height: "54px",
					fontSize: "14px",
					lineHeight: "12px",
					marginTop: "20px",
				},
				[theme.breakpoints.down(421)]: {
					fontSize: "13.5px",
				},
				[theme.breakpoints.down(401)]: {
					fontSize: "13px",
				},
				[theme.breakpoints.down(381)]: {
					height: "46px",
					fontSize: "12.5px",
					lineHeight: "9px",
					marginTop: "14px",
				},
				[theme.breakpoints.down(361)]: {
					fontSize: "12px",
				},
				[theme.breakpoints.down(321)]: {
					height: "40px",
					fontSize: "10px",
					lineHeight: "8px",
					marginTop: "12px",
				},
		},
	};
};
