import React, { Component } from "react";
import CMicon from "../../assets/icons/comingSoon/coming-soon-icon.svg";
import CMBGicon from "../../assets/icons/comingSoon/coming-soon-bg.svg";

import { Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { color } from "../UI/Theme";

const styles = (theme) => {
  return {
    coming_soon: {
      textAlign: "center",
      color: color,
      fontSize: "30px",
      [theme.breakpoints.down("641")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
    },
    coming_soon_img: {
      width: "100%",
      height: "100%",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      objectPosition: "center",
      objectFit: "contain",
    },
    bgCss: {
      background: `url(..${CMBGicon}) no-repeat center / contain`,
      display: "flex",
      justifyContent: "center",
    },
    iconCss: {
      width: "40%",
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(..${CMicon})`,
      "mask-image": `url(..${CMicon})`,
      mask: `url(..${CMicon}) no-repeat center / contain`,
      "-webkit-mask": `url(..${CMicon}) no-repeat center / contain`,
    },
    button: {
      ...theme.palette.typography.doneButtons,
      minHeight: "92px",
      width: "auto",
      fontFamily: "Montserrat",
      margin: "18px auto",
      [theme.breakpoints.down(641)]: {
        minHeight: "80px",
      },
      [theme.breakpoints.down(481)]: {
        minHeight: "54px",
        fontSize: "16px",
      },
      [theme.breakpoints.down(381)]: {
        minHeight: "46px",
        fontSize: "14px",
      },
      [theme.breakpoints.down(321)]: {
        minHeight: "40px",
      },
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  };
};

class ComingSoon extends Component {
  render() {
    const { classes, message, history } = this.props;
    return (
      <div>
        <div
          style={{ width: "100%", height: "351px", overflow: "hidden" }}
          className={classes.bgCss}
        >
          <span className={classes.coming_soon_img + " " + classes.iconCss} />
        </div>
        {history ? (
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => this.props.history.push("/redeem")}
          >
            {message}
          </Button>
        ) : (
          <Typography className={classes.coming_soon}>{message}</Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ComingSoon);

ComingSoon.defaultProps = {
  message: "Coming Soon!",
  history: null,
};
