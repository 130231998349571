import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Login from "../Login/components/Login";
import { connect } from "react-redux";
import { setOTPVerifiedFlag } from "../OtpVerification/redux/action";
import { showLoader } from ".././../../App/redux/action";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import { setRegVisitedflag } from "../OtpVerification/redux/action";

const mapDispatchToProps = {
  setOTPVerifiedFlag,
  showLoader,
  setRegVisitedflag,
};

class LoginContainer extends Component {
  componentDidMount() {
    const { setOTPVerifiedFlag } = this.props;
    setOTPVerifiedFlag(false);
  }
  render() {
    const { showLoader, setRegVisitedflag } = this.props;
    return (
      <Fragment>
        <AlertMessage />
          <Login
            showLoader={showLoader}
            setRegVisitedflag={setRegVisitedflag}
          />
      </Fragment>
    );
  }
}
export default connect(null, mapDispatchToProps)(withRouter(LoginContainer));
