import * as actionType from "./actionTypes";

const initalState = {
  searchText: "",
  searchOffer: "",
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case actionType.SEARCH_TEXT:
      return {
        ...state,
        searchText: action.value,
      };
    case actionType.SEARCH_OFFERS:
      return {
        ...state,
        searchOffer: action.value,
      };
    case actionType.SEARCH_TEXT_RESET:
      return {
        ...state,
        searchText: "",
      };
    case actionType.SEARCH_OFFER_TEXT_RESET:
      return {
        ...state,
        searchOffer: "",
      };
    default:
      return state;
  }
};

export default reducer;
