import React, { useState, useEffect } from "react";
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputBase,
  Paper,
  Button,
  Divider,
} from "@material-ui/core";
import { withStyles, useTheme } from "@material-ui/styles";
import { filterStyles } from "./Style";
import down_arrow from "../../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";

const CustomInput = withStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    fontSize: "20px",
    padding: "10px 24px 10px 32px",
    textAlign: "left",
    backgroundColor: "#fff",
    border: "solid 1px #e4e4e4",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 24px 8px 24px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "6px 24px 6px 16px",
    },
    [theme.breakpoints.down("641")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("481")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("381")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("321")]: {
      fontSize: "12px",
    },
  },
}))(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "50%",
    },
  },
};

const PrivilegeFilter = (props) => {
  const {
    classes,
    selectedLocationId,
    allLocations,
    handleMenuItemClickLocation,
    handleApplyBtn,
    handleResetBtn,
    status,
    selectedStatus,
    handleStatusMenuItemClick,
  } = props;

  const theme = useTheme();

  return (
    <div className={classes.filter_root}>
      <div className={classes.wrapper}>
        <div className={classes.filter_header_label_container}>
          <Typography
            className={
              classes.filter_header + " " + classes.filter_font_color_primary
            }
          >
            Filter By
          </Typography>
        </div>

        <div className={classes.filter_item}>
          <div className={classes.filter_label_container}>
            <Typography
              className={
                classes.filter_font_color_primary + " " + classes.filter_label
              }
            >
              Location
            </Typography>
          </div>
          <div className={classes.selectWrapper}>
            <FormControl fullWidth className={classes.formControl}>
              <Select
                name="location"
                labelId="location"
                id="location"
                value={
                  selectedLocationId !== undefined ? selectedLocationId : ""
                }
                onChange={handleMenuItemClickLocation}
                input={<CustomInput />}
                IconComponent={() => (
                  <img
                    className={classes.down_icon}
                    src={down_arrow}
                    alt=""
                    width="10"
                    height="6"
                  />
                )}
                MenuProps={MenuProps}
              >
                {allLocations.map((option) => (
                  <MenuItem
                    className={classes.menuItem}
                    key={option.id || "all"} // Use 'all' as fallback for key
                    value={option.id || ""} // Allow empty string as a value
                  >
                    {option?.location_name || "All"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Divider />

          <div className={classes.filter_label_container}>
            <Typography
              className={
                classes.filter_font_color_primary + " " + classes.filter_label
              }
            >
              Status
            </Typography>
          </div>
          <div className={classes.selectWrapper}>
            <FormControl fullWidth className={classes.formControl}>
              <Select
                name="status"
                labelId="status"
                id="status"
                value={selectedStatus}
                onChange={(e) => handleStatusMenuItemClick(e)}
                input={<CustomInput />}
                IconComponent={() => (
                  <img
                    className={classes.down_icon}
                    src={down_arrow}
                    alt=""
                    width="10"
                    height="6"
                  />
                )}
                MenuProps={MenuProps}
              >
                {status.map((option, index) => (
                  <MenuItem
                    className={classes.menuItem}
                    key={option.val}
                    value={option.val}
                  >
                    {option?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <div className={classes.inner_btn_container}>
        <div className={classes.button_container}>
          <Button
            className={classes.applyBtn}
            disableRipple
            color="primary"
            variant="contained"
            onClick={handleApplyBtn}
          >
            APPLY
          </Button>
          <Button
            className={classes.resetBtn}
            disableRipple
            variant="contained"
            onClick={handleResetBtn}
          >
            RESET
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withStyles(filterStyles, { withTheme: true })(PrivilegeFilter);
