import React, { useState, useEffect, useCallback } from "react";
import SwipeableViews from "react-swipeable-views";
import { withStyles } from "@material-ui/styles";
import Cancel from "@material-ui/icons/Cancel";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { primaryOrange, lightGrey, whiteColor } from "../UI/Theme";
import platform from "platform";

const styles = (theme) => {
  return {
    wrapper: {
      padding: 0,
      margin: "0 auto",
      display: "block",
      width: "100%",
      overflow: "hidden",
      maxHeight: "100%",
      height: "100%",
    },
    imageContainer: {
      "& .TransformComponent-module_container__3NwNd": {
        width: "100% !important",
        height: "100% !important",
      },
      "& .TransformComponent-module_content__TZU5O": {
        width: "100% !important",
        height: "100% !important",
      },
      display: "inline-block",
      width: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      // height: "100%",
      height: "calc(100% - 152px)",
      [theme.breakpoints.down("641")]: {
        height: "calc(100% - 136px)",
      },
      [theme.breakpoints.down("481")]: {
        height: "calc(100% - 96px)",
      },
      [theme.breakpoints.down("421")]: {
        height: "calc(100% - 96px)",
      },
      [theme.breakpoints.down("401")]: {
        height: "calc(100% - 96px)",
      },
      [theme.breakpoints.down("381")]: {
        height: "calc(100% - 76px)",
      },
      [theme.breakpoints.down("361")]: {
        height: "calc(100% - 76px)",
      },
      [theme.breakpoints.down("321")]: {
        height: "calc(100% - 66px)",
      },
      "& img": {
        height: "100%",
        width: "100%",
        objectFit: "contain",
        objectPosition: "center",
      },
    },
    btnContainer: {
      color: whiteColor,
      textAlign: "right",
      width: "100%",
      padding: "30px",
      [theme.breakpoints.down("610")]: {
        padding: "28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
    },
    doneBtn: {
      ...theme.palette.typography.doneButtons,
      marginBottom: 0,
      fontSize: "22px",
      height: "92px",
      lineHeight: "18px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "18px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "16px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
        fontSize: "12.5px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    slideshow_dots: {
      width: "100%",
      display: "block",
      textAlign: "center",
      listStyle: "none",
      margin: "0",
      padding: 0,
      position: "absolute",
      "& li": {
        display: "inline",
        margin: 0,
        padding: 0,
        "& span": {
          cursor: "pointer",
          display: "inline-block",
          width: "10px",
          height: "10px",
          margin: "0 5px",
          borderRadius: "10px",
          opacity: "0.5",
          transition: ".3s",
        },
      },
    },
    wrapperRotate: {
      padding: 0,
      margin: "0 auto",
      display: "flex",
      width: "100%",
      overflow: "hidden",
      maxHeight: "100%",
      height: "100%",
    },
    imageRotateContainer: {
      "& .TransformComponent-module_container__3NwNd": {
        width: "100% !important",
        height: "100% !important",
      },
      "& .TransformComponent-module_content__TZU5O": {
        width: "100% !important",
        height: "100% !important",
      },
      display: "inline-block",
      width: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      height: "calc(100% - 10px)",
      "& img": {
        height: "100%",
        width: "100%",
        objectFit: "contain",
        objectPosition: "center",
      },
    },
    btnRotateContainer: {
      color: whiteColor,
      textAlign: "left",
      position: "relative",
      zIndex: "1400",
      // width: "100%",
      padding: "30px 10px",
      [theme.breakpoints.down("610")]: {
        padding: "28px 10px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px 10px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px 10px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px 10px",
      },
    },
  };
};

const BillImage = (props) => {
  const { classes } = props;
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [rotate, setrotate] = useState(90);
  const [imageContainer, setimageContainer] = useState("imageContainer");
  const [btnContainer, setbtnContainer] = useState("btnContainer");
  const [wrapper, setwrapper] = useState("wrapper");
  const next = (next) => {
    setActiveImageIndex(next);
  };

  const landscapeMode = useCallback(() => {
    if (props.open) {
      if (
        window.matchMedia("(min-width: 480px) and (orientation: landscape)")
          .matches
      ) {
        setrotate(0);
        setimageContainer("imageRotateContainer");
        setbtnContainer("btnRotateContainer");
        setwrapper("wrapperRotate");

        let orientation = document.getElementById("overite-autoOreintations");
        orientation.style = "display: none !important;";
        let orientation_panel = document.getElementById("overite-inner-panel");
        orientation_panel.style = "display: block !important;";
      } else {
        setrotate(90);
        setimageContainer("imageContainer");
        setbtnContainer("btnContainer");
        setwrapper("wrapper");
        let orientation = document.getElementById("overite-autoOreintations");
        orientation.style = "display: block !important;";
        let orientation_panel = document.getElementById("overite-inner-panel");
        orientation_panel.style = "display: block !important;";
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", landscapeMode);
    return () => {
      window.removeEventListener("resize", landscapeMode);

      let orientation = document.getElementById("overite-autoOreintations");
      orientation.style = "";
      let orientation_panel = document.getElementById("overite-inner-panel");
      orientation_panel.style = "";
    };
  }, [landscapeMode]);

  // if (!props.open) {
  //   let orientation = document.getElementById("overite-autoOreintations");
  //   orientation.style = "";
  //   let orientation_panel = document.getElementById("overite-inner-panel");
  //   orientation_panel.style = "";
  // }

  // if (props.open) {
  //   let orientation = document.getElementById("overite-autoOreintations");
  //   orientation.style = "display: none !important;";
  //   let orientation_panel = document.getElementById("overite-inner-panel");
  //   orientation_panel.style = "display: block !important;";
  // }

  let styling = {
    transform: `rotate(${rotate}deg)`,
    position: "absolute",
    width: "150%",
    right: "-28%",
  };

  if (platform.name === "Chrome Mobile" && rotate === 0) {
    styling = {
      transform: `rotate(${rotate}deg)`,
      position: "absolute",
      width: "125%",
      right: "-10%",
    };
  }

  if (
    platform.os.family === "iOS" &&
    rotate === 0 &&
    platform.name === "Safari"
  ) {
    styling = {
      transform: `rotate(${rotate}deg)`,
      position: "absolute",
      width: "100%",
      right: "0%",
    };
  }

  return (
    <div className={classes[wrapper]}>
      <div className={classes[btnContainer]}>
        <Cancel onClick={props.okBtnHandler} />
      </div>
      {props.multi ? (
        <div style={{ position: "relative" }}>
          <SwipeableViews
            index={activeImageIndex}
            onChangeIndex={next}
            enableMouseEvents
          >
            {props.imgSrc.map((img, index) => (
              <div key={index} className={classes.imageContainer}>
                {Math.abs(activeImageIndex - index) <= 2 ? (
                  <img src={img} alt="Bill" />
                ) : null}
              </div>
            ))}
          </SwipeableViews>
          <div className={classes.slideshow_dots}>
            {props.imgSrc.length > 1 &&
              props.imgSrc.map((item, i) => {
                return (
                  <FiberManualRecordIcon
                    key={i}
                    style={{
                      fontSize: 10,
                      color: activeImageIndex === i ? primaryOrange : lightGrey,
                    }}
                  />
                );
              })}
          </div>
        </div>
      ) : (
        props.open && (
          <div className={classes[imageContainer]}>
            <TransformWrapper key={props.imgSrc} disabled={!props.open}>
              <TransformComponent>
                <img src={props.imgSrc} alt="Bill" style={styling} />
              </TransformComponent>
            </TransformWrapper>
          </div>
        )
      )}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(BillImage);
