import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HeaderRedeem from "../../../components/Layout/Header/HeaderRedeem";
import MyRoute from "../../../hoc/MyRoute";
import {
  getProductDetails,
  addToCart,
  getCartItemCount,
  addCart,
  clearProductDetails,
  TrackProductView,
  onVoucherChange,
  clearSelectedProductDetails
} from "../Redeem/redux/action";
import {
  merchantDetails,
} from "../Dashboard/redux/action";
import { getCartList } from "../MyCart/redux/action";
import ProductDetails from "./ProductDetails";
import { apiData } from "../../../common/common-types";
import { getStorageItem } from "../../../common/localStorage";
import {
  changeActiveTab,
  showLoader,
  logoutUser,
} from "../../../../src/App/redux/action";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import { Grid } from "@material-ui/core";
import Footer from "../../../components/Layout/Footer/Footer";
import PendamicPopupModal from "../../../components/PendamicPopup/PendamicPopupModal";

const mapDispatchToProps = {
  getProductDetails,
  addToCart,
  getCartItemCount,
  addCart,
  getCartList,
  changeActiveTab,
  showLoader,
  logoutUser,
  clearProductDetails,
  TrackProductView,
  onVoucherChange,
  clearSelectedProductDetails,
  merchantDetails,
  
};

const mapStateToProps = (state) => {
  return {
    productList: state.reducerRedeem.productList,
    productDetails: state.reducerRedeem.productDetails,
    cartItemCount: state.reducerRedeem.cartItemCount,
    cartList: state.reducerMyCart.cartList,
    finalCartArray: state.reducerRedeem.finalCartArray,
    addtoCart: state.reducerRedeem.addtoCart,
    selectedProductDetails: state.reducerProductView.selectedProductDetails,
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
    merchantDetailsResponse: state.reducerDashboard.merchantDetailsResponse,
  };
};

class ProductDetailsContainer extends Component {
  componentDidMount = async () => {
    if (getStorageItem("user")) {
      const bodyFormData = new URLSearchParams();
      bodyFormData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      bodyFormData.append("merchant_id", apiData.merchant_id);
      await this.props.getCartItemCount(bodyFormData);
      const bodyCartData = new URLSearchParams();
      bodyCartData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      bodyCartData.append("merchant_id", apiData.merchant_id);
      await this.props.getCartList(bodyCartData);

      // here after cartList is called we need to set finalCartArray (addCart())
      this.props.cartList &&
        this.props.cartList.cart_details &&
        this.props.addCart(this.props.cartList.cart_details);

      const { changeActiveTab } = this.props;
      changeActiveTab(2);
    }
  };

  render() {
    const {
      cartItemCount,
      productDetails,
      getProductDetails,
      getCartItemCount,
      addToCart,
      addtoCart,
      addCart,
      finalCartArray,
      getCartList,
      showLoader,
      logoutUser,
      clearProductDetails,
      TrackProductView,
      selectedProductDetails,
      onVoucherChange,
      clearSelectedProductDetails
    } = this.props;

    const productDetailProps = {
      cartItemCount,
      productDetails,
      getProductDetails,
      getCartItemCount,
      addToCart,
      addtoCart,
      addCart,
      finalCartArray,
      getCartList,
      showLoader,
      logoutUser,
      clearProductDetails,
      TrackProductView,
      selectedProductDetails,
      onVoucherChange,
      clearSelectedProductDetails
    };
    return (
      <Fragment>
        <HeaderRedeem cartItemCount={this.props.cartItemCount} />
        <AlertMessage />
        <MyRoute billUploadStatus={this.props.uploadingStatus}>
          <ProductDetails {...productDetailProps} />
        </MyRoute>
        <Grid item>
					<Footer />
				</Grid>
        <PendamicPopupModal
          isVisible={this.props.merchantDetailsResponse&&this.props.merchantDetailsResponse.is_burn_enabled}
          merchantDetails={this.props.merchantDetailsResponse}
          message={this.props.merchantDetailsResponse&&this.props.merchantDetailsResponse.burn_restriction_message}
        />
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductDetailsContainer));
