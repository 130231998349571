import * as actionTypes from "./actionTypes";

const initialState = {
    activeTab: 0,
    logData: {},
    pointsSelection: 0,
    billStageSelection: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_TRANSACTION_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.val
            }

        case actionTypes.CHANGE_TRANSACTION_POINT_SELECTION:
            return {
                ...state,
                pointsSelection: action.val
            }

        case actionTypes.CHANGE_LOG_TRANSACTION_DATA:
            return {
                ...state,
                logData: action.val
            }

        case actionTypes.CHANGE_TRANSACTION_BILL_STAGE_SELECTION:
            return {
                ...state,
                billStageSelection: action.val
            }

        default:
            return state;
    }
}

export default reducer;