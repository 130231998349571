import { Card, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import defaulticon from '../../../../../assets/icons/more/Default_Image.png';
import mail from '../../../../../assets/icons/more/mail.svg';
import phone from '../../../../../assets/icons/more/phone.svg';
import profile from '../../../../../assets/icons/more/profile.svg';
import NoRecordFound from "../../../../../components/NoRecordFound/NoRecordFound";
import { lightGrey, primaryOrange } from "../../../../../components/UI/Theme";
import Carousal from "./Carousal";
import OffersCard from "../../../../../components/OffersCard/OffersCard";
import { setStorageItem } from "../../../../../common/localStorage";
import { styles } from './StoreStyles';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => {
  return {
    merchantDetailsResponse: state.reducerDashboard.merchantDetailsResponse,
  };
};

const ItemContent = ({ name, classes }) => (
	<div className={classes.itemwrapper}> 
		<div className={classes.itemiconwrapper} style={{ width: "8%" }}>
			<RoomOutlinedIcon className={classes.itemicon}/>
		</div>
		<div className={classes.itemcontent} style={{ marginLeft: 0 }}>{name}</div>
	</div>
);

const ItemContentClock = ({ name, classes }) => (
	<div className={classes.itemwrapper} style={{ marginLeft: 0 }}> 
		<div className={classes.itemiconwrapper}>
			<AccessTimeIcon className={classes.itemicon}/>
		</div>
		<div className={classes.itemcontent} style={{ marginLeft: 0 }}>{name}</div>
	</div>
);

const ItemContentProfile = ({ name, classes }) => (
	<div className={classes.itemwrapper} style={{ marginLeft: 0 }}> 
		<div className={classes.itemiconwrapper}>
			<img src={profile} alt="profile" className={classes.branddetailicons}/>
		</div>
		<div className={classes.itemcontent} style={{ marginLeft: 0 }}>{name}</div>
	</div>
);

const ItemContentPhone = ({ name, classes }) => (
	<div className={classes.itemwrapper} style={{ marginLeft: 0 }}
		onClick={() => {
			window.location.href = `tel:${name}`
		}}
	> 
		<div className={classes.itemiconwrapper}>
			<img src={phone} alt="contact" className={classes.branddetailicons}/>
		</div>
		<div className={classes.itemcontent} style={{ marginLeft: 0 }} 
	
		>{name}</div>
	</div>
);

const ItemContentMail = ({ name, classes }) => (
	<div className={classes.itemwrapper} style={{ marginLeft: 0 }}
		onClick={() => {
			window.location.href = `mailto:${name}`
		}}
	> 
		<div className={classes.itemiconwrapper}>
			<img src={mail} alt="mail" className={classes.branddetailicons1}/>
		</div>
		<div className={classes.itemcontent}
			style={{ marginLeft: 0, textOverflow: "ellipsis", whiteSpace: "pre-wrap", wordBreak: "break-all", display: "block" }}
		>
			{name}
		</div>
	</div>
);

class BrandDetails extends Component {
	state = {
		activeTab: 0,
	};

	componentDidMount() {
	}

	handleTabChange = (e, tabNumber) => {
		this.setState({
			activeTab: tabNumber,
		});
	};

	viewOfferDetails = async (details) => {
    await this.setState({
      offer_id: details.id,
    });
    setStorageItem("offerid", details.id);
    this.props.history.push({
      pathname: "/offers-detail/" + details.id,
      state: {
        details: details,
      },
    });
  };

  convertDate = (expiryDate) => {
    const moment = require("moment");
    const today = moment(expiryDate);
    return today.format("Do MMM'YY");
  };


	render() {
		const { classes, brand } = this.props;
		const { activeTab } = this.state;
		const tabContainer = (
      <Paper
        className={classnames(classes.tabContainer, {[classes.brLTB]:activeTab===0,[classes.brRTB]:activeTab===2,})}
      >
        <Tabs
          classes={{
            indicator: classnames(classes.indicator,{[classes.firstIndicator]:activeTab===0,[classes.lastIndicator]:activeTab===2,}),
          }}
          value={activeTab}
          onChange={this.handleTabChange}
          centered
        >
          <Tab
            label="About"
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
            disabled={false}
          />
          <Tab
            label="Offers"
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
            disabled={false}
          />
		  <Tab
            label="Events"
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
            disabled={false}
          />
        </Tabs>
      </Paper>
		);
		const {
    	merchantDetailsResponse,
    } = this.props;
		const { merchant_name } = merchantDetailsResponse && merchantDetailsResponse;

		return (
			
    	<div style={{ height: "100%" }}>
				<div>
					<div className={classes.malllayout} style={{ margin: 0 }}>
						<Carousal
							imagesArray={
								(brand && brand.shop_images && brand.shop_images.length > 0 ?
									brand.shop_images : [defaulticon])
							}
							alt={
								(brand.brand_name) || [
									"No_image_available",
								]
							}
						/>
					</div>
					<Card className={classes.branddetailcontainer1} style={{ margin: 0, borderRadius: 0 }}>
						<Grid container style={{ height: "100%", margin: 0 }}>
							<Grid item xs={3} sm={3} style={{ height: "100%" }} className={classes.imgwrapper}>
								<img src={brand.brand_logo || defaulticon} alt="brand" className={classes.brandimg} style={{ objectFit: "fill" }} />
							</Grid>
							<Grid item xs={9} sm={9} style={{ display: "table", width: "100%", height: "100%" }}>
								<div style={{ display: "table-cell", verticalAlign : "middle" }} className={classes.branddetails} >
									<div style={{ fontSize: "4.5vw" }}><strong>{brand.brand_name}</strong></div>
									<div>{brand.category_name}</div>
									<ItemContent name={brand && brand.shop_numbers} classes={classes}/>
								</div>
							</Grid>
						</Grid>
					</Card>
				</div>
				<div className={classes.branddetailwrapper}>
					<div>
						{(brand.contact_number || brand.contact_email || brand.shop_time || brand.shop_manager) 
						&& <Card className={classes.branddetail}>
							<Grid container>
								{brand.shop_time
								&& <Grid xs={12} sm={12}>
									<div className={classes.floorata} style={{ borderBottom: "1px solid #d7d7d7", width: "100%" }}>
										<div><strong>Store time</strong></div>
										<ItemContentClock name={brand.shop_time} classes={classes} />
									</div>
								</Grid>}
								{brand.shop_manager
								&& <Grid xs={12} sm={12}>
									<div className={classes.floorata} style={{ borderBottom: "1px solid #d7d7d7", width: "100%" }}>
										<div><strong>Store Manager</strong></div>
										<ItemContentProfile name={brand.shop_manager} classes={classes} />
									</div>
								</Grid>}
								{brand.contact_number
								&& <Grid xs={12} sm={12}>
									<div className={classes.floorata} style={{ borderBottom: "1px solid #d7d7d7", width: "100%" }}>
										<div><strong>Store Contact Number</strong></div>
										<ItemContentPhone name={brand.contact_number} classes={classes} />
									</div>
								</Grid>}
								{brand.contact_email
								&& <Grid xs={12} sm={12}>
									<div className={classes.floorata} style={{ width: "100%" }}>
										<div><strong>Store Email Address</strong></div>
										<ItemContentMail name={brand.contact_email} classes={classes} />
									</div>
								</Grid>}
							</Grid>
						</Card>}
					</div>	
					<div className={classes.branddetail} style={{ padding: 0 }}>
						{tabContainer}
					</div>
					{activeTab === 0
					 && <div className={classes.branddetail} style={{ padding: 0 }}>
						<Card style={{ padding: "4.4vw", marginBottom: 16 }}>
							<div style={{ fontWeight: "bold", fontSize: "4vw", marginBottom: "1.5vh" }}>About {brand.brand_name}</div>
							<div className={classes.textcontent}>
								{brand.about_shop || brand.about_text}
							</div>
						</Card>
					</div>}
					{activeTab === 1
					&& (brand.offers && brand.offers.length > 0
					? <div className={classes.branddetail} style={{ padding: 0 }}>
						{brand.offers.map((offer) => (
							<OffersCard
                key={offer.id}
                id={offer.id}
                offer={offer}
                viewOfferDetails={this.viewOfferDetails}
                convertDate={this.convertDate}
                merchant_name={this.props.merchant_name}
              />
						))}
					</div>
					: <div style={{marginBottom: '10px'}}><NoRecordFound message={"No offers found"} height={160} shrinkImgSize/></div>)
					}
					{activeTab === 2
					&& (brand.events && brand.events.length > 0
					? <div className={classes.branddetail} style={{ padding: 0 }}>
						{brand.events.map((event) => (
						<Card className={classes.brandcontainer}>
							<Grid container style={{ height: "100%", margin: 0 }}>
								<Grid item xs={3} sm={3} style={{ height: "100%" }} >
									<div style={{ width: "90%", height: "100%", background: primaryOrange, color: "white", display: "table", textAlign: "center" }}>
										<div style={{ display: "table-cell", verticalAlign: "middle" }}>
											<div style={{ fontWeight: "bold", fontSize: "5vw", marginBottom: "0.2vh" }}>
												{event.day}
											</div>
											<div className={classes.textcontent} style={{ textAlign: "center" }}>
												{event.month}
											</div>
										</div>
									</div>
								</Grid>
								<Grid item xs={9} sm={9} style={{ display: "table", width: "100%", height: "100%" }}>
									<div style={{ display: "table-cell", verticalAlign : "middle" }} className={classes.branddetails} >
										<div style={{ fontWeight: "bold", fontSize: "3.5vw", marginBottom: "1.2vh" }}>{event.name}</div>
										<div style={{ fontSize: "3vw", marginBottom: "0.2vh" }}>{event.mallname}, {event.branch}, {event.location}.</div>
										<div style={{ fontSize: "3vw", marginBottom: "0.2vh" }}>Starts from: {event.timing}</div>
									</div>
								</Grid>
							</Grid>
						</Card>
						))}
					</div>
					: <div style={{marginBottom: '10px'}}><NoRecordFound message={"No events found"} height={160} shrinkImgSize/></div>)
					}
			</div>
		</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(BrandDetails));
