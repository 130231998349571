import React, { Component } from 'react';
import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { styles } from './ThankYouStyles';
import green_circle_tick from '../../../../assets/icons/thank_you/green_circle_tick.svg';

class ThankYou extends Component {
	handleSubmitBtn = () => {
		this.props.history.push('/submit-bill');
	};

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<div className={classes.mainContainer}>
					<div className={classes.bgCss}>
						<img src={green_circle_tick} className={classes.green_tick} alt="success" />
					</div>
					<div>
						<Typography className={classes.main_title}>
							Thank you for submitting<br />your bill(s)!
						</Typography>
						<Typography className={classes.secondary_title}>
							You will receive a notification once the bills are processed.
						</Typography>
					</div>
				</div>
				<div>
					<Button
						className={classes.doneBtn}
						disableRipple
						variant="contained"
						onClick={this.handleSubmitBtn}
					>
						SUBMIT ANOTHER BILL(S)
					</Button>
				</div>
			</div>
		);
	}
}

export default withRouter(withStyles(styles, { withTheme: true })(ThankYou));
