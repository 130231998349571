import * as React from "react";
import { styles } from "./SliderStyles";
import { primaryOrange } from "../UI/Theme";
import { withStyles } from "@material-ui/core";


function Track({ source, target, getTrackProps, disabled = false, classes }) {
  return (
    <div
      className={classes.track}
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`
      }}
      {...getTrackProps()}
    />
  );
}

export default withStyles(styles, { withTheme: true })(Track);
