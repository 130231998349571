
import actionType from './actionTypes';

const initialState = {
    registerCustomerResponse: null,
    invalidregisterCustomerResponse : false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionType.FETCH_REGISTRATION_DATA:
            return {
                ...state,
                registerCustomerResponse: action.payload
            }
        case actionType.GET_ERRORS:
            return {
                ...state,
                invalidregisterCustomerResponse: true
            }      
             
        default:
            return state
    }
}