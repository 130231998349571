import actionType from "./actionTypes";

const initialState = {
  productList: {},
  dashproductList: {},
  invalidProductList: false,
  invaliddashProductList: false,
  productDetails: null,
  invalidProductDetails: false,
  addCart: [],
  addCartError: false,
  cartItemCount: 0,
  cartItemCountErr: false,
  finalCartArray: [],
  isFilterDrawerOpen: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.FETCH_DASH_PRODUCT_LIST:
      return {
        ...state,
        dashproductList: action.payload,
      };
    case actionType.GET_ERRORS_DASH_PRODUCT_LIST:
      return {
        ...state,
        invaliddashProductList: true,
      };
    case actionType.FETCH_PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload,
      };
    case actionType.GET_ERRORS_PRODUCT_LIST:
      return {
        ...state,
        invalidProductList: true,
      };
    case actionType.FETCH_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: action.payload,
      };
    case actionType.GET_ERRORS_PRODUCT_DETAILS:
      return {
        ...state,
        invalidProductDetails: true,
      };
    case actionType.ADD_CART:
      return {
        ...state,
        addtoCart: action.payload,
      };
    case actionType.ADD_CART_Temp:
      return {
        ...state,
        finalCartArray: [...action.payload],
      };
    case actionType.GET_ERRORS_ADD_CART:
      return {
        ...state,
        addCartError: true,
      };
    case actionType.CART_ITEM_COUNT:
      return {
        ...state,
        cartItemCount: action.payload,
      };
    case actionType.GET_ERRORS_CART_ITEM_COUNT:
      return {
        ...state,
        cartItemCountErr: true,
      };
    case actionType.CLEAR_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: null,
      };
    case actionType.CLEAR_PRODUCT_LIST:
      return {
        ...state,
        productList: {},
      };
    case actionType.TOGGLE_FILTER_DRAWER:
      return {
        ...state,
        isFilterDrawerOpen: action.payload,
      };
    case actionType.RESET_REDEEM_DATA:
      return {
        ...state,
        productList: {},
        dashproductList: {},
        invalidProductList: false,
        invaliddashProductList: false,
        productDetails: null,
        invalidProductDetails: false,
        addCart: [],
        addCartError: false,
        cartItemCount: 0,
        cartItemCountErr: false,
        finalCartArray: [],
        isFilterDrawerOpen: false,
      };
    default:
      return state;
  }
}
