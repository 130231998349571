import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { styles } from "../styles";
import NotificationIcon from "../../../../components/NotificationIcon/NotificationIcon";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { logoutUser, showLoader } from "../../../../App/redux/action";
import { connect } from "react-redux";
import { getStorageItem } from "../../../../common/localStorage";
import { apiData } from "../../../../common/common-types";
import { stockAPI } from "../../../../common/axiosInstance";

class NotificationDetails extends Component {
  state = {
    notification: null,
  };

  async componentDidMount() {
    if (getStorageItem("user")) {
      this.props.showLoader(true);
      const params = new URLSearchParams();
      const id = window.location.pathname.split("/")[2];
      params.append("customer_id", JSON.parse(getStorageItem("user")).id);
      params.append("merchant_id", apiData.merchant_id);
      params.append("unique_id", id);
      params.append("notification_type", 0);

      await stockAPI(params, "POST", "/getCustomerNotificationDetails").then(
        (response) => {
          this.setState({
            notification: response[0],
          });
        }
      );

      this.props.showLoader(false);
    } else {
      this.props.logoutUser();
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.main_root}>
        <div className={classes.root1}>
          <div className={classes.icon_label_container}>
            <div className={classes.icon_container}>
              {this.state.notification &&
                this.state.notification.notification_image.length > 0 && (
                  <img
                    alt=""
                    src={
                      this.state.notification &&
                      this.state.notification.notification_image
                    }
                  />
                )}
              {this.state.notification &&
                this.state.notification.notification_image.length === 0 && (
                  <img alt="" src={`${process.env.PUBLIC_URL}/logo512.png`} />
                )}
            </div>
            <Typography className={classes.notification_label}>
              {this.state.notification ? this.state.notification.title : ""}
            </Typography>
          </div>
          <div className={classes.notification_content}>
            <Typography className={classes.content + " " + classes.common_font}>
              {this.state.notification ? this.state.notification.push_text : ""}
            </Typography>
          </div>
          <Typography className={classes.time + " " + classes.common_font}>
            {this.state.notification
              ? moment(
                  this.state.notification.created_at,
                  "YYYY-MM-DD HH:mm:ss"
                ).fromNow()
              : ""}
          </Typography>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  logoutUser,
  showLoader,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(NotificationDetails));
