import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { styles } from './StoreStyles';
import Expandicon from "@material-ui/icons/ControlPoint";
import Collapseicon from '@material-ui/icons/RemoveCircleOutline';
import {
  primaryOrange,
} from "../../../../../components/UI/Theme";

const mapDispatchToProps = {
};

class Categories extends Component {
	state = {
		expanded: "",
	};

	componentDidMount() {
		
	}

	handleChange = (panel, ele) => {
		if (ele.brands.length > 0) {
			this.setState({
				expanded: panel !== this.state.expanded ? parseInt(panel) : false
			});
		}
	};

	render() {
		const { classes, categories } = this.props;
		const { expanded } = this.state;
		return (
    	<Grid>
				{categories.map((ele, i) => (
					<Card
						key={i}
						style={{ boxShadow: "0 0 3px rgba(0,0,0,0.3)" }}
						className={classes.floorwrapper}
						onClick={() => this.handleChange(i + 1, ele)}
					>
						<CardHeader
							action={ele.brands.length > 0
								? expanded === i + 1
									? <Collapseicon className={classes.expandicon} style={{ color: primaryOrange }}/>
									: <Expandicon className={classes.expandicon} style={{ color: primaryOrange }}/>
								: ""
							}
							classes={{
								subheader: `${classes.textcontent} ${classes.fontWeight}`
							}}
							className={classnames(classes.expansionsummery, expanded === i + 1 ? classes.expandedsummery : "")}
							subheader={<span>{ele.category_name}</span>}
						/>
						<Collapse in={expanded === i + 1} timeout="auto" unmountOnExit className={classes.expdetails}>
							<Grid container>
								{ele.brands.map((b, ind) => (
									<Grid item key={ind} xs={12} sm={12}
									onClick={() => this.props.showBrand(b.brand_id)}
									>
										<div className={classes.floorata} style={{ borderBottom: ind === ele.brands.length - 1 ? "none" : "1px solid #d7d7d7", width: "100%" }}>
											<div style={{ float: "left" }}>
												<div><strong>{b.brand_name}</strong></div>
												<div>{b.shop_numbers}</div>
											</div>
											<div style={{ float: "right" }}>
												<ChevronRightIcon className={classes.rightarrowicon1}/>
											</div>
											<div style={{ clear: "both" }}/>
										</div>
									</Grid>
								))}
							</Grid>
						</Collapse>
					</Card>)
				)}
			</Grid>
		);
	}
}

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Categories));
