import types from "./actionTypes";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import { stockAPI } from "../../../../common/axiosInstance";

export const getMerchantOffers = (bodyObj) => (dispatch) => {
  return stockAPI(
    bodyObj,
    "POST",
    "/getMerchantOffers",
    null,
    null,
    undefined,
    false
  )
    .then(async (response) => {
      if (response.offers) {
        await dispatch({
          type: types.GET_MERCHANT_OFFERS,
          payLoad: response,
        });
        // await dispatch({type: types.GET_NEXT_PAGE, payLoad: response.page_num});
      } else {
        openAlertBox("Oops something went wrong!", "error");
      }
    })
    .catch(function (err) {
      openAlertBox(err && err.message, "error");
    });
};

export const getOfferDetails = (bodyObj) => (dispatch) => {
  return stockAPI(
    bodyObj,
    "POST",
    "/getOfferDetails",
    null,
    null,
    undefined,
    false
  )
    .then(async (response) => {
      if (response.offer_details) {
        await dispatch({
          type: types.GET_OFFERS_DETAILS,
          payLoad: response.offer_details,
        });
      } else {
        // openAlertBox("Oops something went wrong!", "error");
      }
    })
    .catch(function (err) {
      openAlertBox(err && err.message, "error");
    });
};

export const clearOfferDetails = () => (dispatch) => {
  return dispatch({ type: types.CLEAR_OFFERS_DEATILS });
};

export const clearOffers = () => (dispatch) => {
  return dispatch({ type: types.CLEAR_OFFERS });
};
