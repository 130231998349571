import LOCATOR from "../../../../assets/icons/more/locator.svg";
import CONTACTUS from "../../../../assets/icons/more/contactus.svg";
import EMAIL from "../../../../assets/icons/more/message.svg";

export const styles = (theme) => {
  return {
    main_container: {
      display: "flex",
      position: "relative",
      height: "100%",
      flexDirection: "column",
    },
    common_padding: {
      padding: "30px",
      [theme.breakpoints.down("610")]: {
        padding: "28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
    },
    content: {
      "& .MuiTypography-body1": {
        fontSize: "18px",
        userSelect: "none",
        [theme.breakpoints.down(641)]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down(481)]: { fontSize: "14px" },
        [theme.breakpoints.down(421)]: { fontSize: "13.5px" },
        [theme.breakpoints.down(401)]: { fontSize: "13px" },
        [theme.breakpoints.down(381)]: { fontSize: "12.5px" },
        [theme.breakpoints.down(361)]: { fontSize: "12px" },
        [theme.breakpoints.down(321)]: { fontSize: "10px", lineHeight: 1.33 },
      },
      "& .MuiList-padding": {
        padding: 0,
      },
      "& .MuiListItem-root": {
        padding: "16px",
        [theme.breakpoints.down("xs")]: {
          padding: "5px 0px",
        },
      },
      "& .MuiListItemIcon-root": {
        marginTop: "0 !important",
      },
    },
    title: {
      padding: "10px",
      fontWeight: "600",
      userSelect: "none",
      "& span": {
        fontWeight: "normal",
        paddingLeft: "2px",
        userSelect: "none",
      },
    },
    img: {
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      objectFit: "contain",
      marginRight: "10px",
      objectPosition: "center",
      width: "38px",
      height: "24px",
      [theme.breakpoints.down(641)]: { width: "36px", height: "22px" },
      [theme.breakpoints.down(481)]: { width: "34px", height: "20px" },
      [theme.breakpoints.down(381)]: { width: "32px", height: "18px" },
      [theme.breakpoints.down(321)]: { width: "30px", height: "16px" },
    },
    divider: {
      width: "90%",
      margin: "0 auto",
    },
    noData: {
      textAlign: "center",
      fontSize: "18px",
      userSelect: "none",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: { fontSize: "14px" },
      [theme.breakpoints.down(421)]: { fontSize: "13.5px" },
      [theme.breakpoints.down(401)]: { fontSize: "13px" },
      [theme.breakpoints.down(381)]: { fontSize: "12.5px" },
      [theme.breakpoints.down(361)]: { fontSize: "12px" },
      [theme.breakpoints.down(321)]: { fontSize: "10px" },
    },
    locatorCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(..${LOCATOR})`,
      "mask-image": `url(..${LOCATOR})`,
      mask: `url(..${LOCATOR}) no-repeat center / contain`,
      "-webkit-mask": `url(..${LOCATOR}) no-repeat center / contain`,
    },
    contactCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(..${CONTACTUS})`,
      "mask-image": `url(..${CONTACTUS})`,
      mask: `url(..${CONTACTUS}) no-repeat center / contain`,
      "-webkit-mask": `url(..${CONTACTUS}) no-repeat center / contain`,
    },
    emailCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(..${EMAIL})`,
      "mask-image": `url(..${EMAIL})`,
      mask: `url(..${EMAIL}) no-repeat center / contain`,
      "-webkit-mask": `url(..${EMAIL}) no-repeat center / contain`,
    },
    common_mg: {
      margin: "10px",
    },
    map_panel: {
      margin: "10px 0",
    },
    noLocation: {
      textAlign: "center",
      fontFamily: "Montserrat",
      lineHeight: "1.25",
      userSelect: "none",
      width: "100%",
      fontWeight: "500",
      overflow: "hidden",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontSize: "18px",
      margin: "15px 0",
      color: theme.palette.typography.texts.sellingPriceColor,
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: { fontSize: "14px" },
      [theme.breakpoints.down(421)]: { fontSize: "13.5px" },
      [theme.breakpoints.down(401)]: { fontSize: "13px" },
      [theme.breakpoints.down(381)]: { fontSize: "12.5px" },
      [theme.breakpoints.down(361)]: { fontSize: "12px" },
      [theme.breakpoints.down(321)]: { fontSize: "10px" },
    },
  };
};
