import {
  AUTH_USER,
  IS_OTP_VERIFIED,
  IS_OTP_TIME_STARTED,
  IS_EXCEED_OTP_TIME_STARTED,
  SET_RESEND_COUNT,
  SET_TRY_COUNT,
  RESET_RESEND_COUNT,
  RESET_TRY_COUNT,
  SET_USER_VISITED,
  IS_REFERAL_ENABLE,
  REFERAL_CODE,
} from "./actionTypes";

export const authenticate = () => (dispatch) => {
  dispatch({ type: AUTH_USER, payload: true });
};

export const setOTPVerifiedFlag = (flag) => (dispatch) => {
  dispatch({ type: IS_OTP_VERIFIED, payload: flag });
};

export const setOTPTimeFlag = (flag) => (dispatch) => {
  dispatch({ type: IS_OTP_TIME_STARTED, payload: flag });
};

export const setExceedOtpTimeFlag = (flag) => (dispatch) => {
  dispatch({ type: IS_EXCEED_OTP_TIME_STARTED, payload: flag });
};

export const setResendCount = () => (dispatch) => {
  dispatch({ type: SET_RESEND_COUNT });
};

export const resetResendCount = () => (dispatch) => {
  dispatch({ type: RESET_RESEND_COUNT });
};

export const setTryCount = () => (dispatch) => {
  dispatch({ type: SET_TRY_COUNT });
};

export const resetTryCount = () => (dispatch) => {
  dispatch({ type: RESET_TRY_COUNT });
};

export const setRegVisitedflag = (flag) => (dispatch) => {
  dispatch({ type: SET_USER_VISITED, payload: flag })
};
export const isReferalEnable = (flag) => (dispatch) => {
  dispatch({ type: IS_REFERAL_ENABLE, payload: flag })
}
