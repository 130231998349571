import { whiteColor, primaryOrange, borderGrey } from '../UI/Theme';

export const styles = (theme) => {
	return {
    container: {
      height: 110,
      [theme.breakpoints.down(641)]: {
        height: 120,
			},
      [theme.breakpoints.down(481)]: {
        height: 96,
			},
      [theme.breakpoints.down(401)]: {
        height: 94,
			},
      [theme.breakpoints.down(321)]: {
        height: 78,
			},
    },
		tooltip: {
      // width: 75,
      // boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.3)",
      // opacity: 1,
      // transform: "none",
      // transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      // margin: "5px 0px",
      // transformOrigin: "center bottom",
      // position: "relative",
      // fontSize: 16,
      // color: primaryOrange,
      // padding: "4px",
      // maxWidth: "300",
      // fontWeight: 500,
      // lineHeight: "1.4em",
      // borderRadius: 4,
      // backgroundColor: whiteColor,
      // textAlign: "center",
      position: "relative",
      display: "inline-block",
      // borderBottom: "1px dotted #222",
      marginLeft: 32,
    },
    tooltiptext: {
      width: 75,
      backgroundColor: whiteColor,
      color: primaryOrange,
      opacity: 0.8,
      fontSize: 16,
      fontWeight: 500,
      textAlign: "center",
      borderRadius: 4.4,
      padding: "8px 2px",
      position: "absolute",
      zIndex: 1,
      bottom: "150%",
      left: "50%",
      marginLeft: -60,
      background: whiteColor,
      // boxShadow: "0px 3px 3px 0 rgba(0, 0, 0, 0.4)",
      boxShadow: '0 0 3px #000',
      // border: '1px solid #888',
      [theme.breakpoints.down(641)]: {
        padding: "8px 2px",
        width: 72,
        fontSize: 15,
			},
      [theme.breakpoints.down(481)]: {
        borderRadius: 3.3,
        padding: "5px 0px",
        width: 62,
        fontSize: 12,
			},
      [theme.breakpoints.down(421)]: {
        padding: "3px 0px",
        width: 58,
        fontSize: 11,
			},
      [theme.breakpoints.down(381)]: {
        borderRadius: 2.5,
        width: 53,
        fontSize: 10,
			},
      [theme.breakpoints.down(321)]: {
        borderRadius: 2.2,
        width: 45,
        fontSize: 8,
			},
      "&:after": {
        content: '""',
        position: "absolute",
        width: 0,
        height: 0,
        marginLeft: -5,
        bottom: -10,
        left: "50%",
        boxSizing: "border-box",
        border: "5px solid black",
        borderColor: "transparent transparent #fff #fff",
        transformOrigin: "0 0",
        transform: "rotate(-45deg)",
        // boxShadow: "-3px 3px 3px 0 rgba(0, 0, 0, 0.4)",
        boxShadow: "-1px 3px 3px 0 rgba(0, 0, 0, 0.4)",
        // color: "transparent",
        // width: 0,
        // height: 0,
        // margin: "auto",
        // content: "",
        // display: "block",
        // borderStyle: "solid",
        // borderColor: "currentcolor transparent transparent transparent",
        // borderWidth: "1em 1em 0 1em",
      },
    },
    tooltipWrapper: {
      position: "absolute",
      marginLeft: "-8px",
      marginTop: "-38px",
      [theme.breakpoints.down(641)]: {
        marginLeft: "-8px",
        marginTop: "-38px",
      },
      [theme.breakpoints.down(481)]: {
        marginLeft: "-8px",
        marginTop: "-34px",
      },
      [theme.breakpoints.down(421)]: {
        marginLeft: "-5px",
        marginTop: "-32px",
      },
      [theme.breakpoints.down(401)]: {
        marginLeft: "-5px",
        marginTop: "-32px",
      },
      [theme.breakpoints.down(381)]: {
        marginLeft: "-3px",
        marginTop: "-32px",
      },
      [theme.breakpoints.down(361)]: {
        marginLeft: "-3px",
        marginTop: "-32px",
      },
      [theme.breakpoints.down(321)]: {
        marginLeft: "1px",
        marginTop: "-32px",
			},
    },
    tooltipSlider: {
      position: "absolute",
      marginLeft: "-11px",
      marginTop: "-11px",
      zIndex: 400,
      width: 29,
      height: 29,
      cursor: "pointer",
      border: "3px solid white",
      borderRadius: "50%",
      boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.4)",
      backgroundColor: primaryOrange,
      [theme.breakpoints.down(641)]: {
        width: 29,
        height: 29,
        border: "3px solid white",
        marginTop: "-10px",
      },
      [theme.breakpoints.down(481)]: {
        marginTop: "-8px",
        width: 17,
        height: 17,
        border: "2px solid white",
      },
    },
    sliderWrapper: {
      width: "84% !important",
      margin: "11% 8% 12% 8% !important",
      [theme.breakpoints.down(641)]: {
        width: "84% !important",
        margin: "12% 8% 12% 8% !important",
      },
      [theme.breakpoints.down(481)]: {
        width: "80% !important",
        margin: "12% 10% 12% 10% !important",
      },
      [theme.breakpoints.down(421)]: {
        width: "80% !important",
        margin: "14% 10% 12% 10% !important",
      },
      [theme.breakpoints.down(401)]: {
        width: "75% !important",
        margin: "16% 12.5% 12% 12.5% !important",
      },
    },
    labels: {
      fontSize: "18px",
      color: "#7e7e7e",
      position: "relative",
      top: 20,
      padding: "0px 3%",
      [theme.breakpoints.down(641)]: {
        top: 28,
        fontSize: "16px",
        padding: "0px 3%",
			},
      [theme.breakpoints.down(481)]: {
        top: 20,
        fontSize: "14px",
        padding: "0px 5%",
			},
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
        padding: "0px 5%",
			},
      [theme.breakpoints.down(381)]: {
        fontSize: "13px",
        padding: "0px 6%",
			},
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
        padding: "0px 7%",
			},
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        padding: "0px 6%",
			},
    },
    track: {
      position: "absolute",
      height: 12,
      zIndex: 1,
      backgroundColor: primaryOrange,
      borderRadius: 7,
      cursor: "pointer",
      [theme.breakpoints.down(641)]: {
        height: 12,
			},
      [theme.breakpoints.down(481)]: {
        height: 5,
			},
    },
  };
};
