import {
  maroonRed,
  green,
  primaryOrange,
  color,
} from "../../../../../components/UI/Theme";
export const styles = (theme) => {
  return {
    root: {
      display: "block",
      textAlign: "center",
      // overflowY: "auto",
      height: "auto",
      width: "100%",
      background: theme.palette.common.globalBackground,
      margin: "0 auto",
    },
    container: {
      padding: "30px",
      [theme.breakpoints.down("610")]: {
        padding: "28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
    },
    container1: {
      margin: "0 auto",
      padding: "0 30px 30px 30px",
      [theme.breakpoints.down("610")]: {
        padding: "0 28px 28px 28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "0 21px 21px 21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "0 15px 15px 15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "0 13px 13px 13px",
      },
    },
    red: {
      backgroundColor: maroonRed,
      float: "left",
      fontWeight: "bold",
      fontSize: "24px",
      [theme.breakpoints.down("610")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    green: {
      backgroundColor: green,
      float: "left",
      fontWeight: "bold",
      fontSize: "24px",
      [theme.breakpoints.down("610")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    orange: {
      backgroundColor: primaryOrange,
      float: "left",
      fontWeight: "bold",
      fontSize: "24px",
      [theme.breakpoints.down("610")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    brLTB: {
      "& .Mui-selected": {
        borderBottomLeftRadius: "4px",
        borderTopLeftRadius: "4px",
        borderLeft: "0px !important",
        borderRight: "0px !important",
      },
    },
    brRTB: {
      "& .Mui-selected": {
        borderBottomRightRadius: "4px",
        borderTopRightRadius: "4px",
        borderLeft: "0px !important",
        borderRight: "0px !important",
      },
    },
    tabContainer: {
      "& .MuiTab-root": {
        minHeight: "40px",
        padding: 0,
        "&:first-child": {
          borderLeft: "0px ",
        },
        borderLeft: "1px solid rgba(126,126,126, .2)",
      },
      "& .MuiTabs-flexContainer": {
        height: "92px",
        [theme.breakpoints.down("641")]: {
          height: "86px",
        },
        [theme.breakpoints.down("481")]: {
          height: "54px",
        },
        [theme.breakpoints.down("381")]: {
          height: "46px",
        },
        [theme.breakpoints.down("321")]: {
          height: "40px",
        },
      },
      "& .MuiTabs-scroller": {
        height: "92px",
        fontSize: "16px",
        [theme.breakpoints.down("641")]: {
          height: "86px",
          fontSize: "14px",
        },
        [theme.breakpoints.down("481")]: {
          height: "54px",
          fontSize: "12px",
        },
        [theme.breakpoints.down("421")]: {
          fontSize: "11.5px",
        },
        [theme.breakpoints.down("401")]: {
          fontSize: "11px",
        },
        [theme.breakpoints.down("381")]: {
          height: "46px",
          fontSize: "10.5px",
        },
        [theme.breakpoints.down("361")]: {
          fontSize: "10px",
        },
        [theme.breakpoints.down("321")]: {
          height: "40px",
          fontSize: "8px",
        },
      },
      margin: "0 auto",
      width: "100%",
      marginBottom: theme.spacing(2),
      height: "92px",
      fontSize: "16px",
      [theme.breakpoints.down("641")]: {
        height: "86px",
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        height: "54px",
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        height: "46px",
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        height: "40px",
        fontSize: "8px",
      },
    },
    tab: {
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textTransform: "none",
      textAlign: "center",
      opacity: 1,
      fontSize: "18px",
      color: "#000000",
      width: "25%",
      [theme.breakpoints.down(641)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
      },
    },
    selected: {
      background: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
    listTitleText: {
      fontWeight: "bold",
      fontSize: "18px",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    listTitleSecondaryContainer: {
      width: "40%",
      float: "left",
    },
    grandTotalItem: {
      backgroundColor: theme.palette.common.globalBackground,
    },
    listColumnNames: {
      width: "50%",
      float: "left",
      textAlign: "center",
      fontSize: "14px",
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    listItemTextRoot: {
      marginLeft: "0px",
    },
    listPrimaryText: {
      color: color,
      fontSize: "16px",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    listSecondaryText: {
      width: "48%",
      display: "block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: color,
      fontSize: "16px",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    listBrandText: {
      width: "48%",
      display: "block",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color: color,
      fontSize: "18px",
      fontStyle: "italic",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    listPointsContainer: {
      width: "40%",
    },
    listPoints: {
      width: "50%",
      float: "left",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "18px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    billStatus: {
      width: "50%",
      textAlign: "center",
      fontSize: "14px",
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    pointsText: {
      fontSize: "18px",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    pointsListContainer: {
      padding: "0px",
    },
    paperContainer: {
      // maxWidth: "450px",
      margin: "0 auto",
      marginTop: "16px",
      // "& .MuiList-padding": {
      //   paddingTop: 0,
      // },
      "& .MuiListItem-gutters": {
        padding: "4px 12px",
      },
    },
    expiryNote: {
      fontSize: "0.7rem",
      color: theme.palette.typography.texts.subHeading,
      margin: "14px 0",
    },
    no_transaction: {
      fontSize: "18px",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    avtartCss: {
      minWidth: "53px",
      width: "53px",
      height: "52px",
      marginRight: "24px",
      [theme.breakpoints.down("481")]: {
        marginRight: "20px",
      },
      [theme.breakpoints.down("481")]: {
        minWidth: "39px",
        width: "39px",
        height: "40px",
        marginRight: "15px",
      },
      [theme.breakpoints.down("381")]: {
        minWidth: "30px",
        width: "30px",
        height: "30px",
        marginRight: "12px",
      },
      [theme.breakpoints.down("321")]: {
        minWidth: "27px",
        width: "27px",
        height: "26px",
        marginRight: "9px",
      },
    },
    lastIndicator: {
      width: "69px !important",
    },
    firstIndicator: {
      width: "69px !important",
      marginLeft: "4px !important",
    },
    listVoucherContainer: {
      width: "100%",
    },
    listVoucher: {
      width: "100%",
      float: "left",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "18px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    noSelect: {
      "-webkit-touch-callout": "none",
      "-webkit-user-select": "none",
      "-khtml-user-select": "none",
      "-moz-user-select": "none",
      "-ms-user-select": "none",
      userSelect: "none",
      " -webkit-tap-highlight-color": "transparent",
    },
    listDate: {
      fontFamily: "Montserrat",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontSize: "16px",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
  };
};
