import React, { useState, useEffect } from "react";
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputBase,
  Paper,
  Button,
  Grid,
  TextField,
  ClickAwayListener,
} from "@material-ui/core";
import { withStyles, useTheme } from "@material-ui/styles";
import { TextValidator } from "react-material-ui-form-validator";
import down_arrow from "../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import { getStorageItem } from "../../common/localStorage";
import { filterStyles } from "./FilterStyles";
import classnames from "classnames";
import { CustomSlider, ValueLabelComponent } from "./CustomSlider/CustomSlider";
import Slider from "../Slider/index";

const CustomInput = withStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    fontSize: "1.5rem",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
      padding: "0px 24px 0px 24px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      padding: "0px 24px 0px 16px",
    },
  },
}))(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "150px",
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    background: "#fff",
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    "& img": {
      width: 40,
      marginBottom: 24,
      marginTop: 13,
      // "--size ": "70px",
      // display: "inline-block",
      // transition: ".12s",
      // "-webkit-mask-size": "cover",
      // "mask-size ": "cover",
      // objectFit: "contain",
      [theme.breakpoints.down(641)]: {
        width: 40,
        marginBottom: 24,
        marginTop: 13,
      },
      [theme.breakpoints.down(481)]: {
        width: 28,
        marginBottom: 16,
        marginTop: 8,
      },
      [theme.breakpoints.down(421)]: {
        width: 28,
        marginBottom: 16,
        marginTop: 8,
      },
      [theme.breakpoints.down(401)]: {
        width: 27,
        marginBottom: 15,
        marginTop: 8,
      },
      [theme.breakpoints.down(381)]: {
        width: 21,
        marginBottom: 12,
        marginTop: 5,
      },
      [theme.breakpoints.down(361)]: {
        width: 24,
        marginBottom: 12,
        marginTop: 5,
      },
      [theme.breakpoints.down(321)]: {
        width: 22,
        marginBottom: 10,
        marginTop: 4,
      },
    },
  };
}

const Filter = (props) => {
  const { classes } = props;
  const [focus, setFocus] = useState(false);
  const [brand, setBrand] = useState("");
  const [focusLocation, setFocusLocation] = useState(false);
  const [location, setLocation] = useState("");
  useEffect(() => {
    const locations = props.allLocations
      .filter((x) => selectedLocationIds.findIndex((b) => b === x.id) !== -1)
      .map((l) => l.location_name);
    setLocation(locations.join(","));
    const brands = props.allBrands
      .filter((x) => selectedBrandIds.findIndex((b) => b === x.id) !== -1)
      .map((l) => l.brand_name);
    setBrand(brands.join(","));
    return () => {};
  }, []);
  const {
    selectedLocationIds,
    selectedBrandIds,
    selectedPointsRange,
    selectedRewardValueRange,
    allLocations,
    allBrands,
    onChangeHandler,
    pointsRangeChangeHandler,
    rewardValueRangeChangeHandler,
    handleApplyBtn,
    handleResetBtn,
  } = props;

  const filterBrands = [
    ...allBrands.filter((b) =>
      b.brand_name.toLowerCase().includes(brand.toLowerCase())
    ),
  ];
  const totalBrands = [
    ...filterBrands
      .filter((x) => selectedBrandIds.findIndex((b) => b === x.id) !== -1)
      .sort(function (a, b) {
        if (a.brand_name < b.brand_name) {
          return -1;
        }
        if (a.brand_name > b.brand_name) {
          return 1;
        }
        return 0;
      }),
    ...filterBrands
      .filter((x) => selectedBrandIds.findIndex((b) => b === x.id) === -1)
      .sort(function (a, b) {
        if (a.brand_name < b.brand_name) {
          return -1;
        }
        if (a.brand_name > b.brand_name) {
          return 1;
        }
        return 0;
      }),
  ];

  const filterLocations = [
    ...allLocations.filter((b) =>
      b.location_name.toLowerCase().includes(location.toLowerCase())
    ),
  ];
  const totalLocations = [
    ...filterLocations
      .filter((x) => selectedLocationIds.findIndex((b) => b === x.id) !== -1)
      .sort(function (a, b) {
        if (a.location_name < b.location_name) {
          return -1;
        }
        if (a.location_name > b.location_name) {
          return 1;
        }
        return 0;
      }),
    ...filterLocations
      .filter((x) => selectedLocationIds.findIndex((b) => b === x.id) === -1)
      .sort(function (a, b) {
        if (a.location_name < b.location_name) {
          return -1;
        }
        if (a.location_name > b.location_name) {
          return 1;
        }
        return 0;
      }),
  ];

  const theme = useTheme();
  return (
    <div className={classes.filter_root}>
      <div className={classes.wrapper}>
        <div className={classes.filter_header_label_container}>
          <Typography
            className={
              classes.filter_header + " " + classes.filter_font_color_primary
            }
          >
            Filter By
          </Typography>
        </div>
        <div className={classes.filter_item} style={{ paddingBottom: 0 }}>
          <div className={classes.filter_label_container}>
            <Typography
              className={
                classes.filter_font_color_primary + " " + classes.filter_label
              }
            >
              Points Range
            </Typography>
          </div>
          <div
            className={classes.range_container}
            style={{
              borderBottom: "1px solid #e4e4e4",
              padding: 0,
              marginTop: 0,
            }}
          >
            {/* <CustomSlider
            ValueLabelComponent={ValueLabelComponent}
            valueLabelDisplay="on"
            value={selectedPointsRange}
            valueLabelFormat={function valueLabelFormat(value) {
              return (
                selectedPointsRange.filter((item) => item === value) +
                " " +
                getStorageItem("pointShortName")
              );
            }}
            name="pointsRange"
            onChange={pointsRangeChangeHandler}
            marks={[
              { value: 0, label: `0 ${getStorageItem("pointShortName")}` },
              {
                value: 2000,
                label: `2000 ${getStorageItem("pointShortName")}`,
              },
            ]}
            min={0}
            max={2000}
          /> */}
            <Slider
              value={selectedPointsRange}
              onChange={pointsRangeChangeHandler}
              min={0}
              max={2000}
              unitSide="right"
              unit={getStorageItem("pointShortName")}
            />
          </div>
        </div>
        <div className={classes.filter_item}>
          <div className={classes.filter_label_container}>
            <Typography
              className={
                classes.filter_font_color_primary + " " + classes.filter_label
              }
            >
              Reward Location
            </Typography>
          </div>
          <ClickAwayListener
            onClickAway={(e) => {
              setFocusLocation(false);
              const locations = props.allLocations
                .filter(
                  (x) => selectedLocationIds.findIndex((b) => b === x.id) !== -1
                )
                .map((l) => l.location_name);
              setLocation(locations.join(","));
            }}
          >
            <div className={classes.form_item} style={{ position: "relative" }}>
              <Paper className={classes.paper}>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    className={classes.textPincode}
                    value={location}
                    inputProps={{
                      maxLength: 12,
                      type: "text",
                    }}
                    // placeholder="Choose Location"
                    label="Choose Location"
                    onFocus={(e) => {
                      setFocusLocation(true);
                      setLocation("");
                    }}
                    InputProps={{
                      classes: {
                        underline: classes.cssUnderline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                    onChange={(e) => {
                      setLocation(e.target.value || "");
                    }}
                    autoComplete="off"
                  />
                </FormControl>
              </Paper>
              {focusLocation ? (
                <ul
                  className={classnames(classes.selectListUl, {
                    [classes.less]: focusLocation,
                  })}
                >
                  {totalLocations &&
                    totalLocations.length > 0 &&
                    totalLocations.map((elem) => {
                      return (
                        <li
                          key={elem.id}
                          id={elem.id}
                          value={elem.id}
                          className={classnames(classes.selectListLi, {
                            [classes.less]: focusLocation,
                          })}
                          onClick={(e) => {
                            onChangeHandler({
                              target: {
                                value: selectedLocationIds.find(
                                  (br) => br === elem.id
                                )
                                  ? selectedLocationIds.filter(
                                      (x) => x !== elem.id
                                    )
                                  : [...selectedLocationIds, elem.id],
                                name: "locationIds",
                              },
                            });
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid xs={1} sm={1} item>
                              <span
                                className={`${classes.checkbox} ${
                                  selectedLocationIds.find(
                                    (id) => id === elem.id
                                  )
                                    ? classes.selected
                                    : classes.disable_checkbox
                                }`}
                              />
                            </Grid>
                            <Grid xs={11} sm={11} item>
                              <div className={classes.checkboxText}>
                                {elem.location_name}
                              </div>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    })}
                </ul>
              ) : null}
            </div>
          </ClickAwayListener>
        </div>
        <div className={classes.filter_item}>
          <div className={classes.filter_label_container}>
            <Typography
              className={
                classes.filter_font_color_primary + " " + classes.filter_label
              }
            >
              Brands
            </Typography>
          </div>
          <ClickAwayListener
            onClickAway={(e) => {
              setFocus(false);
              const brands = props.allBrands
                .filter(
                  (x) => selectedBrandIds.findIndex((b) => b === x.id) !== -1
                )
                .map((l) => l.brand_name);
              setBrand(brands.join(","));
            }}
          >
            <div className={classes.form_item} style={{ position: "relative" }}>
              <Paper className={classes.paper}>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    className={classes.textPincode}
                    value={brand}
                    inputProps={{
                      maxLength: 12,
                      type: "text",
                    }}
                    // placeholder="Choose Brands"
                    label="Choose Brands"
                    onFocus={(e) => {
                      setFocus(true);
                      setBrand("");
                    }}
                    InputProps={{
                      classes: {
                        underline: classes.cssUnderline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                    onChange={(e) => {
                      setBrand(e.target.value || "");
                    }}
                    autoComplete="off"
                  />
                </FormControl>
              </Paper>
              {focus ? (
                <ul
                  className={classnames(classes.selectListUl, {
                    [classes.less]: focus,
                  })}
                >
                  {totalBrands &&
                    totalBrands.length > 0 &&
                    totalBrands.map((elem) => {
                      return (
                        <li
                          key={elem.id}
                          id={elem.id}
                          value={elem.id}
                          className={classnames(classes.selectListLi, {
                            [classes.less]: focus,
                          })}
                          onClick={(e) => {
                            onChangeHandler({
                              target: {
                                value: selectedBrandIds.find(
                                  (br) => br === elem.id
                                )
                                  ? selectedBrandIds.filter(
                                      (x) => x !== elem.id
                                    )
                                  : [...selectedBrandIds, elem.id],
                                name: "brandIds",
                              },
                            });
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid xs={1} sm={1} item>
                              <span
                                className={`${classes.checkbox} ${
                                  selectedBrandIds.find((id) => id === elem.id)
                                    ? classes.selected
                                    : classes.disable_checkbox
                                }`}
                              />
                            </Grid>
                            <Grid xs={11} sm={11} item>
                              <div className={classes.checkboxText}>
                                {elem.brand_name}
                              </div>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    })}
                </ul>
              ) : null}
            </div>
          </ClickAwayListener>
        </div>
        <div className={classes.filter_item}>
          <div
            className={classes.filter_label_container}
            style={{ paddingBottom: 0 }}
          >
            <Typography
              className={
                classes.filter_font_color_primary + " " + classes.filter_label
              }
            >
              Reward Value
            </Typography>
          </div>
          <div
            className={classes.range_container}
            style={{
              borderBottom: "1px solid #e4e4e4",
              padding: 0,
              marginTop: 0,
            }}
          >
            {/* <CustomSlider
            ValueLabelComponent={ValueLabelComponent}
            valueLabelDisplay="on"
            valueLabelFormat={function valueLabelFormat(value) {
              return (
                getStorageItem("currencyCode") +
                " " +
                selectedRewardValueRange.filter((item) => item === value)
              );
            }}
            value={selectedRewardValueRange}
            name="rewardValueRange"
            onChange={rewardValueRangeChangeHandler}
            marks={[
              { value: 0, label: `${getStorageItem("currencyCode")} 0` },
              { value: 2000, label: `${getStorageItem("currencyCode")} 2000` },
            ]}
            min={0}
            max={2000}
          /> */}
            <Slider
              value={selectedRewardValueRange}
              onChange={rewardValueRangeChangeHandler}
              min={0}
              max={2000}
              unitSide="left"
              unit={getStorageItem("currencyCode")}
            />
          </div>
        </div>
      </div>
      <div className={classes.inner_btn_container}>
        <div className={classes.button_container}>
          <Button
            className={classes.applyBtn}
            disableRipple
            color="primary"
            variant="contained"
            onClick={handleApplyBtn}
          >
            APPLY
          </Button>
          <Button
            className={classes.resetBtn}
            disableRipple
            variant="contained"
            onClick={handleResetBtn}
          >
            RESET
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withStyles(filterStyles, { withTheme: true })(Filter);
