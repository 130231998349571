import React, { Fragment, Component } from "react";
import { Card, CardContent, FormControl, Grid, InputBase, MenuItem, Paper, Select, Tab, Tabs } from '@material-ui/core';
import { connect } from "react-redux";
import Map from "../../../../components/Map/MapComponet";
import Header from "../../../../components/Layout/Header/HeaderSubmitBill";
import { headerScreenLabels, apiData, WHATSAPP_LINK } from "../../../../common/common-types";
import MyRoute from "../../../../hoc/MyRoute";

import {
  primaryOrange,
  whiteColor,
  borderGrey,
} from '../../../../components/UI/Theme';
import { getStorageItem } from "../../../../common/localStorage";
// import down_arrow from '../../../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg';
import down_arrow from '../../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg';
import {
  changeActiveTab,
  logoutUser,
  showLoader,
} from "../../../../../src/App/redux/action";
import { merchantDetails } from "../../Dashboard/redux/action";
import {
  withStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import LOCATOR from "../../../../assets/icons/more/locator.svg";
import CONTACTUS from "../../../../assets/icons/more/contactus.svg";
import FB from "../../../../assets/icons/more/facebook.svg";
import insta from "../../../../assets/icons/more/insta.svg";
import linkedin from "../../../../assets/icons/more/linkedin.svg";
import EMAIL from "../../../../assets/icons/more/message.svg";
import WEBSITE from "../../../../assets/icons/more/website.svg";
import TWITER from "../../../../assets/icons/more/twitter.svg";
import WHATSAPPLOGO from "../../../../assets/icons/more/whatsapp.svg";
import { stockAPI } from "../../../../common/axiosInstance";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";

function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '50%'
    }
  }
};

const CustomInput = withStyles((theme) => ({
  root: {
    width: '96%',
    position: 'relative',
    fontSize: 20,
    padding: '25px 24px 0px 13px',
    textAlign: 'left',
    [theme.breakpoints.down(641)]: {
      fontSize: 21,
      padding: '20px 24px 0px 13px'
    },
    [theme.breakpoints.down(481)]: {
      fontSize: 14,
      padding: '11px 24px 0px 13px'
    },
    [theme.breakpoints.down(421)]: {
      fontSize: 14,
      padding: '11px 24px 0px 13px'
    },
    [theme.breakpoints.down(401)]: {
      fontSize: 13,
      padding: '12px 24px 0px 13px'
    },
    [theme.breakpoints.down(381)]: {
      fontSize: 12,
      padding: '9px 24px 0px 13px'
    },
    [theme.breakpoints.down(361)]: {
      fontSize: 12,
      padding: '8px 24px 0px 13px'
    },
    [theme.breakpoints.down(321)]: {
      fontSize: 10,
      padding: '6px 24px 0px 13px'
    },
  }
}))(InputBase);

const styles = (theme) => {
  return {

    subHeader: {
      height: "132px",
      [theme.breakpoints.down('500')]: {
        height: "114px",
      },
      [theme.breakpoints.down('481')]: {
        height: "79px",
      },
      [theme.breakpoints.down('421')]: {
        height: "74px",
      },
      [theme.breakpoints.down('401')]: {
        height: "72px",
      },
      [theme.breakpoints.down('381')]: {
        height: "63px",
      },
      [theme.breakpoints.down('381')]: {
        height: "63px",
      },
      [theme.breakpoints.down('321')]: {
        height: "55px",
      },
    },
    cardpadding: {
      padding: "20px",
      [theme.breakpoints.down('641')]: {
        padding: "16px",
      },
      [theme.breakpoints.down('481')]: {
        padding: "12px",
      },
      [theme.breakpoints.down('421')]: {
        padding: "10px",
      },
      [theme.breakpoints.down('401')]: {
        padding: "8px",
      },
      [theme.breakpoints.down('381')]: {
        padding: "8px",
      },
      [theme.breakpoints.down('361')]: {
        padding: "8px",
      },
      [theme.breakpoints.down('321')]: {
        padding: "8px",
      },
    },
    select_box: {
      width: '100%',
      display: 'block',
      background: whiteColor,
      border: `1px solid ${borderGrey}`,
      borderRadius: '4px',
      position: 'relative',
      overflow: 'hidden',
      height: '92px',

      [theme.breakpoints.down(641)]: {
        height: '80px'
      },
      [theme.breakpoints.down(481)]: {
        height: '54px'
      },
      [theme.breakpoints.down(381)]: {
        height: '46px'
      },
      [theme.breakpoints.down(321)]: {
        height: '40px'
      },
    },
    down_icon: {
      position: "absolute",
      pointerEvents: "none",
      top: "calc(62% - px)",
      right: 0,
      width: 14,
      height: 10,
      fontWeight: "bold",
      [theme.breakpoints.down(641)]: {
        top: "calc(62% - 3px)",
        right: 0,
        width: 14,
        height: 10,
      },
      [theme.breakpoints.down(481)]: {
        top: "calc(60% - 2px)",
        right: 0,
        width: 9,
        height: 7,
      },
      [theme.breakpoints.down(421)]: {
        top: "calc(60% - 2px)",
        right: 0,
        width: 8,
        height: 6,
      },
      [theme.breakpoints.down(321)]: {
        top: "calc(60% - 2px)",
        right: 0,
        width: 6,
        height: 4,
      },
    },
    formControl: {
      minHeight: 'calc(100% - 92px)',
      '& .MuiTextField-root': {
        height: '92px'
      },
      '& .MuiInputLabel-root': {
        marginTop: '5px',
        fontSize: '20px'
      },
      '& .MuiFormHelperText-root': {
        fontSize: '0.623rem'
      },
      "& .MuiSelect-select:focus": {
        background: whiteColor,
      },
      [theme.breakpoints.down(641)]: {
        minHeight: 'calc(100% - 80px)',
        '& .MuiTextField-root': {
          height: '80px'
        },
        '& .MuiInputLabel-root': {
          fontSize: '16px',
          marginTop: '1px',
          fontFamily: 'Montserrat'
        }
      },
      [theme.breakpoints.down(481)]: {
        minHeight: 'calc(100% - 54px)',
        '& .MuiTextField-root': {
          height: '54px'
        },
        '& .MuiInputLabel-root': {
          fontSize: '14px',
          marginTop: '-7px'
        }
      },
      [theme.breakpoints.down(421)]: {
        '& .MuiInputLabel-root': {
          fontSize: '13.5px'
        }
      },
      [theme.breakpoints.down(401)]: {
        '& .MuiInputLabel-root': {
          fontSize: '13px'
        }
      },
      [theme.breakpoints.down(381)]: {
        minHeight: 'calc(100% - 46px)',
        '& .MuiTextField-root': {
          height: '46px'
        },
        '& .MuiInputLabel-root': {
          fontSize: '12.5px',
          marginTop: '-8px'
        }
      },
      [theme.breakpoints.down(361)]: {
        '& .MuiInputLabel-root': {
          fontSize: '12px'
        }
      },
      [theme.breakpoints.down(321)]: {
        minHeight: 'calc(100% - 40px)',
        '& .MuiTextField-root': {
          height: '40px'
        },
        '& .MuiInputLabel-root': {
          marginTop: '-11px',
          fontSize: '10px'
        }
      }
    },

    pageConatiner: {
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.common.globalBackground,
      // display: "block",
      // position: "absolute",
    },
    subContainer: {
      display: "flex",
    },
    contentContainer: {
      zIndex: "999",
      display: "inline-block",
      bottom: 4,
      position: "absolute",
      width: "99%",
      margin: "0 auto",
      left: 0,
      right: 0,
      [theme.breakpoints.down(641)]: { bottom: 12 },
      [theme.breakpoints.down(481)]: { bottom: iOS() ? 1 : 3 },
      [theme.breakpoints.down(361)]: { bottom: 8.5 },
      [theme.breakpoints.down(321)]: { bottom: 4 },
    },
    mapCss: {},
    paper: {
      height: "100%",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    content: {
      "& .MuiTypography-body1": {
        fontSize: "18px",
        [theme.breakpoints.down(641)]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down(481)]: { fontSize: "14px" },
        [theme.breakpoints.down(421)]: { fontSize: "13.5px" },
        [theme.breakpoints.down(401)]: { fontSize: "13px" },
        [theme.breakpoints.down(381)]: { fontSize: "12.5px" },
        [theme.breakpoints.down(361)]: { fontSize: "12px" },
        [theme.breakpoints.down(321)]: { fontSize: "10px" },
      },
      "& .MuiList-padding": {
        padding: 0,
      },
      "& .MuiListItem-root": {
        padding: "3px 16px",
        [theme.breakpoints.down("xs")]: {
          padding: "2px 10px",
        },
      },
    },
    title: {
      padding: "10px",
      fontWeight: "600",
      "& span": {
        fontWeight: "normal",
        paddingLeft: "2px",
      },
    },
    img: {
      // paddingRight: "10px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      objectFit: "contain",
      objectPosition: "center",
      width: "38px",
      height: "70px",
      [theme.breakpoints.down(641)]: { width: "36px", height: "22px" },
      [theme.breakpoints.down(481)]: { width: "34px", height: "20px" },
      [theme.breakpoints.down(381)]: { width: "32px", height: "18px" },
      [theme.breakpoints.down(321)]: { width: "30px", height: "16px" },
    },
    img1: {
      // paddingRight: "10px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      objectFit: "contain",
      objectPosition: "center",
      width: "58px",
      height: "70px",
      [theme.breakpoints.down(641)]: { width: "48px", height: "70px" },
      [theme.breakpoints.down(481)]: { width: "34px", height: "30px" },
      [theme.breakpoints.down(381)]: { width: "32px", height: "26px" },
      [theme.breakpoints.down(321)]: { width: "30px", height: "24px" },
    },
    divider: {
      width: "90%",
      margin: "0 auto",
    },
    noData: {
      textAlign: "center",
      fontSize: "18px",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: { fontSize: "14px" },
      [theme.breakpoints.down(421)]: { fontSize: "13.5px" },
      [theme.breakpoints.down(401)]: { fontSize: "13px" },
      [theme.breakpoints.down(381)]: { fontSize: "12.5px" },
      [theme.breakpoints.down(361)]: { fontSize: "12px" },
      [theme.breakpoints.down(321)]: { fontSize: "10px" },
    },
    locatorCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${LOCATOR})`,
      "mask-image": `url(.${LOCATOR})`,
      mask: `url(.${LOCATOR}) no-repeat center / contain`,
      "-webkit-mask": `url(.${LOCATOR}) no-repeat center / contain`,
    },
    contactCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${CONTACTUS})`,
      "mask-image": `url(.${CONTACTUS})`,
      mask: `url(.${CONTACTUS}) no-repeat center / contain`,
      "-webkit-mask": `url(.${CONTACTUS}) no-repeat center / contain`,
    },
    emailCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${EMAIL})`,
      "mask-image": `url(.${EMAIL})`,
      mask: `url(.${EMAIL}) no-repeat center / contain`,
      "-webkit-mask": `url(.${EMAIL}) no-repeat center / contain`,
    },
    WebsiteCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${WEBSITE})`,
      "mask-image": `url(.${WEBSITE})`,
      mask: `url(.${WEBSITE}) no-repeat center / contain`,
      "-webkit-mask": `url(.${WEBSITE}) no-repeat center / contain`,
    },
    iconwrapper: {
      padding: "6px 12px",
      [theme.breakpoints.down(641)]: { padding: "6px 12px" },
      [theme.breakpoints.down(481)]: { padding: "6px 12px" },
      [theme.breakpoints.down(421)]: { padding: "8px 12px" },
      [theme.breakpoints.down(401)]: { padding: "8px 12px" },
      [theme.breakpoints.down(381)]: { padding: "8px 12px" },
      [theme.breakpoints.down(361)]: { padding: "8px 12px" },
      [theme.breakpoints.down(321)]: { padding: "6px 12px" },
    },
    innericonwrapper: {
      height: 112,
      flex:1,
      [theme.breakpoints.down(641)]: { height: 92 },
      [theme.breakpoints.down(481)]: { height: 64 },
      [theme.breakpoints.down(421)]: { height: 64 },
      [theme.breakpoints.down(401)]: { height: 60 },
      [theme.breakpoints.down(381)]: { height: 58 },
      [theme.breakpoints.down(361)]: { height: 58 },
      [theme.breakpoints.down(321)]: { height: 52 },
    },
  };
};

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}



class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactus: {},
      contactNumber: "",
      emailId: "",
      merchantName: "",
      location: "",
      lng: "",
      lat: "",
      address: "",
      selectedlocation: "",
      locationArray: [],
      selectedLocationAddress: '',
    };
  }
  async componentDidMount() {
    this.props.changeActiveTab(4);
    this.props.showLoader(true);

    if (getStorageItem("user")) {
      this.getLocations();
      this.props.showLoader(true);
      const formData = new URLSearchParams();
      formData.append("platform", apiData.platform);
      formData.append("userId", JSON.parse(getStorageItem("user")).id);
      formData.append("merchant_id", apiData.merchant_id);
      formData.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
      await this.props.merchantDetails(formData);

      // await this.setState({
      //   lng:
      //     this.props.merchantDetailsResponse &&
      //     this.props.merchantDetailsResponse.merchant_head_office_addres &&
      //     this.props.merchantDetailsResponse.merchant_head_office_addres
      //       .longitude &&
      //     this.props.merchantDetailsResponse.merchant_head_office_addres
      //       .longitude,
      //   address:
      //     this.props.merchantDetailsResponse &&
      //     this.props.merchantDetailsResponse.merchant_head_office_addres &&
      //     this.props.merchantDetailsResponse.merchant_head_office_addres
      //       .address_line1 &&
      //     this.props.merchantDetailsResponse.merchant_head_office_addres
      //       .address_line1,
      //   lat:
      //     this.props.merchantDetailsResponse &&
      //     this.props.merchantDetailsResponse.merchant_head_office_addres &&
      //     this.props.merchantDetailsResponse.merchant_head_office_addres
      //       .latitude &&
      //     this.props.merchantDetailsResponse.merchant_head_office_addres
      //       .latitude,
      //   contactNumber:
      //     this.props.merchantDetailsResponse &&
      //     this.props.merchantDetailsResponse.contact_person_phone &&
      //     this.props.merchantDetailsResponse.contact_person_phone,
      //   emailId:
      //     this.props.merchantDetailsResponse &&
      //     this.props.merchantDetailsResponse.contact_person_email &&
      //     this.props.merchantDetailsResponse.contact_person_email,
      //   twiter:
      //     this.props.merchantDetailsResponse &&
      //     this.props.merchantDetailsResponse.twitter_url &&
      //     this.props.merchantDetailsResponse.twitter_url,
      //   fb:
      //     this.props.merchantDetailsResponse &&
      //     this.props.merchantDetailsResponse.facebook_url &&
      //     this.props.merchantDetailsResponse.facebook_url,
      //   insta:
      //     this.props.merchantDetailsResponse &&
      //     this.props.merchantDetailsResponse.instagram_url &&
      //     this.props.merchantDetailsResponse.instagram_url,
      //   linkedin:
      //     this.props.merchantDetailsResponse &&
      //     this.props.merchantDetailsResponse.linkedin_url &&
      //     this.props.merchantDetailsResponse.linkedin_url,
      //   whatsappNumber: this.props.merchantDetailsResponse &&
      //     this.props.merchantDetailsResponse.whatsapp_mobile_number &&
      //     this.props.merchantDetailsResponse.whatsapp_mobile_number,
      //   merchantName:
      //     this.props.merchantDetailsResponse &&
      //     this.props.merchantDetailsResponse.merchant_name &&
      //     this.props.merchantDetailsResponse.merchant_name,
      //   location:
      //     this.props.merchantDetailsResponse &&
      //     this.props.merchantDetailsResponse.merchant_head_office_addres &&
      //     this.props.merchantDetailsResponse.merchant_head_office_addres
      //       .city_name &&
      //     this.props.merchantDetailsResponse.merchant_head_office_addres
      //       .city_name,
      // });
      setTimeout(() => this.props.showLoader(false), 2000);
    } else {
      this.props.logoutUser();
    }

  }

  getLocations() {
    const data = new FormData();
    data.append("merchant_id", apiData.merchant_id);
    data.append("platform", apiData.platform);
    data.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
    stockAPI(data, 'POST', '/getStoreLocations')
      .then(response => {
        let data = response;
        this.contactDetails(data.locations[0]);
        this.state.locationArray = [...data.locations]
      })
      .catch((err) => {
        openAlertBox(err && err.message, 'error');
      });
  }

  contactDetails = async (merchantDetails) => {
    await this.setState({
      lng:
        merchantDetails &&
        merchantDetails.longitude,
      address:
        merchantDetails &&
        merchantDetails.address,
      lat:
        merchantDetails &&
        merchantDetails.latitude,
      contactNumber:
        merchantDetails &&
        merchantDetails.primary_contact_number,
      emailId:
        merchantDetails &&
        merchantDetails.primary_contact_email,
      twiter:
        merchantDetails &&
        merchantDetails.twitter_url &&
        merchantDetails.twitter_url,
      fb:
        merchantDetails &&
        merchantDetails.facebook_url &&
        merchantDetails.facebook_url,
      insta:
        merchantDetails &&
        merchantDetails.instagram_url &&
        merchantDetails.instagram_url,
      linkedin:
        merchantDetails &&
        merchantDetails.linkedin_url &&
        merchantDetails.linkedin_url,
      whatsappNumber: merchantDetails &&
        merchantDetails.whatsapp_mobile_number &&
        merchantDetails.whatsapp_mobile_number,
      merchantName:
        merchantDetails &&
        merchantDetails.primary_contact_name &&
        merchantDetails.primary_contact_name,
      location:
        merchantDetails &&
        merchantDetails.location_name,
      selectedlocation: merchantDetails.id,
      websiteLink: merchantDetails &&
        merchantDetails.website_url &&
        merchantDetails.website_url,
    });

  }

  openMap = (lat, lng, address) => {
    if ((!lat || !lng) && address) {
      if ("standalone" in window.navigator && window.navigator.standalone) {
        var win = window.open(
          "https://maps.google.com/?q=" + address + "",
          "_top"
        );
        win.focus();
      } else {
        var win = window.open(
          "https://maps.google.com/?q=" + address + "",
          "_blank"
        );
        win.focus();
      }
    } else {
      if ("standalone" in window.navigator && window.navigator.standalone) {
        var win = window.open(
          "https://maps.google.com/?q=" + lat + "," + lng + "",
          "_top"
        );
        win.focus();
      } else {
        var win = window.open(
          "https://maps.google.com/?q=" + lat + "," + lng + "",
          "_blank"
        );
        win.focus();
      }
    }
  };
  render() {

    const { classes } = this.props;
    return (
      <Fragment>
        <Header screenLabel={headerScreenLabels.contactUs} />
        <div>
          <Card className={classes.subHeader}>

            <CardContent className={classes.cardpadding}>
              <div className={classes.select_box}>

                <FormControl fullWidth className={classes.formControl}>

                  <Select
                    value={this.state.selectedlocation}
                    onChange={(e) => {
                      this.setState({
                        selectedlocation: e.target.value,
                      });
                      let result = this.state.locationArray.filter(i => i.id == e.target.value);
                      this.contactDetails(result[0]);
                    }}

                    input={<CustomInput />}

                    IconComponent={() => (

                      <img className={classes.down_icon} src={down_arrow} alt="" />
                    )}
                    MenuProps={MenuProps}
                  >

                    <MenuItem disabled value={1}>
                      Select location
										</MenuItem>
                    {this.state.locationArray.map((loc) => (

                      <MenuItem key={loc.id} value={loc.id}>{loc.location_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </CardContent>
          </Card>
        </div>

        <MyRoute
          normalHeight={true}
          billUploadStatus={this.props.uploadingStatus}
        >
          <div className={classes.pageConatiner}>
            {!this.state.lat || !this.state.lng ? (
              <div className={classes.noData}> Loading map please wait!...</div>
            ) : (
              <div className={classes.subContainer}>
                <div className={classes.mapCss}>
                  <Map lat={this.state.lat} lng={this.state.lng} />
                </div>
                <div className={classes.contentContainer}>
                  <Paper className={classes.paper}>
                    <div className={classes.title}>
                      {this.state.merchantName}
                      <span>({this.state.location})</span>
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.content}>
                      <List>
                        <ListItemLink
                          onClick={() =>
                            this.openMap(
                              this.state.lat,
                              this.state.lng,
                              this.state.address
                            )
                          }
                        >
                          <ListItemIcon>
                            <span
                              className={classes.img + " " + classes.locatorCss}
                            />
                          </ListItemIcon>
                          <ListItemText primary={this.state.address} />
                        </ListItemLink>
                        <ListItemLink href={`tel:${this.state.contactNumber}`}>
                          <ListItemIcon>
                            <span
                              className={classes.img + " " + classes.contactCss}
                            />
                          </ListItemIcon>
                          <ListItemText primary={this.state.contactNumber} />
                        </ListItemLink>
                        <ListItemLink
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.assign(
                              `mailto:${this.state.emailId}`
                            );
                          }}
                        >
                          <ListItemIcon>
                            <span
                              className={classes.img + " " + classes.emailCss}
                            />
                          </ListItemIcon>
                          <ListItemText primary={this.state.emailId} />
                        </ListItemLink>
                        <ListItemLink
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `${this.state.websiteLink}`
                            );
                          }}
                        >
                          <ListItemIcon>
                            <span
                              className={classes.img + " " + classes.WebsiteCss}
                            />
                          </ListItemIcon>
                          <ListItemText primary={this.state.websiteLink} />
                        </ListItemLink>
                        <Grid
                          container
                          spacing={1}
                          className={classes.iconwrapper}
                          alignItems="center"
                          justify="center"
                        >

                          {this.state.whatsappNumber &&
                            (this.state.whatsappNumber === "9999999999" ? null :
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                className={classes.innericonwrapper}
                                style={{ width: "100%", display: "table" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  // window.open(`fb:${this.state.fb.replace(":", "s:")}/`);
                                  window.open(
                                    `${WHATSAPP_LINK}${this.state.whatsappNumber}?text=Hi`
                                  );
                                }}
                              >
                                <Paper
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    display: "table-cell",
                                  }}
                                >
                                  <img className={classes.img1} src={WHATSAPPLOGO} alt="" />
                                </Paper>
                              </Grid>
                            )}



                          {this.state.fb && (
                            <Grid
                              item
                              xs={3}
                              sm={3}
                              className={classes.innericonwrapper}
                              style={{ width: "100%", display: "table" }}
                              onClick={(e) => {
                                e.preventDefault();
                                // window.open(`fb:${this.state.fb.replace(":", "s:")}/`);
                                window.open(
                                  `${this.state.fb.replace(":", "s:")}/`
                                );
                              }}
                            >
                              <Paper
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  display: "table-cell",
                                }}
                              >
                                <img className={classes.img1} src={FB} alt="" />
                              </Paper>
                            </Grid>
                          )}

                          {this.state.twiter && (
                            <Grid
                              item
                              xs={3}
                              sm={3}
                              className={classes.innericonwrapper}
                              style={{ width: "100%", display: "table" }}
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `${this.state.twiter.replace(":", "s:")}/`
                                );
                              }}
                            >
                              <Paper
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  display: "table-cell",
                                }}
                              >
                                <img
                                  className={classes.img1}
                                  src={TWITER}
                                  alt=""
                                />
                              </Paper>
                            </Grid>
                          )}
                          {this.state.insta && (
                            <Grid
                              item
                              xs={3}
                              sm={3}
                              className={classes.innericonwrapper}
                              style={{ width: "100%", display: "table" }}
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(`${this.state.insta}/`);
                              }}
                            >
                              <Paper
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  display: "table-cell",
                                }}
                              >
                                <img
                                  className={classes.img1}
                                  src={insta}
                                  alt=""
                                />
                              </Paper>
                            </Grid>
                          )}
                          {this.state.linkedin && (
                            <Grid
                              item
                              xs={3}
                              sm={3}
                              className={classes.innericonwrapper}
                              style={{ width: "100%", display: "table" }}
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `${this.state.linkedin.replace(":", "s:")}/`
                                );
                              }}
                            >
                              <Paper
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                  display: "table-cell",
                                }}
                              >
                                <img
                                  className={classes.img1}
                                  src={linkedin}
                                  alt=""
                                />
                              </Paper>
                            </Grid>
                          )}
                        </Grid>
                      </List>
                    </div>
                  </Paper>
                </div>
              </div>
            )}
          </div>
        </MyRoute>
      </Fragment >
    );
  }
}
const mapStateToProps = (state) => {
  return {
    merchantDetailsResponse: state.reducerDashboard.merchantDetailsResponse,
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
  };
};
const mapDispatchToProps = {
  changeActiveTab,
  logoutUser,
  showLoader,
  merchantDetails,
};
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(ContactUs)
);
