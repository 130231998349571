import React, { Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import { Avatar, Typography } from "@material-ui/core";
import { getStorageItem } from "../../common/localStorage";
import { primaryOrange, maroonRed, green, skyBlue } from "../UI/Theme";
import default_product from "../../assets/images/product/default_product.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { lightGrey, color } from "../../components/UI/Theme";
import moment from "moment";
import { styles } from "./OrderSummaryCardStyle";
import { openAlertBox } from "../../common/AlertMessage/AlertMessage";
import classnames from "classnames";

const OrderSummaryCard = (props) => {
  const { classes } = props;
  const {
    brandName,
    imgSrc,
    voucherValue,
    subProductName,
    itemQuantity,
    price,
    showStatus,
    location_name,
    status,
    e_vouchers,
    expiry_date,
    key,
  } = props;
  const currencySymbol = getStorageItem("currencyCode")
    ? getStorageItem("currencyCode")
    : "₹";
  const statusColor =
    status === "Collected" ||
    status === "Delivered" ||
    status === "Delivered/Collected"
      ? green
      : status === "Ordered" || status === "Kept Aside"
      ? primaryOrange
      : status === "Partially(D/R/C)"
      ? skyBlue
      : maroonRed;

  return (
    <div className={classes.root} key={key}>
      <div
        className={classnames(classes.rootChild, {
          [classes.mb10]: e_vouchers.length > 0,
        })}
        key={key}
      >
        <div className={classes.imageContainer}>
          <img alt={"product"} src={imgSrc ? imgSrc : default_product} />
        </div>
        <div className={classes.details}>
          <div className={classes.main_content}>
            <div className={classes.pro_content}>
              <div className={classes.productDetails}>
                <Typography className={classes.brandName}>
                  {brandName}
                </Typography>
                <Typography className={classes.productName}>
                  {subProductName}
                </Typography>
              </div>
            </div>
            <Typography className={classes.price_mallName}>
              <Typography className={classes.price_mall} component="span">
                {`${currencySymbol} ${voucherValue} voucher`}
              </Typography>
              <Typography className={classes.separator} component="span">
                {location_name && "|"}
              </Typography>
              <Typography className={classes.price_mall} component="span">
                {location_name}
              </Typography>
            </Typography>
          </div>
          <div className={classes.point_cal}>
            <div className={classes.sub_content}>
              <span className={classes.rsp}>
                {price}{" "}
                {getStorageItem("pointShortName")
                  ? getStorageItem("pointShortName")
                  : "P"}
              </span>
              <span className={classes.mgSmallClose}>X</span>
              <span className={classes.rsp}>{itemQuantity}</span>
            </div>
            <Typography className={classes.price_points}>
              {price * itemQuantity}{" "}
              {getStorageItem("pointShortName")
                ? getStorageItem("pointShortName")
                : "P"}
            </Typography>
          </div>
        </div>
        {showStatus && (
          <div
            className={classes.orderStatus}
            style={{ borderColor: statusColor }}
          >
            <Typography
              className={classes.orderStatusValue}
              style={{ color: statusColor }}
            >
              {status === "Delivered/Collected" ? "Delivered" : status}
            </Typography>
          </div>
        )}
      </div>
      {e_vouchers &&
        e_vouchers.map((voucher, number) => {
          return (
            <div
              className={classes.voucher_wrapper}
              key={voucher.voucher_pin + "-" + number}
            >
              {e_vouchers.length !== 1 ? (
                <Avatar
                  aria-label="recipe"
                  className={classes.avtarCss + " " + classes.silver}
                >
                  {number + 1}
                </Avatar>
              ) : (
                <div></div>
              )}
              <div className={classes.voucherContainer}>
                {/* {voucher_code && !voucher_pin && (
            <div className={classes.coupons_box + " " + classes.noSelect}>
              <CopyToClipboard
                text={voucher_code}
                onCopy={() =>
                  openAlertBox("Voucher copied to clipboard", "success")
                }
              >
                <Typography
                  className={classes.listVoucher}
                  style={{
                    color: color,
                    float: "right",
                    textAlign: "right",
                  }}
                >
                  {voucher_code}
                </Typography>
              </CopyToClipboard>
            </div>
          )} */}
                <div
                  className={classnames(
                    classes.voucher_pin_box + " " + classes.noSelect,
                    { [classes.pd]: !voucher.voucher_pin }
                  )}
                >
                  <div>
                    {voucher.voucher_pin && (
                      <Typography
                        className={classes.listVoucher}
                        style={{
                          color: lightGrey,
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        Voucher #
                      </Typography>
                    )}
                    <CopyToClipboard
                      text={voucher.voucher_code}
                      onCopy={() =>
                        openAlertBox("Voucher copied to clipboard", "success")
                      }
                    >
                      <Typography
                        className={classes.listVoucher}
                        style={{
                          color: color,
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        {voucher.voucher_code}
                      </Typography>
                    </CopyToClipboard>
                  </div>
                  {voucher.voucher_pin && (
                    <div>
                      <CopyToClipboard
                        text={voucher.voucher_pin}
                        onCopy={() => {
                          openAlertBox(
                            "Voucher Pin copied to clipboard",
                            "success"
                          );
                        }}
                      >
                        <Typography
                          className={classes.voucherPin}
                          style={{
                            float: "right",
                            textAlign: "right",
                          }}
                          component={"span"}
                        >
                          <div className={classes.pin}>PIN</div>
                          {voucher.voucher_pin}
                        </Typography>
                      </CopyToClipboard>
                    </div>
                  )}
                </div>
                {/* {(voucher.voucher_code || voucher.voucher_pin) && (
                  <Typography
                    className={classes.dateValidity}
                    style={{
                      color: lightGrey,
                      float: "right",
                      textAlign: "left",
                    }}
                  >
                    {expiry_date
                      ? "Valid till" +
                        " " +
                        moment(expiry_date).format("Do MMM' YY")
                      : "-"}
                  </Typography>
                )} */}
              </div>
            </div>
          );
        })}
           <div>
            <Typography  className={classes.dateValidity}
                    style={{
                      color: lightGrey,
                      float: "right",
                      textAlign: "left",
                      marginRight:'6px'
                    }}>
                {expiry_date
                      ? "Valid till" +
                        " " +
                        moment(expiry_date).format("Do MMM' YY")
                      : "-"}
              </Typography>
              </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(OrderSummaryCard);
