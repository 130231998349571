import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
    return {
        plusIcon: {
			height: '60%',
			width: '60%'
		},
		newContainer: {
			backgroundColor: theme.palette.cards.backgroundColor,
			border: '2px dashed #cecece',
			borderRadius: '5px',
			cursor: 'pointer',
            display: 'flex !important',
			alignItems: 'center !important',
            justifyContent: 'center !important',
            width: '92px',
			height: '92px',
			[theme.breakpoints.down('xs')]: {
				border: '1px dashed #cecece',
            },
            [theme.breakpoints.down(641)]: {
				height: '80px',
				width: '80px',
			},
            [theme.breakpoints.down(481)]: {
				width: '54px',
				height: '54px',
			},
			[theme.breakpoints.down(381)]: {
				width: '46px',
				height: '46px'
			},
			[theme.breakpoints.down(321)]: {
				width: '40px',
				height: '40px'
			}
        }
    };
});