import { lightPrimaryColor2 } from "../../../../components/UI/Theme";

export const styles = (theme) => {
  return {
    root: {
      maxWidth: "660px",
      margin: 'auto',
      padding: "30px",
      [theme.breakpoints.down("610")]: {
        padding: "28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
    },
    status: {
      background: lightPrimaryColor2,
      display: "flex",
      alignItems: "center",
      padding: "30px 33px",
      marginBottom: "30px",
      marginLeft: "-30px",
      marginRight: "-30px",
      [theme.breakpoints.down("610")]: {
        marginBottom: "28px",
        marginLeft: "-28px",
        marginRight: "-28px",
        padding: "28px 31px",
      },
      [theme.breakpoints.down("481")]: {
        marginBottom: "21px",
        marginLeft: "-21px",
        marginRight: "-21px",
        padding: "21px 24px",
      },
      [theme.breakpoints.down("381")]: {
        marginLeft: "-15px",
        marginRight: "-15px",
        marginBottom: "15px",
        padding: "15px 18px",
      },
      [theme.breakpoints.down("321")]: {
        marginBottom: "13px",
        marginLeft: "-13px",
        marginRight: "-13px",
        padding: "13px 16px",
      },
    },
    doneBtn: {
      ...theme.palette.typography.doneButtons,
      width: "100%",
      height: "92px",
      fontSize: "22px",
      lineHeight: "18px",
      marginBottom: "0px !important",
      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "16px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "14px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
        fontSize: "12.5px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    dont: {
      cursor: "pointer",
      color: "#211f20",
      fontSize: "24px",
      [theme.breakpoints.down("641")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "11px",
      },
    },
    checkbox: {
      cursor: "pointer",
      backgroundColor: "#fff",
      borderRadius: "6px",
      marginRight: "30px",
      width: 40,
      [theme.breakpoints.down("610")]: {
        marginRight: "28px",
      },
      [theme.breakpoints.down(641)]: {
        width: 40,
      },
      [theme.breakpoints.down(481)]: {
        width: 28,
        marginRight: "21px",
      },
      [theme.breakpoints.down(421)]: {
        width: 28,
      },
      [theme.breakpoints.down(401)]: {
        width: 27,
      },
      [theme.breakpoints.down(381)]: {
        width: 21,
        marginRight: "15px",
      },
      [theme.breakpoints.down(361)]: {
        width: 24,
      },
      [theme.breakpoints.down(321)]: {
        width: 22,
        marginRight: "13px",
      },
    },
  };
};
