import React, { Component, Fragment } from 'react';
import { apiData } from '../../../common/common-types';
import { openAlertBox } from '../../../common/AlertMessage/AlertMessage';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { styles } from './ProfileContainerStyles';
import { removeSaveMessage } from './redux/Action';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { changeActiveTab, logoutUser, showLoader } from '../../../App/redux/action';
import { connect } from 'react-redux';
import { stockAPI } from '../../../common/axiosInstance';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { getStorageItem, setStorageItem } from '../../../common/localStorage';

const mapDispatchToProps = {
	changeActiveTab,
	logoutUser,
	showLoader,
	removeSaveMessage
};

const mapStateToProps = (state) => ({
	showmsg: state.reducerProfile.showmsg,
});

const gender = [ '', 'Male', 'Female', 'Other' ];
const martialStatus = [ '', 'Single', 'Married', 'Widow' ];

class Profile extends Component {
	state = {
		fname: '',
		lname: '',
		mobileNumber: '',
		email: '',
		pincode: '',
		gender: '',
		maritalStatus: '',
		dob: '',
		spouseDOB: '',
		anniversaryDate: '',
		city: '',
		state: '',
		country: '',

		pincodeId: '',
		verifiedEmail: false,
		deactivatedTime: getStorageItem('deactivatedTime') || null,

		show: true,
      	scrollPos: 0,
		isDisableBtn:false
	};

	componentDidMount() {
		window.addEventListener("scroll", this.detectPageScroll);
		this.props.showLoader(true);
		if (this.props.showmsg) {
			openAlertBox('Profile updated successfully', 'success');
		}
		this.props.removeSaveMessage();
		if (getStorageItem('user')) {
			const formData = new URLSearchParams();
			formData.append('platform', apiData.platform);
			formData.append('userId', JSON.parse(getStorageItem('user')).id);
			formData.append('merchant_id', apiData.merchant_id);
			formData.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);

			stockAPI(formData, 'POST', '/getMerchantDetails').then((response) => {
				this.props.showLoader(false);
				if (response.merchant_list && response.merchant_list.added[1].customerDetails) {
					this.setState({
						fname: response.merchant_list.added[1].customerDetails.first_name,
						lname: response.merchant_list.added[1].customerDetails.last_name,
						mobileNumber: response.merchant_list.added[1].customerDetails.mobile_number,
						email: response.merchant_list.added[1].customerDetails.email_address,
						pincode: response.merchant_list.added[1].customerDetails.pin_code,
						pincodeId: response.merchant_list.added[1].customerDetails.pin_code_id,
						gender:
							response.merchant_list.added[1].customerDetails.gender &&
							gender[response.merchant_list.added[1].customerDetails.gender],
						maritalStatus:
							response.merchant_list.added[1].customerDetails.marital_status &&
							martialStatus[response.merchant_list.added[1].customerDetails.marital_status],
						dob:
							response.merchant_list.added[1].customerDetails.date_of_birth === ''
								? ''
								: moment(
										new Date(response.merchant_list.added[1].customerDetails.date_of_birth)
									).format('DD - MMM - YYYY'),
						spouseDOB:
							response.merchant_list.added[1].customerDetails.spouse_dob === ''
								? ''
								: moment(new Date(response.merchant_list.added[1].customerDetails.spouse_dob)).format(
										'DD - MMM - YYYY'
									),
						anniversaryDate:
							response.merchant_list.added[1].customerDetails.anniversary_date === ''
								? ''
								: moment(
										new Date(response.merchant_list.added[1].customerDetails.anniversary_date)
									).format('DD - MMM - YYYY'),
						verifiedEmail:
							response.merchant_list.added[1].customerDetails.email_verified === '1' ? true : false
					});
				} else {
					openAlertBox('Oops something went wrong!', 'error');
				}
			});
		} else {
			this.props.showLoader(false);
			this.props.logoutUser();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.pincodeId !== prevState.pincodeId) {
			this.getAddressDetail();
		}
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.detectPageScroll);
		if (this.state.verifiedEmail) {
			localStorage.removeItem('deactivatedTime');
		}
	}

	getAddressDetail = () => {
		if (this.state.pincodeId !== '') {
			const formData = new URLSearchParams();
			formData.append('pin_code', this.state.pincodeId);
			stockAPI(formData, 'POST', '/getPincodeStateCity').then((response) => {
				if (response) {
					this.setState({
						city: response.cityName,
						state: response.stateName,
						country: response.countryName
					});
				} else {
					openAlertBox('Oops something went wrong!', 'error');
				}
			});
		}
	};

	pincodeValue = (e) => {
		this.setState({
			pincode: e.target.value,
			pincodeId: e.target.id,
			pinCodeDiv: false
		});
	};

	openEditMode = (e) => {
		e.preventDefault();
		this.props.history.push('/edit-profile');
	};

	submitButton = (e) => {
		e.preventDefault();
	};

	handleVerifyEmailBtnClicked = (e) => {
		this.setState({
			...this?.state,
			isDisableBtn: true
		})
		if (this.state.email !== '') {
			const formData = new URLSearchParams();
			formData.append('platform', apiData.platform);
			formData.append('merchant_id', apiData.merchant_id);
			formData.append('email_address', this.state.email);
			formData.append('mobile_number', this.state.mobileNumber);
			stockAPI(formData, 'POST', '/requestToVerifyCustomerEmail', null, null, null, true)
			.then(response => {
				if (response.statusCode === 100) {
					openAlertBox('Verification email has been sent successfully.', 'success');
					this.setState({
						deactivatedTime: moment().add(5, 'minutes').toDate().getTime()
					}, () => {
						setStorageItem('deactivatedTime', this.state.deactivatedTime);
					});
					this.setState({
						...this?.state,
						isDisableBtn: false
					})
				} else {
					openAlertBox('Oops something went wrong!', 'error');
					this.setState({
						...this?.state,
						isDisableBtn: false
					})
				}
			})
		}else{
			this.setState({
				...this?.state,
				isDisableBtn: false
			})
		}
	};

	detectPageScroll = (e) => {
		this.setState({
			scrollPos: document.body.getBoundingClientRect().top,
			show: document.body.getBoundingClientRect().top > this.state.scrollPos
		});

	}

	render() {
		const { classes } = this.props;

		return (
			<Fragment>
			<div className={classes.pageContainer}>
				<div className={classes.root}>
					<ValidatorForm
						ref="form"
						onSubmit={this.submitButton}
						onError={(errors) => console.log(errors)}
						className={classes.formControl}
					>
						<div className={classes.itemContainer + ' ' + classes.padd_right}>
							<TextValidator
								InputProps={{
									classes: {
										underline: classes.cssUnderline,
										input: classes.input
									}
								}}
								InputLabelProps={{
									classes: {
										root: classes.cssLabel,
										focused: 'focused',
										shrink: 'shrink'
									}
								}}
								className={classes.fieldSet}
								label="First Name"
								value={this.state.fname}
								FormHelperTextProps={{ style: { marginLeft: '10px' } }}
								disabled
							/>
						</div>
						<div className={classes.itemContainer + ' ' + classes.padd_left}>
							<TextValidator
								InputProps={{
									classes: {
										underline: classes.cssUnderline,
										input: classes.input
									}
								}}
								InputLabelProps={{
									classes: {
										root: classes.cssLabel,
										focused: 'focused',
										shrink: 'shrink'
									}
								}}
								className={classes.fieldSet}
								label="Last Name"
								value={this.state.lname}
								FormHelperTextProps={{ style: { marginLeft: '10px' } }}
								disabled
							/>
						</div>

						<TextValidator
							InputProps={{
								classes: {
									underline: classes.cssUnderline,
									input: classes.input
								}
							}}
							InputLabelProps={{
								classes: {
									root: classes.cssLabel,
									focused: 'focused',
									shrink: 'shrink'
								}
							}}
							className={classes.fieldSet}
							disabled
							label="Mobile Number"
							value={'+91 ' + this.state.mobileNumber}
						/>
						{this.state.verifiedEmail ? (
							<div style={{ position: 'relative' }}>
								<TextValidator
									InputProps={{
										classes: {
											underline: classes.cssUnderline,
											input: classes.input
										}
									}}
									InputLabelProps={{
										classes: {
											root: classes.cssLabel,
											focused: 'focused',
											shrink: 'shrink'
										}
									}}
									className={classes.fieldSet}
									label="Email Address"
									value={this.state.email}
									disabled
								/>
								<span className={classes.greenTickCss + ' ' + classes.tick} />
							</div>
						) : (
							<div className={classes.email_verifyBtn_container}>
								<div className={classes.emailFieldContainer}>
									<TextValidator
										InputProps={{
											classes: {
												underline: classes.cssUnderline,
												input: classes.input
											}
										}}
										InputLabelProps={{
											classes: {
												root: classes.cssLabel,
												focused: 'focused',
												shrink: 'shrink'
											}
										}}
										className={classes.fieldSet}
										label="Email Address"
										value={this.state.email}
										disabled
									/>
								</div>
								<div className={classes.verifyBtnContainer}>
									<Button
										onClick={this.handleVerifyEmailBtnClicked}
										disableRipple
										className={classes.submitBtn}
										disabled={
											this.state.deactivatedTime &&
											this.state.deactivatedTime >= moment().toDate().getTime() || this?.state?.isDisableBtn
										}
									>
										VERIFY
									</Button>
								</div>
							</div>
						)}
						<div className={classes.itemContainer + ' ' + classes.padd_right}>
							<TextValidator
								className={classes.fieldSet}
								label="Pincode"
								value={this.state.pincode}
								validators={[ 'required' ]}
								errorMessages={[ 'Pincode is required' ]}
								inputProps={{
									maxLength: 6,
									type: 'tel'
								}}
								InputProps={{
									classes: {
										underline: classes.cssUnderline,
										input: classes.input
									}
								}}
								InputLabelProps={{
									classes: {
										root: classes.cssLabel,
										focused: 'focused',
										shrink: 'shrink'
									}
								}}
								onChange={(e) => this.onPinchange(e)}
								helperText={this.state.helperText}
								error={this.state.error}
								disabled
							/>
						</div>
						<div className={classes.itemContainer + ' ' + classes.padd_left}>
							<TextValidator
								InputProps={{
									classes: {
										underline: classes.cssUnderline,
										input: classes.input
									}
								}}
								InputLabelProps={{
									classes: {
										root: classes.cssLabel,
										focused: 'focused',
										shrink: 'shrink'
									}
								}}
								className={classes.fieldSet}
								label="City"
								name="city"
								value={this.state.city}
								disabled
							/>
						</div>
						<div className={classes.itemContainer + ' ' + classes.padd_right}>
							<TextValidator
								InputProps={{
									classes: {
										underline: classes.cssUnderline,
										input: classes.input
									}
								}}
								InputLabelProps={{
									classes: {
										root: classes.cssLabel,
										focused: 'focused',
										shrink: 'shrink'
									}
								}}
								className={classes.fieldSet}
								label="State"
								value={this.state.state}
								disabled
							/>
						</div>
						<div className={classes.itemContainer + ' ' + classes.padd_left}>
							<TextValidator
								InputProps={{
									classes: {
										underline: classes.cssUnderline,
										input: classes.input
									}
								}}
								InputLabelProps={{
									classes: {
										root: classes.cssLabel,
										focused: 'focused',
										shrink: 'shrink'
									}
								}}
								className={classes.fieldSet}
								label="Country"
								name="country"
								value={this.state.country}
								disabled
							/>
						</div>
						<div className={classes.itemContainer + ' ' + classes.padd_right}>
							<TextValidator
								InputProps={{
									classes: {
										underline: classes.cssUnderline,
										input: classes.input
									}
								}}
								InputLabelProps={{
									classes: {
										root: classes.cssLabel,
										focused: 'focused',
										shrink: 'shrink'
									}
								}}
								className={classes.fieldSet}
								label="Gender"
								value={this.state.gender}
								disabled
							/>
						</div>
						<div className={classes.itemContainer + ' ' + classes.padd_left}>
							<TextValidator
								InputProps={{
									classes: {
										underline: classes.cssUnderline,
										input: classes.input
									}
								}}
								InputLabelProps={{
									classes: {
										root: classes.cssLabel,
										focused: 'focused',
										shrink: 'shrink'
									}
								}}
								className={classes.fieldSet}
								label="Date of Birth"
								value={this.state.dob}
								disabled
							/>
						</div>

						<TextValidator
							InputProps={{
								classes: {
									underline: classes.cssUnderline,
									input: classes.input
								}
							}}
							InputLabelProps={{
								classes: {
									root: classes.cssLabel,
									focused: 'focused',
									shrink: 'shrink'
								}
							}}
							className={classes.fieldSet}
							label="Marital Status"
							value={this.state.maritalStatus}
							disabled
						/>
						{this.state.maritalStatus === 'Married' && (
							<Fragment>
								<div className={classes.itemContainer + ' ' + classes.padd_right}>
									<TextValidator
										InputProps={{
											classes: {
												underline: classes.cssUnderline,
												input: classes.input
											}
										}}
										InputLabelProps={{
											classes: {
												root: classes.cssLabel,
												focused: 'focused',
												shrink: 'shrink'
											}
										}}
										className={classes.fieldSet}
										label="Spouse DoB"
										value={this.state.spouseDOB}
										disabled
									/>
								</div>
								<div className={classes.itemContainer + ' ' + classes.padd_left}>
									<TextValidator
										InputProps={{
											classes: {
												underline: classes.cssUnderline,
												input: classes.input
											}
										}}
										InputLabelProps={{
											classes: {
												root: classes.cssLabel,
												focused: 'focused',
												shrink: 'shrink'
											}
										}}
										className={classes.fieldSet}
										label="Anniversary Date"
										value={this.state.anniversaryDate}
										disabled
									/>
								</div>
							</Fragment>
						)}
						<div style={{clear: "both"}}></div>
					</ValidatorForm>
					<div className={classes.btnContainer}>
						<Button onClick={this.openEditMode} disableRipple className={classes.submitBtn}>
							EDIT PROFILE
						</Button>
					</div>
					<div style={{clear: "both"}}></div>
				</div>
				<div style={{clear: "both"}}></div>
			</div>
			<div style={{clear: "both"}}></div>
			</Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles, { withTheme: true })(Profile)));
