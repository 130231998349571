import { stockAPI } from "../../../../common/axiosInstance";
import {
  FETCH_CART_LIST,
  GET_ERRORS_CART_LIST,
  CLEAR_CARTLIST,
} from "./actionTypes";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";

export const getCartList = (bodyObj) => (dispatch) => {
  return stockAPI(bodyObj, "POST", "/getCart", null, null, undefined, true)
    .then(async (response) => {
      if (response.response) {
        await dispatch({ type: FETCH_CART_LIST, payload: response.response });
      }else{        
        if (response.response === '') {
        } else {
          openAlertBox('Oops something went wrong!', 'error');
        }
      }
    })
    .catch(function (err) {
      openAlertBox(err && err.message, "error");
      dispatch({ type: GET_ERRORS_CART_LIST, payload: err });
    });
};

export const clearCartList = () => (dispatch) => {
  return dispatch({ type: CLEAR_CARTLIST });
};
