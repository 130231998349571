import React from "react";
import { withStyles } from "@material-ui/styles";
import { styles } from "./NotificationCardStyles";
import NotificationIcon from "../NotificationIcon/NotificationIcon";
import { IconButton, Typography } from "@material-ui/core";
import moment from "moment";
import Swipeout from "rc-swipeout";
import "rc-swipeout/assets/index.css";
import deleteBtn from "../../assets/icons/notifications/delete.svg";

const swipeoutStyle = {
  backgroundColor: "#ec4343",
  color: "white",
};
const NotificationCard = (props) => {
  const {
    classes,
    read,
    success,
    title,
    body,
    date,
    handleNotificationClicked,
    notification_image,
  } = props;

  const notification_background_color = read ? "#ffffff" : "#f1f1f1";

  return (
    <Swipeout
      right={[
        {
          text: (
            <IconButton>
              <img
                src={deleteBtn}
                alt="delete"
                className={classes.deleteBtnIcon}
              />
            </IconButton>
          ),
          onPress: () => {
            props.handleDeleteNotification(props.id);
          },
          style: { ...swipeoutStyle },
        },
      ]}
      autoClose={true}
    >
      <div
        className={classes.root}
        onClick={() =>
          handleNotificationClicked(props.uniqueId, props.notification)
        }
        style={{ backgroundColor: notification_background_color }}
      >
        <div className={classes.notication_icon}>
          <NotificationIcon
            src={
              notification_image
                ? notification_image
                : `${process.env.PUBLIC_URL}/logo512.png`
            }
          />
        </div>
        <div className={classes.notication_content}>
          <Typography className={classes.notification_label}>
            {title}
          </Typography>
          <Typography
            className={
              classes.common_font_styling +
              " " +
              classes.common_font_size +
              " " +
              classes.notification_content
            }
          >
            {body}
          </Typography>
          <Typography
            className={
              classes.notification_time +
              " " +
              classes.common_font_styling +
              " " +
              classes.common_font_size
            }
          >
            {moment(date, "YYYY-MM-DD HH:mm:ss").fromNow()}
          </Typography>
        </div>
      </div>
    </Swipeout>
  );
};

export default withStyles(styles, { withTheme: true })(NotificationCard);
