import { SAVEMESSAGE, REMOVEMESSAGE } from './Action-types';

const initialState = {
    showmsg: false
};

const reducer = (state = initialState,  action) => {
    switch(action.type) {
        case SAVEMESSAGE:
            return {
              showmsg: true
            }
        case REMOVEMESSAGE:
          return {
            showmsg: false
          }
        default:
            return state;
    }
};

export default reducer;