import React, {
  memo,
  useState,
  useCallback,
  CSSProperties,
  useEffect,
} from "react";
import SingleInput from "./SingleInput";
import { number, boolean, string, func } from "prop-types";

export function OTPInputComponent(props) {
  const {
    length,
    isNumberInput,
    autoFocus,
    disabled,
    onChangeOTP,
    inputClassName,
    inputStyle,
    clear,
    ...rest
  } = props;

  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState("");
  const [symbolsArr] = useState(["e", "E", "+", "-", ".", ","]);

  useEffect(() => {
    if (!props.isOtpVerified) {
      if (window.OTPCredential || window.CredentialsContainer) {
        const input = document.querySelector(
          'input[autoComplete="one-time-code"]'
        );
        var event = new Event("input", { bubbles: true });

        //Toggling simulated=false or true and will not trigger the value change
        event.simulated = true;
        if (!input) return;
        const ac = new AbortController();
        navigator.credentials
          .get({
            otp: { transport: ["sms"] },
            signal: ac.signal,
          })
          .then((otp) => {
            input.value = otp.code;

            //here otp is pasted in input field
            handleOnAutoRead(otp.code);
            input.defaultValue = otp.code;

            //call API to login
            props.handleVerifyotp(otp.code);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    return () => {
      // cleanup
    };
  }, []);

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp) => {
      const otpValue = otp.join("");
      onChangeOTP(otpValue);
    },
    [onChangeOTP]
  );

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (str) => {
      let changedValue = str;
      if (!isNumberInput) {
        return changedValue;
      }
      return /\d/.test(changedValue) ? changedValue : "";
    },
    [isNumberInput]
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str) => {
      const updatedOTPValues = clear ? [] : [...otpValues];
      updatedOTPValues[activeInput] = str[0] || "";
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues, clear]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index) => () => {
      focusInput(index);
      changeCodeAtFocus("");
    },
    [focusInput, changeCodeAtFocus]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e) => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  // Hanlde onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e) => {
      const kc = e.which || e.keyCode;
      if (
        [32, 69, 190, 188, 186, 222, 221, 219].indexOf(kc) > -1 ||
        symbolsArr.includes(e.key)
      ) {
        e.preventDefault();
        changeCodeAtFocus("");
        return;
      }
      switch (e.key) {
        case "Backspace":
        case "Delete": {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus("");
          } else {
            focusPrevInput();
          }
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          focusNextInput();
          break;
        }
        case " ": {
          e.preventDefault();
          break;
        }
        default:
          break;
      }
    },
    [
      activeInput,
      changeCodeAtFocus,
      focusNextInput,
      focusPrevInput,
      otpValues,
      symbolsArr,
    ]
  );

  // const handleOnPaste = useCallback(
  //   (e) => {
  //     e.preventDefault();
  //     const pastedData = e.clipboardData
  //       .getData("text/plain")
  //       .trim()
  //       .slice(0, length - activeInput)
  //       .split("");
  //     if (pastedData) {
  //       let nextFocusIndex = 0;
  //       const updatedOTPValues = [...otpValues];
  //       updatedOTPValues.forEach((val, index) => {
  //         if (index >= activeInput) {
  //           const changedValue = getRightValue(pastedData.shift() || val);
  //           if (changedValue) {
  //             updatedOTPValues[index] = changedValue;
  //             nextFocusIndex = index;
  //           }
  //         }
  //       });
  //       setOTPValues(updatedOTPValues);
  //       setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
  //     }
  //   },
  //   [activeInput, getRightValue, length, otpValues]
  // );

  const handleOnAutoRead = useCallback(
    (value) => {
      const pastedData = value
        .trim()
        .slice(0, length - activeInput)
        .split("");
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...pastedData];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length));
      }
    },
    [activeInput, getRightValue, length, otpValues]
  );

  return (
    <div {...rest}>
      {Array(length)
        .fill("")
        .map((_, index) => (
          <SingleInput
            key={`SingleInput-${index}`}
            id={`SingleInput-${index}`}
            focus={activeInput === index}
            autoFocus={autoFocus}
            value={clear ? "" : otpValues && otpValues[index]}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={onBlur}
            onPaste={(e) => e.preventDefault()}
            style={inputStyle}
            className={inputClassName}
            disabled={disabled}
            autoComplete="one-time-code"
            min="0"
            inputmode="numeric"
            pattern="[0-9]*"
          />
        ))}
    </div>
  );
}

const OTPInput = memo(OTPInputComponent);
export default OTPInput;

OTPInputComponent.prototype = {
  length: number,
  onChangeOTP: func,
  autoFocus: boolean,
  isNumberInput: boolean,
  disabled: boolean,
  style: CSSProperties,
  className: string,
  inputStyle: CSSProperties,
  inputClassName: string,
  type: number,
};
