export const styles = (theme) => {
	return {
		pageContainer: {
			height: '100%',
			overflow: 'auto',
			backgroundColor: '#f5f5f5'
		},
		root: {
			padding: '30px',
			maxWidth: '660px',
			margin: '0 auto',
			[theme.breakpoints.down('610')]: {
				padding: '28px'
			},
			[theme.breakpoints.down('481')]: {
				padding: '21px'
			},
			[theme.breakpoints.down('381')]: {
				padding: '15px'
			},
			[theme.breakpoints.down('321')]: {
				padding: '13px'
			}
		},
		formControl: {
			'& .MuiTextField-root': {
				height: '92px'
			},
			'& .MuiInputLabel-root': {
				marginTop: '5px',
				fontSize: '20px'
			},
			'& .MuiFormHelperText-root': {
				fontSize: '0.623rem'
			},
			[theme.breakpoints.down(641)]: {
				'& .MuiTextField-root': {
					height: '80px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '16px',
					marginTop: '1px',
					fontFamily: 'Montserrat'
				}
			},
			[theme.breakpoints.down(481)]: {
				'& .MuiTextField-root': {
					height: '54px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '14px',
					marginTop: '-7px'
				}
			},
			[theme.breakpoints.down(381)]: {
				'& .MuiTextField-root': {
					height: '46px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '12px',
					marginTop: '-8px'
				}
			},
			[theme.breakpoints.down(321)]: {
				'& .MuiTextField-root': {
					height: '40px'
				},
				'& .MuiInputLabel-root': {
					marginTop: '-11px',
					fontSize: '10px'
				}
			},
			'& .MuiInputBase-input:focus': {
				backgroundColor: 'unset'
			}
		},
		itemContainer: {
			display: 'inline-block',
			width: '50%',
			verticalAlign: 'top',
			position: 'relative'
		},
		padd_right: {
			paddingRight: '15px',
			[theme.breakpoints.down(641)]: {
				paddingRight: '14px'
			},
			[theme.breakpoints.down(481)]: {
				paddingRight: '10.5px'
			},
			[theme.breakpoints.down(381)]: {
				paddingRight: '7.5px'
			},
			[theme.breakpoints.down(321)]: {
				paddingRight: '6.5px'
			}
		},
		padd_left: {
			paddingLeft: '15px',
			[theme.breakpoints.down(641)]: {
				paddingLeft: '14px'
			},
			[theme.breakpoints.down(481)]: {
				paddingLeft: '10.5px'
			},
			[theme.breakpoints.down(381)]: {
				paddingLeft: '7.5px'
			},
			[theme.breakpoints.down(321)]: {
				paddingLeft: '6.5px'
			}
		},
		fieldSet: {
			borderRadius: '5px',
			border: 'solid 1px #e4e4e4',
			margin: '0px auto 30px auto',
			display: 'block',
			width: '100%',
			padding: '10px 10px 0 11px',
			backgroundColor: '#fff',
			'&& input': {
				fontSize: '20px',
				lineHeight: '13.5px',
				color: '#211f20'
			},
			[theme.breakpoints.down(641)]: {
				margin: '0px auto 28px auto',
				'&& input': {
					fontSize: '16px',
					lineHeight: '1.2'
				}
			},
			[theme.breakpoints.down(481)]: {
				margin: '0px auto 21px auto',
				'&& input': {
					fontSize: '14px',
					lineHeight: '1.11'
				}
			},
			[theme.breakpoints.down(381)]: {
				margin: '0px auto 15px auto',
				'&& input': {
					fontSize: '12px',
					lineHeight: '1.07'
				}
			},
			[theme.breakpoints.down(321)]: {
				margin: '0px auto 13px auto',
				'&& input': {
					fontSize: '10px',
					lineHeight: '13.5px'
				}
			},
			'& label': {
				paddingLeft: '14px'
			},
			'& div': {
				margin: '0px',
				height: '100%',
				width: '100%',
				padding: '0px'
			},
			'&& p': {
				marginTop: '1px'
			},
			'&& div:before': {
				borderBottom: 'none'
			},
			'&& div:hover': {
				borderBottom: 'none'
			}
		},
		cssUnderline: {
			'&:after': {
				borderBottom: 'none'
			},
			'&&&&:before': {
				borderBottom: 'none'
			}
		},
		input: {
			'&:-webkit-autofill': {
				WebkitBoxShadow: '0 0 0 1000px white inset'
			}
		},
		cssLabel: {
			color: '#aaaaaa !important',
			'&.focused': {
				color: '#aaaaaa'
			},
			'&.shrink': {
				transform: 'translate(1px, 12px) scale(0.75)',
				[theme.breakpoints.down('481')]: {
					transform: 'translate(1px, 16px) scale(0.75)'
				},
				[theme.breakpoints.down('381')]: {
					transform: 'translate(1px, 14px) scale(0.75)'
				},
				[theme.breakpoints.down('321')]: {
					transform: 'translate(1px, 18px) scale(0.75)'
				}
			}
		},
		submitBtn: {
			...theme.palette.typography.doneButtons,
			width: '100%',
			height: '92px',
			fontSize: '22px',
			lineHeight: '18px',
			[theme.breakpoints.down(641)]: {
				height: '80px',
				fontSize: '16px',
				lineHeight: '16px'
			},
			[theme.breakpoints.down(481)]: {
				height: '54px',
				fontSize: '14px',
				lineHeight: '12px'
			},
			[theme.breakpoints.down(381)]: {
				height: '46px',
				fontSize: '12px',
				lineHeight: '9px'
			},
			[theme.breakpoints.down(321)]: {
				height: '40px',
				fontSize: '10px',
				lineHeight: '8px'
			}
		},
		cancelBtn: {
			backgroundColor: '#e4e4e4',
			color: '#211f20',
			width: '100%',
			height: '92px',
			fontSize: '22px',
			lineHeight: '18px',
			[theme.breakpoints.down(641)]: {
				height: '80px',
				fontSize: '16px',
				lineHeight: '16px'
			},
			[theme.breakpoints.down(481)]: {
				height: '54px',
				fontSize: '14px',
				lineHeight: '12px'
			},
			[theme.breakpoints.down(381)]: {
				height: '46px',
				fontSize: '12px',
				lineHeight: '9px'
			},
			[theme.breakpoints.down(321)]: {
				height: '40px',
				fontSize: '10px',
				lineHeight: '8px'
			}
		},
		menuItem: {
			fontSize: '18px',
			[theme.breakpoints.down('641')]: {
				fontSize: '16px'
			},
			[theme.breakpoints.down('481')]: {
				fontSize: '14px'
			},
			[theme.breakpoints.down('381')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down('321')]: {
				fontSize: '10px'
			}
		},
		down_icon: {
			position: 'absolute',
			pointerEvents: 'none',
			right: '10px',
			top: 'calc(50% - 4px)'
		},
		pageItems: {
			margin: '0px auto 30px auto',
			[theme.breakpoints.down(641)]: {
				margin: '0px auto 28px auto'
			},
			[theme.breakpoints.down(481)]: {
				margin: '0px auto 21px auto'
			},
			[theme.breakpoints.down(381)]: {
				margin: '0px auto 15px auto'
			},
			[theme.breakpoints.down(321)]: {
				margin: '0px auto 13px auto'
			}
		},
		textMessage: {
			resize: 'none',
			width: '100%',
			color: '#211f20',
			fontFamily: 'Montserrat, sans-serif !important',
			padding: '10px',
			border: 'solid 1px #e4e4e4',
			borderRadius: '5px',
			'-webkit-appearance': 'none',
			fontSize: '20px',
			[theme.breakpoints.down(641)]: {
				fontSize: '16px'
			},
			[theme.breakpoints.down(481)]: {
				fontSize: '14px'
			},
			[theme.breakpoints.down(381)]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down(321)]: {
				fontSize: '10px'
			},
			'&:focus': {
				outline: 'transparent'
			}
		}
		// textMessage::placeholder: {
		//     color: blue;
		//     font-size: 1.5em;
		//   }
	};
};
