import {
  FETCH_REGISTRATION_DATA,
  GET_ERRORS,
  SET_USER_VISITED,
} from "./actionTypes";
import { stockAPI } from "../../../../common/axiosInstance";
import { setStorageItem } from "../../../../common/localStorage";
import { AUTH_USER } from "../../OtpVerification/redux/actionTypes";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import { isPWAInstalled } from "../../../../common/commonFunctions";

export const registerCustomer = (bodyObj, history) => (dispatch) => {
  stockAPI(bodyObj, "POST", "/registerCustomer", null, null, null, true)
    .then(async (response) => {
      if (response && response.statusCode === 100) {
        const user = {
          id: response.response.merchant_list.added[1].customerDetails.id,
          mobile_number:
            response.response.merchant_list.added[1].customerDetails
              .mobile_number,
        };
        const currency_code =
          response.response.merchant_list.added[1].loyalty_currency_code;
        const points_unit_short_name =
          response.response.merchant_list.added[1].points_unit_short_name;
        const loyalty_id = response.response.merchant_list.added[1].loyalty_id;
        const guidelinesFlag = response.response.merchant_list.added[1].customerDetails.bill_upload_guidelines_flag;
        const token = response?.response?.token?.token;
        const refreshToken = response?.response?.token?.refresh_token;

        await setStorageItem("user", JSON.stringify(user));
        await setStorageItem("currencyCode", `${currency_code}`);
        await setStorageItem("pointShortName", points_unit_short_name);
        await setStorageItem("loyaltyId", loyalty_id);
        await setStorageItem("guidelinesFlag", guidelinesFlag);
        await localStorage.setItem("acc_token", token);
        await localStorage.setItem("refresh_token", refreshToken);

        dispatch({ type: FETCH_REGISTRATION_DATA, payload: response });
        dispatch({ type: AUTH_USER, payload: true });
        history.push("/dashboard");
        isPWAInstalled(response.response.merchant_list.added[1].customerDetails.mobile_number);
      } else {
        openAlertBox("Oops something went wrong!", "error");
      }
    })
    .catch(function (err) {
      dispatch({ type: GET_ERRORS, payload: err });
    });
};


