import type from "./actionTypes";

let INITIAL_STATE = {
  brandList: [],
  nextpage: 1,
  curpage: "",
  hasMore: false,
  searchtext: "",
};

const reducerHandler = (action, state = INITIAL_STATE) => {
  const reducerObject = {
    [type.SAVE_BRANDS]: () => ({
      ...state,
      brandList: action.payload.brandList,
      nextpage: action.payload.nextpage,
      curpage: action.payload.curpage,
      hasMore: action.payload.hasMore,
      searchtext: action.payload.searchtext,
    }),
    [type.CLEAR_BRANDS]: () => ({
      ...state,
      brandList: [],
      nextpage: 1,
      curpage: "",
      hasMore: false,
      searchtext: "",
    }),
  };
  return (reducerObject[action.type] && reducerObject[action.type]()) || state;
};

const reducerBrand = (action, state) => reducerHandler(state, action);
export default reducerBrand;
