import search from "../../assets/icons/redeem/searchIcon.svg";
import { primaryOrange, whiteColor } from "../UI/Theme";

export const searchStyles = (theme) => {
  return {
    searchWrap: {
      width: "100%",
      display: "block",
      position: "relative",
      paddingBottom: "1rem",
    },
    searchWrap1: {
      width: "100%",
      display: "block",
      position: "relative",
    },
    searchIcon: {
      position: "absolute",
      top: "15px",
      left: "1.1%",
      zIndex: "10",
      display: "block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      height: "20px",
      width: "20px",
      [theme.breakpoints.down("sm")]: {
        height: "18px",
        width: "18px",
        top: "16px",
        left: "2.4%",
      },
      [theme.breakpoints.down(641)]: {
        left: "3%",
      },
      [theme.breakpoints.down("xs")]: {
        height: "16px",
        left: "5%",
        top: "15px",
        width: "16px",
      },
    },
    searchBox: {
      width: "100%",
      padding: "0px",
      display: "flex",
      '&& input[type="search"]::-webkit-search-decoration': {
        "-webkit-appearance": "none",
      },
      '&& input[type="search"]::-webkit-search-cancel-button': {
        "-webkit-appearance": "none",
      },
      '&& input[type="search"]::-webkit-search-results-button': {
        "-webkit-appearance": "none",
      },
      '&& input[type="search"]::-webkit-search-results-decoration': {
        "-webkit-appearance": "none",
      },

      "&& input:-webkit-autofill": {
        "-webkit-box-shadow": ` 0 0 0 30px ${theme.palette.common.globalBackground} inset !important`,
      },
      "&& input:-webkit-autofill:hover": {
        "-webkit-box-shadow": ` 0 0 0 30px ${theme.palette.common.globalBackground} inset !important`,
      },
      "&& input:-webkit-autofill:focus": {
        "-webkit-box-shadow": ` 0 0 0 30px ${theme.palette.common.globalBackground} inset !important`,
      },
      "&& input:-webkit-autofill:active ": {
        "-webkit-box-shadow": ` 0 0 0 30px ${theme.palette.common.globalBackground} inset !important`,
      },

      "&& input": {
        padding: "10px 10px 10px 40px",
        height: "22px",
        fontSize: "16px",
      },
      [theme.breakpoints.down(641)]: {
        "&& input": {
          fontSize: "14px",
        },
      },
      [theme.breakpoints.down(481)]: {
        "&& input": {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down(421)]: {
        "&& input": {
          fontSize: "11.5px",
        },
      },
      [theme.breakpoints.down(401)]: {
        "&& input": {
          fontSize: "11px",
        },
      },
      [theme.breakpoints.down(381)]: {
        "&& input": {
          fontSize: "12.5px",
        },
      },
      [theme.breakpoints.down(361)]: {
        "&& input": {
          fontSize: "10px",
        },
      },
      [theme.breakpoints.down(321)]: {
        "&& input": {
          fontSize: "8px",
        },
      },
    },
    searchBox1: {
      width: "100%",
      padding: "0px",
      display: "flex",
      background: whiteColor,
      '&& input[type="search"]::-webkit-search-decoration': {
        "-webkit-appearance": "none",
      },
      '&& input[type="search"]::-webkit-search-cancel-button': {
        "-webkit-appearance": "none",
      },
      '&& input[type="search"]::-webkit-search-results-button': {
        "-webkit-appearance": "none",
      },
      '&& input[type="search"]::-webkit-search-results-decoration': {
        "-webkit-appearance": "none",
      },

      "&& input:-webkit-autofill": {
        "-webkit-box-shadow": ` 0 0 0 30px ${theme.palette.common.globalBackground} inset !important`,
      },
      "&& input:-webkit-autofill:hover": {
        "-webkit-box-shadow": ` 0 0 0 30px ${theme.palette.common.globalBackground} inset !important`,
      },
      "&& input:-webkit-autofill:focus": {
        "-webkit-box-shadow": ` 0 0 0 30px ${theme.palette.common.globalBackground} inset !important`,
      },
      "&& input:-webkit-autofill:active ": {
        "-webkit-box-shadow": ` 0 0 0 30px ${theme.palette.common.globalBackground} inset !important`,
      },

      "&& input": {
        padding: "10px 10px 10px 0px",
        height: "58px",
        fontSize: "21px",
      },
      [theme.breakpoints.down(641)]: {
        "&& input": {
          height: "48px",
          fontSize: "19px",
        },
      },
      [theme.breakpoints.down(481)]: {
        "&& input": {
          height: "30px",
          fontSize: "16px",
        },
      },
      [theme.breakpoints.down(421)]: {
        "&& input": {
          height: "26px",
          fontSize: "14px",
        },
      },
      [theme.breakpoints.down(401)]: {
        "&& input": {
          height: "24px",
          fontSize: "13px",
        },
      },
      [theme.breakpoints.down(381)]: {
        "&& input": {
          height: "20px",
          fontSize: "12.5px",
        },
      },
      [theme.breakpoints.down(361)]: {
        "&& input": {
          height: "20px",
          fontSize: "11px",
        },
      },
      [theme.breakpoints.down(321)]: {
        "&& input": {
          height: "18px",
          fontSize: "10px",
        },
      },
    },
    p15: {
      padding: "0 5px",
      [theme.breakpoints.down(321)]: {
        padding: "0 3px",
      },
    },
    pB10: {
      paddingBottom: "0.5rem",
    },
    pB0: {
      paddingBottom: 0,
    },
    noRecord: {
      fontSize: "1.5rem",
      padding: "30px 15px",
      fontWeight: 400,
      // color:'#F7942F',
      color: "black",
      opacity: 0.4,
      margin: "1rem auto",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    searchIconCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${search})`,
      "mask-image": `url(.${search})`,
      mask: `url(.${search}) no-repeat center / contain`,
      "-webkit-mask": `url(.${search}) no-repeat center / contain`,
    },
    searchicon: {
      width: "1.6em",
      height: "2em",
      color: primaryOrange,
      [theme.breakpoints.down("641")]: {
        width: "1.5em",
        height: "3em",
      },
      [theme.breakpoints.down("481")]: {
        width: "1em",
      },
      [theme.breakpoints.down("421")]: {
        width: "1em",
      },
      [theme.breakpoints.down("401")]: {
        width: "0.9em",
      },
      [theme.breakpoints.down("381")]: {
        width: "0.8em",
      },
      [theme.breakpoints.down("321")]: {
        width: "0.8em",
      },
    },
  };
};
