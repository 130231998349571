import React, { Component, Fragment } from "react";

import Header from "../../../components/Layout/Header/HeaderBillUploadProgress";
import MyRoute from "../../../hoc/MyRoute";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import { changeActiveTab, logoutUser } from "../../../App/redux/action";
import { deleteBillHandler } from "./redux/action";
import { connect } from "react-redux";
import BillUploadProgress from "./component/BillUploadProgress";
import { Grid } from "@material-ui/core";
import Footer from "../../../components/Layout/Footer/Footer";

const mapDispatchToProps = {
  changeActiveTab,
  logoutUser,
  deleteBillHandler,
};

const mapStateToProps = (state) => {
  return {
    imagesToUpload: state.reducerBillUploadProgress.imagesToUpload,
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
    activeTab: state.reducerApp.activeTab
  };
};

class BillUploadProgressContainer extends Component {
  componentDidMount() {
    this.props.changeActiveTab(0);
  }

  render() {
    return (
      <Fragment>
        <Header imagesToUpload={this.props.imagesToUpload} />
        <AlertMessage />
        <MyRoute billUploadStatus={this.props.uploadingStatus}>
          <BillUploadProgress
            logoutUser={this.props.logoutUser}
            imagesToUpload={this.props.imagesToUpload}
            deleteBillHandler={this.props.deleteBillHandler}
          />
        </MyRoute>
        <Grid item>
					<Footer />
				</Grid>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillUploadProgressContainer);
