import { color, grey, lightGrey, errorRed } from '../../../../components/UI/Theme';
import disable_checkbox from '../../../../assets/icons/book_visit/disable-checkbox.svg';
import selected from '../../../../assets/icons/book_visit/selected.svg';
export const styles = (theme) => {
	return {
		pageContainer: {
			height: '100%',
			// overflowY: 'auto',
			position: 'relative',
			backgroundColor: "#fff",
			overflowY: "auto",
			overflowX: "hidden"
		},
		root: {
			maxWidth: '660px',
			margin: '0 auto',
			height: '100%',
			padding: '30px',
			overflowY: "auto",
			overflowX: "hidden",
			[theme.breakpoints.down('610')]: {
				padding: '28px'
			},
			[theme.breakpoints.down('481')]: {
				padding: '21px'
			},
			[theme.breakpoints.down('381')]: {
				padding: '15px'
			},
			[theme.breakpoints.down('321')]: {
				padding: '13px'
			}
		},
		itemContainer: {
			display: 'inline-block',
			width: '50%',
			verticalAlign: 'top',
			position: 'relative'
		},
		padd_right: {
			paddingRight: '15px',
			[theme.breakpoints.down(641)]: {
				paddingRight: '14px'
			},
			[theme.breakpoints.down(481)]: {
				paddingRight: '10.5px'
			},
			[theme.breakpoints.down(381)]: {
				paddingRight: '7.5px'
			},
			[theme.breakpoints.down(321)]: {
				paddingRight: '6.5px'
			}
		},
		padd_left: {
			paddingLeft: '15px',
			[theme.breakpoints.down(641)]: {
				paddingLeft: '14px'
			},
			[theme.breakpoints.down(481)]: {
				paddingLeft: '10.5px'
			},
			[theme.breakpoints.down(381)]: {
				paddingLeft: '7.5px'
			},
			[theme.breakpoints.down(321)]: {
				paddingLeft: '6.5px'
			}
		},
		fieldSet: {
			borderRadius: '5px',
			border: 'solid 1px #e4e4e4',
			margin: '0px auto 30px auto',
			display: 'block',
			width: '100%',
			padding: '10px 10px 0 11px',
			backgroundColor: '#fff',
			'&& input': {
				fontSize: '20px',
				lineHeight: '13.5px',
				color: color
			},
			[theme.breakpoints.down(641)]: {
				margin: '0px auto 28px auto',
				'&& input': {
					fontSize: '16px',
					lineHeight: '1.2'
				}
			},
			[theme.breakpoints.down(481)]: {
				margin: '0px auto 21px auto',
				'&& input': {
					fontSize: '14px',
					lineHeight: '1.11'
				}
			},
			[theme.breakpoints.down(421)]: {
				'&& input': {
					fontSize: '13.5px'
				}
			},
			[theme.breakpoints.down(401)]: {
				'&& input': {
					fontSize: '13px'
				}
			},
			[theme.breakpoints.down(381)]: {
				margin: '0px auto 15px auto',
				'&& input': {
					fontSize: '12.5px',
					lineHeight: '1.07'
				}
			},
			[theme.breakpoints.down(361)]: {
				'&& input': {
					fontSize: '12px'
				}
			},
			[theme.breakpoints.down(321)]: {
				margin: '0px auto 13px auto',
				'&& input': {
					fontSize: '10px',
					lineHeight: '13.5px'
				}
			},
			'& label': {
				paddingLeft: '14px'
			},
			'& div': {
				margin: '0px',
				height: '100%',
				width: '100%',
				padding: '0px'
			},
			'&& p': {
				marginTop: '1px'
			},
			'&& div:before': {
				borderBottom: 'none'
			},
			'&& div:hover': {
				borderBottom: 'none'
			}
		},
		fieldSet1: {
			borderRadius: '5px',
			border: 'solid 1px #e4e4e4',
			margin: '0px auto 16px auto',
			display: 'block',
			width: '100%',
			padding: '10px 10px 0 11px',
			backgroundColor: '#fff',
			'&& input': {
				fontSize: '20px',
				lineHeight: '13.5px',
				color: color
			},
			[theme.breakpoints.down(641)]: {
				margin: '0px auto 15px auto',
				'&& input': {
					fontSize: '16px',
					lineHeight: '1.2'
				}
			},
			[theme.breakpoints.down(481)]: {
				margin: '0px auto 12px auto',
				'&& input': {
					fontSize: '14px',
					lineHeight: '1.11'
				}
			},
			[theme.breakpoints.down(421)]: {
				'&& input': {
					fontSize: '13.5px'
				}
			},
			[theme.breakpoints.down(401)]: {
				'&& input': {
					fontSize: '13px'
				}
			},
			[theme.breakpoints.down(381)]: {
				margin: '0px auto 11px auto',
				'&& input': {
					fontSize: '12.5px',
					lineHeight: '1.07'
				}
			},
			[theme.breakpoints.down(361)]: {
				'&& input': {
					fontSize: '12px'
				}
			},
			[theme.breakpoints.down(321)]: {
				margin: '0px auto 9px auto',
				'&& input': {
					fontSize: '10px',
					lineHeight: '13.5px'
				}
			},
			'& label': {
				paddingLeft: '14px'
			},
			'& div': {
				margin: '0px',
				height: '100%',
				width: '100%',
				padding: '0px'
			},
			'&& p': {
				marginTop: '1px'
			},
			'&& div:before': {
				borderBottom: 'none'
			},
			'&& div:hover': {
				borderBottom: 'none'
			}
		},
		submitBtn: {
			...theme.palette.typography.doneButtons,
			width: '100%',
			fontSize: '22px',
			lineHeight: '18px',
			margin: '0 !important',
			height: '92px',
			marginTop: "20px !important",
			[theme.breakpoints.down(641)]: {
				height: '80px',
				fontSize: '16px',
				lineHeight: '16px'
			},
			[theme.breakpoints.down(481)]: {
				height: '54px',
				fontSize: '14px',
				lineHeight: '12px'
			},
			[theme.breakpoints.down(421)]: {
				fontSize: '13.5px'
			},
			[theme.breakpoints.down(401)]: {
				fontSize: '13px'
			},
			[theme.breakpoints.down(381)]: {
				height: '46px',
				fontSize: '12.5px',
				lineHeight: '9px'
			},
			[theme.breakpoints.down(361)]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down(321)]: {
				height: '40px',
				fontSize: '10px',
				lineHeight: '8px'
			}
		},
		selectListUl: {
			margin: '0px',
			padding: '0px',
			display: 'block',
			listStyle: 'none',
			position: 'absolute',
			top: '45px',
			background: '#fffefe',
			border: '1px solid #ccc',
			height: '120px',
			overflowY: 'scroll',
			zIndex: '99',
			width: '100%'
		},
		less: {
			height: '70%',
			overflow: 'hidden',
			[theme.breakpoints.down(321)]: {
				height: '90%'
			}
		},
		selectListLi: {
			width: '100%',
			height: 'auto',
			margin: '0px',
			padding: '10px 10px',
			display: 'block',
			fontSize: '0.875rem',
			textAlign: 'left',
			borderBottom: '1px solid #ccc',
			cursor: 'pointer'
		},
		cssLabel: {
			color: `${grey} !important`,
			'&.focused': {
				color: grey
			},
			'&.shrink': {
				transform: 'translate(1px, 12px) scale(0.75)',
				[theme.breakpoints.down('481')]: {
					transform: 'translate(1px, 16px) scale(0.75)'
				},
				[theme.breakpoints.down('381')]: {
					transform: 'translate(1px, 14px) scale(0.75)'
				},
				[theme.breakpoints.down('321')]: {
					transform: 'translate(1px, 18px) scale(0.75)'
				}
			}
		},
		cssUnderline: {
			'&:after': {
				borderBottom: 'none'
			},
			'&&&&:before': {
				borderBottom: 'none'
			}
		},
		icons: {
			width: "260px",
			height: "135px",
			marginBottom: 10,
			marginRight: "50px",
			"mask-size ": "cover",
			objectFit: "contain",
			[theme.breakpoints.down(641)]: {
				width: "260px",
				height: "135px",
				marginBottom: 10,
				marginRight: "50px"
			},
			[theme.breakpoints.down(481)]: {
			  width: "190px",
				height: "100px",
				marginBottom: 10,
				marginRight: "40px"
			},
			[theme.breakpoints.down(421)]: {
				width: "180px",
				height: "100px",
				marginBottom: 10,
				marginRight: "37px"
			},
			[theme.breakpoints.down(401)]: {
				width: "170px",
				height: "90px",
				marginBottom: 10,
				marginRight: "34px"
			},
			[theme.breakpoints.down(381)]: {
				width: "160px",
				height: "85px",
				marginBottom: 10,
				marginRight: "31px"
			},
			[theme.breakpoints.down(361)]: {
				width: "150px",
				height: "75px",
				marginBottom: 10,
				marginRight: "28px"
			},
			[theme.breakpoints.down(321)]: {
			  width: "130px",
				height: "60px",
				marginBottom: 10,
				marginRight: "23px"
			},
		},
		calenderIcon: {
			width: 32,
			marginTop: -12,
			marginRight: 12,
			[theme.breakpoints.down(641)]: {
				width: 28,
				marginTop: -12,
				marginRight: 9,
			},
			[theme.breakpoints.down(481)]: {
				width: 20,
				marginTop: -12,
				marginRight: 3,
			},
			[theme.breakpoints.down(421)]: {
				width: 19,
				marginTop: -12,
				marginRight: 3,
			},
			[theme.breakpoints.down(401)]: {
				width: 18,
				marginTop: -12,
				marginRight: 2,
			},
			[theme.breakpoints.down(381)]: {
				width: 17,
				marginTop: -12,
				marginRight: 0,
			},
			[theme.breakpoints.down(361)]: {
				width: 16,
				marginTop: -12,
				marginRight: 0,
			},
			[theme.breakpoints.down(321)]: {
			  width: 15,
				marginTop: -12,
				marginRight: 0,
			},
		},
		heading: {
			fontSize: "32px",
			fontWeight: "bold",
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: "0.94",
			letterSpacing: "normal",
			textAlign: "center",
			color: theme.palette.typography.texts.color,
			marginTop: "0px",
			[theme.breakpoints.down(641)]: {
				fontSize: "28px",
				lineHeight: "0.96",
				marginTop: "0px",
			},
			[theme.breakpoints.down(481)]: {
				fontSize: "18px",
				lineHeight: "0.91",
				marginTop: "0px",
			},
			[theme.breakpoints.down(421)]: {
				fontSize: "17.5px",
			},
			[theme.breakpoints.down(401)]: {
				fontSize: "17px",
			},
			[theme.breakpoints.down(381)]: {
				fontSize: "16.5px",
				lineHeight: "0.83",
				marginTop: "0px",
			},
			[theme.breakpoints.down(361)]: {
				fontSize: "16px",
			},
			[theme.breakpoints.down(321)]: {
				fontSize: "14px",
				lineHeight: "0.84",
				marginTop: "00px",
			},
		},
		subHeading: {
			height: "53px",
			width: "100%",
			fontSize: "18px",
			fontWeight: "normal",
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: "1.64",
			letterSpacing: "normal",
			textAlign: "center",
			color: lightGrey,
			margin: "24px 0px 0px 0px",
			[theme.breakpoints.down(641)]: {
					height: "54px",
					lineHeight: "1.64",
					fontSize: "16px",
					margin: "20px 0px 0px 0px",
			},
			[theme.breakpoints.down(481)]: {
					height: "41px",
					lineHeight: "1.5",
					fontSize: "14px",
					margin: "14px 0px 0px 0px",
			},
			[theme.breakpoints.down(421)]: {
					fontSize: "13px",
			},
			[theme.breakpoints.down(401)]: {
					fontSize: "12px",
			},
			[theme.breakpoints.down(381)]: {
					height: "31px",
					lineHeight: "1.5",
					fontSize: "12px",
					margin: "12px 0px 0px 0px",
			},
			[theme.breakpoints.down(361)]: {
					fontSize: "11px",
			},
			[theme.breakpoints.down(321)]: {
					height: "27px",
					fontSize: "10px",
					lineHeight: "1.6",
					margin: "10px 0px 0px 0px",
			},
		},
		selectediconCss: {
			backgroundColor: theme.palette.primary.main,
			" -webkit-mask-image": `url(.${selected})`,
			"mask-image": `url(.${selected})`,
			mask: `url(.${selected}) no-repeat center / contain`,
			"-webkit-mask": `url(.${selected}) no-repeat center / contain`,
		  },
		  noselectediconCss: {
			backgroundColor: theme.palette.primary.main,
			" -webkit-mask-image": `url(.${disable_checkbox})`,
			"mask-image": `url(.${disable_checkbox})`,
			mask: `url(.${disable_checkbox}) no-repeat center / contain`,
			"-webkit-mask": `url(.${disable_checkbox}) no-repeat center / contain`,
		  },
		checkbox: {
			width: 40,
			marginBottom: 24,
			marginTop: 13,
			// "--size ": "70px",
			// display: "inline-block",
			// transition: ".12s",
			// "-webkit-mask-size": "cover",
			// "mask-size ": "cover",
			// objectFit: "contain",
			[theme.breakpoints.down(641)]: {
				width: 40,
				marginBottom: 24,
				marginTop: 13,
			},
			[theme.breakpoints.down(481)]: {
				width: 28,
				marginBottom: 16,
				marginTop: 8,
			},
			[theme.breakpoints.down(421)]: {
				width: 28,
				marginBottom: 16,
				marginTop: 8,
			},
			[theme.breakpoints.down(401)]: {
				width: 27,
				marginBottom: 15,
				marginTop: 8,
			},
			[theme.breakpoints.down(381)]: {
				width: 21,
				marginBottom: 12,
				marginTop: 5,
			},
			[theme.breakpoints.down(361)]: {
				width: 24,
				marginBottom: 12,
				marginTop: 5,
			},
			[theme.breakpoints.down(321)]: {
				width: 22,
				marginBottom: 10,
				marginTop: 4,
			},
		},
		checkboxText: {
			fontSize: 20,
			marginTop: 18,
			marginLeft: 10,
			[theme.breakpoints.down(641)]: {
				fontSize: 18,
				marginTop: 19,
				marginLeft: 10,
			},
			[theme.breakpoints.down(481)]: {
				fontSize: 14,
				marginTop: 12,
				marginLeft: 10,
			},
			[theme.breakpoints.down(421)]: {
				fontSize: 13,
				marginTop: 12,
				marginLeft: 10,
			},
			[theme.breakpoints.down(401)]: {
				fontSize: 12,
				marginTop: 12,
				marginLeft: 10,
			},
			[theme.breakpoints.down(381)]: {
				fontSize: 12,
				marginTop: 7,
				marginLeft: 10,
			},
			[theme.breakpoints.down(361)]: {
				fontSize: 12,
				marginTop: 8,
				marginLeft: 10,
			},
			[theme.breakpoints.down(321)]: {
				fontSize: 11,
				marginTop: 6,
				marginLeft: 10,
			},
		},
		input: {
			'&:-webkit-autofill': {
				WebkitBoxShadow: '0 0 0 1000px white inset'
			}
		},
		formControl: {
			minHeight: 'calc(100% - 92px)',
			'& .MuiTextField-root': {
				height: '92px'
			},
			'& .MuiInputLabel-root': {
				marginTop: '5px',
				fontSize: '20px'
			},
			'& .MuiFormHelperText-root': {
				fontSize: '0.623rem'
			},
			"& .MuiSelect-select:focus": {
				background: "#fff",
			},
			[theme.breakpoints.down(641)]: {
				minHeight: 'calc(100% - 80px)',
				'& .MuiTextField-root': {
					height: '80px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '16px',
					marginTop: '1px',
					fontFamily: 'Montserrat'
				}
			},
			[theme.breakpoints.down(481)]: {
				minHeight: 'calc(100% - 54px)',
				'& .MuiTextField-root': {
					height: '54px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '14px',
					marginTop: '-7px'
				}
			},
			[theme.breakpoints.down(421)]: {
				'& .MuiInputLabel-root': {
					fontSize: '13.5px'
				}
			},
			[theme.breakpoints.down(401)]: {
				'& .MuiInputLabel-root': {
					fontSize: '13px'
				}
			},
			[theme.breakpoints.down(381)]: {
				minHeight: 'calc(100% - 46px)',
				'& .MuiTextField-root': {
					height: '46px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '12.5px',
					marginTop: '-8px'
				}
			},
			[theme.breakpoints.down(361)]: {
				'& .MuiInputLabel-root': {
					fontSize: '12px'
				}
			},
			[theme.breakpoints.down(321)]: {
				minHeight: 'calc(100% - 40px)',
				'& .MuiTextField-root': {
					height: '40px'
				},
				'& .MuiInputLabel-root': {
					marginTop: '-11px',
					fontSize: '10px'
				}
			}
		},
		formControl1: {
			height: '100%',

		},
		formContent: {
			minHeight: 'calc(100% - 112px)',
			'& .MuiTextField-root': {
				height: '92px'
			},
			'& .MuiInputLabel-root': {
				marginTop: '5px',
				fontSize: '20px'
			},
			'& .MuiFormHelperText-root': {
				fontSize: '0.623rem'
			},
			[theme.breakpoints.down(641)]: {
				minHeight: 'calc(100% - 100px)',
				'& .MuiTextField-root': {
					height: '80px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '16px',
					marginTop: '1px',
					fontFamily: 'Montserrat'
				}
			},
			[theme.breakpoints.down(481)]: {
				minHeight: 'calc(100% - 80px)',
				'& .MuiTextField-root': {
					height: '54px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '14px',
					marginTop: '-7px'
				}
			},
			[theme.breakpoints.down(421)]: {
				minHeight: 'calc(100% - 80px)',
				'& .MuiInputLabel-root': {
					fontSize: '13.5px'
				}
			},
			[theme.breakpoints.down(401)]: {
				minHeight: 'calc(100% - 74px)',
				'& .MuiInputLabel-root': {
					fontSize: '13px'
				}
			},
			[theme.breakpoints.down(381)]: {
				minHeight: 'calc(100% - 74px)',
				'& .MuiTextField-root': {
					height: '46px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '12.5px',
					marginTop: '-8px'
				}
			},
			[theme.breakpoints.down(361)]: {
				minHeight: 'calc(100% - 66px)',
				'& .MuiInputLabel-root': {
					fontSize: '12px'
				}
			},
			[theme.breakpoints.down(321)]: {
				minHeight: 'calc(100% - 60px)',
				'& .MuiTextField-root': {
					height: '40px'
				},
				'& .MuiInputLabel-root': {
					marginTop: '-11px',
					fontSize: '10px'
				}
			}
		},
		menuItem: {
			fontSize: '18px',
			[theme.breakpoints.down('641')]: {
				fontSize: '16px'
			},
			[theme.breakpoints.down('481')]: {
				fontSize: '14px'
			},
			[theme.breakpoints.down('421')]: {
				fontSize: '13.5px'
			},
			[theme.breakpoints.down('401')]: {
				fontSize: '13px'
			},
			[theme.breakpoints.down('381')]: {
				fontSize: '12.5px'
			},
			[theme.breakpoints.down('361')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down('321')]: {
				fontSize: '10px'
			}
		},
		disabledField: {
			// backgroundColor: '#f1f1f1'
		},
		btnContainer: {
		//	width: '100%',
//			position: "fixed",
	//		bottom: 0,
		//	marginBottom: '30px',
			[theme.breakpoints.down('640')]: {
				width: '100%',
				//marginBottom: '28px',
			},
			[theme.breakpoints.down('481')]: {
				width: '100%',
				//marginBottom: '21px',

			},
			[theme.breakpoints.down('421')]: {
				width: '100%',
				//marginBottom: '21px',

			},
			[theme.breakpoints.down('381')]: {
				width: '100%',
				//marginBottom: '15px',

			},
			[theme.breakpoints.down('321')]: {
				width: '100%',
				// marginBottom: '13px',
			}
		},
		formWrap: {
      width: "100%",
    },
    selDrop: {
	  	width: "100%",
			border: "1px solid #e4e4e4",
			borderRadius: 6,
			height: 92,
      "& .MuiFormLabel-root": {
        fontFamily: "Montserrat",
      },
      "& div": {
        height: "92px",
      },
      "& .Mui-disabled": {
        color: "#454545",
      },
      "& label": {
        fontSize: "20px",
        lineHeight: "2.5",
        color: "#454545",
				opacity: "1",
				marginTop: 0,
      },
      [theme.breakpoints.down(641)]: {
        "& div": {
          height: "80px",
        },
        "& label": {
          fontSize: "16px",
          // lineHeight: "0.7",
				},
				height: 81,
      },
      [theme.breakpoints.down(481)]: {
        "& div": {
          height: "54px",
        },
        "& label": {
          fontSize: "14px",
          lineHeight: "0.9",
				},
				height: 54,
      },
      [theme.breakpoints.down(421)]: {
        "& label": {
          fontSize: "13.5px",
				},
				height: 54,
      },
      [theme.breakpoints.down(401)]: {
        "& label": {
          fontSize: "13px",
				},
				height: 53,
      },
      [theme.breakpoints.down(381)]: {
        "& div": {
          height: "46px",
        },
        "& label": {
          fontSize: "12.5px",
          lineHeight: "0.7",
				},
				height: 46,
      },
      [theme.breakpoints.down(361)]: {
        "& label": {
          fontSize: "12px",
				},
				height: 46,
      },
      [theme.breakpoints.down(321)]: {
        "& div": {
          height: "40px",
        },
        "& label": {
          fontSize: "10px",
          lineHeight: "0.4",
        },
        height: 40,
      },
    },
    mobileText: {
	  width: "98%",
	  border: "1px solid #e4e4e4",
      "& div": {
        height: "92px",
      },
      "&& input": {
        fontSize: "20px",
        lineHeight: "1",
      },
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "right",
      color: theme.palette.typography.texts.color,
      marginLeft: "10px",
    //   [theme.breakpoints.between(424, 570)]: {
    //     //width: "73.5%",
    //   },
    //   [theme.breakpoints.between(360, 425)]: {
    //     width: "73.5%",
    //   },
    //   [theme.breakpoints.between(0, 359)]: {
    //     width: "68%",
    //   },
      [theme.breakpoints.down(641)]: {
				width: "97%",
        "& div": {
          height: "80px",
        },
        "&& input": {
          fontSize: "16px",
          // lineHeight: "1.2",
        },
      },
      [theme.breakpoints.down(481)]: {
				width: "97%",
        "& div": {
          height: "54px",
        },
        "&& input": {
          fontSize: "14px",
          // lineHeight: "1.11",
        },
      },
      [theme.breakpoints.down(421)]: {
				width: "97%",
        "&& input": {
          fontSize: "13.5px",
          // lineHeight: "1.07",
        },
      },
      [theme.breakpoints.down(401)]: {
				width: "96%",
        "&& input": {
          fontSize: "13px",
          // lineHeight: "1.07",
        },
      },
      [theme.breakpoints.down(381)]: {
				width: "96%",
        "& div": {
          height: "46px",
        },
        "&& input": {
          fontSize: "12.5px",
          // lineHeight: "1.07",
        },
      },
      [theme.breakpoints.down(361)]: {
				width: "96%",
        "&& input": {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down(321)]: {
				width: "95.5%",
        "& div": {
          height: "40px",
        },
        "&& input": {
          fontSize: "10px",
          // lineHeight: "13.5px",
        },
      },
      "& p": {
        margin: "0px",
      },
    //   "& .MuiOutlinedInput-root": {
    //     "&.Mui-focused fieldset": {
    //       borderColor: "#e4e4e4",
    //     },
    //   },
		},
		down_icon: {
      position: "absolute",
      pointerEvents: "none",
			top: "calc(62% - 23px)",
			right: 0,
			width: 14,
			height: 10,
      fontWeight: "bold",
      [theme.breakpoints.down(641)]: {
				top: "calc(62% - 18px)",
				right: 0,
				width: 14,
				height: 10,
			},
			[theme.breakpoints.down(481)]: {
				top: "calc(50% - 15px)",
				right: 0,
				width: 6,
				height: 4,
			},
			[theme.breakpoints.down(421)]: {
				top: "calc(50% - 15px)",
				right: 0,
				width: 6,
				height: 4,
			},
			[theme.breakpoints.down(401)]: {
				top: "calc(50% - 13px)",
				right: 0,
				width: 6,
				height: 4,
			},
			[theme.breakpoints.down(381)]: {
				top: "calc(50% - 16px)",
				right: 0,
				width: 6,
				height: 4,
			},
			[theme.breakpoints.down(361)]: {
				top: "calc(50% - 12px)",
				right: 0,
			},
			[theme.breakpoints.down(321)]: {
				top: "calc(50% - 10px)",
				right: 0,
				width: 6,
				height: 4,
			},
		},
		down_icon1: {
      position: "absolute",
      pointerEvents: "none",
			top: "calc(62% - 17px)",
			right: 10,
			width: 14,
			height: 10,
      fontWeight: "bold",
      [theme.breakpoints.down(641)]: {
				top: "calc(50% - 5px)",
				right: 10,
				width: 14,
				height: 10,
			},
			[theme.breakpoints.down(481)]: {
				top: "calc(50% - 1px)",
				right: 10,
				width: 6,
				height: 4,
			},
			[theme.breakpoints.down(421)]: {
				top: "calc(50% - 1px)",
				right: 10,
				width: 6,
				height: 4,
			},
			[theme.breakpoints.down(401)]: {
				top: "calc(50% - 1px)",
				right: 10,
				width: 6,
				height: 4,
			},
			[theme.breakpoints.down(381)]: {
				top: "calc(50% - 1px)",
				right: 10,
				width: 6,
				height: 4,
			},
			[theme.breakpoints.down(361)]: {
				top: "calc(50% - 1px)",
				right: 10,
			},
			[theme.breakpoints.down(321)]: {
				top: "calc(50% - 1px)",
				right: 10,
				width: 6,
				height: 4,
			},
    },
    errorMessage: {
      ...theme.palette.errorMessage,
      position: "absolute",
      left: 0,
      color: errorRed,
      fontSize: "11px",
			marginLeft: "96px",
			marginTop: -23,
      [theme.breakpoints.down(641)]: {
				fontSize: "06px",
				marginLeft: 40,
				marginTop: -20,
      },
      [theme.breakpoints.down(481)]: {
				fontSize: "10px",
				marginLeft: 32,
				marginTop: -17,
      },
      [theme.breakpoints.down(421)]: {
				fontSize: "10px",
				marginLeft: 32,
				marginTop: -16,
      },
      [theme.breakpoints.down(401)]: {
				fontSize: "10px",
				marginLeft: 32,
				marginTop: -16,
      },
      [theme.breakpoints.down(381)]: {
				fontSize: "10px",
				marginLeft: 26,
				marginTop: -14,
      },
      [theme.breakpoints.down(361)]: {
				fontSize: "10px",
				marginLeft: 26,
				marginTop: -14,
      },
      [theme.breakpoints.down(321)]: {
				fontSize: "9px",
				marginLeft: 26,
				marginTop: -12,
      },
		},
		errorMessageselect: {
      ...theme.palette.errorMessage,
      position: "absolute",
      left: 0,
      color: errorRed,
      fontSize: "11px",
			marginLeft: "96px",
			marginTop: -10,
      [theme.breakpoints.down(641)]: {
				fontSize: "06px",
				marginLeft: 40,
				marginTop: -7,
      },
      [theme.breakpoints.down(481)]: {
				fontSize: "10px",
				marginLeft: 32,
				marginTop: -10,
      },
      [theme.breakpoints.down(421)]: {
				fontSize: "10px",
				marginLeft: 32,
				marginTop: -10,
      },
      [theme.breakpoints.down(401)]: {
				fontSize: "10px",
				marginLeft: 32,
				marginTop: -10,
      },
      [theme.breakpoints.down(381)]: {
				fontSize: "10px",
				marginLeft: 26,
				marginTop: -10,
      },
      [theme.breakpoints.down(361)]: {
				fontSize: "10px",
				marginLeft: 26,
				marginTop: -10,
      },
      [theme.breakpoints.down(321)]: {
				fontSize: "9px",
				marginLeft: 26,
				marginTop: -9,
      },
		},
		select_box: {
			width: '100%',
			display: 'block',
			background: '#fff',
			border: '1px solid #e4e4e4',
			borderRadius: '4px',
			position: 'relative',
			overflow: 'hidden',
			height: '92px',

			[theme.breakpoints.down(641)]: {
				height: '80px'
			},
			[theme.breakpoints.down(481)]: {
				height: '54px'
			},
			[theme.breakpoints.down(381)]: {
				height: '46px'
			},
			[theme.breakpoints.down(321)]: {
				height: '40px'
			},
		},
		tx_label: {
			padding: '20px 0 0px 13px',
			fontSize: '18px',
			color: '#aaaaaa',
			display: 'block',
			textAlign: 'left',
			[theme.breakpoints.down(641)]: {
				fontSize: '14px',
				padding: '16px 0 0px 13px'
			},
			[theme.breakpoints.down(481)]: {
				fontSize: '12px',
				padding: '5px 0 0px 13px'
			},
			[theme.breakpoints.down(421)]: {
				fontSize: '11.5px',
				padding: '5px 0 0px 13px'
			},
			[theme.breakpoints.down(401)]: {
				fontSize: '11px',
				padding: '5px 0 0px 13px'
			},
			[theme.breakpoints.down(381)]: {
				fontSize: '10.5px',
				padding: '5px 0 0px 13px'
			},
			[theme.breakpoints.down(361)]: {
				fontSize: '10px',
				padding: '5px 0 0px 13px'
			},
			[theme.breakpoints.down(321)]: {
				fontSize: '8px',
				padding: '5px 0 0px 13px'
			}
		},
		pageItems: {
			width: '100%',
			verticalAlign: 'baseline',
			marginBottom: theme.spacing(1),
			[theme.breakpoints.down('sm')]: {
				marginBottom: '14px'
			},
			[theme.breakpoints.down('xs')]: {
				marginBottom: '12px'
			}
		},
	};
};
