import React from 'react';
import { styles } from './GuidelineCardStyles';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import green_tick from '../../assets/icons/bill_upload_guidelines/green_tick.png';
import red_cross from '../../assets/icons/bill_upload_guidelines/red_cross.png';

const GuidelineCard = (props) => {
    const { classes } = props;
    let iconClass = null;
    switch (props.num) {
        case 0:
            iconClass = classes.g1
            break;

        case 1:
            iconClass = classes.g2
            break;

        case 2:
            iconClass = classes.g3
            break;

        case 3:
            iconClass = classes.g4
            break;
    
        default:
            break;
    }
    return (
        <div className={classes.root}>
            <div className={classes.info}>
                <div className={classes.margin_right1}>
                    <span className={classes.icon+' '+iconClass} />
                </div>
                <Typography className={classes.text}>{props.text}</Typography>
            </div>
            <div>
                <div className={classes.images_container+' '+classes.margin_right}>
                    <img className={classes.images} src={props.correct_image} alt='correct' />
                    <img src={green_tick} alt='correct' className={classes.ops_icon} />
                </div>
                <div className={classes.images_container+' '+classes.margin_left}>
                    <img className={classes.images} src={props.wrong_image} alt='incorrect' />
                    <img src={red_cross} alt='incorrect' className={classes.ops_icon} />
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles, { withTheme: true })(GuidelineCard);