import React from "react";
import { withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import { searchStyles } from "./SearchStyles";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
const Search = (props) => {
  const { classes } = props;
  const { onSearchTextChange, searchText } = props;

  return (
    <div className={classes.searchWrap1}>
      <form onSubmit={props.searchCall} action=".">
        <TextField
          className={classes.searchBox1}
          onChange={onSearchTextChange}
          id="outlined-search"
          label=""
          placeholder="Search Brand"
          type="search"
          variant="outlined"
          value={searchText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchText ? (
                  <IconButton
                    aria-label="clear"
                    onClick={props.resetSearchText}
                    style={{ padding: 0 }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : (
                  <div></div>
                )}
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchicon} />
              </InputAdornment>
            ),
          }}
        />
      </form>
    </div>
  );
};

export default withStyles(searchStyles, { withTheme: true })(Search);

Search.defaultProps = {
  className: "",
  paddingBottom: "",
};
