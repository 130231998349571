import React from "react";
import { withStyles } from "@material-ui/styles";
import { styles } from "./NotificationIconStyles";
import successIcon from "../../assets/icons/notifications/successIcon.svg";
import errIcon from "../../assets/icons/notifications/errIcon.svg";
import { primaryOrange, green } from "../UI/Theme";

const NotificationIcon = (props) => {
  const { classes, src } = props;

  const icon = src ? successIcon : errIcon;

  const iconBackgroundColor = src ? green : primaryOrange;

  return (
    <div
      className={classes.root}
      //   style={{ backgroundColor: iconBackgroundColor }}
    >
      <img src={src} alt="notification_icon" />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(NotificationIcon);
