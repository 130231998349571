import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Typography } from "@material-ui/core";
import deleteIcon from "../../assets/icons/submit_bill/delete_btn.svg";
import circle_tick from "../../assets/icons/submit_bill/green_tick.svg";
import { primaryOrange } from "../../components/UI/Theme";
import pdfIcon from "../../assets/icons/submit_bill/pdf.svg";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      height: "158px",
      backgroundColor: theme.palette.cards.backgroundColor,
      marginBottom: theme.spacing(2),
      borderRadius: "5px",
      [theme.breakpoints.down(481)]: {
        height: "118px",
      },
      [theme.breakpoints.down(381)]: {
        height: "89px",
      },
      [theme.breakpoints.down(321)]: {
        height: "80px",
      },
    },
    smallProgress: {
      backgroundColor: theme.palette.progressBar.processing,
      height: "6px",
      width: "100%",
      marginTop: "1px",
      borderRadius: "5px",
      [theme.breakpoints.down(481)]: {
        height: "4px",
      },
      [theme.breakpoints.down(381)]: {
        height: "3px",
      },
    },
    largeProgress: {
      backgroundColor: theme.palette.progressBar.processing,
      height: "10px",
      width: "100%",
      borderRadius: "5px",
      [theme.breakpoints.down(481)]: {
        height: "8px",
      },
      [theme.breakpoints.down(381)]: {
        height: "6px",
      },
    },
    ranger: {
      height: "6px",
      borderRadius: "5px",
      [theme.breakpoints.down(481)]: {
        height: "4px",
      },
      [theme.breakpoints.down(381)]: {
        height: "3px",
      },
    },
    largeRanger: {
      borderRadius: "5px",
      height: "10px",
      [theme.breakpoints.down(481)]: {
        height: "8px",
      },
      [theme.breakpoints.down(381)]: {
        height: "6px",
      },
    },
    content: {
      display: "flex",
      width: "100%",
      padding: "9px",
      [theme.breakpoints.down(481)]: {
        padding: "6px",
      },
      [theme.breakpoints.down(381)]: {
        padding: "5px",
      },
      [theme.breakpoints.down(321)]: {
        padding: "4px",
      },
    },
    imgContainer: {
      cursor: "pointer",
      height: "146px",
      width: "146px",
      marginRight: theme.spacing(1.5),
      [theme.breakpoints.down(641)]: {
        height: "131px",
        width: "131px",
      },
      [theme.breakpoints.down(481)]: {
        height: "99px",
        width: "99px",
      },
      [theme.breakpoints.down(381)]: {
        height: "74px",
        width: "74px",
      },
      [theme.breakpoints.down(321)]: {
        height: "66px",
        width: "66px",
      },
    },
    icon: {
      objectFit: "contain",
      objectPosition: "center",
      backgroundColor: theme.palette.img.backgroundColor,
      width: "100%",
      height: "146px",
      [theme.breakpoints.down(641)]: {
        height: "131px",
      },
      [theme.breakpoints.down(481)]: {
        height: "99px",
      },
      [theme.breakpoints.down(381)]: {
        height: "74px",
      },
      [theme.breakpoints.down(321)]: {
        height: "66px",
      },
    },
    mainContent: {
      width: "70%",
      cursor: "pointer",
    },
    headerContainer: {
      padding: theme.spacing(1),
      width: "100%",
      display: "block",
    },
    header: {
      textAlign: "left",
      position: "relative",
    },
    billStatus: {
      fontWeight: "500",
      fontSize: "20px",
      width: "90%",
      lineHeight: "1.8",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "1.6",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.4",
      },
    },
    billName: {
      fontSize: "16px",
      maxWidth: "300px",
      marginTop:'10px',
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "90%",
      [theme.breakpoints.down(641)]: {
        maxWidth: "100%",
        fontSize: "14px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
      },
      "& span": {
        fontWeight: "bold",
        fontSize: "18px",
        [theme.breakpoints.down(641)]: {
          fontSize: "16px",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "13.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "12.5px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "10px",
        },
      },
    },
    close_tick_container: {
      position: "absolute",
      right: "0px",
    },
    closeBtnContainer: {
      padding: "0px",
      position: "absolute",
      right: "0px",
      bottom: "0px",
      [theme.breakpoints.down(641)]: {
        bottom: "0px",
      },
      [theme.breakpoints.down(481)]: {
        bottom: "-4px",
      },
      [theme.breakpoints.down(381)]: {
        bottom: "-1px",
      },
      [theme.breakpoints.down(321)]: {
        bottom: "-2px",
      },
    },
    tick_container: {
      height: "28px",
      width: "28px",
      top: "4px",
      [theme.breakpoints.down(481)]: {
        height: "16px",
        width: "16px",
        top: "3px",
      },
      [theme.breakpoints.down(381)]: {
        height: "14px",
        width: "14px",
        top: "2px",
      },
      [theme.breakpoints.down(321)]: {
        top: "-2px",
      },
    },
    closeBtn: {
      height: "28px",
      width: "28px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      [theme.breakpoints.down(481)]: {
        height: "16px",
        width: "16px",
      },
      [theme.breakpoints.down(381)]: {
        height: "14px",
        width: "14px",
      },
    },
    deleteIcon: {
      width: "30px",
      height: "30px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      [theme.breakpoints.down(641)]: {
        width: "27px",
        height: "27px",
      },
      [theme.breakpoints.down(481)]: {
        width: "21px",
        height: "21px",
      },
      [theme.breakpoints.down(381)]: {
        width: "16px",
        height: "16px",
      },
      [theme.breakpoints.down(321)]: {
        width: "14px",
        height: "14px",
      },
    },
    icondeleteCss: {
      backgroundColor: '#cecece',
      " -webkit-mask-image": `url(.${deleteIcon})`,
      "mask-image": `url(.${deleteIcon})`,
      mask: `url(.${deleteIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${deleteIcon}) no-repeat center / contain`,
    },
    orangeTickCss: {
      backgroundColor: theme.palette.tick.pending,
      " -webkit-mask-image": `url(.${circle_tick})`,
      "mask-image": `url(.${circle_tick})`,
      mask: `url(.${circle_tick}) no-repeat center / contain`,
      "-webkit-mask": `url(.${circle_tick}) no-repeat center / contain`,
    },
    greenTickCss: {
      backgroundColor: theme.palette.tick.green,
      " -webkit-mask-image": `url(.${circle_tick})`,
      "mask-image": `url(.${circle_tick})`,
      mask: `url(.${circle_tick}) no-repeat center / contain`,
      "-webkit-mask": `url(.${circle_tick}) no-repeat center / contain`,
    },
    pdfIconCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${pdfIcon})`,
      "mask-image": `url(.${pdfIcon})`,
      mask: `url(.${pdfIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${pdfIcon}) no-repeat center / contain`,
    },
    pdfCss: {
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      marginTop: "15px",
      height: "40%",
      width: "40%",
    },
  };
});

const UploadingBill = (props) => {
  const classes = useStyles();
  const { status, percentage, duplicate } = props;

  return (
    <Grid container className={classes.root}>
      <div className={classes.smallProgress}>
        <div
          className={classes.ranger}
          style={{
            backgroundColor: `${
              percentage === 70 && percentage !== 100
                ? "#4fa8fa"
                : percentage === 100
                ? status === "manual"
                  ? primaryOrange
                  : status === "errorCode"
                  ? "#f44336"
                  : "#58ac22"
                : "#fac64f"
            }`,
            width: `${
              percentage > 70 && percentage !== 100 ? 70 : percentage
            }%`,
          }}
        />
      </div>
      <Grid className={classes.content}>
        <Grid
          className={classes.imgContainer}
          onClick={() =>
            !props.isPdf && props.onImageClicked(props.src, props.isPdf)
          }
        >
          {" "}
          {props.isPdf ? (
            <a href={props.src} target="_blank" rel="noopener noreferrer">
              {/* <img src={props.pdfIcon} alt="bill" className={classes.icon} /> */}
              <span className={classes.pdfIconCss + " " + classes.pdfCss} />
            </a>
          ) : (
            <img src={props.src} alt="bill" className={classes.icon} />
          )}
        </Grid>
        <Grid
          className={classes.mainContent}
          onClick={() => props.onBillClick(props.img)}
        >
          <Grid className={classes.headerContainer}>
            <Grid className={classes.header}>
              <Typography
                variant="h6"
                className={classes.billStatus}
                style={{
                  color: `${
                    percentage === 100
                      ? status === "manual"
                        ? primaryOrange
                        : status === "errorCode"
                        ? "#f44336"
                        : "#58ac22"
                      : "#211f20"
                  }`,
                }}
              >
                {percentage === 70
                  ? "Processing bill..."
                  : percentage < 70
                  ? "Uploading bill..."
                  : percentage === 100 &&
                    ((status === "manual" &&
                      !duplicate &&
                      "Bill submitted for manual processing.") ||
                      (status === "errorCode" &&
                        !duplicate &&
                        "Upload failed.") ||
                      (status === "errorCode" &&
                        duplicate &&
                        "Duplicate bill."))}
              </Typography>
              {status === "manual" && (
                <IconButton
                  className={
                    classes.closeBtnContainer +
                    " " +
                    classes.close_tick_container
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    props.delete(props.my_id);
                  }}
                >
                  <span
                    className={classes.deleteIcon + " " + classes.icondeleteCss}
                  />
                </IconButton>
              )}
              {percentage === 100 && status !== "errorCode" && (
                <div
                  className={
                    classes.tick_container + " " + classes.close_tick_container
                  }
                >
                  <span
                    className={classnames({
                      [classes.closeBtn]: true,
                      [classes.orangeTickCss]: status === "manual",
                      [classes.greenTickCss]: status !== "manual",
                    })}
                  />
                </div>
              )}
              <Typography className={classes.billName}>{props.name}</Typography>
              {status === "complete" && percentage === 100 && (
                <Typography className={classes.billName}>
                  You have earned{" "}
                  <Typography component="span">{props.points}</Typography>
                </Typography>
              )}
            </Grid>
          </Grid>
          {percentage !== 100 && (
            <Grid>
              <div className={classes.largeProgress}>
                <div
                  className={classes.largeRanger}
                  style={{
                    backgroundColor: `${
                      percentage === 70 && percentage !== 100
                        ? "#4fa8fa"
                        : "#fac64f"
                    }`,
                    width: `${
                      percentage === 70 && percentage !== 100 ? 70 : percentage
                    }%`,
                  }}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UploadingBill;
