export const styles = (theme) => {
	return {
		down_icon: {
			position: 'absolute',
			pointerEvents: 'none',
			right: '20px',
			top: 'calc(50% - 5px)'
		},
		formControl: {
			'& .MuiInputBase-input:focus': {
				backgroundColor: 'unset'
			}
		},
		menuItem: {
			fontSize: '18px',
			[theme.breakpoints.down('641')]: {
				fontSize: '16px'
			},
			[theme.breakpoints.down('481')]: {
				fontSize: '14px'
			},
			[theme.breakpoints.down('421')]: {
				fontSize: '13.5px'
			},
			[theme.breakpoints.down('401')]: {
				fontSize: '13px'
			},
			[theme.breakpoints.down('381')]: {
				fontSize: '12.5px'
			},
			[theme.breakpoints.down('361')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down('321')]: {
				fontSize: '10px'
			}
		}
	};
};
