import React, { Component, Fragment } from 'react';
import MyRoute from '../../../hoc/MyRoute';
import MyMembership from './components/MyMembership';
import HeaderMyMembership from '../../../components/Layout/Header/HeaderMyMembership';
import AlertMessage from '../../../common/AlertMessage/AlertMessage';
import { changeActiveTab, showLoader,logoutUser } from '../../../App/redux/action';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Footer from '../../../components/Layout/Footer/Footer';

const mapDispatchToProps = {
	changeActiveTab,
	showLoader,
	logoutUser,
};

const mapStateToProps=(state)=>{
	return {
		merchantDetailsResponse: state.reducerDashboard.merchantDetailsResponse,
		uploadingStatus: state.reducerBillUploadProgress.uploadingStatus	
	}
}

class MyMembershipContainer extends Component {
	componentDidMount(){		
		this.props.changeActiveTab(0);
	}

	render() {
		return (
			<Fragment>
				<HeaderMyMembership />
				<AlertMessage />
				<MyRoute billUploadStatus={this.props.uploadingStatus}>
					<MyMembership openLoader={this.props.showLoader}  logoutUser={this.props.logoutUser}  merchantDetailsResponse={this.props.merchantDetailsResponse}/>
				</MyRoute>
				<Grid item>
					<Footer />
				</Grid>
			</Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyMembershipContainer);
