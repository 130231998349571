import React, { Component, Fragment } from 'react';
import HeaderSubmitBill from '../../../components/Layout/Header/HeaderSubmitBill';
import { headerScreenLabels } from '../../../common/common-types';
import AlertMessage from '../../../common/AlertMessage/AlertMessage';
import MyRoute from '../../../hoc/MyRoute';
import EditProfile from '../../../components/EditProfile/EditProfile';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
	return {
		uploadingStatus: state.reducerBillUploadProgress.uploadingStatus
	};
};

class EditProfileContainer extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<Fragment>
				<HeaderSubmitBill screenLabel={headerScreenLabels.editprofile} />
				<AlertMessage />
				<MyRoute billUploadStatus={this.props.uploadingStatus}>
					<EditProfile />
				</MyRoute>
			</Fragment>
		);
	}
}

export default connect(mapStateToProps)(EditProfileContainer);
