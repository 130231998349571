import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OtpVerification from "../OtpVerification/components/OtpVerification";
import {
  authenticate,
  setOTPVerifiedFlag,
  setExceedOtpTimeFlag,
  setOTPTimeFlag,
  setResendCount,
  setTryCount,
  resetResendCount,
  resetTryCount,
  setRegVisitedflag,
  isReferalEnable
} from "./redux/action";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import { showLoader } from ".././../../App/redux/action";

const mapDispatchToProps = {
  authenticate,
  setOTPVerifiedFlag,
  setExceedOtpTimeFlag,
  setOTPTimeFlag,
  setResendCount,
  setTryCount,
  resetResendCount,
  resetTryCount,
  setRegVisitedflag,
  showLoader,
  isReferalEnable,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.reducerOtpverification.isAuthenticated,
    isOtpVerified: state.reducerOtpverification.isOtpVerified,
    isOtpTime: state.reducerOtpverification.isOtpTime,
    isExceedTime: state.reducerOtpverification.isExceedTime,
    resendCount: state.reducerOtpverification.resendCount,
    wrongOtpCount: state.reducerOtpverification.wrongOtpCount,
    isReferalBoxEnable:state.reducerOtpverification.isReferalEnable
  };
};

class OtpVerificationContainer extends Component {
  render() {
  
    const {
      setOTPVerifiedFlag,
      authenticate,
      isOtpVerified,
      isAuthenticated,
      setExceedOtpTimeFlag,
      setOTPTimeFlag,
      isOtpTime,
      isExceedTime,
      setResendCount,
      resendCount,
      wrongOtpCount,
      setTryCount,
      resetResendCount,
      resetTryCount,
      setRegVisitedflag,
      showLoader,
      isReferalBoxEnable,
      isReferalEnable
    } = this.props;
    const otpVerificationProps = {
      setOTPVerifiedFlag,
      authenticate,
      isOtpVerified,
      isAuthenticated,
      setExceedOtpTimeFlag,
      setOTPTimeFlag,
      isOtpTime,
      isExceedTime,
      setResendCount,
      resendCount,
      wrongOtpCount,
      setTryCount,
      resetResendCount,
      resetTryCount,
      setRegVisitedflag,
      showLoader,
      isReferalBoxEnable,
      isReferalEnable,
    };
    return (
      <Fragment>
        <AlertMessage />
        <OtpVerification {...otpVerificationProps} />
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OtpVerificationContainer));
