import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
	return {
		root: {
			height: '146px',
			width: '100%',
			backgroundColor: theme.palette.cards.backgroundColor,
			border: '2px dashed #cecece',
			borderRadius: '5px',
			marginBottom: theme.spacing(2),
			cursor: 'pointer',
			[theme.breakpoints.down('sm')]: {
				height: '130px'
			},
			[theme.breakpoints.down('xs')]: {
				border: '1px dashed #cecece',
				height: '100px'
			}
		},
		mainTitle: {
			textAlign: 'center',
			fontSize: '20px',
			lineHeight: '1.5',
			fontWeight: '600',
			color: '#7e7e7e',
			display: 'block',
			width: '100%',
			[theme.breakpoints.down(641)]: {
				fontSize: '16px',
				lineHeight: '1.45'
			},
			[theme.breakpoints.down(481)]: {
				fontSize: '14px',
				lineHeight: '1.5'
			},
			[theme.breakpoints.down(421)]: {
				fontSize: '13.5px',
				lineHeight: '1.29'
			},
			[theme.breakpoints.down(401)]: {
				fontSize: '13px'
			},
			[theme.breakpoints.down(381)]: {
				fontSize: '12.5px'
			},
			[theme.breakpoints.down(361)]: {
				fontSize: '12px',
				lineHeight: '1.29'
			},
			[theme.breakpoints.down(321)]: {
				fontSize: '10px',
				lineHeight: '1.33'
			}
		},
		secondaryTitle: {
			textAlign: 'center',
			fontSize: '18px',
			lineHeight: '1.5',
			[theme.breakpoints.down(641)]: {
				fontSize: '14px',
				lineHeight: '1.45'
			},
			[theme.breakpoints.down(481)]: {
				fontSize: '12px',
				lineHeight: '1.5'
			},
			[theme.breakpoints.down(421)]: {
				fontSize: '11.5px',
				lineHeight: '1.29'
			},
			[theme.breakpoints.down(401)]: {
				fontSize: '11px'
			},
			[theme.breakpoints.down(381)]: {
				fontSize: '10.5px'
			},
			[theme.breakpoints.down(361)]: {
				fontSize: '10px'
			},
			[theme.breakpoints.down(321)]: {
				fontSize: '8px',
				lineHeight: '1.33'
			},
			color: '#7e7e7e',
			display: 'block',
			width: '100%'
		},
		iconContainer: {
			height: '134px',
			width: '134px',
			display: 'flex',
			alignItems: 'center',
			border: '1px dashed #cecece',
			justifyContent: 'center',
			backgroundColor: '#f5f5f5',
			borderRadius: '10px',
			[theme.breakpoints.down('sm')]: {
				height: '118px',
				width: '118px',
			},
			[theme.breakpoints.down('xs')]: {
				height: '88px',
				width: '88px',
			}
		},
		contentContainer: {
			width: 'calc(100% - 124px)',
			[theme.breakpoints.down('sm')]: {
				width: 'calc(100% - 108px)',
			},
			[theme.breakpoints.down('xs')]: {
				width: 'calc(100% - 78px)',
			}
		},
		plusIcon: {
			height: '40%',
			width: '40%'
		},
		newContainer: {
			padding: '6px',
			height: '146px',
			width: '100%',
			backgroundColor: theme.palette.cards.backgroundColor,
			border: '2px dashed #cecece',
			borderRadius: '5px',
			marginBottom: theme.spacing(2),
			cursor: 'pointer',
			display: 'flex',
			[theme.breakpoints.down('sm')]: {
				height: '130px'
			},
			[theme.breakpoints.down('xs')]: {
				border: '1px dashed #cecece',
				height: '100px'
			}
		}
	};
});
