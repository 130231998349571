module.exports = {
  //redeem

  FETCH_PRODUCT_LIST: "FETCH_PRODUCT_LIST",
  GET_ERRORS_PRODUCT_LIST: "GET_ERRORS_PRODUCT_LIST",

  FETCH_DASH_PRODUCT_LIST: "FETCH_DASH_PRODUCT_LIST",
  GET_ERRORS_DASH_PRODUCT_LIST: "GET_ERRORS_DASH_PRODUCT_LIST",

  FETCH_PRODUCT_DETAILS: "FETCH_PRODUCT_DETAILS",
  GET_ERRORS_PRODUCT_DETAILS: "GET_ERRORS_PRODUCT_DETAILS",

  ADD_CART: "ADD_CART",
  GET_ERRORS_ADD_CART: "GET_ERRORS_ADD_CART",

  ADD_CART_Temp: "ADD_CART_Temp",

  CART_ITEM_COUNT: "CART_ITEM_COUNT",
  GET_ERRORS_CART_ITEM_COUNT: "GET_ERRORS_CART_ITEM_COUNT",
  CLEAR_PRODUCT_DETAILS: "CLEAR_PRODUCT_DETAILS",
  CLEAR_PRODUCT_LIST: "CLEAR_PRODUCT_LIST",
  SET_PRODUCT_VIEW: "SET_PRODUCT_VIEW",
  SET_SORT_BY: "SET_SORT_BY",
  CLEAR_PRODUCT_VIEW: "CLEAR_PRODUCT_VIEW",
  CLEAR_SORT_BY: "CLEAR_SORT_BY",
  ON_VOUCHER_CHANGE: "ON_VOUCHER_CHANGE",
  CLEAR_SELECTED_PRODUCT_DETAILS: "CLEAR_SELECTED_PRODUCT_DETAILS",
  TOGGLE_FILTER_DRAWER: "TOGGLE_FILTER_DRAWER",

  SET_FROM_ORDER: "SET_FROM_ORDER",
  RESET_REDEEM: "RESET_REDEEM",
  RESET_REDEEM_DATA: "RESET_REDEEM_DATA",
};
