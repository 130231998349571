import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Avatar,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Divider,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/styles";
import { stockAPI } from "../../../../common/axiosInstance";
import { getStorageItem } from "../../../../common/localStorage";
import { apiData } from "../../../../common/common-types";
const badgeIcon = require("../../../../assets/icons/transactions/badge.svg");

const styles = (theme) => {
  return {
    root: {
      display: "block",
      textAlign: "center",
      overflowY: "auto",
      width: "100%",
      "& .MuiList-padding": {
        padding: 0,
      },
      margin: "0 auto",
    },
    listRoot: {
      width: "100%",
      maxWidth: "600px",
      margin: "0 auto",
    },
    listItemTextRoot: {
      maxWidth: "50%",
      flex: "0 auto",
      [theme.breakpoints.down("lg")]: {
        marginRight: "10px",
      },
      [theme.breakpoints.down("sm")]: {
        marginRight: "10px",
      },
      [theme.breakpoints.down("xs")]: {
        marginRight: "5px",
      },
    },
    listItemTextRootMaxlen: {
      maxWidth: "45%",
      flex: "0 auto",
      marginRight: "10px",
      [theme.breakpoints.down("lg")]: {
        marginRight: "10px",
      },
      [theme.breakpoints.down("641")]: {
        marginRight: "10px",
        maxWidth: "34%",
      },
      [theme.breakpoints.down("481")]: {
        marginRight: "5px",
        maxWidth: "38%",
      },
      [theme.breakpoints.down("401")]: {
        marginRight: "5px",
        maxWidth: "40%",
      },
    },
    square: {
      width: "100%",
      height: "53px",
      padding: "10px",
      color: "#FFFFFF",
      backgroundColor: '#00abe5',
      float: "left",
      textAlign: "center",
      fontWeight: "bold",
      [theme.breakpoints.down("lg")]: {
        borderRadius: "4px",
      },
      [theme.breakpoints.down("sm")]: {
        borderRadius: "4px",
      },
      [theme.breakpoints.down("xs")]: {
        borderRadius: "4px",
        height: "48px",
      },
      fontSize: "18px",
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
        height: "37px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
        height: "28px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "9px",
        height: "25px",
      },
    },
    listPoints: {
      fontWeight: "bold",
      fontSize: "20px",
      [theme.breakpoints.down("641")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
    },
    loyalty_name: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontWeight: "500",
      fontSize: "20px",
      [theme.breakpoints.down("641")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
    },
    tierAvatarRoot: {
      [theme.breakpoints.down("lg")]: {
        Width: "50px",
        Height: "35px",
      },
      [theme.breakpoints.down("sm")]: {
        Width: "50px",
        Height: "35px",
      },
      [theme.breakpoints.down("xs")]: {
        Width: "22px",
        Height: "15px",
      },
    },
    headerPointsContainer: {
      width: "50%",
      float: "right",
    },
    stepperListItemRoot: {
      padding: 0,
      "& .MuiStepConnector-alternativeLabel": {
        left: "calc(-40% + 16px)",
        right: "calc(60% + 16px)",
        [theme.breakpoints.down("481")]: {
          left: "calc(-40% + 10px)",
          right: "calc(60% + 10px)",
        },
        position: "absolute",
      },
      "& .MuiStepper-root": {
        padding: "10px",
      },
    },
    customeFontSize: {
      fontSize: "18px",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    infoContainer: {
      padding: "0 10px 10px 10px",
    },
    info: {
      color: theme.palette.typography.texts.color,
      width: "100%",
      textAlign: "center",
      fontSize: "16px",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    info_price: {
      fontWeight: "bold",
      color: theme.palette.typography.texts.specialPointsColor,
    },
    info_tier_name: {
      fontWeight: "bold",
    },
    tierLabelContainer: {
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        marginTop: "12px",
      },
    },
    loyaltyName: {
      fontSize: "34px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      marginRight: "20px",
      textAlign: "left",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      [theme.breakpoints.down("641")]: {
        fontSize: "22px",
        marginRight: "13px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "17.5px",
        marginRight: "13px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "16.5px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "16px",
        marginRight: "6px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "13.5px",
        marginRight: "5px",
      },
    },
  };
};

const ColorlibConnector = withStyles(
  (theme) => {
    return {
      alternativeLabel: {
        top: 22,
        [theme.breakpoints.down("481")]: {
          top: 17,
        },
        [theme.breakpoints.down("421")]: {
          top: 16,
        },
        [theme.breakpoints.down("381")]: {
          top: 15,
        },
        [theme.breakpoints.down("361")]: {
          top: 13,
        },
        [theme.breakpoints.down("321")]: {
          top: 12,
        },
      },
      active: {
        "& $line": {
          backgroundColor: "#00abe5",
        },
      },
      completed: {
        "& $line": {
          backgroundColor: "#00abe5",
        },
      },
      line: {
        height: "8px",
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 2,
        [theme.breakpoints.down("481")]: {
          height: "7px",
        },
        [theme.breakpoints.down("421")]: {
          height: "6px",
        },
        [theme.breakpoints.down("361")]: {
          height: "5px",
        },
      },
    };
  },
  { withTheme: true }
)(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: "58px",
    height: "58px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("641")]: {
      width: "52px",
      height: "52px",
    },
    [theme.breakpoints.down("481")]: {
      width: "40px",
      height: "40px",
    },
    [theme.breakpoints.down("421")]: {
      width: "37px",
      height: "37px",
    },
    [theme.breakpoints.down("381")]: {
      width: "33px",
      height: "33px",
    },
    [theme.breakpoints.down("361")]: {
      width: "30px",
      height: "30px",
    },
    [theme.breakpoints.down("321")]: {
      width: "27px",
      height: "27px",
    },
  },
  active: {
    backgroundColor: "#00abe5",
    // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "#00abe5",
  },
  imageSize: {
    width: "35px",
    height: "40px",
    "--size ": "70px",
    display: "inline-block",
    transition: ".12s",
    "-webkit-mask-size": "cover",
    "mask-size ": "cover",
    [theme.breakpoints.down("641")]: {
      width: "32px",
      height: "37px",
    },
    [theme.breakpoints.down("481")]: {
      width: "29px",
      height: "33px",
    },
    [theme.breakpoints.down("421")]: {
      width: "26px",
      height: "30px",
    },
    [theme.breakpoints.down("381")]: {
      width: "22px",
      height: "27px",
    },
    [theme.breakpoints.down("361")]: {
      width: "18px",
      height: "24px",
    },
    [theme.breakpoints.down("321")]: {
      width: "15px",
      height: "19px",
    },
  },
  badgeIconCss: {
    backgroundColor: theme.palette.header.badge,
    " -webkit-mask-image": `url(.${badgeIcon})`,
    "mask-image": `url(.${badgeIcon})`,
    mask: `url(.${badgeIcon}) no-repeat center / contain`,
    "-webkit-mask": `url(.${badgeIcon}) no-repeat center / contain`,
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <span className={classes.imageSize + " " + classes.badgeIconCss} />
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

class LoyaltyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tiers: [],
      steps: ["Blue", "Silver", "Gold", "Platinum"],
      balancePoints: null,
      currentTierId: null,
      loyaltyProgramName: null,
      currentTierName: null,
      activeStep: 0,
      ptShortName: "P",

      awayBy: 0,
      nextTierName: "",
    };
  }

  async componentDidMount() {
    var user;
    let loyaltyId;
    let ptShortName;
    if (getStorageItem("user") && getStorageItem("loyaltyId")) {
      user = JSON.parse(getStorageItem("user"));
      loyaltyId = parseInt(getStorageItem("loyaltyId"));
      ptShortName = getStorageItem("pointShortName")
        ? getStorageItem("pointShortName")
        : "P";
      const params = new FormData();
      params.append("customer_id", user.id);
      params.append("platform", apiData.platform);
      params.append("loyalty_id", loyaltyId);
      params.append("merchant_id", apiData.merchant_id);

      var status = await stockAPI(
        params,
        "POST",
        "/getLoyaltyProgramTierDetails"
      );

      if (status) {
        if (status.merchant_tiers && status.merchant_tiers.length > 0) {
          var stepNumber = status.merchant_tiers.findIndex((item, index) => {
            return item.tier_id === status.customer_current_tier_id;
          });
        }

        await this.setState({
          tiers: status.merchant_tiers ? status.merchant_tiers : [],
          activeStep: stepNumber ? stepNumber : 0,
          balancePoints: status.current_points_balance
            ? parseFloat(status.current_points_balance) % 1 !== 0
              ? parseFloat(status.current_points_balance).toFixed(2)
              : Math.floor(status.current_points_balance)
            : 0,
          currentTierId: status.customer_current_tier_id
            ? status.customer_current_tier_id
            : 0,
          loyaltyProgramName: status.loyalty_program_name
            ? status.loyalty_program_name
            : "",
          currentTierName: status.customer_current_tier_name
            ? status.customer_current_tier_name
            : "",
          ptShortName,
          awayBy: status.away_from_next_membership
            ? status.away_from_next_membership
            : 0,
          nextTierName: status.away_from_next_tier_name,
        });
      }
    }
  }

  render() {
    const { classes } = this.props;
    const {
      tiers,
      balancePoints,
      loyaltyProgramName,
      currentTierName,
      activeStep,
      ptShortName,
    } = this.state;

    return (
      <div>
        {loyaltyProgramName && currentTierName ? (
          <Paper variant="elevation" className={classes.root}>
            <List className={classes.listRoot}>
              {this.props.isVisible ? null : <ListItem>
                <ListItemText
                  disableTypography
                  classes={{
                    root: loyaltyProgramName
                      ? loyaltyProgramName.length <= 11
                        ? classes.listItemTextRoot
                        : classes.listItemTextRootMaxlen
                      : classes.listItemTextRoot,
                    primary: classes.customeFontSize,
                  }}
                  primary={
                    <div className={classes.loyaltyName}>
                      {loyaltyProgramName
                        ? loyaltyProgramName
                        : "The Infiniti World loyalty"}
                    </div>
                    // <Typography className={classes.loyalty_name}>
                    //   {loyaltyProgramName ? loyaltyProgramName : ""}
                    // </Typography>
                  }
                />
                {tiers.length > 1 && currentTierName && (
                  <ListItemAvatar classes={{ root: classes.tierAvatarRoot }}>
                    <Avatar variant="square" className={classes.square}>
                      {currentTierName}
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemSecondaryAction
                  className={classes.headerPointsContainer}
                >
                  <Typography
                    className={classes.listPoints}
                    style={{ float: "right" }}
                  >
                    {balancePoints}
                    {" " + ptShortName}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>}
              {this.props.isVisible?null:
                tiers.length > 1 && <Divider variant="middle" /> 
              }
              <ListItem classes={{ root: classes.stepperListItemRoot }}>
                {tiers.length > 1 && (
                  <div
                    style={{
                      width: "100%",
                      margin: "0 auto",
                      padding: "0px 10px",
                    }}
                  >
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={<ColorlibConnector />}
                    >
                      {tiers.map((item, index) => (
                        <Step key={index}>
                          <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                            classes={{ label: classes.customeFontSize }}
                            className={classes.tierLabelContainer}
                          >
                            {item.tier_name}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                )}
              </ListItem>
              {this.state.awayBy > 0 &&
                this.state.awayBy !== 0 &&
                tiers.length > 1 && (
                  <ListItem className={classes.infoContainer}>
                    <Typography className={classes.info}>
                      You are{" "}
                      <span className={classes.info_price}>
                        {apiData.merchant_id === 10071
                          ? " " +
                            this.state.awayBy.toLocaleString() +
                            " " +
                            getStorageItem("pointShortName") +
                            " "
                          : getStorageItem("currencyCode") +
                            " " +
                            this.state.awayBy.toLocaleString() +
                            " "}
                      </span>
                      away from unlocking{" "}
                      <span className={classes.info_tier_name}>
                        {this.state.nextTierName + " "}
                      </span>
                      membership
                    </Typography>
                  </ListItem>
                )}
            </List>
          </Paper>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(LoyaltyInfo));
