export const styles = (theme) => {
  return {
    root: {
      position: "relative",
      width: "100%",
      backgroundColor: theme.palette.cards.backgroundColor,
      padding: "1px",
      marginBottom: theme.spacing(2),
      fontFamily: "Montserrat",
      // height: "177px",
      overflow: "hidden",
      borderRadius: "4px",
      // [theme.breakpoints.down(641)]: {
      //   height: "131px",
      // },
      // [theme.breakpoints.down(481)]: {
      //   height: "103px",
      // },
      // [theme.breakpoints.down(381)]: {
      //   height: "86px",
      // },
      // [theme.breakpoints.down(321)]: {
      //   height: "80px",
      // },
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    imageContainer: {
      display: "inline-block",
      width: "177px",
      height: "177px",
      borderRadius: "4px",
      // backgroundColor: theme.palette.img.backgroundColor,
      [theme.breakpoints.down("641")]: {
        height: "129px",
        width: "131px",
      },
      [theme.breakpoints.down("481")]: {
        height: "101px",
        width: "103px",
      },
      [theme.breakpoints.down("381")]: {
        height: "84px",
        width: "86px",
      },
      [theme.breakpoints.down("321")]: {
        height: "78px",
        width: "80px",
      },
      "& img": {
        height: "100%",
        width: "100%",
        borderRadius: "4px",
        objectFit: "contain",
        objectPosition: "center",
      },
    },
    details: {
      display: "inline-block",
      padding: "9px 20px 7px 30px",
      fontFamily: "Montserrat",
      width: "calc(100% - 177px)",
      height: "100%",
      verticalAlign: "top",
      [theme.breakpoints.down("641")]: {
        width: "calc(100% - 131px)",
        padding: "5px 10px 0px 12px",
      },
      [theme.breakpoints.down("481")]: {
        // height: "103px",
        width: "calc(100% - 103px)",
      },
      [theme.breakpoints.down("381")]: {
        // height: "86px",
        width: "calc(100% - 86px)",
      },
      [theme.breakpoints.down("321")]: {
        // height: "80px",
        width: "calc(100% - 80px)",
      },
    },
    brandName: {
      fontWeight: "500",
      color: theme.palette.typography.texts.color,
      fontFamily: "Montserrat",
      fontSize: "16px",
      height: "17px",
      width: "90%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      textAlign: "left",
      marginTop: "5px",
      [theme.breakpoints.down(641)]: {
        fontSize: "14px",
        height: "15px",
        lineHeight: "1.2",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
        height: "13px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "10.5px",
        height: "11px",
        lineHeight: "1.07",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
        height: "9px",
        lineHeight: "1.13",
      },
    },
    productName: {
      fontWeight: "600",
      color: theme.palette.typography.texts.color,
      fontFamily: "Montserrat",
      textAlign: "left",
      lineHeight: "1.5",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      width: "100%",
      paddingTop: "2px",
      fontSize: "20px",
      [theme.breakpoints.down("641")]: {
        fontSize: "18px",
        height: "48px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
        height: "30px",
        lineHeight: "15px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
        height: "30px",
        lineHeight: "14px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        lineHeight: "11px",
        height: "25px",
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        height: "24px",
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        lineHeight: "9px",
        fontSize: "10px",
        height: "20px",
      },
    },
    price_mall: {
      fontSize: "16px",
      textAlign: "left",
      lineHeight: 1.67,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontFamily: "Montserrat",
      maxWidth: "50%",
      [theme.breakpoints.down(641)]: {
        fontSize: "14px",
        lineHeight: "1.66",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
        lineHeight: "1.43",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "10.5px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
        lineHeight: "1.35",
      },
    },
    separator: {
      margin: "0px 18px",
      padding: "0px",
      fontSize: "16px",
      color: " #e4e4e4",
      lineHeight: 1,
      [theme.breakpoints.down("xs")]: {
        margin: "0px 8px",
        fontSize: "12px",
      },
    },
    plusRow: {
      // textTransform: 'uppercase',
      marginTop: "15px",
      fontFamily: "Montserrat",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0px",
        "& .MuiTypography-body1": {
          lineHeight: 1,
        },
      },
    },
    rsp: {
      // textTransform: 'uppercase',
      fontSize: "16px",
      lineHeight: "1",
      fontFamily: "Montserrat",
      color: theme.palette.typography.texts.color,
      [theme.breakpoints.down(641)]: {
        fontSize: "14px",
        lineHeight: "0.98",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "13px",
        lineHeight: "1",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
      },
    },
    mgSmallClose: {
      margin: "0px 16px",
      fontSize: "16px",
      color: "#7e7e7e",
      [theme.breakpoints.down(641)]: {
        fontSize: "14px",
        lineHeight: "0.98",
        margin: "0px 10px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
        lineHeight: "1",
        margin: "0px 6px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
      },
    },
    price_points: {
      fontSize: "18px",
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontFamily: "Montserrat",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
      },
    },
    price_mallName: {
      textAlign: "left",
      fontFamily: "Montserrat",
      lineHeight: 1,
      paddingBottom: "5px",
      margin: "5px 0 0 0",
      [theme.breakpoints.down(641)]: {
        marginTop: "2px",
        paddingBottom: "0px",
        lineHeight: 0.5,
      },
    },
    img: {
      width: "30px",
      height: "30px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        width: "16px",
        height: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "20px",
        height: "20px",
      },
      [theme.breakpoints.down(450)]: {
        width: "14px",
        height: "14px",
      },
    },
    rootChild: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      height: "100%",
    },
    mb10: {
      marginBottom: "10px",
    },
    sub_content: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    pro_content: {
      display: "flex",
      justifyContent: "space-between",
    },
    point_cal: {
      display: "flex",
      justifyContent: "space-between",
    },
    productDetails: {
      width: "90%",
    },
    down_icon: {
      position: "absolute",
      pointerEvents: "none",
      right: "5px",
      top: "calc(50% - 4px)",
      [theme.breakpoints.down(641)]: {
        top: "calc(50% - 3px)",
      },
      [theme.breakpoints.down(481)]: {
        top: "calc(50% - 1.8px)",
      },
      [theme.breakpoints.down(381)]: {
        top: "calc(50% - 1.8px)",
      },
      [theme.breakpoints.down(321)]: {
        top: "calc(50% - 2.8px)",
      },
    },
    formControl: {
      "& :first-child": {
        paddingRight: "10px",
        paddingTop: "2px",
        [theme.breakpoints.down(381)]: {
          paddingTop: "0px",
        },
      },
      "& .MuiInputBase-input": {
        fontSize: "16px",
        color: theme.palette.typography.texts.color,
        fontWeight: "normal",
        fontFamily: "Montserrat",
        [theme.breakpoints.down(641)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "11px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "10px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "9px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "9px",
        },
      },
      "& .MuiSelect-selectMenu": {
        padding: 0,
      },
      "& .MuiInputBase-input:focus": {
        backgroundColor: "unset",
      },
    },
    select_box: {
      display: "block",
      background: "#fff",
      border: "1px solid #e4e4e4",
      borderRadius: "4px",
      position: "relative",
      overflow: "hidden",
      height: "50px",
      width: "50px",
      [theme.breakpoints.down(641)]: {
        width: "48px",
        height: "26px",
      },
      [theme.breakpoints.down(481)]: {
        width: "44px",
        height: "24px",
      },
      [theme.breakpoints.down(421)]: {
        width: "40px",
        height: "24px",
      },
      [theme.breakpoints.down(381)]: {
        width: "34px",
        height: "22px",
      },
      [theme.breakpoints.down(321)]: {
        width: "34px",
        height: "20px",
      },
    },
    orderStatusValue: {
      fontSize: "16px",
      lineHeight: 1.3,
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    orderStatus: {
      borderWidth: "2px",
      borderRadius: "15px",
      borderStyle: "solid",
      padding: "1px 5px",
      position: "absolute",
      top: "16px",
      right: "5px",
      [theme.breakpoints.down("641")]: {
        top: "5px",
        borderWidth: "1px",
      },
    },
    voucher_pin_box: {
      marginBottom: "4px",
      padding: "10px 10px 10px 24px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      border: "dashed 2px #a7da2b",
      cursor: "pointer",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down(421)]: {
        padding: "3px 3px 3px 14px",
      },
      [theme.breakpoints.down(321)]: {
        padding: "2px 2px 2px 12px",
      },
      "& .MuiTypography-body1": {
        lineHeight: 1.3,
      },
    },
    voucherPin: {
      fontFamily: "Montserrat",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontSize: "18px",
      color: "#fff",
      padding: "6px 10px",
      backgroundColor: "#A8DA2A",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    voucher_wrapper: {
      display: "flex",
      justifyContent: "space-between",
      margin: "0px 10px",
    },
    voucherContainer: {
      width: "80%",
      margin: "5px 0px",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    listVoucher: {
      width: "100%",
      float: "left",
      textAlign: "center",
      fontWeight: "400",
      fontSize: "16px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    dateValidity: {
      fontFamily: "Montserrat",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      padding:'2px 4px',
      letterSpacing: "normal",
      fontSize: "16px",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "9px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "8.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "8px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "6px",
      },
    },

    pin: {
      padding: 0,
      margin: 0,
      textAlign: "left",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    coupons_box: {
      width: "fit-content",
      minWidth: "90px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      float: "right",
      // borderRadius: "4px",
      border: "dashed 2px #a7da2b",
      cursor: "pointer",
      height: "100%",
      padding: "10px 10px",
      [theme.breakpoints.down(421)]: {
        padding: "3px 7px",
      },
      // [theme.breakpoints.down(381)]: {
      //   padding: "5px 7px",
      // },
      [theme.breakpoints.down(321)]: {
        padding: "4px 10px",
      },
    },
    coupons: {
      color: theme.palette.typography.texts.color,
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "600",
      // padding: "3px 0px",
      [theme.breakpoints.down("641")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "17.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "16.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "16px",
      },
    },
    avtarCss: {
      minWidth: "53px",
      width: "53px",
      height: "52px",
      marginRight: "24px",
      [theme.breakpoints.down("481")]: {
        marginRight: "20px",
      },
      [theme.breakpoints.down("481")]: {
        minWidth: "39px",
        width: "39px",
        height: "40px",
        marginRight: "15px",
      },
      [theme.breakpoints.down("381")]: {
        minWidth: "30px",
        width: "30px",
        height: "30px",
        marginRight: "12px",
      },
      [theme.breakpoints.down("321")]: {
        minWidth: "27px",
        width: "27px",
        height: "26px",
        marginRight: "9px",
      },
    },
    silver: {
      margin: "10px",
      backgroundColor: "#f5f5f5",
      color: theme.palette.typography.texts.color,
      textAlign: "center",
      fontSize: "18px",
      fontWeight: "500",
      // padding: "3px 0px",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
    },
    pd: {
      padding: "10px 12px",
    },
    noSelect: {
      "-webkit-touch-callout": "none",
      "-webkit-user-select": "none",
      "-khtml-user-select": "none",
      "-moz-user-select": "none",
      "-ms-user-select": "none",
      userSelect: "none",
      " -webkit-tap-highlight-color": "transparent",
    },
  };
};
