import { stockAPI } from "../../../../common/axiosInstance";
import {
  FETCH_DASH_PRODUCT_LIST,
  GET_ERRORS_DASH_PRODUCT_LIST,
  FETCH_PRODUCT_LIST,
  GET_ERRORS_PRODUCT_LIST,
  FETCH_PRODUCT_DETAILS,
  GET_ERRORS_PRODUCT_DETAILS,
  ADD_CART,
  GET_ERRORS_ADD_CART,
  CART_ITEM_COUNT,
  GET_ERRORS_CART_ITEM_COUNT,
  ADD_CART_Temp,
  CLEAR_PRODUCT_DETAILS,
  CLEAR_PRODUCT_LIST,
  SET_SORT_BY,
  SET_PRODUCT_VIEW,
  CLEAR_PRODUCT_VIEW,
  CLEAR_SORT_BY,
  ON_VOUCHER_CHANGE,
  CLEAR_SELECTED_PRODUCT_DETAILS,
  TOGGLE_FILTER_DRAWER,
  SET_FROM_ORDER,
} from "./actionTypes";
import { SHOW_LOADER } from "../../../../App/redux/actionTypes";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";

export const getProductList = (bodyObj) => (dispatch) => {
  return stockAPI(
    bodyObj,
    "POST",
    "/getProductList",
    null,
    null,
    undefined,
    true
  )
    .then(async (productList) => {
      if (productList) {
        dispatch({ type: SHOW_LOADER, loader: false });
        await dispatch({
          type: FETCH_PRODUCT_LIST,
          payload: productList,
        });
      } else {
        // openAlertBox("Oops something went wrong!", "error");
        dispatch({ type: SHOW_LOADER, loader: false });
      }
    })
    .catch(function (err) {
      dispatch({ type: SHOW_LOADER, loader: false });
      dispatch({ type: GET_ERRORS_PRODUCT_LIST, payload: err });
    });
};

export const getDashProductList = (bodyObj) => (dispatch) => {
  return stockAPI(
    bodyObj,
    "POST",
    "/getProductList",
    null,
    null,
    undefined,
    true
  )
    .then(async (productList) => {
      if (productList) {
        dispatch({ type: SHOW_LOADER, loader: false });
        await dispatch({
          type: FETCH_DASH_PRODUCT_LIST,
          payload: productList,
        });
      } else {
        // openAlertBox("Oops something went wrong!", "error");
      }
    })
    .catch(function (err) {
      dispatch({ type: SHOW_LOADER, loader: false });
      dispatch({ type: GET_ERRORS_DASH_PRODUCT_LIST, payload: err });
    });
};

export const getProductDetails = (bodyObj, selectedProductID) => (dispatch) => {
  dispatch({ type: SHOW_LOADER, loader: true });
  return stockAPI(
    bodyObj,
    "POST",
    "/getProductDetails",
    null,
    null,
    undefined,
    true
  )
    .then(async (productDetails) => {
      if (productDetails.response && productDetails.statusCode === 100) {
        dispatch({ type: SHOW_LOADER, loader: false });
        dispatch({
          type: FETCH_PRODUCT_DETAILS,
          payload: productDetails.response,
        });
        let selectedProDetails = productDetails.response.productDetails.filter(
          (x) => {
            if (x.id === selectedProductID) return x;
          }
        );
        dispatch({
          type: ON_VOUCHER_CHANGE,
          payload: selectedProDetails,
        });
      } else {
        // openAlertBox("Oops something went wrong!", "error");
      }
    })
    .catch(function (err) {
      openAlertBox(err && err.message, "error");
      dispatch({ type: SHOW_LOADER, loader: false });
      // dispatch({ type: GET_ERRORS_PRODUCT_DETAILS, payload: err });
    });
};

export const addToCart = (bodyObj) => (dispatch) => {
  return stockAPI(bodyObj, "POST", "/addCart", null, null, undefined, true)
    .then(async (addtoCart) => {
      if (addtoCart.statusCode) {
        dispatch({ type: ADD_CART, payload: addtoCart.statusCode });
      } else {
        openAlertBox("Oops something went wrong!", "error");
      }
    })
    .catch(function (err) {
      openAlertBox(err && err.message, "error");
      dispatch({ type: GET_ERRORS_ADD_CART, payload: err });
    });
};

export const addCart = (bodyObj) => (dispatch) => {
  dispatch({ type: ADD_CART_Temp, payload: bodyObj });
};

export const getCartItemCount = (bodyObj) => (dispatch) => {
  return stockAPI(
    bodyObj,
    "POST",
    "/getCartItemCount",
    null,
    null,
    undefined,
    true
  )
    .then(async (cartItemCount) => {
      if (cartItemCount.statusCode === 100) {
        if (cartItemCount.response) {
          dispatch({ type: CART_ITEM_COUNT, payload: cartItemCount.response });
        } else {
          if (cartItemCount.statusDescription) {
            dispatch({
              type: GET_ERRORS_CART_ITEM_COUNT,
              payload: cartItemCount.statusDescription,
            });
          }
        }
      } else {
        if (cartItemCount.statusDescription) {
          dispatch({
            type: GET_ERRORS_CART_ITEM_COUNT,
            payload: cartItemCount.statusDescription,
          });
        }
        // openAlertBox("Oops something went wrong!", "error");
      }
    })
    .catch(function (err) {
      openAlertBox(err && err.message, "error");
      dispatch({ type: GET_ERRORS_CART_ITEM_COUNT, payload: err });
    });
};

export const TrackProductView = (bodyObj) => (dispatch) => {
  return stockAPI(bodyObj, "POST", "/trackProductView").catch(function (err) {
    openAlertBox(err && err.message, "error");
  });
};

export const clearProductList = () => (dispatch) => {
  dispatch({ type: CLEAR_PRODUCT_LIST });
};

export const clearProductDetails = () => (dispatch) => {
  dispatch({ type: CLEAR_PRODUCT_DETAILS });
};

export const setSortBy = (sort_by) => (dispatch) => {
  dispatch({ type: SET_SORT_BY, payload: sort_by });
};

export const setProductView = () => (dispatch) => {
  dispatch({ type: SET_PRODUCT_VIEW });
};

export const clearSortBy = () => (dispatch) => {
  dispatch({ type: CLEAR_SORT_BY, payload: "" });
};

export const clearProductView = () => (dispatch) => {
  dispatch({ type: CLEAR_PRODUCT_VIEW });
};

export const onVoucherChange = (selected, data) => (dispatch) => {
  const updated_data = data.filter((x) => {
    if (x.id === selected) return x;
  });
  dispatch({ type: ON_VOUCHER_CHANGE, payload: updated_data });
};

export const clearSelectedProductDetails = () => (dispatch) => {
  dispatch({ type: CLEAR_SELECTED_PRODUCT_DETAILS });
};

export const toggleFilterDrawer = (flag) => (dispatch) => {
  dispatch({ type: TOGGLE_FILTER_DRAWER, payload: flag });
};

export const setFromOrderFlag = (flag) => ({
  type: SET_FROM_ORDER,
  payload: flag,
});
