import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { headerScreenLabels } from "../../../common/common-types";
import MyRoute from "../../../hoc/MyRoute";
import Header from "../../../components/Layout/Header/HeaderSubmitBill";
import { changeActiveTab, logoutUser } from "../../../../src/App/redux/action";
import MoreComponent from "./components/MoreComponent";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import { Grid } from "@material-ui/core";
import Footer from "../../../components/Layout/Footer/Footer";

const mapDispatchToProps = {
  changeActiveTab,
  logoutUser,
};
const mapStateToProps = (state) => {
	return {
		uploadingStatus: state.reducerBillUploadProgress.uploadingStatus
	};
};

class MoreContainer extends Component {
  componentDidMount() {
    const { changeActiveTab } = this.props;
    changeActiveTab(4);
  }

  render() {
    const { logoutUser } = this.props;
    const moreComponent = {
      logoutUser,
    };
    return (
      <Fragment>
        <Header screenLabel={headerScreenLabels.more} />
        <AlertMessage />
        <MyRoute billUploadStatus={this.props.uploadingStatus}>
          <MoreComponent {...moreComponent}/>
        </MyRoute>
        <Grid item>
					<Footer />
				</Grid>
      </Fragment>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MoreContainer);
