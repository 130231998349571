import * as actionTypes from "./actionTypes";
import { stockAPI } from "../../../../common/axiosInstance";

export const getCustomerNotification = (params) => (dispatch) => {
  return stockAPI(params, "POST", "/getCustomerNotifications").then(
    async (response) => {
      if (response && response.notifications) {
        await dispatch({
          type: actionTypes.GET_CUSTOMER_NOTIFICATION,
          payLoad: {
            notifications: response.notifications,
            pageNum: response.page_num,
          },
        });
      }
    }
  );
};

export const getMoreCustomerNotification = (params) => (dispatch) => {
  return stockAPI(params, "POST", "/getCustomerNotifications").then(
    async (response) => {
      if (response && response.notifications) {
        await dispatch({
          type: actionTypes.GET_MORE_CUSTOMER_NOTIFICATION,
          payLoad: {
            notifications: response.notifications,
            pageNum: response.page_num,
          },
        });
      }
    }
  );
};

export const deleteNotification = (id) => (dispatch) => {
  const params = new URLSearchParams();
  params.append("id", id);
  return stockAPI(params, "POST", "/deleteNotification").then(
    async (response) => {
      if (response) {
        await dispatch({ type: actionTypes.DELETE_NOTIFICATION, id: id });
      }
    }
  );
};

export const setNotificationTapped = (params) => (dispatch) => {
  return stockAPI(params, "POST", "/setNotificationTapped").then((response) => {
    // if (response && response[0] && params.get("inAppTap") === 0) {
    dispatch({
      type: actionTypes.SET_NOTIFICATION_TAPPED,
      notification: response[0],
    });
    // }
  });
};
