import React, { useState, Fragment } from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import circle_tick from "../../assets/icons/submit_bill/green_tick.svg";
import classnames from "classnames";
import toggle_closed_btn_icon from "../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import toggle_open_btn_icon from "../../assets/icons/submit_bill/toggle_open_btn_icon.svg";
import Details from "./Details";
import { Slide, Dialog } from "@material-ui/core/";
import BillDetailDialog from "../BillDetailsDialog/BillDetailsDialog";
import BillImage from "../BillImage/BillImage";
import { primaryOrange, green, errorRed } from "../../components/UI/Theme";
import pdfIcon from "../../assets/icons/submit_bill/pdf.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme) => {
  return {
    bill_process_block: {
      margin: "0px auto 20px auto",
      padding: "2px 0px 0px 0px",
      display: "block",
      width: "100%",
      boxShadow: "0px 0px 10px #dad4d4",
      backgroundColor: theme.palette.cards.backgroundColor,
      borderRadius: "5px",
    },
    process: {
      display: "block",
      width: "100%",
      padding: "0px 1px",
      height: "6px",
      borderRadius: "5px",
      [theme.breakpoints.down(481)]: {
        height: "4px",
      },
      [theme.breakpoints.down(361)]: {
        height: "3px",
      },
    },
    process_cnt: {
      margin: "0px 0px 0px 0px",
      padding: "5px",
      display: "block",
      width: "100%",
    },
    img_container: {
      margin: "0px 10px 0px 0px",
      padding: "0px",
      backgroundColor: theme.palette.cards.backgroundColor,
      display: "inline-block",
      width: "132px",
      height: "132px",
      overflow: "hidden",
      position: "relative",
      borderRadius: "5px",
      [theme.breakpoints.down(641)]: {
        width: "112px",
        height: "112px",
      },
      [theme.breakpoints.down(481)]: {
        width: "100px",
        height: "100px",
      },
      [theme.breakpoints.down(381)]: {
        width: "83px",
        height: "83px",
      },
      [theme.breakpoints.down(361)]: {
        width: "83px",
        height: "83px",
      },
      "& $overlay": {
        margin: 0,
        padding: 0,
        backgroundColor: "rgb(0,0,0,0.4)",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        zIndex: 999,
        color: "#fff",
        fontSize: "2rem",
        textAlign: "center",
        borderRadius: "5px",
        lineHeight: "135px",
        [theme.breakpoints.down("sm")]: {
          lineHeight: "118px",
          fontSize: "1.5rem",
        },
        [theme.breakpoints.down("xs")]: {
          lineHeight: "84px",
          fontSize: "1rem",
        },
        [theme.breakpoints.down("361")]: {
          lineHeight: "74px",
          fontSize: "1rem",
        },
      },
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      objectPosition: "center",
    },
    cnt: {
      margin: "0px 0px 0px 0px",
      padding: "0",
      display: "inline-block",
      verticalAlign: "top",
      position: "relative",
      width: "calc(100% - 162px)",
      [theme.breakpoints.down(641)]: {
        width: "calc(100% - 132px)",
      },
      [theme.breakpoints.down(481)]: {
        width: "calc(100% - 110px)",
      },
      [theme.breakpoints.down(381)]: {
        width: "calc(100% - 93px)",
      },
      [theme.breakpoints.down(361)]: {
        width: "calc(100% - 93px)",
      },
      [theme.breakpoints.down(321)]: {
        width: "calc(100% - 93px)",
      },
    },
    tick: {
      position: "absolute",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      height: "28px",
      width: "28px",
      top: "6px",
      right: "6px",
      [theme.breakpoints.down(481)]: {
        height: "16px",
        width: "16px",
        top: "5px",
      },
      [theme.breakpoints.down(361)]: {
        height: "14px",
        width: "14px",
        top: "4px",
      },
      [theme.breakpoints.down(321)]: {
        top: "3px",
      },
    },
    left_align: {
      textAlign: "left",
    },
    bill_status: {
      margin: "0px 0px 16px 0px",
      display: "block",
      width: "90%",
      fontWeight: "500",
      fontSize: "20px",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        margin: "0px 0px 8px 0px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        margin: "0px 0px 8px 0px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        margin: "0px 0px 2px 0px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
        margin: "0px 0px 8px 0px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        margin: "0px 0px 8px 0px",
      },
    },
    common_fonts: {
      fontSize: "18px",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
      },
    },
    toggler_icon: {
      position: "absolute",
      height: "24px",
      width: "24px",
      bottom: "0px",
      right: "calc(12px - 8px)",
      [theme.breakpoints.down("sm")]: {
        height: "20px",
        width: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "16px",
        width: "16px",
      },
    },
    overlay: {},
    dialogModal: {
      "& .MuiDialog-paperWidthMd": {
        width: "100%",
      },
      "& .MuiDialog-paper": {
        margin: "15px",
      },
    },
    orangeTickCss: {
      backgroundColor: theme.palette.tick.pending,
      " -webkit-mask-image": `url(.${circle_tick})`,
      "mask-image": `url(.${circle_tick})`,
      mask: `url(.${circle_tick}) no-repeat center / contain`,
      "-webkit-mask": `url(.${circle_tick}) no-repeat center / contain`,
    },
    greenTickCss: {
      backgroundColor: theme.palette.tick.green,
      " -webkit-mask-image": `url(.${circle_tick})`,
      "mask-image": `url(.${circle_tick})`,
      mask: `url(.${circle_tick}) no-repeat center / contain`,
      "-webkit-mask": `url(.${circle_tick}) no-repeat center / contain`,
    },
    toggleClosedCss: {
      backgroundColor: theme.palette.cards.deleteIcon,
      " -webkit-mask-image": `url(.${toggle_closed_btn_icon})`,
      "mask-image": `url(.${toggle_closed_btn_icon})`,
      mask: `url(.${toggle_closed_btn_icon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${toggle_closed_btn_icon}) no-repeat center / contain`,
    },
    toggleOpenCss: {
      backgroundColor: theme.palette.cards.deleteIcon,
      " -webkit-mask-image": `url(.${toggle_open_btn_icon})`,
      "mask-image": `url(.${toggle_open_btn_icon})`,
      mask: `url(.${toggle_open_btn_icon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${toggle_open_btn_icon}) no-repeat center / contain`,
    },
    pdfIconCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${pdfIcon})`,
      "mask-image": `url(.${pdfIcon})`,
      mask: `url(.${pdfIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${pdfIcon}) no-repeat center / contain`,
    },
    pdfCss: {
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      marginTop: "15px",
      height: "40%",
      width: "40%",
    },
    downArrowCss: {
      width: "10px",
      height: "6px",
    },
  };
};
function getExtension(filename) {
  var parts = filename.split(".");
  return parts[parts.length - 1];
}

function isPDF(filename) {
  var ext = getExtension(filename);
  if (ext.toLowerCase() === "pdf") {
    return true;
  }
  return false;
}
const BillDetails = (props) => {
  const [details, setDetails] = useState(props.openedStatus);
  const { classes } = props;
  const {
    status,
    billDate,
    billAmount,
    billNumber,
    brand,
    location,
    billImg,
    points,
    isMulti,
    billName,
    fromTransactions
  } = props;

  const [billDetailDialog, setBillDetailDialog] = useState(false);
  const [billImagesDialog, setBillImagesDialog] = useState(false);

  const handleBillClicked = () => {
    if (isMulti || fromTransactions) {
      setBillDetailDialog(true);
    }
  };

  const handleBillClickedClosed = () => {
    setBillDetailDialog(false);
  };

  const handleBillPDFClicked = (file) => {
    window.open(file);
  };

  const handleBillImageClicked = () => {
    // if (isMulti) {
    setBillImagesDialog(true);
    // }
  };

  const handleBillImageClickedClose = () => {
    setBillImagesDialog(false);
  };

  return (
    <Grid>
      <div className={classes.bill_process_block}>
        <div
          className={classes.process}
          style={{
            backgroundColor:
              status === "Processing"
                ? primaryOrange
                : status === "Processed"
                ? green
                : (status === "Rejected" || status === "Cancelled") && errorRed,
          }}
        />
        <div className={classes.process_cnt}>
          {isPDF(billName[0]) ? (
            <div
              className={classes.img_container}
              style={{ cursor: (isMulti || fromTransactions) && "pointer" }}
              onClick={() => handleBillPDFClicked(billImg[0])}
            >
              <span className={classes.pdfIconCss + " " + classes.pdfCss} />
            </div>
          ) : (
            <div
              className={classes.img_container}
              style={{ cursor: (isMulti || fromTransactions) && "pointer" }}
              onClick={handleBillImageClicked}
            >
              {billImg.length > 1 ? (
                <Fragment>
                  <img src={billImg[0]} alt="bill" className={classes.img} />
                  <div className={classes.overlay}>
                    {`+ ${billImg.length - 1}`}
                    {/* <Typography>{`+ ${billImg.length - 1}`}</Typography> */}
                  </div>
                </Fragment>
              ) : (
                <img src={billImg[0]} alt="bill" className={classes.img} />
              )}
            </div>
          )}
          <div
            className={classes.cnt}
            style={{ cursor: (isMulti || fromTransactions) && "pointer" }}
            onClick={handleBillClicked}
          >
            <Typography
              className={classes.left_align + " " + classes.bill_status}
              style={{
                color:
                  status === "Processing"
                    ? primaryOrange
                    : status === "Processed"
                    ? green
                    : (status === "Rejected" || status === "Cancelled") &&
                      errorRed,
              }}
            >
              {status === "Processing"
                ? "Bill submitted for manual processing."
                : status === "Processed"
                ? "Bill processed."
                : (status === "Rejected" || status === "Cancelled") &&
                  `Bill ${status}.`}
            </Typography>
            <span
              className={classnames({
                [classes.tick]: true,
                [classes.orangeTickCss]: status === "Processing",
                [classes.greenTickCss]: status === "Processed",
              })}
            />
            <Typography
              className={classes.left_align + " " + classes.common_fonts}
            >
              {/* Location: <b>{merchant_name ? merchant_name : null }{location ? `, ${location}` : ''} </b> */}
              Location: <b>{location ? location : "-"} </b>
            </Typography>
            <Typography
              className={classes.left_align + " " + classes.common_fonts}
            >
              Brand: <b>{brand ? brand : "-"}</b>
            </Typography>
            <Typography
              className={classes.left_align + " " + classes.common_fonts}
            >
              Points Earned: <b>{points ?
               parseFloat(points) % 1 !== 0
              ? parseFloat(points).toFixed(2)
              : Math.floor(points)
              : "-"}</b>
            </Typography>
            {isMulti ? null : details ? (
              <IconButton
                className={classes.toggler_icon}
                onClick={(e) => {
                  e.stopPropagation();
                  setDetails(false);
                }}
              >
                <img
                  src={details ? toggle_open_btn_icon : toggle_closed_btn_icon}
                  alt={details ? "Open" : "Close"}
                  className={classes.downArrowCss}
                />
              </IconButton>
            ) : (
              <IconButton
                className={classes.toggler_icon}
                onClick={(e) => {
                  e.stopPropagation();
                  setDetails(true);
                }}
              >
                <img
                  src={details ? toggle_open_btn_icon : toggle_closed_btn_icon}
                  alt={details ? "Open" : "Close"}
                  className={classes.downArrowCss}
                />
              </IconButton>
            )}
          </div>
        </div>
        {isMulti ? (
          <Details
            billNumber={billNumber}
            billDate={billDate}
            billAmount={billAmount}
          />
        ) : (
          details && (
            <Details
              billNumber={billNumber}
              billDate={billDate}
              billAmount={billAmount}
            />
          )
        )}
      </div>
      <Dialog
        open={billDetailDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleBillClickedClosed}
        maxWidth="md"
        className={classes.dialogModal}
      >
        <BillDetailDialog
          okBtnHandler={handleBillClickedClosed}
          fileName={billName[0]}
          brand={brand}
          pointsEarned={points}
          status={status}
          billNumber={billNumber}
          billDate={billDate}
          billAmount={billAmount}
          billImages={billImg}
          location={location}
        />
      </Dialog>
      <Dialog
        open={billImagesDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleBillImageClickedClose}
        maxWidth="md"
        className={classes.dialogModal}
      >
        <BillImage
          open={billImagesDialog}
          multi={isMulti}
          imgSrc={billImg}
          okBtnHandler={handleBillImageClickedClose}
        />
      </Dialog>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(BillDetails);
