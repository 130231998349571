import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles, Grid } from "@material-ui/core";
const styles = (theme) => {
  return {
    pageContainer: {
      padding: "30px",
      backgroundColor: theme.palette.common.globalBackground,
      height: "100%",
      width: "100%",
      display: "block",
      textAlign: "left",
      overflow: "hidden",
      // overflowY: "auto",
      [theme.breakpoints.down("641")]: {
        padding: "26px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "20px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
    },
    root: {
      "& .cls_content": {
        fontWeight: "normal",
        lineHeight: "1.67 !important",
        fontStretch: "normal",
        letterSpacing: "normal",
        fontSize: "18px !important",
        [theme.breakpoints.down("641")]: {
          fontSize: "16px !important",
          lineHeight: "1.67 !important",
        },
        [theme.breakpoints.down("481")]: {
          fontSize: "14px !important",
          lineHeight: "1.67 !important",
        },
        [theme.breakpoints.down("421")]: {
          fontSize: "13.5px !important",
          lineHeight: "1.67 !important",
        },
        [theme.breakpoints.down("401")]: {
          fontSize: "13px !important",
          lineHeight: "1.67 !important",
        },
        [theme.breakpoints.down("381")]: {
          fontSize: "12.5px !important",
          lineHeight: "1.67 !important",
        },
        [theme.breakpoints.down("361")]: {
          fontSize: "12px !important",
          lineHeight: "1.67 !important",
        },
        [theme.breakpoints.down("321")]: {
          fontSize: "10px !important",
          lineHeight: "1.67 !important",
        },
      },
      "& .cls_title": {
        fontSize: "20px !important",
        [theme.breakpoints.down("641")]: {
          fontSize: "18px !important",
        },
        [theme.breakpoints.down("481")]: {
          fontSize: "16px !important",
        },
        [theme.breakpoints.down("421")]: {
          fontSize: "15.5px !important",
        },
        [theme.breakpoints.down("401")]: {
          fontSize: "15px !important",
        },
        [theme.breakpoints.down("381")]: {
          fontSize: "14.5px !important",
        },
        [theme.breakpoints.down("361")]: {
          fontSize: "14px !important",
        },
        [theme.breakpoints.down("321")]: {
          fontSize: "12px !important",
        },
      },
      "& p": {
        fontWeight: "normal",
        lineHeight: "1.67 !important",
        fontStretch: "normal",
        letterSpacing: "normal",
        fontSize: "16px !important",
        [theme.breakpoints.down("641")]: {
          fontSize: "14px !important",
          lineHeight: "1.67 !important",
        },
        [theme.breakpoints.down("481")]: {
          fontSize: "12px !important",
          lineHeight: "1.67 !important",
        },
        [theme.breakpoints.down("421")]: {
          fontSize: "11.5px !important",
          lineHeight: "1.67 !important",
        },
        [theme.breakpoints.down("401")]: {
          fontSize: "11px !important",
          lineHeight: "1.67 !important",
        },
        [theme.breakpoints.down("381")]: {
          fontSize: "10.5px !important",
          lineHeight: "1.67 !important",
        },
        [theme.breakpoints.down("361")]: {
          fontSize: "10px !important",
          lineHeight: "1.67 !important",
        },
        [theme.breakpoints.down("321")]: {
          fontSize: "8px !important",
          lineHeight: "1.67 !important",
        },
      },
      "& .cls_circle": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      "& .cls_line": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
     
      "& ol": {
        margin:'0 !important',
        padding: "0px 0px 0px 30px !important",
        [theme.breakpoints.down("641")]: {
          padding: "0px 0px 0px 26px !important",
        },
        [theme.breakpoints.down("481")]: {
          padding: "0px 0px 0px 20px !important",
        },
        [theme.breakpoints.down("381")]: {
          padding: "0px 0px 0px 15px !important",
        },
        [theme.breakpoints.down("321")]: {
          padding: "0px 0px 0px 13px !important",
        },
      },
    },
    noData: {
      textAlign: "center",
    },
  };
};
const ShowInfo = (props) => {
  const { classes, data } = props;
  return (
    <div className={classes.pageContainer}>
      <Grid className={classes.root}>
        <Grid style={{ width: "100%" }}>
          <div
            className={classes.content}
            dangerouslySetInnerHTML={{
              __html: data
                ? data
                : " <div style='text-align:center;'>No data available</div>",
            }}
          ></div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(withStyles(styles, { withTheme: true })(ShowInfo));
