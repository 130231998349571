import React, { Component, Fragment } from "react";
import Header from "../../../components/Layout/Header/HeaderTransactions";
import MyRoute from "../../../hoc/MyRoute";
import TransactionsLog from "./components/TransactionsLog/TransactionsLog";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import { resetFilter } from "../../../components/Filter/redux/action";
import { resetSearchText } from "../../../components/Search/redux/action";
import {
  changeActiveTab,
  showLoader,
  logoutUser,
} from "../../../App/redux/action";
import {
  clearSortBy,
  clearProductView,
  setFromOrderFlag,
} from "../Redeem/redux/action";
import {
  changeTransactionTab,
  changeBillStageSelection,
  changeLogData,
  changePointsSelection,
} from "./redux/action";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import Footer from "../../../components/Layout/Footer/Footer";

const mapStateToProps = (state) => {
  return {
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
    activeTransactionTab: state.reducerTransactions.activeTab,
    logData: state.reducerTransactions.logData,
    pointsSelection: state.reducerTransactions.pointsSelection,
    billStageSelection: state.reducerTransactions.billStageSelection,
  };
};

const mapDispatchToProps = {
  changeActiveTab,
  showLoader,
  logoutUser,
  clearSortBy,
  clearProductView,
  resetSearchText,
  resetFilter,
  setFromOrderFlag,
  changeTransactionTab,
  changeBillStageSelection,
  changeLogData,
  changePointsSelection,
};

class TransactionContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.changeActiveTab(3);
    this.props.clearSortBy();
    this.props.clearProductView();
    this.props.resetFilter();
    // this.props.setFromOrderFlag(true);
    // this.props.resetSearchText();
  }

  render() {
    return (
      <Fragment>
        <Header />
        <AlertMessage />
        <MyRoute billUploadStatus={this.props.uploadingStatus}>
          <TransactionsLog
            openLoader={this.props.showLoader}
            logout={this.props.logoutUser}
            changeTransactionTab={this.props.changeTransactionTab}
            activeTransactionTab={this.props.activeTransactionTab}
            changeBillStageSelection={this.props.changeBillStageSelection}
            billStageSelection={this.props.billStageSelection}
            changeLogData={this.props.changeLogData}
            logData={this.props.logData}
            changePointsSelection={this.props.changePointsSelection}
            pointsSelection={this.props.pointsSelection}
          />
        </MyRoute>
        <Grid item>
          <Footer />
        </Grid>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionContainer);
