import actionType from "./actionTypes";

const initialState = {
  merchantDetailsResponse: null,
  invalidmerchantDetailsResponse: false,
  banners: [],
  notificationUnReadCount: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.FETCH_MERCHANT_DATA:
      return {
        ...state,
        merchantDetailsResponse: action.payload,
      };
    case actionType.FETCH_BANNER_DATA:
      return {
        ...state,
        banners: action.payload,
      };
    case actionType.GET_ERRORS:
      return {
        ...state,
        invalidmerchantDetailsResponse: true,
      };

    case actionType.RESET_DASHBOARD:
      return {
        ...state,
        merchantDetailsResponse: null,
        invalidmerchantDetailsResponse: false,
        banners: [],
      };

    case actionType.UPDATE_NOTIFICTION_COUNT:
      return {
        ...state,
        notificationUnReadCount: action.count
      }

    default:
      return state;
  }
}
