import closeIcon from "../../assets/icons/submit_bill/close_btn.svg";
import { primaryOrange, maroonRed } from '../UI/Theme';

const green = "#57ab21";

export const styles = (theme) => {
  return {
    progress_row: {
      margin: "0px",
      padding: "0px",
      display: "block",
      width: "100%",
      boxShadow: "0px 2px 3px 0 rgba(0, 0, 0, 0.3)",
      position: "relative",
      height: "45px",
      [theme.breakpoints.down("640")]: {
        height: "42px",
      },
      [theme.breakpoints.down("481")]: {
        height: "38px",
      },
      [theme.breakpoints.down("381")]: {
        height: "35px",
      },
      [theme.breakpoints.down("321")]: {
        height: "31px",
      },
    },
    progress_bar_bg: {
      margin: "0px",
      padding: "0px",
      display: "block",
      position: "relative",
      zIndex: 10,
      height: "45px",
      [theme.breakpoints.down("640")]: {
        height: "42px",
      },
      [theme.breakpoints.down("481")]: {
        height: "38px",
      },
      [theme.breakpoints.down("381")]: {
        height: "35px",
      },
      [theme.breakpoints.down("321")]: {
        height: "31px",
      },
    },
    progress_cnt_row: {
      margin: "0px",
      display: "flex",
      position: "absolute",
      top: 0,
      zIndex: 20,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0px 30px",
      [theme.breakpoints.down("610")]: {
        padding: "0px 28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "0px 21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "0px 15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "5px 13px",
      },
    },
    mainText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
    //   fontWeight: "500",
      textOverflow: "ellipsis",
      fontSize: "18px",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    inprogress: {
      color: primaryOrange,
    },
    success: {
      color: green,
    },
    btn: {
      borderWidth: "1px",
      borderStyle: "solid",
      padding: "2px 6px",
      marginRight: "12px",
      [theme.breakpoints.down("xs")]: {
        marginRight: "10px",
      },
    },
    iconBtnClose: {
      padding: "10px",
      [theme.breakpoints.down("xs")]: {
        padding: "5px 0",
      },
    },
    icon_container: {
      display: "inline-block",
      position: "relative",
      "& $closeIcon": {
        width: "24px",
        height: "24px",
        "--size ": "70px",
        display: "inline-block",
        transition: ".12s",
        "-webkit-mask-size": "cover",
        "mask-size ": "cover",
        [theme.breakpoints.down(641)]: {
          width: "20px",
          height: "20px",
        },
        [theme.breakpoints.down(481)]: {
          width: "14px",
          height: "14px",
        },
        [theme.breakpoints.down(381)]: {
          width: "12px",
          height: "12px",
        },
        [theme.breakpoints.down(321)]: {
          width: "10px",
          height: "10px",
        },
      },
    },
    closeIconCss: {
      backgroundColor: primaryOrange,
      " -webkit-mask-image": `url(.${closeIcon})`,
      "mask-image": `url(.${closeIcon})`,
      mask: `url(.${closeIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${closeIcon}) no-repeat center / contain`,
    },
    closeIconCssSuccess: {
      backgroundColor: green,
      " -webkit-mask-image": `url(.${closeIcon})`,
      "mask-image": `url(.${closeIcon})`,
	  mask: `url(.${closeIcon}) no-repeat center / contain`,
	  "-webkit-mask": `url(.${closeIcon}) no-repeat center / contain`,
	},
	closeIconCssFail: {
		backgroundColor: maroonRed,
		' -webkit-mask-image': `url(.${closeIcon})`,
		'mask-image': `url(.${closeIcon})`,
		mask: `url(.${closeIcon}) no-repeat center / contain`,
		'-webkit-mask': `url(.${closeIcon}) no-repeat center / contain`
	},
    closeIcon: {},
    item: {
      width: "50%",
    },
    item1: {
      paddingRight: "5px",
    },
    items2: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  };
};
