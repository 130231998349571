export const RESET_FILTER = "RESET_FILTER";
export const UPDATE_PRICE_RANGE = "UPDATE_PRICE_RANGE";
export const UPDATE_POINTS_RANGE = "UPDATE_POINTS_RANGE";
export const UPDATE_BRANDS_FILTER = "UPDATE_BRANDS_FILTER";
export const UPDATE_LOCATION_FILTER = "UPDATE_LOCATION_FILTER";
export const UPDATE_FILTER_APPLIED = "UPDATE_FILTER_APPLIED";

export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATIONS_FAIL = "FETCH_LOCATIONS_FAIL";

export const FETCH_BRANDS_SUCCESS = "FETCH_BRANDS_SUCCESS";
export const FETCH_BRANDS_FAIL = "FETCH_BRANDS_FAIL";

export const APPLY_RELATED_PRODUCTS = "APPLY_RELATED_PRODUCTS";
export const REMOVE_RELATED_PRODUCTS = "REMOVE_RELATED_PRODUCTS";

export const REMOVE_BRAND_LIST = "REMOVE_BRAND_LIST";

export const RESET_FILTERS = "RESET_FILTERS";
