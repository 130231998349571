import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, Button, IconButton, Typography } from '@material-ui/core';
import { styles } from './UploadProgressBarStyles';
import { connect } from 'react-redux';
import { openAlertBox } from '../../common/AlertMessage/AlertMessage';
import { hideProgress } from '../../containers/Screens/BillUploadProgress/redux/action';
import { lightPrimary, lightGreen } from '../UI/Theme';

const mapDispatchToProps = {
	hideProgress
};

const mapStateToProps = (state) => {
	return {
		uploadPercentage: state.reducerBillUploadProgress.uploadPercentage,
		imagesToUpload: state.reducerBillUploadProgress.imagesToUpload,
	};
};

class UploadProgressBar extends Component {
	handleViewProgress = () => {
		this.props.history.push({
			pathname: '/bill-upload'
		});
	};

	handleViewSummary = () => {
		// pushing bill summary page
		// with submitted bill id's
		const { imagesToUpload } = this.props;

		let billIds = [];
		billIds = imagesToUpload.map((img) => {
			if (img.last_bill_id) {
				return img.last_bill_id;
			} else {
				return 0;
			}
		});
		const newBillIds = billIds.filter((id) => id > 0);
		let idStrings = newBillIds.toString();
		if (idStrings !== '') {
			this.props.hideProgress();
			this.props.history.push({
				pathname: '/bill-summary',
				state: {
					billIds: idStrings,
					isMultiClickBill: false
				}
			});
		} else {
			openAlertBox('Please upload at least one bill image', 'error');
		}
	};

	handleClose = () => {
		this.props.hideProgress();
	};

	getMessage = () => {
		const { imagesToUpload, classes } = this.props;
		let failedCounter = 0;
		let status = null;
		
		for (let i = 0; i < imagesToUpload.length; i++) {
			const file = imagesToUpload[i];
			if (file.status === 'errorCode') {
				failedCounter++;
			}
		}

		if (failedCounter === 0) {
			status = <Typography className={classes.mainText + ' ' + classes.success}>
				All bills submitted successfully.
			</Typography>
		} else if (failedCounter === imagesToUpload.length) {
			status = <Typography className={classes.mainText + ' ' + classes.failed}>
				None of the bills could be processed. Please re-upload.
			</Typography>
		} else {
			status = <Typography className={classes.mainText + ' ' + classes.success}>
			{`${imagesToUpload.length - failedCounter} out of ${imagesToUpload.length} bills submitted successfully`}
		</Typography>
		}

		return status;
	}

	getColor = () => {
		const { imagesToUpload } = this.props;
		let passCounter = 0;
		let returningColor = null;
		for (let i = 0; i < imagesToUpload.length; i++) {
			const file = imagesToUpload[i];
			if (file.status === 'complete' || file.status === 'manual') {
				passCounter++;
				break;
			}
		}
		if (passCounter === 0) {
			returningColor = '#f8d7d7';
		} else {
			returningColor = lightGreen;
		}
		return returningColor;
	}

	getBillStatus = () => {
		const { imagesToUpload } = this.props;
		let passCounter = 0;
		let status = false;
		for (let i = 0; i < imagesToUpload.length; i++) {
			const file = imagesToUpload[i];
			if (file.status === 'complete' || file.status === 'manual') {
				passCounter++;
				break;
			}
		}
		if (passCounter === 0) {
			status = false;
		} else {
			status = true;
		}
		return status;
	}

	render() {
		const { classes, uploadPercentage } = this.props;
		return (
			<div className={classes.progress_row}>
				<div
					className={classes.progress_bar_bg}
					style={{
						backgroundColor: `${uploadPercentage === 100 ? this.getColor() : lightPrimary}`,
						width: `${uploadPercentage}%`
					}}
				/>
				<div className={classes.progress_cnt_row}>
					<div className={classes.item1}>
						{uploadPercentage === 100 ? (
							this.getMessage()
						) : (
							<Typography className={classes.mainText + ' ' + classes.inprogress}>
								Bill(s) upload in progress...
							</Typography>
						)}
					</div>
					<div className={classes.items2}>
						{ 
							uploadPercentage === 100 && this.getBillStatus() ?
							<Button
								className={classes.btn + ' ' + classes.mainText + ' ' + classes.success}
								onClick={this.handleViewSummary}
							>
								VIEW SUMMARY
							</Button> : <div />
						}
						
						{uploadPercentage !== 100 ? this.props.location.pathname === '/bill-upload' ? (
							<div />
						) : (
							<Button
								className={classes.btn + ' ' + classes.mainText + ' ' + classes.inprogress}
								onClick={this.handleViewProgress}
							>
								View Progress
							</Button>
						) : null}
						<div className={classes.icon_container}>
							<IconButton className={classes.iconBtnClose} onClick={this.handleClose}>
								<span
									className={`${classes.closeIcon} ${uploadPercentage === 100
										? this.getBillStatus() ? classes.closeIconCssSuccess : classes.closeIconCssFail
										: classes.closeIconCss}`}
								/>
							</IconButton>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(
	withRouter(withStyles(styles, { withTheme: true })(UploadProgressBar))
);
