import React from "react";
import { styles } from "./BillUploadGuidelinesStyles";
import { withStyles } from "@material-ui/styles";
import GuidelineCard from "../../../../components/GuidelineCard/GuidelineCard";
import { Button, Typography } from "@material-ui/core";
import disable_checkbox from "../../../../assets/icons/book_visit/disable-checkbox.svg";
import selected from "../../../../assets/icons/book_visit/selected.svg";
import { getStorageItem } from "../../../../common/localStorage";

const BillUploadGuidelines = (props) => {
  const {
    classes,
    handleRadioButton,
    guidelines,
    guidelinesFlag,
    handleDoneBtnClick,
  } = props;
  const isGuideline =
    getStorageItem("guidelinesFlag") && getStorageItem("guidelinesFlag") == 1;
  return (
    <div className={classes.root}>
      {guidelines.map((guideline, i) => {
        return (
          <GuidelineCard
            key={i}
            text={guideline.text}
            correct_image={guideline.correct_image}
            wrong_image={guideline.wrong_image}
            num={i}
          />
        );
      })}
      {!isGuideline && (
        <div className={classes.status}>
          <img
            alt="mall"
            className={classes.checkbox}
            onClick={handleRadioButton}
            src={guidelinesFlag ? selected : disable_checkbox}
          />
          <Typography className={classes.dont} onClick={handleRadioButton}>
            Don't show this message again.
          </Typography>
        </div>
      )}
      <div>
        <Button
          className={classes.doneBtn}
          disableRipple
          color="primary"
          variant="contained"
          onClick={handleDoneBtnClick}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(BillUploadGuidelines);
