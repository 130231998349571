import * as actionTypes from "./actionTypes";
import { stockAPI } from "../../../common/axiosInstance";
import { openAlertBox } from "../../../common/AlertMessage/AlertMessage";

const resetFilter = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_FILTER });
  };
};

const rewardValueRangeChangeHandler = (val) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_PRICE_RANGE, value: val });
  };
};

const pointsRangeChangeHandler = (val) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_POINTS_RANGE, value: val });
  };
};

const updateBrandsFilter = (str) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_BRANDS_FILTER, value: str });
  };
};

const updateLocationFilter = (str) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_LOCATION_FILTER, value: str });
    if (str.length === 0) {
      dispatch(removeBrandList());
    }
  };
};

const updateAppliedFilter = (val) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_FILTER_APPLIED, value: val });
  };
};

const getLocations = (data) => {
  return (dispatch) =>
    stockAPI(data, "POST", "/getStoreLocations")
      .then(async (response) => {
        if (response && response.locations) {
          await dispatch(getLocationsSuccess(response.locations));
          if (
            response &&
            response.locations &&
            response.locations.length === 1 &&
            response.locations[0].id !== ""
          ) {
            await dispatch({
              type: actionTypes.UPDATE_LOCATION_FILTER,
              value: [response.locations[0].id],
            });
          }
        } else {
          await dispatch(getLocationsFail());
        }
      })
      .catch((err) => {
        openAlertBox(err && err.message, "error");
        dispatch(getLocationsFail());
      });
};

const getLocationsSuccess = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_LOCATIONS_SUCCESS, payLoad: payLoad });
  };
};

const getLocationsFail = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_LOCATIONS_FAIL });
  };
};

const getBrands = (data) => {
  return (dispatch) =>
    stockAPI(data, "POST", "/getPWABrandName")
      .then((response) => {
        if (response) {
          dispatch(getBrandsSuccess(response));
          if (response.length === 1) {
            dispatch({
              type: actionTypes.UPDATE_BRANDS_FILTER,
              value: [response[0].id],
            });
          }
        } else {
          dispatch(getBrandsFail());
        }
      })
      .catch((err) => {
        openAlertBox(err && err.message, "error");
        dispatch(getBrandsFail());
      });
};

const getBrandsSuccess = (payLoad) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_BRANDS_SUCCESS, payLoad: payLoad });
  };
};

const getBrandsFail = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_BRANDS_FAIL });
  };
};

const applyRelatedProducts = (productId, productMerchantId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.APPLY_RELATED_PRODUCTS,
      productId: productId,
      productMerchantId: productMerchantId,
    });
  };
};

const removeRelatedProducts = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.REMOVE_RELATED_PRODUCTS });
  };
};

const removeBrandList = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.REMOVE_BRAND_LIST });
  };
};

export {
  resetFilter,
  rewardValueRangeChangeHandler,
  pointsRangeChangeHandler,
  updateBrandsFilter,
  updateLocationFilter,
  updateAppliedFilter,
  getLocations,
  getBrands,
  applyRelatedProducts,
  removeRelatedProducts,
  removeBrandList,
};
