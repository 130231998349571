import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';
import plus from '../../assets/icons/submit_bill/plus1.svg';

const PlusButton = React.memo((props) => {
	const classes = useStyles();
	return (
		<Fragment>
			<input
				color="primary"
				accept="image/*,.pdf"
				type="file"
				disabled={props.disabled}
				onChange={props.handleClick}
				id="icon-button-file"
				style={{ display: 'none' }}
				multiple
			/>
			<label htmlFor="icon-button-file">
				<Grid
					className={classes.newContainer}
					container
					direction="column"
					justify="center"
					alignItems="center"
				>
					<img alt="select" className={classes.plusIcon} src={plus} />
				</Grid>
			</label>
		</Fragment>
	);
});

export default PlusButton;
