import * as actionType from "./actionTypes";

const initalState = {
  pointsRange: [0, 1000],
  brandIds: [],
  locationIds: [],
  rewardValueRange: [0, 1000],
  filterApplied: false,
  locationList: [],
  brandList: [],
  productId: null,
  productMerchantId: null,
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case actionType.RESET_FILTER:
      return {
        pointsRange: [0, 1000],
        brandIds: [],
        locationIds: [],
        rewardValueRange: [0, 1000],
        filterApplied: false,
        brandList: [],
        locationList: [],
        relatedProductsApplied: false,
        productId: null,
        productMerchantId: null,
      };

    case actionType.UPDATE_POINTS_RANGE:
      return {
        ...state,
        pointsRange: action.value,
      };

    case actionType.UPDATE_PRICE_RANGE:
      return {
        ...state,
        rewardValueRange: action.value,
      };

    case actionType.UPDATE_BRANDS_FILTER:
      return {
        ...state,
        brandIds: action.value,
      };

    case actionType.UPDATE_LOCATION_FILTER:
      return {
        ...state,
        locationIds: action.value,
      };

    case actionType.UPDATE_FILTER_APPLIED:
      return {
        ...state,
        filterApplied: action.value,
      };

    case actionType.FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        locationList: action.payLoad,
      };

    case actionType.FETCH_LOCATIONS_FAIL:
      return {
        ...state,
        brandList: [],
        locationList: [],
      };

    case actionType.FETCH_BRANDS_SUCCESS:
      return {
        ...state,
        brandList: action.payLoad,
      };

    case actionType.FETCH_BRANDS_FAIL:
    case actionType.REMOVE_BRAND_LIST:
      return {
        ...state,
        brandList: [],
        brandIds: [],
      };

    // case actionType.REMOVE_BRAND_LIST:
    //     return {
    //         ...state,
    //         brandList: [],
    //     };

    case actionType.APPLY_RELATED_PRODUCTS:
      return {
        ...state,
        productId: action.productId,
        productMerchantId: action.productMerchantId,
      };

    case actionType.REMOVE_RELATED_PRODUCTS:
      return {
        ...state,
        productId: null,
        productMerchantId: null,
      };

    case actionType.RESET_FILTERS:
      return {
        ...state,
        pointsRange: [0, 1000],
        brandIds: [],
        locationIds: [],
        rewardValueRange: [0, 1000],
        filterApplied: false,
        locationList: [],
        brandList: [],
        productId: null,
        productMerchantId: null,
      };

    default:
      return state;
  }
};

export default reducer;
