import React, { useState, us } from "react";
import SwipeableViews from "react-swipeable-views";
import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { primaryOrange, lightGrey } from "../UI/Theme";

const styles = (theme) => {
  return {
    wrapper: {
      padding: 0,
      margin: "0 auto",
      display: "block",
      width: "100%",
      overflow: "hidden",
      maxHeight: "600px",
    },
    imageContainer: {
      "& .TransformComponent-module_container__3NwNd": {
        width: "100% !important",
        height: "100% !important",
      },
      display: "inline-block",
      width: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      height: "450px",
      [theme.breakpoints.down("321")]: {
        height: "425px",
      },
      "@media screen and (max-height: 560px)": {
        height: "350px",
      },
      "@media screen and (max-height: 480px)": {
        height: "300px",
      },
      "& img": {
        height: "100%",
        width: "100%",
        objectFit: "contain",
        objectPosition: "center",
      },
    },
    btnContainer: {
      width: "100%",
      padding: "30px",
      [theme.breakpoints.down("610")]: {
        padding: "28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
    },
    doneBtn: {
      ...theme.palette.typography.doneButtons,
      marginBottom: 0,
      fontSize: "22px",
      height: "92px",
      lineHeight: "18px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "18px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "16px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
        fontSize: "12.5px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    slideshow_dots: {
      width: "100%",
      display: "block",
      textAlign: "center",
      listStyle: "none",
      margin: "0",
      padding: 0,
      position: "absolute",
      "& li": {
        display: "inline",
        margin: 0,
        padding: 0,
        "& span": {
          cursor: "pointer",
          display: "inline-block",
          width: "10px",
          height: "10px",
          margin: "0 5px",
          borderRadius: "10px",
          opacity: "0.5",
          transition: ".3s",
        },
      },
    },
  };
};

const BillImage = (props) => {
  const { classes } = props;
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const next = (next) => {
    setActiveImageIndex(next);
  };

  return (
    <div className={classes.wrapper}>
      {props.multi ? (
        <div style={{ position: "relative" }}>
          <SwipeableViews
            index={activeImageIndex}
            onChangeIndex={next}
            enableMouseEvents
          >
            {props.imgSrc
              .filter((x) => !/pdf/.test(x.toLowerCase()))
              .map((img, index) => (
                <div key={index} className={classes.imageContainer}>
                  {Math.abs(activeImageIndex - index) <= 2 ? (
                    <img src={img} alt="Bill" />
                  ) : null}
                </div>
              ))}
          </SwipeableViews>
          <div className={classes.slideshow_dots}>
            {props.imgSrc.length > 1 &&
              props.imgSrc
                .filter((x) => !/pdf/.test(x.toLowerCase()))
                .map((item, i) => {
                  return (
                    <FiberManualRecordIcon
                      key={i}
                      style={{
                        fontSize: 10,
                        color:
                          activeImageIndex === i ? primaryOrange : lightGrey,
                      }}
                    />
                  );
                })}
          </div>
        </div>
      ) : (
        props.open && (
          <div className={classes.imageContainer}>
            <TransformWrapper key={props.imgSrc} disabled={!props.open}>
              <TransformComponent>
                <img src={props.imgSrc} alt="Bill" />
              </TransformComponent>
            </TransformWrapper>
          </div>
        )
      )}
      <div className={classes.btnContainer}>
        <Button
          disableRipple
          color="primary"
          variant="contained"
          className={classes.doneBtn}
          onClick={props.okBtnHandler}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(BillImage);
