import React from 'react';
import { withStyles } from '@material-ui/styles';
import { IconButton, Typography } from '@material-ui/core';
import { styles } from './SingleClickImageStyles';

const SingleClickImage = (props) => {
	const { classes } = props;
	const { bill_src, bill_name, bill_size, deleteHandler, id, onImageClicked, isPdf } = props;
	return (
		<div className={classes.billatt}>
			<div className={classes.img_container} onClick={(e) => onImageClicked(bill_src, isPdf)}>
			{isPdf ? (
            <a href={bill_src} target="_blank" rel="noopener noreferrer">
              <span className={classes.pdfIconCss + " " + classes.pdfCss} />
            </a>
          ) : (
            <img src={bill_src} alt="bill" className={classes.icon} />
          )}
			</div>
			<div className={classes.cnt}>
				<div className={classes.content}>
					<Typography className={classes.bill_name}>{bill_name}</Typography>

					<Typography className={classes.bill_size}>{`${(bill_size / (1024 * 1024)).toFixed(
						2
					)}MB`}</Typography>
				</div>
				<div className={classes.icon_container}>
					<IconButton className={classes.iconBtnDel} onClick={() => deleteHandler(id)}>
						<span className={classes.deleteIcon + ' ' + classes.icondeleteCss} />
					</IconButton>
				</div>
			</div>
		</div>
	);
};

export default withStyles(styles, { withTheme: true })(SingleClickImage);
