import gridView from '../../assets/icons/redeem/gridView.svg';
import sort from '../../assets/icons/redeem/sort.svg';
import listView from '../../assets/icons/redeem/listView.svg';
import filter from '../../assets/icons/redeem/filter.svg';

export const styles = (theme) => {
	return {
		subHeader: {
			height: '55px',
            borderRadius: '0px',
            position: 'relative',
		},
		yourBal: {
			fontSize: '16px',
			[theme.breakpoints.down(641)]: { fontSize: '14px' },
			[theme.breakpoints.down(481)]: { fontSize: '12px' },
			[theme.breakpoints.down(421)]: { fontSize: '11.5px' },
			[theme.breakpoints.down(401)]: { fontSize: '11px' },
			[theme.breakpoints.down(381)]: { fontSize: '10.5px' },
			[theme.breakpoints.down(361)]: { fontSize: '10px' },
			[theme.breakpoints.down(321)]: { fontSize: '8px' },
			color: theme.palette.redeemHeader.text
		},
		yourPoints: {
			'& span': {
				fontWeight: 'normal'
			},
			color: theme.palette.redeemHeader.text,
			fontWeight: '600',
			fontSize: '20px',
			[theme.breakpoints.down(641)]: {
				fontSize: '18px'
			},
			[theme.breakpoints.down(481)]: { fontSize: '16px' },
			[theme.breakpoints.down(421)]: { fontSize: '15.5px' },
			[theme.breakpoints.down(401)]: { fontSize: '15px' },
			[theme.breakpoints.down(381)]: { fontSize: '14.5px' },
			[theme.breakpoints.down(361)]: { fontSize: '14px' },
			[theme.breakpoints.down(321)]: { fontSize: '12px' }
		},
		gridIconCss: {
			backgroundColor: theme.palette.redeemHeader.iconsColor,
			' -webkit-mask-image': `url(.${gridView})`,
			'mask-image': `url(.${gridView})`,
			mask: `url(.${gridView}) no-repeat center / contain`,
			'-webkit-mask': `url(.${gridView}) no-repeat center / contain`,
			width: '23.8px !important',
			[theme.breakpoints.down('xs')]: {
				width: '17.8px !important',
			}
		},
		cardIcons: {
			width: '22px',
			height: '22px',
			display: 'inline-block',
			transition: '.12s',
			'-webkit-mask-size': 'cover',
			'mask-size ': 'cover',
			marginLeft: '2rem',
			// float: 'right',
			[theme.breakpoints.down('xs')]: {
				width: '16px',
				height: '16px',
				marginLeft: '1rem'
			}
		},
		sortIconCss: {
			backgroundColor: theme.palette.redeemHeader.iconsColor,
			' -webkit-mask-image': `url(.${sort})`,
			'mask-image': `url(.${sort})`,
			mask: `url(.${sort}) no-repeat center / contain`,
			'-webkit-mask': `url(.${sort}) no-repeat center / contain`
		},
		listViewIconCss: {
			backgroundColor: theme.palette.redeemHeader.iconsColor,
			' -webkit-mask-image': `url(.${listView})`,
			'mask-image': `url(.${listView})`,
			mask: `url(.${listView}) no-repeat center / contain`,
			'-webkit-mask': `url(.${listView}) no-repeat center / contain`
		},
		filterIconCss: {
			backgroundColor: theme.palette.redeemHeader.iconsColor,
			' -webkit-mask-image': `url(.${filter})`,
			'mask-image': `url(.${filter})`,
			mask: `url(.${filter}) no-repeat center / contain`,
			'-webkit-mask': `url(.${filter}) no-repeat center / contain`
		},
		sortList: {
			'& .MuiTypography-body1': {
				fontSize: '18px',
				[theme.breakpoints.down(641)]: {
					fontSize: '16px'
				},
				[theme.breakpoints.down(481)]: { fontSize: '14px' },
				[theme.breakpoints.down(421)]: { fontSize: '13.5px' },
				[theme.breakpoints.down(401)]: { fontSize: '13px' },
				[theme.breakpoints.down(381)]: { fontSize: '12.5px' },
				[theme.breakpoints.down(361)]: { fontSize: '12px' },
				[theme.breakpoints.down(321)]: { fontSize: '10px' }
			},
			'& .MuiTypography-h5': {
				fontWeight: '600',
				fontSize: '20px',
				[theme.breakpoints.down(641)]: {
					fontSize: '18px'
				},
				[theme.breakpoints.down(481)]: { fontSize: '16px' },
				[theme.breakpoints.down(421)]: { fontSize: '15.5px' },
				[theme.breakpoints.down(401)]: { fontSize: '15px' },
				[theme.breakpoints.down(381)]: { fontSize: '14.5px' },
				[theme.breakpoints.down(361)]: { fontSize: '14px' },
				[theme.breakpoints.down(321)]: { fontSize: '12px' }
			},
			'& .MuiList-padding': {
				padding: 0
			},
			'& .MuiListItem-root': {
				paddingTop: '6px',
				paddingBottom: '6px'
			}
		},
		filter_icon_container: {
			position: 'relative',
			padding: 0,
			borderRadius: 0,
			overflow: 'auto',
			display: 'inline-block',
			height: '100%',
			margin: 0
		},
		filter_dot: {
			borderRadius: '50%',
			position: 'absolute',
			top: 0,
			right: 0,
			zIndex: 20,
			backgroundColor: theme.palette.primary.main,
			width: '11px',
			height: '11px',
			[theme.breakpoints.down('xs')]: {
				width: '8px',
				height: '8px',
			}
		},
		icon_container: {
			display: 'flex',
			justifyContent: 'flex-end',
		}
	};
};
