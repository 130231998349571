export const styles = (theme) => {
  return {
    redeemCnt: {
      display: "block",
      height: "100%",
      backgroundColor: theme.palette.common.globalBackground,
      width: "100%",
      margin: "45px 0 0 0",
      [theme.breakpoints.down("481")]: {
        margin: "24px 0 0 0",
      },
      [theme.breakpoints.down("381")]: {
        margin: "20px 0 0 0",
      },
      [theme.breakpoints.down("321")]: {
        margin: "17px 0 0 0",
      },
    },
    infiniteScroll: {
      height: "calc(100% - 2px)",
      overflowY: "auto",
      overflowX: "hidden",
      [theme.breakpoints.down("641")]: {
        height: "calc(100% - 8px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
      [theme.breakpoints.down("481")]: {
        height: "calc(100% - 2px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
      [theme.breakpoints.down("421")]: {
        height: "calc(100% - 0px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
      [theme.breakpoints.down("401")]: {
        height: "calc(100% - 0px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
      [theme.breakpoints.down("381")]: {
        height: "calc(100% - 3px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
      [theme.breakpoints.down("321")]: {
        height: "calc(100% - 0px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
    },
    common_padding: {
      padding: "30px 30px 0",
      [theme.breakpoints.down("610")]: {
        padding: "28px 28px 0",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px 21px 0",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px 15px 0",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px 13px 0",
      },
    },
    common_padding2: {
      padding: "0px 30px 0",
      [theme.breakpoints.down("610")]: {
        padding: "0px 28px 0",
      },
      [theme.breakpoints.down("481")]: {
        padding: "0px 21px 0",
      },
      [theme.breakpoints.down("381")]: {
        padding: "0px 15px 0",
      },
      [theme.breakpoints.down("321")]: {
        padding: "0px 13px 0",
      },
    },
    searchBox_padding: {
      padding: "30px",
      [theme.breakpoints.down("610")]: {
        padding: "28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
    },
    searchActive: {
      height: "calc(100% - 112px)",
      overflowY: "auto",
      overflowX: "hidden",
      [theme.breakpoints.down("641")]: {
        height: "calc(100% - 106px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
      [theme.breakpoints.down("481")]: {
        height: "calc(100% - 86px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
      [theme.breakpoints.down("421")]: {
        height: "calc(100% - 82px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
      [theme.breakpoints.down("401")]: {
        height: "calc(100% - 82px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
      [theme.breakpoints.down("381")]: {
        height: "calc(100% - 74px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
      [theme.breakpoints.down("361")]: {
        height: "calc(100% - 78px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
      [theme.breakpoints.down("321")]: {
        height: "calc(100% - 69px)",
        overflowY: "auto",
        overflowX: "hidden",
      },
    },
    redeemHd: {
      padding: "0px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      position: "relative",
    },
    redeemText: {
      padding: "0px",
      fontWeight: "500",
      color: theme.palette.typography.texts.color,
      fontSize: "24px",
      [theme.breakpoints.down("641")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "17.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "16.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "14px",
      },
    },
    redeemLinks: {
      margin: "0",
      padding: "0px",
      fontWeight: "500",
      color: theme.palette.typography.texts.specialPointsColor,
      textDecoration: "none",
      top: "6px",
      right: "0",
      cursor: "pointer",
      fontSize: "16px",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    txtlocation: {
      margin: "0",
      padding: "0px",
      display: "inline-block",
      position: "relative",
      verticalAlign: "middle",
    },
    one: {
      display: "block",
      width: "100%",
      fontWeight: "500",
      color: theme.palette.typography.texts.sellingPriceColor,
      textDecorationLine: "line-through",
      fontSize: "16px",
      textAlign: "left",
      lineHeight: "normal",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("430")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    two: {
      display: "block",
      whiteSpace: "nowrap",
      textAlign: "left",
      color: theme.palette.typography.texts.specialPointsColor,
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "normal",
      [theme.breakpoints.down("641")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    noRecord: {
      fontSize: "1.5rem",
      padding: "30px 15px",
      fontWeight: 400,
      textAlign: "center !important",
      color: theme.palette.typography.texts.color,
      opacity: 0.4,
      margin: " 0 1rem auto",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    loaderProgress: {
      zIndex: 999,
      position: "absolute",
      top: "60%",
      left: "48%",
      marginTop: -12,
      backgroundColor: "transparent",
      boxShadow: "none",
      marginLeft: -12,
      color: theme.palette.typography.texts.specialPointsColor,
    },
    listViewClass: {
      height: "100%",
      overflowY: "auto",
      padding: "30px",
      [theme.breakpoints.down("641")]: {
        padding: "25px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "19px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
    },
    ovrflHidden: {
      overflow: "hidden",
    },
    searchBox: {
      width: "100%",
      [theme.breakpoints.down(641)]: {
        marginTop: "16px",
      },
      [theme.breakpoints.down(481)]: {
        marginTop: "14px",
      },
      [theme.breakpoints.down(381)]: {
        marginTop: "12px",
      },
      [theme.breakpoints.down(321)]: {
        marginTop: "10px",
      },
    },
  };
};
