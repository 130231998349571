import React, { Component, Fragment } from "react";
import { apiData } from "../../common/common-types";
import { openAlertBox } from "../../common/AlertMessage/AlertMessage";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { styles } from "../../containers/Screens/Profile/ProfileContainerStyles";
import { setSaveMessage } from "../../containers/Screens/Profile/redux/Action";
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputBase,
  InputLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import classnames from "classnames";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { stockAPI } from "../../common/axiosInstance";
import { getStorageItem, setStorageItem } from "../../common/localStorage";
import {
  changeActiveTab,
  logoutUser,
  showLoader,
} from "../../App/redux/action";
import { connect } from "react-redux";
import down_arrow from "../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import { withRouter } from "react-router-dom";
import { grey } from "../UI/Theme";
import { capitalize } from "../../common/commonFunctions";
import { encrypt } from "../../crypto-helper";

const mapDispatchToProps = {
  changeActiveTab,
  logoutUser,
  showLoader,
  setSaveMessage,
};

const CustomTextField = withStyles((theme) => ({
  root: {
    width: "96%",
    position: "relative",
    textAlign: "left",
  },
}))(TextField);

const CustomTextField1 = withStyles((theme) => ({
  root: {
    width: "96%",
    textAlign: "left",
    fontSize: "20px",
    display: "block",

    [theme.breakpoints.down(641)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down(481)]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(421)]: {
      fontSize: "13.5px",
    },
    [theme.breakpoints.down(401)]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down(381)]: {
      fontSize: "12.5px",
    },
    [theme.breakpoints.down(361)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(321)]: {
      fontSize: "10px",
    },
  },
}))(InputBase);

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const gender = [
  { id: "0", name: "Select Gender", disable: true },
  { id: "1", name: "Male", disable: false },
  { id: "2", name: "Female", disable: false },
  { id: "3", name: "Other", disable: false },
];

const maritalStatus = [
  { id: "0", name: "Select Marital Status", disable: true },
  { id: "1", name: "Single", disable: false },
  { id: "2", name: "Married", disable: false },
  { id: "3", name: "Widow", disable: false },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "50%",
    },
  },
};

class EditProfile extends Component {
  state = {
    fname: "",
    helperTextFname: "",
    fnameError: false,

    lname: "",
    helperTextLname: "",
    lnameError: false,

    mobileNumber: "",
    helperTextMobile: "",
    mobileError: false,

    email: "",
    helperTextEmail: "",
    emailError: false,

    pincode: "",
    helperText: "",
    pincodeError: false,

    gender: 0,
    maritalStatus: 0,
    spouseDOB: null,
    anniversaryDate: null,
    dob: null,
    city: {
      cityId: "",
      cityName: "",
    },
    state: {
      stateId: "",
      stateName: "",
    },
    country: {
      countryId: "",
      countryName: "",
    },

    pincodeId: "",
    pinList: [],
    pinCodeDiv: false,

    show: true,
    scrollPos: 0,
    status: 1,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.detectPageScroll);
    this.props.showLoader(true);
    if (getStorageItem("user")) {
      const formData = new URLSearchParams();
      formData.append("platform", apiData.platform);
      formData.append("userId", JSON.parse(getStorageItem("user")).id);
      formData.append("merchant_id", apiData.merchant_id);
      formData.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
      stockAPI(formData, "POST", "/getMerchantDetails").then((response) => {
        this.props.showLoader(false);
        if (
          response.merchant_list &&
          response.merchant_list.added[1].customerDetails
        ) {
          this.setState({
            fname: response.merchant_list.added[1].customerDetails.first_name,
            lname: response.merchant_list.added[1].customerDetails.last_name,
            mobileNumber:
              response.merchant_list.added[1].customerDetails.mobile_number,
            email:
              response.merchant_list.added[1].customerDetails.email_address,
            pincode: response.merchant_list.added[1].customerDetails.pin_code,
            pincodeId:
              response.merchant_list.added[1].customerDetails.pin_code_id,
            gender:
              response.merchant_list.added[1].customerDetails.gender &&
              gender[response.merchant_list.added[1].customerDetails.gender].id,
            maritalStatus:
              response.merchant_list.added[1].customerDetails.marital_status &&
              maritalStatus[
                response.merchant_list.added[1].customerDetails.marital_status
              ].id,

            dob:
              response.merchant_list.added[1].customerDetails.date_of_birth ===
              ""
                ? null
                : new Date(
                    response.merchant_list.added[1].customerDetails.date_of_birth
                  ),
            spouseDOB:
              response.merchant_list.added[1].customerDetails.spouse_dob === ""
                ? null
                : new Date(
                    response.merchant_list.added[1].customerDetails.spouse_dob
                  ),
            anniversaryDate:
              response.merchant_list.added[1].customerDetails
                .anniversary_date === ""
                ? null
                : new Date(
                    response.merchant_list.added[1].customerDetails.anniversary_date
                  ),
            status: response.merchant_list.added[1].customerDetails.status
              ? response.merchant_list.added[1].customerDetails.status
              : 1,
          });
        } else {
          // openAlertBox('Oops something went wrong!', 'error');
        }
      });
    } else {
      this.props.showLoader(false);
      this.props.logoutUser();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.pincodeId !== prevState.pincodeId) {
      this.getAddressDetail();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.detectPageScroll);
  }

  getAddressDetail = () => {
    if (this.state.pincodeId !== "") {
      const formData = new URLSearchParams();
      formData.append("pin_code", this.state.pincodeId);
      stockAPI(formData, "POST", "/getPincodeStateCity").then((response) => {
        if (response) {
          this.setState({
            city: {
              cityId: response.cityId,
              cityName: response.cityName,
            },
            state: {
              stateId: response.stateId,
              stateName: response.stateName,
            },
            country: {
              countryId: response.countryId,
              countryName: response.countryName,
            },
          });
        } else {
          openAlertBox("Oops something went wrong!", "error");
        }
      });
    }
  };

  onPinchange = async (e) => {
    const re = /^[0-9\b]+$/;
    this.setState({ pincode: e.target.value, pincodeId: "" });

    if (!e.target.value || e.target.value.length === 0) {
      this.setState({
        helperText: "Pincode is required.",
        error: true,
        pinCodeDiv: false,
        pinList: [],
      });
    } else if (re.test(e.target.value)) {
      this.setState({ helperText: "", error: false });
      if (e.target.value.length > 2) {
        const formData = new URLSearchParams();
        formData.append("platform", apiData.platform);
        formData.append("pin_code", e.target.value);
        formData.append("isMasterPincodeRequired", 0);

        stockAPI(formData, "POST", "/getPincode", null, null, null, true)
          .then(async (response) => {
            if (response && response.statusCode === 100) {
              this.setState({ pinList: response.response, pinCodeDiv: true });
            }

            if (response && response.statusCode === 102) {
              this.setState({
                helperText: response.statusDescription,
                error: true,
                pinCodeDiv: false,
              });
            }
          })
          .catch(function (err) {
            openAlertBox(err && err.message, "error");
          });
      } else {
        if (this.state.pinList.length !== 0) {
          this.setState({
            pinList: [],
            pinCodeDiv: false,
          });
        }
      }
    } else {
      this.setState({
        helperText: "Enter numeric value.",
        error: true,
        pinList: [],
        pinCodeDiv: false,
      });
    }
  };

  pincodeValue = (e) => {
    this.setState({
      pincode: e.target.value,
      pincodeId: e.target.id,
      pinCodeDiv: false,
    });
  };

  submitButton = (e) => {
    e.preventDefault();
    if (
      this.state.helperTextFname === "" &&
      this.state.helperTextLname === "" &&
      this.state.helperTextMobile === "" &&
      this.state.helperTextEmail === "" &&
      this.state.helperText === "" &&
      this.state.pincodeId !== ""
    ) {
      const formData = {
        first_name: this.state.fname.trim(),
        last_name: this.state.lname.trim(),
        mobile_number: this.state.mobileNumber,
        email_address: this.state.email,
        pin_code: this.state.pincodeId,
        transactionType: 201,
        mobile_verified: 1,
        customer_id: JSON.parse(getStorageItem("user")).id,

        date_of_birth: this.state.dob
          ? `${this.state.dob.getFullYear()}-${
              this.state.dob.getMonth() + 1
            }-${this.state.dob.getDate()}`
          : "",
        gender: this.state.gender,
        marital_status: this.state.maritalStatus,

        anniversary_date: this.state.anniversaryDate
          ? `${this.state.anniversaryDate.getFullYear()}-${
              this.state.anniversaryDate.getMonth() + 1
            }-${this.state.anniversaryDate.getDate()}`
          : "",
        spouse_dob: this.state.spouseDOB
          ? `${this.state.spouseDOB.getFullYear()}-${
              this.state.spouseDOB.getMonth() + 1
            }-${this.state.spouseDOB.getDate()}`
          : "",
        customer_city: this.state.city.cityId,
        merchant_id: apiData.merchant_id,
        appVersion: apiData.appVersion,
        platform: apiData.platform,
        status: this.state.status,
      };

      let encryptedPayload = {
        _k: encrypt(JSON.stringify(formData)),
      };

      stockAPI(encryptedPayload, "POST", "/profileUpdate").then((response) => {
        if (response) {
          this.props.setSaveMessage();
          const user = {
            id: JSON.parse(getStorageItem("user")).id,
            mobile_number: this.state.mobileNumber,
          };
          setStorageItem("user", JSON.stringify(user));
          this.props.history.push("/my-profile");
          // openAlertBox('Profile updated successfully', 'success');
        }
      });
    }
  };

  handleCancelBtn = (e) => {
    e.preventDefault();
    this.props.history.push("/my-profile");
  };

  handleChange = (e) => {};

  handleChangeEmail = (event) => {
    const email = event.target.value.toLowerCase();
    if (event.target.value.length > 225) {
      return false;
    }
    this.setState({ email });
    if (validateEmail(email)) {
      this.setState({ helperTextEmail: "", emailError: false });
    } else {
      this.setState({
        helperTextEmail: "Please enter a valid email address.",
        emailError: true,
      });
    }
  };

  handleFname = (e) => {
    const re = /^([a-zA-Z ]+)?([[a-zA-Z'-]{0,1})?[a-zA-Z]+$/;
    let value = e.target.value;
    let errorMessage = "";
    let errorMessageStatus = false;

    if (value.length === 0) {
      errorMessage = "First name is required.";
      errorMessageStatus = true;
    } else {
      if (value.length > 20) {
        return false;
      }
      if (re.test(value)) {
        e.preventDefault();
        errorMessage = "";
        errorMessageStatus = false;
        value = capitalize(value);
      } else {
        errorMessage = "Please enter valid name.";
        errorMessageStatus = true;
      }
    }

    this.setState({
      fname: value,
      helperTextFname: errorMessage,
      fnameError: errorMessageStatus,
    });
  };

  handleLname = (e) => {
    const re = /^([a-zA-Z ]+)?([[a-zA-Z'-]{0,1})?[a-zA-Z]+$/;
    let value = e.target.value;
    let errorMessage = "";
    let errorMessageStatus = false;

    if (value.length === 0) {
      errorMessage = "Last name is required.";
      errorMessageStatus = true;
    } else {
      if (value.length > 20) {
        return false;
      }
      if (re.test(value)) {
        e.preventDefault();
        errorMessage = "";
        errorMessageStatus = false;
        value = capitalize(value);
      } else {
        errorMessage = "Please enter valid name.";
        errorMessageStatus = true;
      }
    }
    this.setState({
      lname: value,
      helperTextLname: errorMessage,
      lnameError: errorMessageStatus,
    });
  };

  handleMobileChange = (event) => {
    const re = /(^[4-9][0-9\s\b]+$)/;
    if (
      event.target.value !== "3" &&
      event.target.value !== "2" &&
      event.target.value !== "1" &&
      event.target.value !== "0"
    ) {
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({ helperTextMobile: "", mobileError: false });
      }
    } else {
      this.setState({
        helperTextMobile: "Please enter a valid 10 digit mobile number.",
        mobileError: true,
      });
    }

    if (
      re.test(event.target.value) &&
      (event.target.value.length === 10 || event.target.value.length > 10)
    ) {
      this.setState({
        helperTextMobile: "",
        mobileError: false,
      });
    } else {
      this.setState({ disabled: true });
      this.setState({
        helperTextMobile: "Please enter a valid 10 digit mobile number.",
        mobileError: true,
      });
    }

    this.setState({ mobileNumber: event.target.value });
  };

  handleDateChange1 = (date) => {
    this.setState({
      spouseDOB: date._d,
    });
  };

  handleDateChange = (date) => {
    this.setState({
      anniversaryDate: date._d,
    });
  };

  handleDateChange2 = (date) => {
    this.setState({
      dob: date._d,
    });
  };

  handleDropDown = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "gender":
        this.setState({
          gender: value,
        });
        break;

      case "maritalStatus":
        if (value === 2) {
          this.setState({
            maritalStatus: value,
          });
        } else {
          this.setState({
            maritalStatus: value,
            spouseDOB: null,
            anniversaryDate: null,
          });
        }
        break;

      default:
        break;
    }
  };

  detectPageScroll = (e) => {
    this.setState({
      scrollPos: document.body.getBoundingClientRect().top,
      show: document.body.getBoundingClientRect().top > this.state.scrollPos,
    });
  };

  render() {
    const { classes } = this.props;

    const isSubmitEnabled = !(
      this.state.helperTextFname === "" &&
      this.state.helperTextLname === "" &&
      this.state.helperTextMobile === "" &&
      this.state.helperTextEmail === "" &&
      this.state.helperText === "" &&
      this.state.pincodeId !== ""
    );

    return (
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        libInstance={moment}
        locale={"en"}
      >
        <div className={classes.pageContainer}>
          <div className={classes.root}>
            <ValidatorForm
              ref="form"
              onSubmit={this.submitButton}
              onError={(errors) => console.log(errors)}
              className={classes.formControl1}
            >
              <div className={classes.formContent}>
                <div
                  className={classes.itemContainer + " " + classes.padd_right}
                >
                  <TextValidator
                    InputProps={{
                      classes: {
                        underline: classes.cssUnderline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                    className={classes.fieldSet}
                    label="First Name"
                    onChange={this.handleFname}
                    value={this.state.fname}
                    validators={["required"]}
                    errorMessages={[
                      "This field is required.",
                      "First name is required.",
                    ]}
                    helperText={this.state.helperTextFname}
                    error={this.state.fnameError}
                    inputProps={{
                      maxLength: 20,
                      autoComplete: "off",
                    }}
                  />
                </div>
                <div
                  className={classes.itemContainer + " " + classes.padd_left}
                >
                  <TextValidator
                    InputProps={{
                      classes: {
                        underline: classes.cssUnderline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                    className={classes.fieldSet}
                    label="Last Name"
                    onChange={this.handleLname}
                    value={this.state.lname}
                    validators={["required"]}
                    errorMessages={[
                      "This field is required.",
                      "Last Name is required.",
                    ]}
                    helperText={this.state.helperTextLname}
                    error={this.state.lnameError}
                    inputProps={{
                      maxLength: 20,
                      autoComplete: "off",
                    }}
                  />
                </div>
                <TextValidator
                  InputProps={{
                    classes: {
                      underline: classes.cssUnderline,
                      input: classes.input,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: "focused",
                      shrink: "shrink",
                    },
                  }}
                  className={classes.fieldSet + " " + classes.disabledField}
                  label="Mobile Number"
                  value={this.state.mobileNumber}
                  onChange={this.handleMobileChange}
                  name="mobileNumber"
                  inputProps={{
                    maxLength: 13,
                    autoComplete: "off",
                  }}
                  validators={["required"]}
                  errorMessages={[
                    "This field is required.",
                    "Mobile Number is required.",
                  ]}
                  error={this.state.mobileError}
                  helperText={this.state.helperTextMobile}
                  type="tel"
                  disabled
                />
                <TextValidator
                  InputProps={{
                    classes: {
                      underline: classes.cssUnderline,
                      input: classes.input,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: "focused",
                      shrink: "shrink",
                    },
                  }}
                  className={classes.fieldSet}
                  label="Email Address"
                  onChange={this.handleChangeEmail}
                  name="email"
                  value={this.state.email}
                  validators={["required"]}
                  errorMessages={[
                    "This field is required.",
                    "Enter a valid email address.",
                  ]}
                  helperText={this.state.helperTextEmail}
                  error={this.state.emailError}
                  inputProps={{
                    autoComplete: "off",
                    maxLength: 100,
                  }}
                />

                <div
                  className={classes.itemContainer + " " + classes.padd_right}
                >
                  <TextValidator
                    className={classes.fieldSet}
                    label="Pincode"
                    value={this.state.pincode}
                    validators={["required"]}
                    errorMessages={["Pincode is required"]}
                    inputProps={{
                      maxLength: 6,
                      type: "tel",
                      autoComplete: "off",
                    }}
                    InputProps={{
                      classes: {
                        underline: classes.cssUnderline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                    onChange={(e) => this.onPinchange(e)}
                    helperText={this.state.helperText}
                    error={this.state.error}
                  />

                  {this.state.pinCodeDiv ? (
                    <ul
                      className={classnames(classes.selectListUl, {
                        [classes.less]: this.state.pinList.length === 1,
                      })}
                    >
                      {this.state.pinList.length > 0 &&
                        this.state.pinList.map((elem) => {
                          return (
                            <li
                              key={elem.id}
                              id={elem.id}
                              value={elem.pc}
                              className={classnames(classes.selectListLi, {
                                [classes.less]: this.state.pinList.length === 1,
                              })}
                              onClick={(e) => {
                                this.pincodeValue(e);
                              }}
                            >
                              {elem.pc}
                            </li>
                          );
                        })}
                    </ul>
                  ) : null}
                </div>
                <div
                  className={classes.itemContainer + " " + classes.padd_left}
                >
                  <TextValidator
                    InputProps={{
                      classes: {
                        underline: classes.cssUnderline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                    className={classes.fieldSet + " " + classes.disabledField}
                    label="City"
                    value={this.state.city.cityName}
                    disabled
                  />
                </div>
                <div
                  className={classes.itemContainer + " " + classes.padd_right}
                >
                  <TextValidator
                    InputProps={{
                      classes: {
                        underline: classes.cssUnderline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                    className={classes.fieldSet + " " + classes.disabledField}
                    label="State"
                    value={this.state.state.stateName}
                    disabled
                  />
                </div>
                <div
                  className={classes.itemContainer + " " + classes.padd_left}
                >
                  <TextValidator
                    InputProps={{
                      classes: {
                        underline: classes.cssUnderline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: "focused",
                        shrink: "shrink",
                      },
                    }}
                    className={classes.fieldSet + " " + classes.disabledField}
                    label="Country"
                    name="country"
                    value={this.state.country.countryName}
                    disabled
                  />
                </div>
                <div
                  className={classes.itemContainer + " " + classes.padd_right}
                >
                  <div className={classes.selectInput}>
                    <FormControl fullWidth>
                      <InputLabel
                        className={classes.selectLabel}
                        id="demo-controlled-open-select-label"
                        style={{
                          width: "100%",
                          display: "block",
                          whiteSpace: "nowrap",
                          color: grey,
                          margin: "-8px 0px 0px",
                        }}
                      >
                        Gender
                      </InputLabel>
                      <Select
                        native
                        name="gender"
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={this.state.gender}
                        onChange={this.handleDropDown}
                        input={<CustomTextField1 />}
                        IconComponent={() => (
                          <img
                            className={classes.down_icon}
                            src={down_arrow}
                            alt=""
                            width="10"
                            height="6"
                          />
                        )}
                        MenuProps={MenuProps}
                      >
                        {gender.map((gen) => (
                          <option
                            disabled={gen.disable}
                            className={classes.menuItem}
                            key={gen.id}
                            value={gen.id}
                          >
                            {gen.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div
                  className={classes.itemContainer + " " + classes.padd_left}
                >
                  <DatePicker
                    autoOk
                    format="DD - MMM - yyyy"
                    value={this.state.dob}
                    onChange={this.handleDateChange2}
                    disableFuture
                    TextFieldComponent={(props) => {
                      return (
                        <CustomTextField
                          {...props}
                          id="dob"
                          inputProps={{
                            style: {
                              padding: 0,
                            },
                            autoComplete: "off",
                          }}
                          className={classes.fieldSet}
                          InputProps={{ disableUnderline: true }}
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: "focused",
                              shrink: "shrink",
                            },
                          }}
                          label="Date of Birth"
                        />
                      );
                    }}
                  />
                </div>
                <div className={classes.selectInput}>
                  <FormControl fullWidth>
                    <InputLabel
                      className={classes.selectLabel}
                      id="demo-controlled-open-select-label1"
                      style={{
                        width: "100%",
                        display: "block",
                        whiteSpace: "nowrap",
                        color: grey,
                        margin: "-8px 0px 0px",
                      }}
                    >
                      Marital Status
                    </InputLabel>
                    <Select
                      native
                      name="maritalStatus"
                      labelId="demo-controlled-open-select-label1"
                      id="demo-controlled-open-select1"
                      value={this.state.maritalStatus}
                      onChange={this.handleDropDown}
                      input={<CustomTextField1 />}
                      IconComponent={() => (
                        <img
                          className={classes.down_icon}
                          src={down_arrow}
                          alt=""
                          width="10"
                          height="6"
                        />
                      )}
                      MenuProps={MenuProps}
                    >
                      {maritalStatus.map((ms) => (
                        <option
                          disabled={ms.disable}
                          className={classes.menuItem}
                          key={ms.id}
                          value={ms.id}
                        >
                          {ms.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {this.state.maritalStatus === "2" && (
                  <Fragment>
                    <div
                      style={{ position: "relative" }}
                      className={
                        classes.itemContainer + " " + classes.padd_right
                      }
                    >
                      <DatePicker
                        autoOk
                        format="DD - MMM - yyyy"
                        value={this.state.spouseDOB}
                        onChange={this.handleDateChange1}
                        disableFuture
                        TextFieldComponent={(props) => {
                          return (
                            <CustomTextField
                              {...props}
                              id="spouseDOB"
                              inputProps={{
                                style: {
                                  padding: 0,
                                },
                                autoComplete: "off",
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: "focused",
                                  shrink: "shrink",
                                },
                              }}
                              className={classes.fieldSet}
                              InputProps={{ disableUnderline: true }}
                              label="Spouse DoB"
                            />
                          );
                        }}
                      />
                    </div>
                    <div
                      className={
                        classes.itemContainer + " " + classes.padd_left
                      }
                    >
                      <DatePicker
                        autoOk
                        format="DD - MMM - yyyy"
                        value={this.state.anniversaryDate}
                        onChange={this.handleDateChange}
                        disableFuture
                        TextFieldComponent={(props) => {
                          return (
                            <CustomTextField
                              {...props}
                              id="anniversaryDate"
                              inputProps={{
                                style: {
                                  padding: 0,
                                },
                                autoComplete: "off",
                              }}
                              className={classes.fieldSet}
                              InputProps={{ disableUnderline: true }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: "focused",
                                  shrink: "shrink",
                                },
                              }}
                              label="Anniversary Date"
                            />
                          );
                        }}
                      />
                    </div>
                  </Fragment>
                )}
              </div>
              <div className={classes.btnContainer}>
                <div
                  className={classes.itemContainer + " " + classes.padd_right}
                >
                  <Button
                    type="submit"
                    disableRipple
                    disabled={isSubmitEnabled}
                    className={classes.submitBtn}
                  >
                    SAVE
                  </Button>
                </div>
                <div
                  className={classes.itemContainer + " " + classes.padd_left}
                >
                  <Button
                    onClick={this.handleCancelBtn}
                    disableRipple
                    className={classes.cancelBtn}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })(EditProfile)));
