import g1 from '../../assets/icons/bill_upload_guidelines/guide_1.svg';
import g2 from '../../assets/icons/bill_upload_guidelines/guide_2.svg';
import g3 from '../../assets/icons/bill_upload_guidelines/guide_3.svg';
import g4 from '../../assets/icons/bill_upload_guidelines/guide_4.svg';

export const styles = (theme) => {
    return {
        root: {
            backgroundColor: '#fff',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
            marginBottom: '30px',
            padding: '30px',
            borderRadius: '5px',
			[theme.breakpoints.down('610')]: {
                padding: '28px',
                marginBottom: '28px',
			},
			[theme.breakpoints.down('481')]: {
                padding: '21px',
                marginBottom: '21px',
			},
			[theme.breakpoints.down('381')]: {
                padding: '15px',
                marginBottom: '15px',
			},
			[theme.breakpoints.down('321')]: {
                padding: '13px',
                marginBottom: '13px',
			}
        },
        images_container: {
            display: 'inline-block',
            width: '47%',
            borderRadius: '10px',
            border: 'solid 1px #dbdbdb',
            overflow: 'hidden',
            backgroundColor: '#f3f3f3',
            position: 'relative',
            height: 150,
			[theme.breakpoints.down('481')]: {
                height: 140,
                borderRadius: '7px',
            },
            [theme.breakpoints.down('421')]: {
                width: '46%',
                height: 120,
                borderRadius: '6px'
			},
			[theme.breakpoints.down('381')]: {
                height: 115,
                borderRadius: '5px',
            },
            [theme.breakpoints.down('361')]: {
                height: 110,
                borderRadius: '4px',
			},
			[theme.breakpoints.down('321')]: {
                height: 92,
                borderRadius: '3px',
			}
        },
        margin_right1: {
            marginRight: '30px',
			[theme.breakpoints.down('610')]: {
				marginRight: '28px',
			},
			[theme.breakpoints.down('481')]: {
				marginRight: '21px',
			},
			[theme.breakpoints.down('381')]: {
				marginRight: '15px',
			},
			[theme.breakpoints.down('321')]: {
				marginRight: '13px',
			}
        },
        margin_right: {
            marginRight: '15px',
			[theme.breakpoints.down('610')]: {
				marginRight: '14px',
			},
			[theme.breakpoints.down('481')]: {
				marginRight: '10.5px',
			},
			[theme.breakpoints.down('381')]: {
				marginRight: '7.5px',
			},
			[theme.breakpoints.down('321')]: {
				marginRight: '6.5px',
			}
        },
        margin_left: {
            marginLeft: '15px',
			[theme.breakpoints.down('610')]: {
				marginLeft: '14px',
			},
			[theme.breakpoints.down('481')]: {
				marginLeft: '10.5px',
			},
			[theme.breakpoints.down('381')]: {
				marginLeft: '7.5px',
			},
			[theme.breakpoints.down('321')]: {
				marginLeft: '6.5px',
			}
        },
        images: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        },
        icon: {
            height: '60px',
            width: '60px',
            display: "inline-block",
            verticalAlign: 'middle',
            transition: ".12s",
            "-webkit-mask-size": "cover",
            "mask-size ": "cover",
            [theme.breakpoints.down(481)]: {
                height: '38px',
                width: '38px',
            },
            [theme.breakpoints.down(421)]: {
                height: '34px',
                width: '34px',
            },
            [theme.breakpoints.down(381)]: {
                height: '32px',
                width: '32px',
            },
            [theme.breakpoints.down(361)]: {
                height: '30px',
                width: '30px',
            },
            [theme.breakpoints.down(321)]: {
                height: '28px',
                width: '28px',
            }
        },
        info: {
            display: 'flex',
            marginBottom: '30px',
            alignItems: 'center',
			[theme.breakpoints.down('610')]: {
				marginBottom: '28px',
			},
			[theme.breakpoints.down('481')]: {
				marginBottom: '21px',
			},
			[theme.breakpoints.down('381')]: {
				marginBottom: '15px',
			},
			[theme.breakpoints.down('321')]: {
				marginBottom: '13px',
			}
        },
        text: {
            fontWeight: 600,
            color: '#211f20',
            fontSize: "24px",
            [theme.breakpoints.down("641")]: {
                fontSize: "20px",
            },
            [theme.breakpoints.down("481")]: {
                fontSize: "15px",
            },
            [theme.breakpoints.down("421")]: {
                fontSize: "13.5px",
            },
            [theme.breakpoints.down("381")]: {
                fontSize: "13px",
            },
            [theme.breakpoints.down("361")]: {
                fontSize: "12px",
            },
            [theme.breakpoints.down("321")]: {
                fontSize: "11px",
            },
        },
        g1: {
            backgroundColor: theme.palette.primary.main,
            " -webkit-mask-image": `url(.${g1})`,
            "mask-image": `url(.${g1})`,
            mask: `url(.${g1}) no-repeat center / contain`,
            "-webkit-mask": `url(.${g1}) no-repeat center / contain`,
        },
        g2: {
            backgroundColor: theme.palette.primary.main,
            " -webkit-mask-image": `url(.${g2})`,
            "mask-image": `url(.${g2})`,
            mask: `url(.${g2}) no-repeat center / contain`,
            "-webkit-mask": `url(.${g2}) no-repeat center / contain`,
        },
        g3: {
            backgroundColor: theme.palette.primary.main,
            " -webkit-mask-image": `url(.${g3})`,
            "mask-image": `url(.${g3})`,
            mask: `url(.${g3}) no-repeat center / contain`,
            "-webkit-mask": `url(.${g3}) no-repeat center / contain`,
        },
        g4: {
            backgroundColor: theme.palette.primary.main,
            " -webkit-mask-image": `url(.${g4})`,
            "mask-image": `url(.${g4})`,
            mask: `url(.${g4}) no-repeat center / contain`,
            "-webkit-mask": `url(.${g4}) no-repeat center / contain`,
        },
        ops_icon: {
            position: 'absolute',
            height: '26px',
            width: '26px',
            top: 5,
            right: 5,
            [theme.breakpoints.down(421)]: {
                height: '24px',
                width: '24px',
            },
            [theme.breakpoints.down(361)]: {
                height: '20px',
                width: '20px',
            }
        }
    }
}