import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const UploadBox = React.memo((props) => {
	const classes = useStyles();
	return (
		<Fragment>
			<input
				color="primary"
				accept="image/*,.pdf"
				type="file"
				disabled={props.disabled}
				onChange={props.handleClick}
				id="icon-button-file"
				style={{ display: 'none' }}
				multiple
			/>
			<label htmlFor="icon-button-file">
				<Grid
					className={classes.root}
					container
					direction="column"
					justify="center"
					alignItems="center"
					component="span"
				>
					<Typography variant="h6" className={classes.mainTitle}>
						{props.mainTitle}
					</Typography>
					<Typography variant="subtitle1" className={classes.secondaryTitle}>
						{props.secondaryTitle}
					</Typography>
					<Typography variant="subtitle1" className={classes.secondaryTitle}>
						{props.note}
					</Typography>
				</Grid>
			</label>
		</Fragment>
	);
});

export default UploadBox;
