import * as actionTypes from "./actionTypes";

const initialState = {
  notificationList: [],
  pageNum: 1,
  hasMore: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CUSTOMER_NOTIFICATION:
      return {
        ...state,
        notificationList: action.payLoad.notifications,
        pageNum: action.payLoad.pageNum,
      };

    case actionTypes.GET_MORE_CUSTOMER_NOTIFICATION:
      return {
        ...state,
        notificationList: [
          ...state.notificationList,
          ...action.payLoad.notifications,
        ],
        pageNum: action.payLoad.pageNum,
      };

    case actionTypes.DELETE_NOTIFICATION:
      return {
        ...state,
        notificationList: state.notificationList.filter(
          (notification) => action.id !== notification.id
        ),
      };

    case actionTypes.SET_NOTIFICATION_TAPPED:
      return {
        ...state,
        notificationList: state.notificationList.map((notification) => {
          if (notification.id === action.notification.id) {
            return action.notification;
          } else {
            return notification;
          }
        }),
      };

    default:
      return state;
  }
};

export default reducer;
