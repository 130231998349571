import * as actionTypes from "./actionTypes";

const searchChangeHandler = (val) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SEARCH_TEXT, value: val });
  };
};

const searchOffers = (val) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SEARCH_OFFERS, value: val });
  };
};

const resetSearchText = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SEARCH_TEXT_RESET });
  };
};

const resetOffersSearchText = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SEARCH_OFFER_TEXT_RESET });
  };
};

export {
  searchChangeHandler,
  resetSearchText,
  searchOffers,
  resetOffersSearchText,
};
