import React, { Component } from "react";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { getStorageItem } from "../../../../common/localStorage";
import CircularProgress from "@material-ui/core/CircularProgress";
import Search from "../../../../components/Search/Search";
import { styles } from "./RedeemProductListStyles";
import ProductListViewCard from "../../../../components/ProductListViewCard/ProductListViewCard";
import NoRecordFound from "../../../../components/NoRecordFound/NoRecordFound";
import RedeemSubHeader from "../../../../components/RedeemSubHeader/RedeemSubHeader";
import { globalBackground } from "../../../../components/UI/Theme";
class RedeemProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      details: {},
      pointsDynamicName: "",
    };
  }

  viewProductDetails = async (details) => {
    this.props.history.push({
      pathname: "/product/" + details.id,
      state: {
        details: details,
      },
    });
  };

  componentDidMount = () => {
    this.setState({
      pointsDynamicName: getStorageItem("pointShortName"),
    });
  };

  convertDate = (expiryDate) => {
    const moment = require("moment");
    const today = moment(expiryDate);
    return today.format("Do MMM'YY");
  };

  getItemPrice = (specialPrice, startDate, endDate, sellingPrice) => {
    const moment = require("moment");
    const currentDate = moment().format("YYYY-MM-DD");
    const { classes } = this.props;
    if (specialPrice === "0.0000") {
      return [
        {
          classKey: classes.two,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
        },
      ];
    } else if (endDate && endDate === currentDate) {
      return [
        {
          classKey: classes.two,
          value:
            parseFloat(specialPrice) % 1 !== 0
              ? parseFloat(specialPrice).toFixed(2)
              : Math.floor(specialPrice),
        },
        {
          classKey: classes.one,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
        },
      ];
    } else if (startDate && startDate === currentDate) {
      return [
        {
          classKey: classes.two,
          value:
            parseFloat(specialPrice) % 1 !== 0
              ? parseFloat(specialPrice).toFixed(2)
              : Math.floor(specialPrice),
        },
        {
          classKey: classes.one,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
        },
      ];
    } else if (moment(currentDate).isBetween(startDate, endDate)) {
      return [
        {
          classKey: classes.two,
          value:
            parseFloat(specialPrice) % 1 !== 0
              ? parseFloat(specialPrice).toFixed(2)
              : Math.floor(specialPrice),
        },
        {
          classKey: classes.one,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
        },
      ];
    } else {
      return [
        {
          classKey: classes.two,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
        },
      ];
    }
  };

  render() {
    const { classes, isFilterDrawerOpen } = this.props;
    return (
      <div
        className={classnames(classes.redeemCnt, {
          [classes.ovrflHidden]: isFilterDrawerOpen,
        })}
        style={this.props.view ? {} : { margin: "0px" }}
      >
        {this.props.isLoader && (
          <CircularProgress size={40} className={classes.loaderProgress} />
        )}
        {this.props.view ? (
          <div className={classes.redeemHd}>
            <Typography className={classes.redeemText}>
              Redeem your points
            </Typography>
            <Link className={classes.redeemLinks} to="/redeem">
              View All
            </Link>
          </div>
        ) : (
          ""
        )}
        <div
          style={{
            backgroundColor: globalBackground,
          }}
        >
          {!this.props.view && (
            <RedeemSubHeader
              isSubHeader={this.props.isSubHeader}
              isListView={this.props.isListView}
              isBottomDrawer={this.props.isBottomDrawer}
              sortProducts={this.props.sortProducts}
              merchantDetailsResponse={this.props.merchantDetailsResponse}
              openListView={this.props.openListView}
              openFilterDrawer={this.props.openFilterDrawer}
              openBottomDrawer={this.props.openBottomDrawer}
            />
          )}
          <div
            className={
              this.props.history.location.pathname === "/dashboard"
                ? classes.productListContainer
                : classes.listViewClass
            }
          >
            {!this.props.view && (
              <Search
                onSearchTextChange={this.props.onSearchText}
                searchText={this.props.searchText}
                resetSearch={this.props.resetSearch}
                searchCall={this.props.searchCall}
              />
            )}
            {this.props.view
              ? this.props.list &&
                this.props.list.length > 0 &&
                this.props.list
                  .slice(0, 5)
                  .map((elem) => (
                    <ProductListViewCard
                      key={elem.temp_id}
                      elem={elem}
                      viewProductDetails={this.viewProductDetails}
                      getItemPrice={this.getItemPrice}
                      pointsDynamicName={this.state.pointsDynamicName}
                      convertDate={this.convertDate}
                      toggleRelatedProducts={this.props.toggleRelatedProducts}
                      relatedProds={true}
                    />
                  ))
              : this.props.list &&
                this.props.list.length > 0 &&
                this.props.list.map((elem) => (
                  <ProductListViewCard
                    key={elem.temp_id}
                    elem={elem}
                    viewProductDetails={this.viewProductDetails}
                    getItemPrice={this.getItemPrice}
                    pointsDynamicName={this.state.pointsDynamicName}
                    convertDate={this.convertDate}
                    toggleRelatedProducts={this.props.toggleRelatedProducts}
                    relatedProds={true}
                  />
                ))}
            {this.props.noRecords === "No record found" && (
              <NoRecordFound message={"No products found"} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(RedeemProductList)
);

RedeemProductList.defaultProps = {
  view: false,
  isLoader: false,
};
