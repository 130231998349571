import CMBGicon from '../../../../assets/icons/comingSoon/coming-soon-bg.svg';

export const styles = (theme) => {
	return {
		root: {
			backgroundColor: theme.palette.common.globalBackground,
			height: '100%',
			display: 'block',
			textAlign: 'center',
			overflowY: 'auto',
			padding: '30px',
			position: 'relative',
			[theme.breakpoints.down('610')]: {
				padding: '28px'
			},
			[theme.breakpoints.down('481')]: {
				padding: '21px'
			},
			[theme.breakpoints.down('381')]: {
				padding: '15px'
			},
			[theme.breakpoints.down('321')]: {
				padding: '13px'
			}
		},
		mainContainer: {
			height: 'calc(100% - 92px)',
			[theme.breakpoints.down(641)]: {
				height: 'calc(100% - 80px)'
			},
			[theme.breakpoints.down(481)]: {
				height: 'calc(100% - 54px)'
			},
			[theme.breakpoints.down(381)]: {
				height: 'calc(100% - 46px)'
			},
			[theme.breakpoints.down(321)]: {
				height: 'calc(100% - 40px)'
			}
		},
		doneBtn: {
			...theme.palette.typography.doneButtons,
			width: '100%',
			height: '92px',
			fontSize: '22px',
			marginBottom: '0 !important',
			[theme.breakpoints.down(641)]: {
				height: '80px',
				fontSize: '16px'
			},
			[theme.breakpoints.down(481)]: {
				height: '54px',
				fontSize: '14px'
			},
			[theme.breakpoints.down(421)]: {
				fontSize: '13.5px'
			},
			[theme.breakpoints.down(401)]: {
				fontSize: '13px'
			},
			[theme.breakpoints.down(381)]: {
				height: '46px',
				fontSize: '12.5px'
			},
			[theme.breakpoints.down(361)]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down(321)]: {
				height: '40px',
				fontSize: '10px'
			}
		},
		bgCss: {
			background: `url(.${CMBGicon}) no-repeat center / contain`,
			display: 'flex',
			justifyContent: 'center',
			width: '100%',
			height: '25vh',
			overflow: 'hidden',
			alignItems: 'center'
		},
		green_tick: {
			height: '90px',
			width: '90px',
			[theme.breakpoints.down(641)]: {
				height: '80px',
				width: '80px'
			},
			[theme.breakpoints.down(481)]: {
				height: '70px',
				width: '70px'
			},
			[theme.breakpoints.down(421)]: {
				height: '53px',
				width: '53px'
			},
			[theme.breakpoints.down(381)]: {
				height: '47px',
				width: '47px'
			},
			[theme.breakpoints.down(361)]: {
				height: '45px',
				width: '45px'
			},
			[theme.breakpoints.down(321)]: {
				height: '40px',
				width: '40px'
			}
		},
		main_title: {
			fontSize: '32px',
			fontWeight: 'bold',
			textAlign: 'center',
			color: theme.palette.typography.texts.color,
			marginBottom: '20px',
			[theme.breakpoints.down(641)]: {
				fontSize: '28px'
			},
			[theme.breakpoints.down(481)]: {
				fontSize: '21px'
			},
			[theme.breakpoints.down(421)]: {
				fontSize: '18px'
			},
			[theme.breakpoints.down(381)]: {
				fontSize: '17px'
			},
			[theme.breakpoints.down(321)]: {
				fontSize: '14px'
			}
		},
		secondary_title: {
			fontSize: '22px',
			color: theme.palette.typography.texts.subHeading,
			[theme.breakpoints.down(641)]: {
				fontSize: '20px'
			},
			[theme.breakpoints.down(481)]: {
				fontSize: '15px'
			},
			[theme.breakpoints.down(421)]: {
				fontSize: '13px'
			},
			[theme.breakpoints.down(381)]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down(321)]: {
				fontSize: '10px'
			}
		}
	};
};
