import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MyCartItem from "../../../../components/MyCartItem/MyCartItem";
import { stockAPI } from "../../../../common/axiosInstance";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import { apiData } from "../../../../common/common-types";
import { getStorageItem } from "../../../../common/localStorage";
import CartIsEmpty from "../../../../components/EmptyCart/CartIsEmpty";
import { setFromOrderFlag } from "../../../../containers/Screens/Redeem/redux/action";

const styles = (theme) => {
  return {
    container: {
      padding: "30px",
      [theme.breakpoints.down("641")]: {
        padding: "28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
      backgroundColor: theme.palette.common.globalBackground,
      height: "100%",
      display: "block",
      overflow: "hidden",
      textAlign: "center",
      overflowY: "auto",
      margin: "0 auto",
    },
    buttons: {
      margin: "0 auto",
      marginTop: "7rem",
      padding: "0",
      display: "block",
      width: "660px",
      textAlign: "center",
      fontSize: "22px",
      color: "#ffffff",
      borderRadius: "6px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        marginTop: "15px",
        // width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "19.5px",
        width: "100%",
        marginTop: "15px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.between(559, 610)]: {
        width: "100%",
      },
    },
    dialogClass: {
      width: "100%",
      textAlign: "center",
      alignItems: "center",
    },
    okButton: {
      width: "150px",
      height: "40px",
      backgroundColor: theme.palette.typography.doneButtons.backgroundColor,
      color: "white",
    },
    cancelButton: {
      width: "150px",
      height: "40px",
      backgroundColor: theme.palette.typography.cancelButtons.backgroundColor,
      color: "black",
    },
    totalRedeem: {
      marginTop: "20px",
      margin: "0 auto",
      userSelect: "none",
      padding: "0px",
      display: "block",
      width: "660px",
      borderRadius: "6px",
      overflow: "hidden",
      boxShadow: "0px 0px 10px #e9e9e9",
      position: "relative",
      backgroundColor: theme.palette.cards.backgroundColor,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        margin: "0px 0px",
        height: "2.125rem",
      },
      [theme.breakpoints.down("sm")]: {
        // width: "100%",
      },
      [theme.breakpoints.between(559, 610)]: {
        width: "100%",
      },
    },
    one: {
      margin: "0px",
      padding: "1rem",
      display: "block",
      overflow: "hidden", //desktop
      [theme.breakpoints.down("xs")]: {
        padding: "0.5rem",
      },
    },
    textTotal: {
      margin: "0px",
      padding: "0px",
      color: theme.palette.typography.texts.color,
      fontSize: "18px",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
      },
    },
    textAmount: {
      margin: "0px",
      padding: "0px",
      display: "inline-block",
      color: theme.palette.typography.texts.color,
      fontWeight: "bold",
      textAlign: "right",
      fontSize: "18px",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
      },
    },
    oops: {
      margin: "0 auto",
      marginTop: "30px",
      userSelect: "none",
      padding: "25px 0",
      display: "block",
      width: "660px",
      fontFamily: "Montserrat",
      border: "1px solid #dc3939",
      textAlign: "center",
      color: "#dc3939",
      borderRadius: "6px",
      [theme.breakpoints.down("sm")]: {
        padding: "0.5rem",
        width: "100%",
      },
      fontSize: "18px",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        padding: "0.5rem",
        width: "100%",
        marginTop: "15px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.between(760, 770)]: {
        width: "90%",
      },
      [theme.breakpoints.between(799, 810)]: {
        width: "86%",
      },
    },
    redeem: {
      float: "left",
      maxWidth: "100%",
    },
    pointsRedeem: {
      float: "right",
      [theme.breakpoints.down("xs")]: {
        marginTop: "-2px",
      },
    },
    buttonsColor: {
      ...theme.palette.typography.doneButtons,
      fontFamily: "Montserrat",
      height: "92px",
      width: "100%",
      fontSize: "18px",
      marginBottom: "0",
      color: "#ffffff!important",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        height: "80px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        height: "54px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        height: "46px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        height: "40px",
      },
    },
    balanceAfter: {
      padding: "5px 10px 25px",
      fontSize: "16px",
      fontFamily: "Montserrat !important",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
      },
    },
    confirmHeading: {
      fontFamily: "Montserrat !important",
      fontSize: "20px",
      marginBottom: "13px",
      fontWeight: "500",
      [theme.breakpoints.down("641")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
    },
    confirmHeading1: {
      fontFamily: "Montserrat !important",
      fontSize: "20px",
      marginBottom: "5px",
      fontWeight: "500",
      [theme.breakpoints.down("641")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
    },
    dialogModal: {
      "& .MuiDialog-paperWidthMd": {
        width: "100%",
      },
      "& .MuiDialog-paper": {
        margin: "15px",
      },
    },
    purchase: {
      fontSize: "16px",
      [theme.breakpoints.down("641")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "9.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "9px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    myCartitem: {
      margin: "0 auto",
      // paddingTop: theme.spacing(2),
      padding: "0px",
      display: "block",
      width: "660px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      [theme.breakpoints.down(650)]: {
        width: "100%",
      },
    },
  };
};

const mapDispatchToProps = {
  setFromOrderFlag,
};

class MyCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: {},
      selectedQuantity: 1,
      modalOpen: false,
      addition: 0,
      arr: [],
      showPurchaseButton: false,
      arrayAfterDelete: [],
      disabled: false,
    };
  }

  totalpointsBefore = async (listArray) => {
    if (listArray.length > 0) {
      let pointsTotal = 0;
      listArray.forEach((element) => {
        pointsTotal += parseFloat(element.best_price * element.qty);
      });
      await this.setState({
        addition:
          parseFloat(pointsTotal) % 1 !== 0
            ? pointsTotal.toFixed(2)
            : pointsTotal,
      });
    }
  };

  calculatedPoints1 = (quantity, value, flag) => {
    let multiply = parseFloat(value) * quantity;
    multiply = multiply.toFixed(2);
    if (flag === "less") {
      this.setState((prevState) => ({
        addition:
          parseFloat(parseFloat(prevState.addition) - parseFloat(multiply)) %
            1 !==
            0
            ? (parseFloat(prevState.addition) - parseFloat(multiply)).toFixed(2)
            : Math.floor(parseFloat(prevState.addition) - parseFloat(multiply)),
      }));
    } else if (flag === "more") {
      this.setState((prevState) => ({
        addition:
          parseFloat(parseFloat(prevState.addition) + parseFloat(multiply)) %
            1 !==
            0
            ? (parseFloat(prevState.addition) + parseFloat(multiply)).toFixed(2)
            : Math.floor(parseFloat(prevState.addition) + parseFloat(multiply)),
      }));
    } else {
      this.setState({
        addition:
          parseFloat(multiply) % 1 !== 0
            ? parseFloat(multiply)
            : Math.floor(multiply),
      });
    }
  };

  handleModalOpen = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  handleModalOpenCancel = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  handleModalOpenOk = () => {
    const { history } = this.props;
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
    let placeOrderArr = [...this.props.finalCartArray];
    let orderArr = [];
    placeOrderArr.map((product) => {
      orderArr.push({
        product_merchant_id: product.product_merchant_id,
        qty: product.qty,
        location_id: product.location_id,
        best_price: product.best_price,
        calculated_points: product.calculated_points,
      });
    });
    if (getStorageItem("user")) {
      const data = new URLSearchParams();
      data.append("customer_id", JSON.parse(getStorageItem("user")).id);
      data.append("merchant_id", apiData.merchant_id);
      data.append("platform", apiData.platform);
      data.append("order_details", JSON.stringify(orderArr));
      stockAPI(data, "POST", "/placeOrder", null, null, undefined, true)
        .then(async (response) => {
          if (response.response && response.statusCode === 100) {
            let finalCartArray = [];
            const bodyFormData = new URLSearchParams();
            bodyFormData.append(
              "customer_id",
              JSON.parse(getStorageItem("user")).id
            );
            bodyFormData.append("merchant_id", apiData.merchant_id);
            bodyFormData.append("platform", apiData.platform);
            bodyFormData.append("cart_details", JSON.stringify(finalCartArray));
            this.props.addCart(finalCartArray);
            this.props.addToCart(bodyFormData);
            this.props.setFromOrderFlag(true);
            const cartCountFormData = new URLSearchParams();
            cartCountFormData.append(
              "customer_id",
              JSON.parse(getStorageItem("user")).id
            );
            cartCountFormData.append("merchant_id", apiData.merchant_id);
            this.props.getCartItemCount(cartCountFormData);

            history.push({
              pathname: "/order-summary",
              state: {
                id: response.response.orderId,
              },
            });
          } else {
            openAlertBox(response.statusDescription, "error");
          }
        })
        .catch((err) => {
          openAlertBox("error while placing order", "error");
        });
    } else {
      this.props.logoutUser();
    }
  };

  componentDidMount = async (props) => {
    const { showLoader } = this.props;
    showLoader(true);
    if (getStorageItem("user")) {
      const bodyFormData = new URLSearchParams();
      bodyFormData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      bodyFormData.append("merchant_id", apiData.merchant_id);
      await this.props.getCartList(bodyFormData);
      // here after cartList is called we need to set finalCartArray (addCart())
      this.props.cartList &&
        this.props.cartList.cart_details &&
        this.props.addCart(this.props.cartList.cart_details);
      this.totalpointsBefore(
        this.props.cartList.cart_details ? this.props.cartList.cart_details : []
      );
      showLoader(false);
    } else {
      this.props.logoutUser();
    }
  };

  quantityOptions = (elem) => {
    var arr = [];
    for (let i = elem.min_quantity; i <= elem.max_quantity; i++) {
      arr.push(
        <option key={elem.index} value={i}>
          {i}
        </option>
      );
    }
    return arr;
  };

  deleteItem = async (selected) => {
    const { showLoader } = this.props;
    showLoader(true);
    //get finalCartArray to perfomr operation on it
    let arrayAfterDelete = [...this.props.finalCartArray];
    // here filter returns the new updated array by deleting the selected ele
    let updated_arrayAfterDelete = arrayAfterDelete.filter(
      (arrItem) => arrItem.product_merchant_id !== selected.product_merchant_id
    );
    if (getStorageItem("user")) {
      const bodyFormData = new URLSearchParams();
      bodyFormData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      bodyFormData.append("merchant_id", apiData.merchant_id);
      bodyFormData.append("platform", apiData.platform);
      bodyFormData.append(
        "cart_details",
        JSON.stringify(updated_arrayAfterDelete)
      );
      this.props.addCart(updated_arrayAfterDelete);
      await this.props.clearCartList();
      await this.props.addToCart(bodyFormData);

      const cartCountFormData = new URLSearchParams();
      cartCountFormData.append(
        "customer_id",
        JSON.parse(getStorageItem("user")).id
      );
      cartCountFormData.append("merchant_id", apiData.merchant_id);
      await this.props.getCartItemCount(cartCountFormData);

      const formData = new URLSearchParams();
      formData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      formData.append("merchant_id", apiData.merchant_id);
      await this.props.getCartList(formData);

      this.totalpointsBefore(
        this.props.cartList.cart_details ? this.props.cartList.cart_details : []
      );
      showLoader(false);
    } else {
      this.props.logoutUser();
    }
  };

  componentWillUnmount() {
    const { clearCartList } = this.props;
    clearCartList();
    this.setState({ cartlist: [], addition: 0 });
  }

  render() {
    const { classes, history } = this.props;
    const isWarningDisabled =
      Math.floor(
        parseInt(
          this.props.merchantDetailsResponse &&
          this.props.merchantDetailsResponse.current_points_balance
        )
      ) < this.state.addition;
    const isPurchase =
      this.props.cartList.length === 0 ||
      (this.props.cartList &&
        this.props.cartList.cart_details &&
        this.props.cartList.cart_details.length <= 0);

    const isShowPurchaseButton =
      this.props.merchantDetailsResponse &&
      !this.props.merchantDetailsResponse.redeem_error &&
      this.state.addition !== 0 &&
      this.state.addition <=
      Math.floor(
        parseInt(
          this.props.merchantDetailsResponse &&
          this.props.merchantDetailsResponse.current_points_balance
        )
      );

    if (isPurchase) {
      return <CartIsEmpty history={history} />;
    }
    if (this.props.cartList && this.props.cartList.length <= 0) {
      return <Grid container className={classes.container}></Grid>;
    }
    return (
      <Grid container className={classes.container}>
        <Grid className={classes.myCartitem}>
          {this.props.cartList &&
            this.props.cartList.cart_details &&
            this.props.cartList.cart_details.map((elem, index) => (
              <MyCartItem
                elem={elem}
                index={index}
                key={elem.product_merchant_id}
                finalCartArray={this.props.finalCartArray}
                addCart={this.props.addCart}
                addToCart={this.props.addToCart}
                calculatedPoints1={this.calculatedPoints1}
                quantityOptions={this.quantityOptions}
                deleteItem={this.deleteItem}
                getCartList={this.props.getCartList}
                getCartItemCount={this.props.getCartItemCount}
                logoutUser={this.props.logoutUser}
              />
            ))}
        </Grid>

        {this.state.addition > 0 && (
          <Grid className={classes.totalRedeem}>
            <Grid item container className={classes.one}>
              <Grid item xs={6} className={classes.redeem}>
                <Typography className={classes.textTotal}>
                  Total Points to Redeem
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.pointsRedeem}>
                <Typography className={classes.textAmount}>
                  {this.state.addition ? this.state.addition : 0}
                  {getStorageItem("pointShortName")
                    ? " " + getStorageItem("pointShortName")
                    : " P"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isShowPurchaseButton ? (
          <Grid className={classes.buttons}>
            <Button
              className={classes.buttonsColor}
              variant="contained"
              color="primary"
              disabled={isPurchase}
              onClick={this.handleModalOpen}
            >
              CONFIRM PURCHASE
            </Button>

            <Dialog
              className={classes.dialogClass + " " + classes.dialogModal}
              open={this.state.modalOpen}
              onClose={this.handleModalOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
                 {this.props.merchantDetailsResponse && this.props.merchantDetailsResponse.auto_reject_or_cancel_order
                !== "0"?
                <h5 className={classes.confirmHeading1}>
                  {this.props.merchantDetailsResponse && this.props.merchantDetailsResponse.auto_reject_or_cancel_order_message}
                </h5>
                : null}

              <h5 className={classes.confirmHeading}>
                {"Confirm Purchase for"}{" "}
                {this.state.addition ? this.state.addition + " " : 0 + " "}
                {getStorageItem("pointShortName")
                  ? getStorageItem("pointShortName")
                  : "P"}
                {"?"}
              </h5>
              <DialogActions
                style={{ marginTop: "0", justifyContent: "center" }}
              >
                <Button
                  onClick={this.handleModalOpenOk}
                  className={classes.okButton}
                  color="primary"
                >
                  OK
                </Button>
                <Button
                  onClick={this.handleModalOpenCancel}
                  className={classes.cancelButton}
                  color="primary"
                  autoFocus
                >
                  CANCEL
                </Button>
              </DialogActions>
              <DialogContent className={classes.balanceAfter}>

                <span className={classes.purchase}>
                  {" "}
                  Balance After This Purchase:{" "}
                  {this.props.merchantDetailsResponse &&
                    parseFloat(
                      (parseFloat(
                        this.props.merchantDetailsResponse.current_points_balance
                      ) %
                        1 !==
                        0
                        ? parseFloat(
                          this.props.merchantDetailsResponse
                            .current_points_balance
                        ).toFixed(2)
                        : Math.floor(
                          this.props.merchantDetailsResponse
                            .current_points_balance
                        )) - parseFloat(this.state.addition)
                    ) %
                    1 !==
                    0
                    ? (
                      (parseFloat(
                        this.props.merchantDetailsResponse
                          .current_points_balance
                      ) %
                        1 !==
                        0
                        ? parseFloat(
                          this.props.merchantDetailsResponse
                            .current_points_balance
                        ).toFixed(2)
                        : Math.floor(
                          this.props.merchantDetailsResponse
                            .current_points_balance
                        )) - parseFloat(this.state.addition)
                    ).toFixed(2)
                    : Math.floor(
                      (parseFloat(
                        this.props.merchantDetailsResponse
                          .current_points_balance
                      ) %
                        1 !==
                        0
                        ? parseFloat(
                          this.props.merchantDetailsResponse
                            .current_points_balance
                        ).toFixed(2)
                        : Math.floor(
                          this.props.merchantDetailsResponse
                            .current_points_balance
                        )) - parseFloat(this.state.addition)
                    )}
                  {getStorageItem("pointShortName")
                    ? " " + getStorageItem("pointShortName")
                    : " P"}
                </span>
              </DialogContent>
            </Dialog>
          </Grid>
        ) : this.props.merchantDetailsResponse &&
          this.props.merchantDetailsResponse.redeem_error ? (
          <Grid className={classes.oops}>
            {this.props.merchantDetailsResponse &&
              this.props.merchantDetailsResponse.redeem_error}
          </Grid>
        ) : isWarningDisabled ? (
          <Grid className={classes.oops}>
            Oops! You do not have sufficient points to redeem.
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })(MyCart)));
