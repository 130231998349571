import * as React from "react";
import { Slider, Handles, Tracks } from "react-compound-slider";
import Track from "./component";
import { withStyles } from "@material-ui/core";
import Handle from "./ToolTip";
import { borderGrey } from "../UI/Theme";
import { styles } from "./SliderStyles";

const sliderStyle = {
  position: "relative",
  width: "80%",
  margin: "20% 10%"
};

class SilderWrapper extends React.Component {
  state = {
  };

  // onUpdate = (update) => {
  //   this.setState({ values: update });
  // };

  // onChange = (values) => {
  //   this.setState({ values });
  // };

  render() {
    const { min, max, value, onChange, unitSide = "left", unit = "" } = this.props;
    const { classes, theme } = this.props;
    const railCenterStyle = {
      position: "absolute",
      width: "100%",
      height: window.innerWidth > 600 ? 12 : 5,
      borderRadius: 4,
      cursor: "pointer",
      pointerEvents: "none",
      backgroundColor: borderGrey,
    };

    return (
      <div className={classes.container} style={{ width: "100%" }}>
        <Slider
          mode={1}
          step={1}
          domain={[min, max]}
          rootStyle={sliderStyle}
          onUpdate={(e) => onChange(null, e)}
          onChange={(e) => {
            onChange(null, e);
          }}
          values={value}
          className={classes.sliderWrapper}
        >
          <div style={railCenterStyle} />
          <Handles>
            {({ handles, activeHandleID, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={[min, max]}
                    isActive={handle.id === activeHandleID}
                    getHandleProps={getHandleProps}
                    unitSide={unitSide}
                    unit={unit}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
        <div className={classes.labels}>
          <div style={{ float: "left" }}>
          {unitSide === "left" ? unit ? `${unit} ${min}` : min : unit ? `${min} ${unit}` : min}
          </div>
          <div style={{ float: "right" }}>
          {unitSide === "left" ? unit ? `${unit} ${max}` : max : unit ? `${max} ${unit}` : max}
          </div>
          <div style={{ clear: "both" }}/>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SilderWrapper);
