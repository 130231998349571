import React from "react";
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import { getStorageItem } from "../../common/localStorage";

const styles = (theme) => {
  return {
    bill_date_amount: {
      display: "block",
      width: "100%",
      background: "#ececec",
    },
    box: {
      padding: theme.spacing(2),
      display: "inline-block",
      width: "33%",
      borderRight: "1px solid #d8d8d8",
      fontSize: "18px",
      fontWeight: "normal",
      color: theme.palette.typography.texts.color,
      textAlign: "center",
      overflow: 'hidden',
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        padding: theme.spacing(1),
      },
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        padding: "8px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
      "& p": {
        margin: "0px",
        padding: "5px 0px",
      },
      "& span": {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontWeight: 'bold',
      },
    },
    last: {
      borderRight: "0px",
    },
  };
};

const Details = (props) => {
  const { classes } = props;
  const { billNumber, billDate, billAmount } = props;
  const currencySymbol = getStorageItem("currencyCode")
    ? getStorageItem("currencyCode")
    : "₹";
  return (
    <div className={classes.bill_date_amount}>
      <div className={classes.box}>
        <p>Bill Number</p>
        <span>{billNumber ? billNumber.length > 12 ? billNumber.substring(0, 12) + '...' : billNumber : "-"}</span>
        {/* <span>{billNumber}</span> */}
      </div>
      <div className={classes.box}>
        <p>Bill Date</p>
        <b>
          {billDate
            ? moment(billDate, "YYYY-MM-DD").format("DD / MM / YYYY")
            : "-"}
        </b>
      </div>
      <div className={classes.box + " " + classes.last}>
        <p>Bill Amount</p>
        <span>{billAmount ? `${currencySymbol} ${billAmount}` : "-"}</span>
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Details);
