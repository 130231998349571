import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { primaryOrange, whiteColor } from "../../../../../components/UI/Theme";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = (theme) => {
  return {
    dots: {
      position: "absolute",
      bottom: "20px",
      right: "30px",
      top: '320px',
      [theme.breakpoints.down(641)]: {
        bottom: "20px",
        right: "30px",
        top: "unset",
      },
      [theme.breakpoints.down("xs")]: {
        // bottom: "10px",
        top: "unset",
        right: "15px",
      },
    },
    topImageDiv: {
      margin: "0",
      padding: "0",
      display: "block",
      height: "431px",
      textAlign: "center",
      position: "relative",
      overflow: "hidden",
      [theme.breakpoints.down(641)]: {
        height: "324px",
      },
      [theme.breakpoints.down(481)]: {
        height: "223px",
      },
      [theme.breakpoints.down(400)]: {
        height: "203px",
      },
      [theme.breakpoints.down(370)]: {
        height: "194px",
      },
      [theme.breakpoints.down(321)]: {
        height: "172px",
      },
    },
    img: {
      width: "100%",
      height: "360px",
      objectFit: "contain",
      objectPosition: "center",
      [theme.breakpoints.down(641)]: {
        height: "324px",
      },
      [theme.breakpoints.down(481)]: {
        height: "223px",
      },
      [theme.breakpoints.down(400)]: {
        height: "203px",
      },
      [theme.breakpoints.down(370)]: {
        height: "194px",
      },
      [theme.breakpoints.down(321)]: {
        height: "172px",
      },
      "& img": {
        maxWidth: "100%",
        height: "100%",
      },
    },
  };
};

class ImageCarousal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeImageIndex: 0,
      interval: 4000,
    };
  }

  next = (next) => {
    this.setState({
      activeImageIndex: next,
    });
  };

  render() {
    const { activeImageIndex } = this.state;
    const { classes, alt, className, dotsCss } = this.props;

    return (
      <div className="col-md-12">
        <div className={className ? className : classes.topImageDiv}>
          <AutoPlaySwipeableViews
            index={activeImageIndex}
            onChangeIndex={this.next}
            enableMouseEvents
          >
            {
              // this.props.imagesArray &&
              // this.props.imagesArray.length > 0 &&
              this.props.imagesArray.map((step, index) => (
                <div key={step}>
                  {Math.abs(activeImageIndex - index) <= 4 ? (
                    <img
                      className={className ? className : classes.img}
                      src={step}
                      alt={alt}
                    />
                  ) : null}
                </div>
              ))
            }
          </AutoPlaySwipeableViews>
          <div className={dotsCss ? dotsCss : classes.dots}>
            {
              // this.props.imagesArray &&
              // this.props.imagesArray.length > 0 &&
              this.props.imagesArray.length > 1 && this.props.imagesArray.map((item, i) => {
                return (
                  <FiberManualRecordIcon
                    key={i}
                    style={{
                      fontSize: 10,
                      color:
                        activeImageIndex === i ? primaryOrange : whiteColor,
                    }}
                  />
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(ImageCarousal)
);
ImageCarousal.defaultProps = {
  imagesArray: [],
};

ImageCarousal.propTypes = {
  imagesArray: PropTypes.array,
};
