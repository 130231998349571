export const styles = (theme) => {
  return {
    main_root: {
      width: "100%",
    },
    root: {
      maxWidth: "600px",
      margin: "0 auto",
    },
    root1: {
      maxWidth: "600px",
      margin: "0 auto",
      padding: "0 30px 30px",
      [theme.breakpoints.down("610")]: {
        padding: "0 28px 28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "0 21px 21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "0 15px 15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "0 13px 13px",
      },
    },
    icon_label_container: {
      padding: "15px",
    },
    icon_container: {
      margin: "0 auto 30px",
      display: "flex",
      justifyContent: "center",

      [theme.breakpoints.down("610")]: {
        margin: "0 auto 28px",
        height: "324px",
      },
      [theme.breakpoints.down("481")]: {
        margin: "0 auto 21px",
        height: "223px",
      },
      [theme.breakpoints.down(400)]: {
        height: "203px",
      },
      [theme.breakpoints.down("381")]: {
        margin: "0 auto 15px",
        height: "194px",
      },
      [theme.breakpoints.down("321")]: {
        margin: "0 auto 13px",
        height: "172px",
      },
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        objectPosition: "center",
      },
    },
    notification_label: {
      textAlign: "center",
      fontWeight: 500,
      color: "#211f20",
      fontSize: "30px",
      [theme.breakpoints.down(481)]: {
        fontSize: "24px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "16px",
      },
    },
    notification_content: {
      marginBottom: "15px",
    },
    common_font: {
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    content: {
      color: "#211f20",
      lineHeight: 1.67,
      fontWeight: 500,
      fontSize: "18px",
      [theme.breakpoints.down(481)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "12px",
      },
    },
    time: {
      color: "#7e7e7e",
      fontSize: "16px",
      [theme.breakpoints.down(481)]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
      },
    },
  };
};
