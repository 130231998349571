import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import ShowInfo from "../../../../components/Showinfo/ShowInfo";
import Header from "../../../../components/Layout/Header/HeaderSubmitBill";
import { headerScreenLabels, apiData } from "../../../../common/common-types";
import MyRoute from "../../../../hoc/MyRoute";
import AlertMessage, {
  openAlertBox,
} from "../../../../common/AlertMessage/AlertMessage";
import { getStorageItem } from "../../../../common/localStorage";
import {
  changeActiveTab,
  logoutUser,
  showLoader,
} from "../../../../../src/App/redux/action";
import { stockAPI } from "../../../../common/axiosInstance";

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      howitworks: [],
    };
  }
  async componentDidMount() {
    this.props.changeActiveTab(4);
    if (getStorageItem("user")) {
      //this.props.showLoader(true);
      let url =
        "/getStaticWebPage?platform=" +
        apiData.platform +
        "&merchant_id=" +
        apiData.merchant_id +
        "&page_type=" +
        301;
      await stockAPI({}, "GET", url, null, null, undefined, true).then(
        (res) => {
          // this.props.showLoader(false);
              // this.props.showLoader(false);
          if (res.statusCode === 100 && res.response) {
            this.setState({ howitworks: res.response });
          } else {
            openAlertBox("Oops something went wrong!", "error");
          }
        }
      );
    } else {
      this.props.logoutUser();
    }
  }
  render() {
    const { howitworks } = this.state;
    return (
      <Fragment>
        <Header screenLabel={headerScreenLabels.howitworks} />
        <AlertMessage />
        <MyRoute fullHeight={true} billUploadStatus={this.props.uploadingStatus}>
          <ShowInfo data={howitworks} />
        </MyRoute>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
	return {
		uploadingStatus: state.reducerBillUploadProgress.uploadingStatus
	};
};

const mapDispatchToProps = {
  changeActiveTab,
  logoutUser,
  showLoader,
};
export default connect(mapStateToProps, mapDispatchToProps)(HowItWorks);
