import React, { Component } from "react";
import NotificationCard from "../../../../components/NotificationCard/NotificationCard";
import { withStyles } from "@material-ui/styles";
import { styles } from "../styles";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import NoRecordFound from "../../../../components/NoRecordFound/NoRecordFound";
import { connect } from "react-redux";
import { getStorageItem } from "../../../../common/localStorage";
import { apiData } from "../../../../common/common-types";
import { logoutUser } from "../../../../App/redux/action";
import { setNotificationTapped } from "../redux/actions";

class NotificationListing extends Component {
  handleClicked = async (unique_id, notification) => {
    const params = new URLSearchParams();
    params.append("customer_id", JSON.parse(getStorageItem("user")).id);
    params.append("merchant_id", apiData.merchant_id);
    params.append("unique_id", unique_id);
    params.append("notification_type", 0);
    params.append("platform", apiData.platform);

    if (notification.tap_status === "0") {
      params.append("inAppTap", 0);
    } else {
      params.append("inAppTap", 1);
    }

    this.props.setNotificationTapped(params);

    if (notification.deep_link_url === "") {
      this.props.history.push("/notifications/" + unique_id);
    } else {
      this.props.history.push(notification.deep_link_url);
    }
  };

  render() {
    const { classes, notificationList } = this.props;

    return (
      <div className={classes.main_root} style={{ marginBottom: "30px" }}>
        <div className={classes.root}>
          <InfiniteScroll
            loadMore={() => {
              if (notificationList.length > 0) {
                this.props.getMoreNotifications(true);
              }
            }}
            hasMore={this.props.hasMore}
            useWindow={true}
          >
            {notificationList.map((notification) => {
              return (
                <NotificationCard
                  key={notification.id}
                  id={notification.id}
                  title={notification.title}
                  body={notification.push_text}
                  date={notification.created_at}
                  read={notification.tap_status === "0"}
                  uniqueId={notification.communication_msg_id}
                  notification={notification}
                  notification_image={notification.notification_image}
                  handleNotificationClicked={this.handleClicked}
                  success={true}
                  handleDeleteNotification={this.props.handleDeleteNotification}
                />
              );
            })}
          </InfiniteScroll>
          {this.props.noRecords && this.props.datarcv ? (
            <NoRecordFound message={"No notifications found"} />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  logoutUser,
  setNotificationTapped,
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })(NotificationListing)));
