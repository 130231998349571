import React from "react";
import { styles } from "./ProductListViewCardStyles.js";
import { withStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import default_product from "../../assets/images/product/default_product.svg";

const ProductListViewCard = (props) => {
  const { classes } = props;
  const {
    elem,
    viewProductDetails,
    getItemPrice,
    pointsDynamicName,
    convertDate,
    toggleRelatedProducts,
  } = props;

  return (
    <div className={classes.root + " " + classes.noSelect}>
      <div
        className={classes.rootChild}
        onClick={() => viewProductDetails(elem)}
      >
        <div className={classes.imageContainer}>
          <img
            alt={elem.product_name}
            src={elem.image_name ? elem.image_name : default_product}
          />
        </div>
        <div className={classes.details}>
          <div className={classes.main_content}>
            <Typography className={classes.txbrand}>
              {elem.brand_name}
            </Typography>
            <Typography className={classes.txapparel}>
              {elem.product_name}
            </Typography>
          </div>
          <div className={classes.rightPoints}>
            <Typography
              style={{
                textAlign: "left",
                verticalAlign: "middle",
                lineHeight: "normal",
              }}
            >
              {getItemPrice(
                elem.special_price_points_value,
                elem.special_price_start_date,
                elem.special_price_end_date,
                elem.selling_price_points_value
              ).map((item, index) => {
                return (
                  <span
                    key={index}
                    style={{ textAlign: "right" }}
                    className={item.classKey}
                  >
                    {item.value}{" "}
                    {props.merchantDetailsResponse
                      ? props.merchantDetailsResponse.points_unit_short_name
                      : pointsDynamicName
                        ? pointsDynamicName
                        : "P"}
                  </span>
                );
              })}
            </Typography>
          </div>

          <div className={classes.related_expiry_box}>
            <Typography className={classes.textExpires}>
              {elem.display_custom_text_or_date === "1" &&
                elem.expiry_date &&
                convertDate(elem.expiry_date) !== "Invalid date"
                ? `Expires ${convertDate(elem.expiry_date)}`
                : ""}
              {elem.display_custom_text_or_date === "2" &&
                elem.custom_text &&
                elem.custom_text}
            </Typography>
            {elem.related_products_count === 0 ? null : props.relatedProds ? (
              <div
                onClick={(e) =>
                  toggleRelatedProducts(e, elem.product_id, elem.id)
                }
                className={classes.related_products_box}
              >
                <Typography className={classes.related_products}>
                  {"+ " + elem.related_products_count}
                </Typography>
              </div>
            ) : null}
            <div>
              {elem.location_names &&
                elem.locations && (
                  <Typography className={classes.related_products}>
                    {elem.locations}
                    {elem.location_names.split(",").length - 1 !== 0 &&
                      ` +${elem.location_names.split(",").length - 1}`}
                  </Typography>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ProductListViewCard);
