import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { withStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

function ElevationScroll(props) {
	const { children } = props;

	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0
	});

	return React.cloneElement(children, {
		elevation: trigger ? 4 : 0
	});
}

const styles = (theme) => {
	return {
		toolbarMargin: {
			minHeight: "86px",
			[theme.breakpoints.down("641")]: {
			  minHeight: "82px",
			},
			[theme.breakpoints.down("481")]: {
			  minHeight: "64px",
			},
			[theme.breakpoints.down("421")]: {
			  minHeight: "54px",
			},
			[theme.breakpoints.down("381")]: {
			  minHeight: "50px",
			},
			[theme.breakpoints.down("361")]: {
			  minHeight: '48px'
			},
			[theme.breakpoints.down("321")]: {
			  minHeight: '43px'
			},
		},
		appbar: {
			height: 'auto',
			backgroundColor: theme.palette.primary.main,
		},
		mainHeader: {
			color: theme.palette.typography.texts.headerTextColor,
            fontSize: "20px",
			[theme.breakpoints.down("641")]: {
				fontSize: "18px",
			},
			[theme.breakpoints.down("481")]: {
				fontSize: "16px",
			},
			[theme.breakpoints.down("421")]: {
				fontSize: "15.5px",
			},
			[theme.breakpoints.down("401")]: {
				fontSize: "15px",
			},
			[theme.breakpoints.down("381")]: {
				fontSize: "14.5px",
			},
			[theme.breakpoints.down("361")]: {
				fontSize: "14px",
			},
		},
		toolbar_bill_summary: {
			width: "100%",
      		justifyContent: "center",
		},
		regular: {
			minHeight: "86px",
			padding: "11px 30px 11px 30px",
			[theme.breakpoints.down("641")]: {
			  padding: "9px 26px 9px 26px",
			  minHeight: "82px",
			},
			[theme.breakpoints.down("481")]: {
			  padding: "7px 20px 7px 20px",
			  minHeight: "64px",
			},
			[theme.breakpoints.down("421")]: {
			  minHeight: "54px",
			},
			[theme.breakpoints.down("381")]: {
			  padding: "0px 15px 0px 15px",
			  minHeight: "50px",
			},
			[theme.breakpoints.down("361")]: {
			  minHeight: '48px'
			},
			[theme.breakpoints.down("321")]: {
			  padding: "0px 13px 0px 13px",
			  minHeight: '43px'
			},
		}
	};
};

class Header extends Component {

	render() {
		const { classes } = this.props;

		return (
			<Fragment>
				<ElevationScroll>
					<AppBar position="fixed" className={classes.appbar}>
                        <Toolbar disableGutters className={classes.toolbar_bill_summary} classes={{regular: classes.regular}}>
                            <Typography variant="h3" className={classes.mainHeader}>
                                Transactions
                            </Typography>
                        </Toolbar>
					</AppBar>
				</ElevationScroll>
				<div className={classes.toolbarMargin} />
			</Fragment>
		);
	}
}

export default withRouter(withStyles(styles, { withTheme: true })(Header));
