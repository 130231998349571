import React from "react";
import { withStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import { Button, Typography } from "@material-ui/core";
import BillDetails from "../../../../components/BillDetails/BillDetails";
import { getStorageItem } from "../../../../common/localStorage";
import { primaryOrange, lightGrey } from "../../../../components/UI/Theme";

const styles = (theme) => {
  return {
    root: {
      backgroundColor: theme.palette.common.globalBackground,
      width: "100%",
      height: "100%",
      overflowY: "auto",
      padding: "30px",
      [theme.breakpoints.down("610")]: {
        padding: "28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "13px",
      },
    },
    pageContainer: {
      display: "block",
      margin: "0 auto",
      height: "100%",
      textAlign: "center",
      maxWidth: "600px",
      [theme.breakpoints.down("sm")]: {
        width: "85%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    pageItems: {
      display: "block",
      width: "100%",
    },
    billtotal_Points: {
      boxShadow: "0px 0px 10px #dad4d4",
      borderRadius: "5px",
      marginBottom: theme.spacing(2),
      overflow: "hidden",
    },
    top: {
      backgroundColor: theme.palette.billSummary.top,
    },
    box: {
      padding: theme.spacing(2),
      display: "inline-block",
      width: "33%",
      borderRight: "1px solid #d8d8d8",
      fontSize: "18px",
      fontWeight: "normal",
      color: theme.palette.typography.texts.color,
      verticalAlign: "middle",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
    },
    last: {
      borderRight: 0,
    },
    bottom: {
      margin: "0px",
      padding: "0px",
      width: "100%",
      backgroundColor: theme.palette.cards.backgroundColor,
    },
    bottom_directions: {
      padding: "15px",
      display: "inline-block",
      width: "50%",
      [theme.breakpoints.down(700)]: {
        padding: "10px",
      },
      [theme.breakpoints.down(388)]: {
        padding: "8px",
      },
      [theme.breakpoints.down(377)]: {
        padding: "6px",
      },
      [theme.breakpoints.down(365)]: {
        padding: "12px",
      },
      [theme.breakpoints.down(344)]: {
        padding: "10px",
      },
      [theme.breakpoints.down(335)]: {
        padding: "8px",
      },
      [theme.breakpoints.down(327)]: {
        padding: "6px",
      },
    },
    bottom_directions_font: {
      fontSize: "24",
      [theme.breakpoints.down(641)]: {
        fontSize: "18px",
        lineHeight: "1.45",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
        lineHeight: "1.29",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
        lineHeight: "1.29",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        lineHeight: "1.29",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
        lineHeight: "1.29",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.33",
      },
    },
    bottom_directions_left: {
      textAlign: "left",
      fontWeight: "500",
      color: theme.palette.typography.texts.color,
    },
    bottom_directions_right: {
      textAlign: "right",
      fontWeight: "bold",
    },
    doneBtn: {
      ...theme.palette.typography.doneButtons,
      fontSize: "22px",
      height: "96px",
      lineHeight: "18px",
      [theme.breakpoints.down("sm")]: {
        height: "92px",
        fontSize: "22px",
        lineHeight: "18px",
      },

      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "16px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "14px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
        fontSize: "12.5px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    error_message: {
      color: theme.palette.errorMessage.color,
      fontSize: "18px",
      textAlign: "left",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
      },
    },
  };
};

const BillSummary = (props) => {
  const { classes } = props;
  const {
    billsSubmitted,
    billsProcessed,
    billsPending,
    totalPointsEarned,
    bills,
    userTotalPoints,
    fromTransactions,
  } = props;

  return (
    <Grid className={classes.root}>
      <Grid className={classes.pageContainer}>
        <Grid className={classes.pageItems}>
          <Grid>
            {fromTransactions ? null : (
              <div className={classes.billtotal_Points}>
                <div className={classes.top}>
                  <div className={classes.box}>Submitted: {billsSubmitted}</div>
                  <div className={classes.box}>Processed: {billsProcessed}</div>
                  <div className={classes.box + " " + classes.last}>
                    Pending: {billsPending}
                  </div>
                </div>
                <div className={classes.bottom}>
                  <div className={classes.bottom_directions}>
                    <Typography
                      className={
                        classes.bottom_directions_left +
                        " " +
                        classes.bottom_directions_font
                      }
                    >
                      Total points earned
                    </Typography>
                  </div>
                  <div className={classes.bottom_directions}>
                    <Typography
                      className={
                        classes.bottom_directions_right +
                        " " +
                        classes.bottom_directions_font
                      }
                      style={{
                        color: totalPointsEarned !== 0 ? primaryOrange : lightGrey,
                      }}
                    >
                      {totalPointsEarned !== 0 && totalPointsEarned !== "-"
                        ? `+ 
                        ${parseFloat(totalPointsEarned) % 1 !== 0 ?
                          parseFloat(totalPointsEarned).toFixed(2): 
                          Math.floor(totalPointsEarned)} ${
                            getStorageItem("pointShortName")
                              ? getStorageItem("pointShortName")
                              : "P"
                          }`
                        : "-"}
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </Grid>
          <Grid>
            {bills.length > 0 &&
              bills.map((bill) => {
                return (
                  <React.Fragment key={bill.key}>
                    <BillDetails
                      status={bill.status}
                      billDate={bill.bill_date}
                      billAmount={bill.bill_amount}
                      billNumber={bill.bill_number}
                      location={bill.location_name}
                      brand={bill.brand_name}
                      points={bill.points_earned}
                      billImg={bill.bill_image}
                      isMulti={
                        props.isMultiClickBill || bill.bill_image.length > 1
                      }
                      billName={bill.bill_image_name}
                      openedStatus={bill.detailOpenedStatus}
                      fromTransactions={fromTransactions}
                    />
                    {fromTransactions &&
                      (bill.status === "Rejected" ||
                        bill.status === "Cancelled") &&
                      bill.bill_reason && (
                        <Typography
                          className={classes.error_message}
                        >{`Reason: ${bill.bill_reason}`}</Typography>
                      )}
                  </React.Fragment>
                );
              })}
          </Grid>
        </Grid>
        {fromTransactions ? null : props.isMultiClickBill ||
          userTotalPoints === 0 ? (
          <Grid className={classes.pageItems}>
            <Button
              className={classes.doneBtn}
              disableRipple
              color="primary"
              variant="contained"
              onClick={props.submitAnotherBill}
            >
              SUBMIT ANOTHER BILL
            </Button>
          </Grid>
        ) : (
          <Grid className={classes.pageItems}>
            <Button
              className={classes.doneBtn}
              disableRipple
              color="primary"
              variant="contained"
              onClick={props.redeemBtnHandler}
            >
              REDEEM
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(BillSummary);
