import React, { Component, Fragment } from 'react';
import Header from '../../../components/Layout/Header/HeaderBillUploadProgress';
import MyRoute from '../../../hoc/MyRoute';
import AlertMessage from '../../../common/AlertMessage/AlertMessage';
import { changeActiveTab } from '../../../../src/App/redux/action';
import { connect } from 'react-redux';
import ThankYou from './component/ThankYou';
import { Grid } from '@material-ui/core';
import Footer from '../../../components/Layout/Footer/Footer';

const mapStateToProps = (state) => {
	return {
		uploadingStatus: state.reducerBillUploadProgress.uploadingStatus
	};
};

const mapDispatchToProps = {
	changeActiveTab
};

class ThankYouContainer extends Component {
	componentDidMount() {
		this.props.changeActiveTab(0);
	}

	render() {
		return (
			<Fragment>
				<Header />
				<AlertMessage />
				<MyRoute billUploadStatus={this.props.uploadingStatus}>
					<ThankYou />
				</MyRoute>
				<Grid item>
					<Footer />
				</Grid>
			</Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouContainer);
