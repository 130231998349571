import actionType from "./actionTypes";

const initialState = {
  cartList: [],
  invalidCartList: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.FETCH_CART_LIST:
      return {
        ...state,
        cartList: action.payload,
      };
    case actionType.GET_ERRORS_PRODUCT_LIST:
      return {
        ...state,
        invalidCartList: true,
      };
    case actionType.CLEAR_CARTLIST:
      return {
        ...state,
        cartList: [],
      };
    default:
      return state;
  }
}
