import * as actionTypes from './actionTypes';

const changeTransactionTab = (val) => {
    return (dispatch) => {
		dispatch({ type: actionTypes.CHANGE_TRANSACTION_ACTIVE_TAB, val: val });
	};
}

const changeLogData = val => {
    return (dispatch) => {
		dispatch({ type: actionTypes.CHANGE_LOG_TRANSACTION_DATA, val: val });
	};
}

const changePointsSelection = val => {
    return (dispatch) => {
		dispatch({ type: actionTypes.CHANGE_TRANSACTION_POINT_SELECTION, val: val });
	};
}

const changeBillStageSelection = val => {
    return (dispatch) => {
		dispatch({ type: actionTypes.CHANGE_TRANSACTION_BILL_STAGE_SELECTION, val: val });
	};
}

export {
    changeTransactionTab,
    changeLogData,
    changePointsSelection,
    changeBillStageSelection
};