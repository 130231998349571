import React, { Component, Fragment } from "react";

import { headerScreenLabels, apiData } from "../../../common/common-types";
import Header from "../../../components/Layout/Header/HeaderSubmitBill";
import MyRoute from "../../../hoc/MyRoute";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import {
  changeActiveTab,
  logoutUser,
  showLoader,
} from "../../../../src/App/redux/action";
import {
  getCustomerNotification,
  deleteNotification,
  getMoreCustomerNotification,
} from "./redux/actions";
import { connect } from "react-redux";
import NotificationListing from "./components/NotificationListing";
import NotificationDetails from "./components/NotificationDetails";
import { getStorageItem } from "../../../common/localStorage";

class NotificationsContainer extends Component {
  state = {
    hasMore: false,
    datarcv: false,
  };

  async componentDidMount() {
    this.props.changeActiveTab(0);

    if (getStorageItem("user")) {
      this.props.showLoader(true);
      const params = new URLSearchParams();
      params.append("customer_id", JSON.parse(getStorageItem("user")).id);
      params.append("merchant_id", apiData.merchant_id);
      params.append("platform", apiData.platform);
      params.append("page_num", 1);
      params.append(
        "loyalty_id",
        getStorageItem("loyaltyId") && getStorageItem("loyaltyId")
      );

      await this.props.getCustomerNotification(params);
      await this.setState({
        datarcv: true,
        hasMore: this.props.pageNum ? true : false,
      });
      this.props.showLoader(false);
    } else {
      this.props.logoutUser();
    }
  }

  getMoreNotifications = async (isScroll) => {
    if (this.props.pageNum) {
      !isScroll && this.props.showLoader(true);
      const params = new URLSearchParams();
      params.append("customer_id", JSON.parse(getStorageItem("user")).id);
      params.append("merchant_id", apiData.merchant_id);
      params.append("platform", apiData.platform);
      params.append("page_num", this.props.pageNum);
      params.append("loyalty_id", 2);

      await this.props.getMoreCustomerNotification(params);

      await this.setState({
        datarcv: true,
        hasMore: this.props.pageNum ? true : false,
      });

      this.props.showLoader(false);
    } else {
      await this.setState({
        hasMore: false,
      });
    }
  };

  handleDeleteNotification = async (id) => {
    this.props.showLoader(true);
    await this.props.deleteNotification(id);
    this.props.showLoader(false);
  };

  render() {
    return (
      <Fragment>
        <Header screenLabel={headerScreenLabels.notifications} />
        <AlertMessage />
        <MyRoute
          fullHeight={true}
          billUploadStatus={this.props.uploadingStatus}
        >
          {window.location.pathname === "/notifications" ? (
            <NotificationListing
              handleNotificationClicked={this.handleNotificationClicked}
              notificationList={this.props.notificationList}
              hasMore={this.state.hasMore}
              datarcv={this.state.datarcv}
              noRecords={
                false ||
                (this.props.notificationList &&
                  this.props.notificationList.length === 0)
              }
              getMoreNotifications={this.getMoreNotifications}
              handleDeleteNotification={this.handleDeleteNotification}
            />
          ) : (
            <NotificationDetails />
          )}
        </MyRoute>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
    pageNum: state.reducerNotifications.pageNum,
    notificationList: state.reducerNotifications.notificationList,
  };
};

const mapDispatchToProps = {
  changeActiveTab,
  logoutUser,
  showLoader,
  getCustomerNotification,
  deleteNotification,
  getMoreCustomerNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsContainer);
