import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { Typography, FormControl, MenuItem } from "@material-ui/core";
import { apiData } from "../../common/common-types";
import { getStorageItem } from "../../common/localStorage";
import Select from "@material-ui/core/Select";
import down_arrow from "../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import { styles } from "./MyCartItemStyle";
import { primaryOrange, maroonRed, green, skyBlue } from "../UI/Theme";
import default_product from "../../assets/images/product/default_product.svg";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 6;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
      width: "25%",
    },
  },
};

class MyCartItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedQuantity: 0,
      calculatedPoints: 0,
      pointsDynamicName: "",
    };
  }

  roundOff = (voucherValue) => {
    return parseFloat(voucherValue) % 1 !== 0
      ? parseFloat(voucherValue).toFixed(2)
      : Math.floor(voucherValue);
  };
  quantityOptions = (elem) => {
    var arr = [];
    for (let i = elem.min_quantity; i <= elem.max_quantity; i++) {
      arr.push(
        <MenuItem key={i + "-" + "key"} value={i}>
          {i}
        </MenuItem>
      );
    }
    return arr;
  };

  componentDidMount = () => {
    this.setState({
      pointsDynamicName: getStorageItem("pointShortName"),
    });
  };

  addProductToCart = async (obj) => {
    const { selected, selectedValue } = obj;
    this.setState({
      selectedQuantity: selectedValue,
      calculatedPoints: selectedValue * selected.best_price,
    });
    let finalCartArray = [...this.props.finalCartArray];
    finalCartArray.map((item) => {
      if (item.product_merchant_id === selected.product_merchant_id) {
        item.qty = selectedValue;
        item.calculated_points = selected.best_price * selectedValue;
      }
    });
    if (getStorageItem("user")) {
      const bodyFormData = new URLSearchParams();
      bodyFormData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      bodyFormData.append("merchant_id", apiData.merchant_id);
      bodyFormData.append("platform", apiData.platform);
      bodyFormData.append("cart_details", JSON.stringify(finalCartArray));

      await this.props.addCart(finalCartArray);
      await this.props.addToCart(bodyFormData);

      const cartCountFormData = new URLSearchParams();
      cartCountFormData.append(
        "customer_id",
        JSON.parse(getStorageItem("user")).id
      );
      const bodyFormCartData = new URLSearchParams();
      bodyFormCartData.append(
        "customer_id",
        JSON.parse(getStorageItem("user")).id
      );
      bodyFormCartData.append("merchant_id", apiData.merchant_id);
      await this.props.getCartList(bodyFormCartData);

      cartCountFormData.append("merchant_id", apiData.merchant_id);
      this.props.getCartItemCount(cartCountFormData);
    } else {
      this.props.logoutUser();
    }
  };

  handleQuantity = async (e, best_price, product) => {
    const { calculatedPoints1 } = this.props;
    if (parseInt(product.qty) < parseInt(e.target.value)) {
      calculatedPoints1(
        parseInt(e.target.value) - parseInt(product.qty),
        best_price,
        "more"
      );
    } else if (parseInt(product.qty) === parseInt(e.target.value)) {
      calculatedPoints1(parseInt(e.target.value), best_price, "equal");
    } else {
      calculatedPoints1(
        parseInt(product.qty) - parseInt(e.target.value),
        best_price,
        "less"
      );
    }
    this.addProductToCart({
      selected: product,
      selectedValue: parseInt(e.target.value),
    });
  };

  getPoints = (calculatedPoints, best_price, qty) => {
    if (calculatedPoints) {
      return this.roundOff(calculatedPoints);
    } else {
      return this.roundOff(best_price * qty);
    }
  };

  render() {
    const { classes, elem } = this.props;
    const {
      brand_name,
      sub_product_name,
      image_name,
      location_name,
      voucher_value,
      best_price,
      itemQuantity,
      key,
      qty,
      expiry_date,
      is_stock_available,
    } = elem;
    const { calculatedPoints } = this.state;
    const currencySymbol = getStorageItem("currencyCode")
      ? getStorageItem("currencyCode")
      : "₹";
    const isExpired = moment(expiry_date).isBefore(
      moment().format("YYYY-MM-DD")
    );
    const statusColor =
      is_stock_available === "no" && isExpired
        ? maroonRed
        : is_stock_available === "no"
        ? primaryOrange
        : isExpired
        ? maroonRed
        : primaryOrange;

    const status =
      is_stock_available === "no" && isExpired
        ? "Expired"
        : is_stock_available === "no"
        ? "Out of stock"
        : "Expired";

    return (
      <div key={key} className={classes.root}>
        <div key={key} className={classes.rootChild}>
          <div className={classes.imageContainer}>
            <img
              alt={"product"}
              src={image_name ? image_name : default_product}
            />
          </div>
          <div className={classes.details}>
            <div className={classes.main_content}>
              <div className={classes.pro_content}>
                <div className={classes.productDetails}>
                  <Typography className={classes.brandName}>
                    {brand_name}
                  </Typography>
                  <Typography className={classes.productName}>
                    {sub_product_name}
                  </Typography>
                </div>
                <a>
                  <span
                    className={classes.img + " " + classes.icondeleteCss}
                    onClick={async (e) => {
                      await this.setState({
                        calculatedPoints: 0,
                        selectedQuantity: 0,
                      });
                      this.props.deleteItem(elem);
                    }}
                  />
                </a>
              </div>
              <Typography className={classes.price_mallName}>
                <Typography className={classes.price_mall} component="span">
                  {/* {currencySymbol} {voucher_value} voucher */}
                  {`${currencySymbol} ${parseFloat(voucher_value).toFixed(
                    2
                  )} voucher`}
                </Typography>
                <Typography className={classes.separator} component="span">
                  {location_name && "|"}
                </Typography>
                <Typography className={classes.price_mall} component="span">
                  {location_name}
                </Typography>
              </Typography>
            </div>
            <div className={classes.point_cal}>
              <div className={classes.sub_content}>
                <span className={classes.rsp}>
                  {best_price + " "}
                  {this.state.pointsDynamicName
                    ? this.state.pointsDynamicName
                    : " P"}
                </span>
                {!(is_stock_available === "no" || isExpired) && (
                  <span className={classes.mgSmallClose}>X</span>
                )}
                {!(is_stock_available === "no" || isExpired) && (
                  <div className={classes.select_box}>
                    <FormControl fullWidth className={classes.formControl}>
                      <Select
                        name="qty"
                        labelId="qty"
                        id="qty"
                        disableUnderline={true}
                        disabled={is_stock_available === "no" || isExpired}
                        value={
                          this.state.selectedQuantity
                            ? this.state.selectedQuantity
                            : qty
                        }
                        onChange={(e) =>
                          this.handleQuantity(e, best_price, elem)
                        }
                        IconComponent={() => (
                          <img
                            className={classes.down_icon}
                            src={down_arrow}
                            alt=""
                            width="9"
                            height="6"
                          />
                        )}
                        MenuProps={MenuProps}
                      >
                        {this.quantityOptions(elem)}
                      </Select>
                    </FormControl>
                  </div>
                )}
                {!(is_stock_available === "no" || isExpired) && (
                  <span className={classes.rsp}>{itemQuantity}</span>
                )}
              </div>
              {(is_stock_available === "no" || isExpired) && (
                <div
                  className={classes.orderStatus}
                  style={{ borderColor: statusColor }}
                >
                  <Typography
                    className={classes.orderStatusValue}
                    style={{ color: statusColor }}
                  >
                    {status}
                  </Typography>
                </div>
              )}
              {is_stock_available !== "no" && !isExpired && (
                <Typography className={classes.price_points}>
                  {this.getPoints(calculatedPoints, best_price, qty) + " "}
                  {this.state.pointsDynamicName
                    ? this.state.pointsDynamicName
                    : " P"}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(MyCartItem));
