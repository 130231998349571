import React, { Component, Fragment } from 'react';

import { headerScreenLabels } from '../../../common/common-types';
import Header from '../../../components/Layout/Header/HeaderSubmitBill';
import MyRoute from '../../../hoc/MyRoute';
import BillSummary from './component/BillSummary';
import { stockAPI } from '../../../common/axiosInstance';
import { connect } from 'react-redux';
import { changeActiveTab, logoutUser, showLoader } from '../../../App/redux/action';
import { openAlertBox } from '../../../common/AlertMessage/AlertMessage';
import AlertMessage from '../../../common/AlertMessage/AlertMessage';
import { apiData } from '../../../common/common-types';
import { getStorageItem } from '../../../common/localStorage';
import { Grid } from '@material-ui/core';
import Footer from '../../../components/Layout/Footer/Footer';

class BillSummaryContainer extends Component {
	state = {
		billsSubmitted: 0,
		billsProcessed: 0,
		billsPending: 0,
		totalPointsEarned: '-',
		bills: [],
		userTotalPoints: 0
	};

	componentDidMount() {
		this.props.showLoader(true);
		this.props.changeActiveTab(0);
		let billIds = this.props.history.location.state ? this.props.history.location.state.billIds : '';
		if (billIds.charAt(billIds.length - 1) === ',') {
			billIds = billIds.substr(0, billIds.length - 1);
		}
		if (billIds.charAt(0) === ',') {
			billIds = billIds.substr(1, billIds.length);
		}

		if (billIds !== '' || billIds !== '0') {
			if (getStorageItem('user')) {
				let data = new FormData();
				data.append('customer_id', JSON.parse(getStorageItem('user')).id);
				data.append('merchant_id', apiData.merchant_id);
				data.append('platform', apiData.platform);
				data.append('bill_ids', billIds);
				stockAPI(data, 'POST', '/getBillSummary')
					.then((response) => {
						this.props.showLoader(false);
						if (response) {
							const billIdsArray = billIds.split(',');
							const data = billIdsArray.map((id, i) => {
								if (i === 0) {
									return { key: id, ...response.bill_data[id], detailOpenedStatus: true };
								} else {
									return { key: id, ...response.bill_data[id], detailOpenedStatus: false };
								}
							});
							this.setState({
								billsSubmitted: response.submitted,
								billsProcessed: response.processed,
								billsPending: response.pending,
								totalPointsEarned: response.total_points_earned,
								userTotalPoints: response.all_points_earned && parseFloat(response.all_points_earned),
								bills: data
							});
						} else {
							openAlertBox('Oops something went wrong!', 'error');
						}
					})
					.catch((err) => {
						this.props.showLoader(false);
						openAlertBox(err.message, 'error');
					});
			} else {
				this.props.logoutUser();
			}
		} else {
			this.props.showLoader(false);
		}
	}

	handleRedeem = () => {
		this.props.changeActiveTab(2);
		this.props.history.push('/redeem');
	};

	handleSubmitAnotherBill = () => {
		const isMultiClickBill = this.props.history.location.state
			? this.props.history.location.state.isMultiClickBill
			: false;
		this.props.history.push({
			pathname: '/submit-bill',
			state: {
				tab: isMultiClickBill ? 1 : 0
			}
		});
	};

	render() {
		const { billsSubmitted, billsProcessed, billsPending, totalPointsEarned, bills, userTotalPoints } = this.state;

		return (
			<Fragment>
				<Header screenLabel={headerScreenLabels.billSummary} />
				<AlertMessage />
				<MyRoute fullHeight={this.props.history.location.state.from === '/transactions'} billUploadStatus={this.props.uploadingStatus}>
					<BillSummary
						billsSubmitted={billsSubmitted}
						billsProcessed={billsProcessed}
						billsPending={billsPending}
						totalPointsEarned={totalPointsEarned}
						bills={bills}
						redeemBtnHandler={this.handleRedeem}
						isMultiClickBill={
							this.props.history.location.state ? (
								this.props.history.location.state.isMultiClickBill
							) : (
								false
							)
						}
						submitAnotherBill={this.handleSubmitAnotherBill}
						userTotalPoints={userTotalPoints}
						fromTransactions={this.props.history.location.state.from === '/transactions'}
					/>
				</MyRoute>
				{
					this.props.history.location.state.from !== '/transactions' &&
					<Grid item>
						<Footer />
					</Grid>
				}
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
		activeTab: state.reducerApp.activeTab
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		changeActiveTab: (id) => dispatch(changeActiveTab(id)),
		logoutUser: () => dispatch(logoutUser()),
		showLoader: (val) => dispatch(showLoader(val))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BillSummaryContainer);
