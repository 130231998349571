import React from "react";
import { Slider, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { primaryOrange } from "../../UI/Theme";

const theme = createMuiTheme();
const my_theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      popper: {
        zIndex: 1300,
      },
      tooltip: {
        color: primaryOrange,
        backgroundColor: "#ffffff",
        textAlign: "center",
        boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.3)",
        padding: "4px 8px",
        fontSize: "16px",
        [theme.breakpoints.down(641)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "10.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "10px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "9.5px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "9px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "8px",
        },
        "& span": {
          color: "transparent",
        },
      },
      arrow: {
        color: "#fff",
        border: "1em solid black",
        borderColor: "transparent transparent #fff #fff",
        boxShadow: "-1px 1px 1px 0 rgba(0,0,0,0.3)",
        transform: "rotate(-45deg)",
        transformOrigin: "4px 5px",
      },
      tooltipPlacementTop: {
        margin: "5px 0px",
      },
      touch: {
        padding: "4px 8px",
        fontSize: "16px",
        [theme.breakpoints.down(641)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(481)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "10.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "10px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "9.5px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "9px",
        },
        [theme.breakpoints.down(321)]: {
          fontSize: "8px",
        },
      },
    },
  },
});

export function ValueLabelComponent(props) {
  const { children, open, value } = props;
  return (
    <MuiThemeProvider theme={my_theme}>
      <Tooltip
        open={open}
        // enterTouchDelay={0}
        arrow
        placement="top"
        title={value}
        interactive={false}
        enterNextDelay={0}
        enterDelay={0}
        leaveTouchDelay={0}
      >
        {children}
      </Tooltip>
    </MuiThemeProvider>
  );
}

export const CustomSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
  },
  thumb: {
    padding: 5,
    height: 26,
    width: 26,
    backgroundColor: theme.palette.primary.main,
    border: "2px solid #ffffff",
    marginTop: -8,
    marginLeft: -12,
    borderRadius: 26,
    boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.3)",
    "&:focus, &:hover, &$active": {
      boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.3)",
    },
    [theme.breakpoints.down("xs")]: {
      height: 16,
      width: 16,
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 10,
    borderRadius: 4,
    [theme.breakpoints.down("xs")]: {
      height: 5,
    },
  },
  rail: {
    height: 10,
    borderRadius: 5,
    color: "#e4e4e4",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      height: 5,
    },
  },
  markLabel: {
    color: "#7e7e7e",
    fontSize: "18px",
    [theme.breakpoints.down(641)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down(481)]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down(421)]: {
      fontSize: "13.5px",
    },
    [theme.breakpoints.down(401)]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down(381)]: {
      fontSize: "12.5px",
    },
    [theme.breakpoints.down(361)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(321)]: {
      fontSize: "10px",
    },
  },
}))(Slider);
