import actionType from "./actionTypes";

const initialState = {
  sort_by: "",
  isListView: false,
  selectedProductDetails: [],
  isFromOrder: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.ON_VOUCHER_CHANGE:
      return {
        ...state,
        selectedProductDetails: action.payload,
      };
    case actionType.CLEAR_SELECTED_PRODUCT_DETAILS:
      return {
        ...state,
        selectedProductDetails: [],
      };
    case actionType.SET_SORT_BY:
      return {
        ...state,
        sort_by: action.payload,
      };
    case actionType.SET_PRODUCT_VIEW:
      return {
        ...state,
        isListView: !state.isListView,
      };
    case actionType.CLEAR_SORT_BY:
      return {
        ...state,
        sort_by: "",
      };
    case actionType.CLEAR_PRODUCT_VIEW:
      return {
        ...state,
        isListView: false,
      };
    case actionType.SET_FROM_ORDER:
      return {
        ...state,
        isFromOrder: action.payload,
      };
    case actionType.RESET_REDEEM:
      return {
        ...state,
        sort_by: "",
        isListView: false,
        selectedProductDetails: [],
        isFromOrder: false,
      };
    default:
      return state;
  }
}
