import {
  FETCH_MERCHANT_DATA,
  GET_ERRORS,
  FETCH_BANNER_DATA,
  UPDATE_NOTIFICTION_COUNT,
} from "./actionTypes";
import { SET_SUBMIT_BILL } from "../../SubmitBill/redux/actionTypes";
import { stockAPI } from "../../../../common/axiosInstance";
import { apiData } from "../../../../common/common-types";
import { getStorageItem, setStorageItem } from "../../../../common/localStorage";

export const merchantDetails = (bodyObj) => {
  return (dispatch) => {
    stockAPI(bodyObj, "POST", "/getMerchantDetails")
      .then(async (response) => {
        if (response) {
          dispatch({
            type: FETCH_MERCHANT_DATA,
            payload: response.merchant_list && response.merchant_list.added[1],
          });
          if (getStorageItem('pointShortName') === 'pts') {
            const user = {
              id: response.merchant_list.added[1].customerDetails.id,
              mobile_number:
                response.merchant_list.added[1].customerDetails.mobile_number,
            };
            const currency_code =
              response.merchant_list.added[1].loyalty_currency_code;
            const points_unit_short_name =
              response.merchant_list.added[1].points_unit_short_name;
            const loyalty_id = response.merchant_list.added[1].loyalty_id;

            const guidelinesFlag =
              response.merchant_list.added[1].customerDetails
                .bill_upload_guidelines_flag;

            setStorageItem("user", JSON.stringify(user));
            setStorageItem("currencyCode", `${currency_code}`);
            setStorageItem("pointShortName", points_unit_short_name);
            setStorageItem("loyaltyId", loyalty_id);
            setStorageItem("guidelinesFlag", guidelinesFlag);
            if (localStorage.getItem('isPWAInstalled') === 'not installed') {
              setStorageItem("isPWAInstalled", "not installed");
            }
            if (localStorage.getItem('isPWAInstalled') === 'installed') {
              setStorageItem("isPWAInstalled", "installed");
            }
          }
          dispatch({
            type: SET_SUBMIT_BILL,
            payLoad:
              response.merchant_list &&
              response.merchant_list.added[1].allow_short_bill_upload === "1",
          });
        }
      })
      .catch(function (err) {
        dispatch({ type: GET_ERRORS, payload: err });
      });
  };
};

export const getBannerData = (bodyObj) => {
  return (dispatch) => {
    stockAPI(bodyObj, "POST", "/getBanners")
      .then(async (response) => {
        if (response && response.banners) {
          dispatch({
            type: FETCH_BANNER_DATA,
            payload: response.banners ? response.banners : [],
          });
        }
      })
      .catch(function (err) {
        dispatch({ type: GET_ERRORS, payload: err });
      });
  };
};

export const getNotificationCount = () => {
  const notificationCountParams = new URLSearchParams();
  notificationCountParams.append("id", 0);
  notificationCountParams.append(
    "customer_id",
    JSON.parse(getStorageItem("user")).id
  );
  notificationCountParams.append("merchant_id", apiData.merchant_id);

  return (dispatch) => {
    stockAPI(notificationCountParams, "POST", "/getPushNotificationCount").then(
      (response) => {
        if (response && response.push_notification_count) {
          dispatch({
            type: UPDATE_NOTIFICTION_COUNT,
            count: response.push_notification_count,
          });
        }
      }
    );
  };
};
