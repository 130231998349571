import React, { Component, Fragment } from 'react';
import BookVisitStart from "./BookVisitStart";
import BookVisitForm from "./BookVisitForm";
import BookVisitConfirm from "./BookVisitConfirm";

class BookWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			data: {},
		};

		this.setPage = this.setPage.bind(this);
		this.setData = this.setData.bind(this);
	}

	componentDidMount() {
	}

	setPage(page) {
		this.setState({
			page,
		});
	}

	setData(data) {
		this.setState({
			data,
		});
	}

	render() {
		const { page } = this.state;
		return (
			<Fragment>
				{page === 1
				? <BookVisitStart {...this.props} {...this.state} setPage={this.setPage}/>
				: page === 2
				? <BookVisitForm {...this.props} {...this.state} setPage={this.setPage} setData={this.setData}/>
				: <BookVisitConfirm {...this.props} {...this.state}/>
				}
			</Fragment>
		);
	}
}

export default BookWrapper;
