import * as actions from './actionTypes';

const initialState = {
	imagesToUpload: [],
	uploadPercentage: 0,
	uploadingStatus: false
};

const getPercentage = (files) => {
	if (files.length > 0) {
		let percent = 0;
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			percent = percent + file.percent;
		}
		percent = Math.floor(percent / files.length);
		return percent;
	} else {
		return 0;
	}
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.UPDATE_IMAGES_TO_UPLOAD:
			return {
				...state,
				imagesToUpload: [ ...state.imagesToUpload, ...action.payLoad ]
			};

		case actions.UPDATING_FILE_PERCENTAGE: {
			return {
				...state,
				imagesToUpload: state.imagesToUpload.map(
					(img) => (img.my_id === action.file.my_id ? { ...action.file } : img)
				)
			};
		}

		case actions.UPDATE_UPLOAD_PERCENTAGE: {
			return {
				...state,
				uploadPercentage: getPercentage(state.imagesToUpload),
				uploadingStatus: getPercentage(state.imagesToUpload) === 100 ? true : state.uploadingStatus
			};
		}

		case actions.SHOW_PROGESS_BAR: {
			return {
				...state,
				uploadingStatus: true
			};
		}

		case actions.HIDE_PROGESS_BAR: {
			return {
				...state,
				uploadingStatus: false
			};
		}

		case actions.DELETE_BILL: {
			return {
				...state,
				imagesToUpload: state.imagesToUpload.filter((file) => file.my_id !== action.payLoad)
			};
		}

		case actions.RESET_BILL_UPLOAD: {
			return {
				imagesToUpload: [],
				uploadPercentage: 0,
				uploadingStatus: false
			};
		}

		default:
			return state;
	}
};

export default reducer;
