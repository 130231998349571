import React, { Component, Fragment } from "react";

import { apiData, headerScreenLabels } from "../../../common/common-types";
import Header from "../../../components/Layout/Header/HeaderSubmitBill";
import MyRoute from "../../../hoc/MyRoute";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import BillUploadGuidelines from "./component/BillUploadGuidelines";
import { changeActiveTab, logoutUser, showLoader } from "../../../../src/App/redux/action";
import { getBillUploadGuidelines } from './redux/action';
import { connect } from "react-redux";
import { getStorageItem, setStorageItem } from "../../../common/localStorage";
import { stockAPI } from "../../../common/axiosInstance";

const mapStateToProps = (state) => {
  return {
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
    guidelines: state.reducerBillUploadGuidelines.guideLines
  };
};

const mapDispatchToProps = {
  changeActiveTab,
  logoutUser,
  showLoader,
  getBillUploadGuidelines
};

class BillUploadGuidelinesContainer extends Component {
  state = {
    guidelinesFlag:
      getStorageItem("guidelinesFlag") &&
      parseInt(getStorageItem("guidelinesFlag")) === 0
        ? false
        : true,
    guidelines: [],
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.props.showLoader(true);
    this.props.changeActiveTab(0);
    // this.getGuidelines();

    if (this.props.guidelines.length === 0) {
      const formData = new URLSearchParams();
      formData.append("platform", apiData.platform);
      formData.append("merchant_id", apiData.merchant_id);
      formData.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
      await this.props.getBillUploadGuidelines(formData);
    }
    
    this.props.showLoader(false);
  }

  getGuidelines = () => {
    const formData = new URLSearchParams();
    formData.append("platform", apiData.platform);
    formData.append("merchant_id", apiData.merchant_id);
    formData.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);
    stockAPI(formData, "POST", "/getBillUploadGuidelines").then((response) => {
      this.props.showLoader(false);
      if (response) {
        this.setState({
          guidelines: response.bill_upload_guidelines,
        });
      }
    });
  };

  handleRadioButton = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        guidelinesFlag: !prevState.guidelinesFlag,
      };
    });
  };

  changeGuidelineStatus = () => {
    if (getStorageItem("user")) {
      const formData = new URLSearchParams();
      formData.append("platform", apiData.platform);
      formData.append("merchant_id", apiData.merchant_id);
      formData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      formData.append("flag", this.state.guidelinesFlag);
      stockAPI(
        formData,
        "POST",
        "/setBillUploadGuidelinesFlag",
        null,
        null,
        null,
        true
      ).then((response) => {
        if (response && response.statusCode === 100) {
          setStorageItem("guidelinesFlag", 1);
        }
      });
    } else {
      this.props.logoutUser();
    }
  };

  handleDoneBtnClick = () => {
    if (
      (getStorageItem("guidelinesFlag") &&
      parseInt(getStorageItem("guidelinesFlag")) === 1
        ? true
        : false) !== this.state.guidelinesFlag
    ) {
      // console.log('inside if check');
      this.changeGuidelineStatus();
    }
    this.props.history.replace("/submit-bill");
  };

  render() {
    return (
      <Fragment>
        <Header screenLabel={headerScreenLabels.billUploadGuidelines} />
        <AlertMessage />
        <MyRoute billUploadStatus={this.props.uploadingStatus} fullHeight>
          <BillUploadGuidelines
            handleRadioButton={this.handleRadioButton}
            guidelines={this.props.guidelines}
            guidelinesFlag={this.state.guidelinesFlag}
            handleDoneBtnClick={this.handleDoneBtnClick}
          />
        </MyRoute>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillUploadGuidelinesContainer);
