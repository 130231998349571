import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import { styles } from "./BillUploadProgressStyles";
import { withRouter } from "react-router-dom";
import UploadingBill from "../../../../components/UploadingBill/UploadingBill";
import { getStorageItem } from "../../../../common/localStorage";
import { Dialog, Slide, Button } from "@material-ui/core";
import BillImage from "../../../../components/BillImage/BillImage";
import { apiData } from "../../../../common/common-types";
import { stockAPI } from "../../../../common/axiosInstance";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import BillDetailsDialog from "../../../../components/BillDetailsDialog/BillDetailsDialog";
import NoRecordFound from "../../../../components/NoRecordFound/NoRecordFound";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class BillUploadProgress extends Component {
  state = {
    singleBillImageSelected: null,
    singleBillImagePopup: false,

    singleBillDetailPopup: false,
    singleBillDetailSelected: null,
  };

  deleteUploadedBill = (id) => {
    const { imagesToUpload } = this.props;

    const deleteBill = imagesToUpload.find((bill) => bill.my_id === id);

    if (deleteBill && deleteBill.last_bill_id) {
      this.props.deleteBillHandler(deleteBill, id);
    }
  };

  //clicked on the image to view the details of single click bill image uploaded
  handleBillImageClicked = (imageSrc, isPdf) => {
    if (isPdf) {
      window.open(imageSrc);
    } else {
      this.setState({
        singleBillImageSelected: imageSrc,
        singleBillImagePopup: true,
      });
    }
  };

  //clicked on the image to view the details of single click bill image uploaded closed
  handleBillImageClickedClosed = () => {
    this.setState({
      singleBillImagePopup: false,
      singleBillImageSelected: null,
    });
  };

  //clicked on the bill to view the details of single click bill image uploaded
  handleBillClicked = (bill) => {
    if (bill.last_bill_id) {
      if (getStorageItem("user")) {
        let data = new FormData();
        data.append("customer_id", JSON.parse(getStorageItem("user")).id);
        data.append("merchant_id", apiData.merchant_id);
        data.append("platform", apiData.platform);
        data.append("bill_ids", bill.last_bill_id);

        stockAPI(data, "POST", "/getBillSummary")
          .then(async (response) => {
            if (response) {
              const bill_data = {
                fileName: bill.file.name,
                ...response.bill_data[`${bill.last_bill_id}`],
              };
              await this.setState({
                singleBillDetailPopup: true,
                singleBillDetailSelected: bill_data,
              });
            } else {
              openAlertBox("Oops something went wrong!", "error");
            }
          })
          .catch(async (err) => {
            openAlertBox(err && err.message, "error");
          });
      } else {
        this.props.logoutUser();
      }
    } else {
      openAlertBox("Bill not uploaded.", "error");
    }
  };

  //clicked on the bill to view the details of single click bill image uploaded closed
  handleBillClickedClosed = () => {
    this.setState({
      singleBillDetailPopup: false,
      singleBillDetailSelected: null,
    });
  };

  render() {
    const { classes, imagesToUpload } = this.props;
    const {
      singleBillImageSelected,
      singleBillImagePopup,
      singleBillDetailPopup,
      singleBillDetailSelected,
    } = this.state;

    return (
      <div className={classes.root}>
        {imagesToUpload.length > 0 ? (
          imagesToUpload.map((img) => (
            <UploadingBill
              key={img.my_id}
              my_id={img.my_id}
              percentage={img.percent}
              src={img.uri}
              status={img.status}
              name={img.file && img.file.name}
              delete={this.deleteUploadedBill}
              img={img}
              isPdf={img.pdf}
              pdfIcon={img.pdfIcon}
              points={
                img.pointsEarned
                  ? `+ ${
                      parseFloat(img.pointsEarned) % 1 !== 0
                        ? parseFloat(img.pointsEarned).toFixed(2)
                        : Math.floor(img.pointsEarned)
                    } ${
                      getStorageItem("pointShortName")
                        ? getStorageItem("pointShortName")
                        : "P"
                    }`
                  : `+ 0 ${
                      getStorageItem("pointShortName")
                        ? getStorageItem("pointShortName")
                        : "P"
                    }`
              }
              duplicate={img.duplicate ? img.duplicate : false}
              onImageClicked={this.handleBillImageClicked}
              onBillClick={this.handleBillClicked}
            />
          ))
        ) : (
          // <p>No bills currently in progress.</p>
          <Fragment>
            <NoRecordFound message={"No bills currently in progress."} />
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => this.props.history.push("/submit-bill")}
            >
              SUBMIT BILL(s)
            </Button>
          </Fragment>
        )}
        <Dialog
          open={singleBillImagePopup}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleBillImageClickedClosed}
          maxWidth="md"
          className={classes.dialogModal}
        >
          <BillImage
            multi={false}
            open={singleBillImagePopup}
            imgSrc={singleBillImageSelected}
            okBtnHandler={this.handleBillImageClickedClosed}
          />
        </Dialog>
        <Dialog
          open={singleBillDetailPopup}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleBillClickedClosed}
          maxWidth="md"
        >
          <BillDetailsDialog
            okBtnHandler={this.handleBillClickedClosed}
            fileName={
              singleBillDetailSelected && singleBillDetailSelected.fileName
            }
            brand={
              singleBillDetailSelected && singleBillDetailSelected.brand_name
            }
            pointsEarned={
              singleBillDetailSelected && singleBillDetailSelected.points_earned
            }
            status={singleBillDetailSelected && singleBillDetailSelected.status}
            billNumber={
              singleBillDetailSelected && singleBillDetailSelected.bill_number
            }
            billDate={
              singleBillDetailSelected && singleBillDetailSelected.bill_date
            }
            billAmount={
              singleBillDetailSelected && singleBillDetailSelected.bill_amount
            }
            billImages={
              singleBillDetailSelected && singleBillDetailSelected.bill_image
            }
            location={
              singleBillDetailSelected && singleBillDetailSelected.location_name
            }
          />
        </Dialog>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(BillUploadProgress)
);
