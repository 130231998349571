import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Registration from "../Registration/components/Registration";
import { registerCustomer } from "../Registration/redux/action";
import { authenticate } from "../OtpVerification/redux/action";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import { authUser, logoutUser } from "../../../App/redux/action";
import { setRegVisitedflag } from "../OtpVerification/redux/action";
import {isReferalEnable} from "../OtpVerification/redux/action";

const mapDispatchToProps = {
  registerCustomer,
  authenticate,
  authUser,
  logoutUser,
  setRegVisitedflag,
  isReferalEnable,
};

const mapStateToProps = (state) => {
  return {
    customerResponse: state.reducerRegistration.registerCustomerResponse,
    isAuthenticated: state.reducerOtpverification.isAuthenticated,
    isOtpVerified: state.reducerOtpverification.isOtpVerified,
    isUserVisited: state.reducerOtpverification.isUserVisited,
    isReferalBoxEnable:state.reducerOtpverification.isReferalEnable
  };
};

class RegistrationContainer extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <Fragment>
        <AlertMessage />
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <Registration
            customerResponse={this.props.customerResponse}
            registerCustomer={this.props.registerCustomer}
            authenticate={this.props.authenticate}
            isAuthenticated={this.props.isAuthenticated}
            authUser={this.props.authUser}
            logoutUser={this.props.logoutUser}
            isOtpVerified={this.props.isOtpVerified}
            isUserVisited={this.props.isUserVisited}
            isReferalBoxEnable={this.props.isReferalBoxEnable}
            // setRegVisitedflag={this.props.setRegVisitedflag}
          />
        </div>
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RegistrationContainer));
