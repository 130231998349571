import * as actionTypes from "./actionTypes";

const initialState = {
  doneBtnStatus: false,
  imagesToUpload: [],
  singleClickImages: [],
  singleBillDetailPopup: false,
  singleBillImagePopup: false,
  singleBillDetailSelected: null,
  singleBillImageSelected: null,
  removeImageId: "",
  isShortBill: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DONE_BUTTON_STATUS:
      return {
        doneBtnStatus: action.payLoad,
      };
    case actionTypes.SET_SUBMIT_BILL:
      return {
        isShortBill: action.payLoad,
      };
    default:
      return state;
  }
};

export default reducer;
