export const styles = (theme) => {
	return {
		root: {
			maxWidth: '660px',
			backgroundColor: theme.palette.common.globalBackground,
			height: '100%',
			display: 'block',
			textAlign: 'center',
			overflowY: 'auto',
			padding: '30px',
			[theme.breakpoints.down('610')]: {
				padding: '28px'
			},
			[theme.breakpoints.down('481')]: {
				padding: '21px'
			},
			[theme.breakpoints.down('381')]: {
				padding: '15px'
			},
			[theme.breakpoints.down('321')]: {
				padding: '13px'
			}
		},
		dialogModal: {
			'& .MuiDialog-paperWidthMd': {
				width: '100%'
			},
			'& .MuiDialog-paper': {
				margin: '15px'
			}
		},
		button: {
			...theme.palette.typography.doneButtons,
			minHeight: "92px",
			width: "auto",
			fontFamily: "Montserrat",
			margin: "18px auto",
			[theme.breakpoints.down(641)]: {
			  minHeight: "80px",
			},
			[theme.breakpoints.down(481)]: {
			  minHeight: "54px",
			  fontSize: "16px",
			},
			[theme.breakpoints.down(381)]: {
			  minHeight: "46px",
			  fontSize: "14px",
			},
			[theme.breakpoints.down(321)]: {
			  minHeight: "40px",
			},
			backgroundColor: theme.palette.primary.main,
			color: "white",
		}
	};
};
