import React, { Component, Fragment } from "react";
import Footer from "../../../../components/Layout/Footer/Footer";
import HeaderReferFriend from "../../../../components/Layout/Header/HeaderReferFriend";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReferFriend from "./ReferFriend";
import {
    merchantDetails,

  } from "../../Dashboard/redux/action";
import AlertMessage from "../../../../common/AlertMessage/AlertMessage";
const mapDispatchToProps = {
    merchantDetails,
  };
  
  const mapStateToProps = (state) => {
    return {
      merchantDetailsResponse: state.reducerDashboard.merchantDetailsResponse,
    };
  };
class ReferFriendContainer extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <Fragment>
                <HeaderReferFriend />
                <AlertMessage />
                <ReferFriend  merchantDetails={this.props.merchantDetailsResponse}/>
                <Footer />
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(ReferFriendContainer));
