import types from "./actionTypes";

export const saveBrands = (bodyObj) => ({
  type: types.SAVE_BRANDS,
  payload: bodyObj,
});

export const clearBrands=() => ({
  type: types.CLEAR_BRANDS
});
