import {
  primaryOrange,
	whiteColor,
	borderGrey,
} from "../../../../../components/UI/Theme";

export const styles = (theme) => {
	return {
		subHeader: {
			height: "132px",
			[theme.breakpoints.down('641')]: {
				height: "114px",
			},
			[theme.breakpoints.down('481')]: {
				height: "79px",
			},
			[theme.breakpoints.down('421')]: {
				height: "74px",
			},
			[theme.breakpoints.down('401')]: {
				height: "72px",
			},
			[theme.breakpoints.down('381')]: {
				height: "63px",
			},
			[theme.breakpoints.down('381')]: {
				height: "63px",
			},
			[theme.breakpoints.down('321')]: {
				height: "55px",
			},
		},
		cardpadding: {
			padding: "20px",
			[theme.breakpoints.down('641')]: {
				padding: "16px",
			},
			[theme.breakpoints.down('481')]: {
				padding: "12px",
			},
			[theme.breakpoints.down('421')]: {
				padding: "10px",
			},
			[theme.breakpoints.down('401')]: {
				padding: "8px",
			},
			[theme.breakpoints.down('381')]: {
				padding: "8px",
			},
			[theme.breakpoints.down('361')]: {
				padding: "8px",
			},
			[theme.breakpoints.down('321')]: {
				padding: "8px",
			},
		},
		cardpadding1: {
			padding: "0px 20px",
			[theme.breakpoints.down('641')]: {
				padding: "0px 16px",
			},
			[theme.breakpoints.down('481')]: {
				padding: "0px 12px",
			},
			[theme.breakpoints.down('421')]: {
				padding: "0px 10px",
			},
			[theme.breakpoints.down('401')]: {
				padding: "0px 8px",
			},
			[theme.breakpoints.down('381')]: {
				padding: "0px 8px",
			},
			[theme.breakpoints.down('361')]: {
				padding: "0px 8px",
			},
			[theme.breakpoints.down('321')]: {
				padding: "0px 8px",
			},
		},
		formControl: {
			minHeight: 'calc(100% - 92px)',
			'& .MuiTextField-root': {
				height: '92px'
			},
			'& .MuiInputLabel-root': {
				marginTop: '5px',
				fontSize: '20px'
			},
			'& .MuiFormHelperText-root': {
				fontSize: '0.623rem'
			},
			"& .MuiSelect-select:focus": {
				background: whiteColor,
			},
			[theme.breakpoints.down(641)]: {
				minHeight: 'calc(100% - 80px)',
				'& .MuiTextField-root': {
					height: '80px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '16px',
					marginTop: '1px',
					fontFamily: 'Montserrat'
				}
			},
			[theme.breakpoints.down(481)]: {
				minHeight: 'calc(100% - 54px)',
				'& .MuiTextField-root': {
					height: '54px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '14px',
					marginTop: '-7px'
				}
			},
			[theme.breakpoints.down(421)]: {
				'& .MuiInputLabel-root': {
					fontSize: '13.5px'
				}
			},
			[theme.breakpoints.down(401)]: {
				'& .MuiInputLabel-root': {
					fontSize: '13px'
				}
			},
			[theme.breakpoints.down(381)]: {
				minHeight: 'calc(100% - 46px)',
				'& .MuiTextField-root': {
					height: '46px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '12.5px',
					marginTop: '-8px'
				}
			},
			[theme.breakpoints.down(361)]: {
				'& .MuiInputLabel-root': {
					fontSize: '12px'
				}
			},
			[theme.breakpoints.down(321)]: {
				minHeight: 'calc(100% - 40px)',
				'& .MuiTextField-root': {
					height: '40px'
				},
				'& .MuiInputLabel-root': {
					marginTop: '-11px',
					fontSize: '10px'
				}
			}
		},
		select_box: {
			width: '100%',
			display: 'block',
			background: whiteColor,
			border: `1px solid ${borderGrey}`,
			borderRadius: '4px',
			position: 'relative',
			overflow: 'hidden',
			height: '92px',

			[theme.breakpoints.down(641)]: {
				height: '80px'
			},
			[theme.breakpoints.down(481)]: {
				height: '54px'
			},
			[theme.breakpoints.down(381)]: {
				height: '46px'
			},
			[theme.breakpoints.down(321)]: {
				height: '40px'
			},
		},
		down_icon: {
      position: "absolute",
      pointerEvents: "none",
			top: "calc(62% - px)",
			right: 0,
			width: 14,
			height: 10,
      fontWeight: "bold",
      [theme.breakpoints.down(641)]: {
				top: "calc(62% - 3px)",
				right: 0,
				width: 14,
				height: 10,
			},
			[theme.breakpoints.down(481)]: {
				top: "calc(60% - 2px)",
				right: 0,
				width: 9,
				height: 7,
			},
			[theme.breakpoints.down(421)]: {
				top: "calc(60% - 2px)",
				right: 0,
				width: 8,
				height: 6,
			},
			[theme.breakpoints.down(321)]: {
				top: "calc(60% - 2px)",
				right: 0,
				width: 6,
				height: 4,
			},
		},
		root: {
			padding: '19px',
			// maxWidth: '660px',
			height: "100%",
			margin: '0 auto',
			width: "100%",
			background: theme.palette.common.globalBackground,
			[theme.breakpoints.down('641')]: {
				padding: '16px',
			},
			[theme.breakpoints.down('481')]: {
				padding: '12px',
			},
			[theme.breakpoints.down('421')]: {
				padding: '8px',
			},
		},
		rootSearch: {
			padding: '19px',
			// maxWidth: '660px',
			height: "100%",
			margin: '0 auto',
			width: "100%",
			//marginTop: -24,
			background: theme.palette.common.globalBackground,
			[theme.breakpoints.down('641')]: {
				padding: '16px',
				// marginTop: -21,
			},
			[theme.breakpoints.down('481')]: {
				padding: '12px',
				// marginTop: -15,
			},
			[theme.breakpoints.down('421')]: {
				padding: '8px',
				// marginTop: -14,
			},
			[theme.breakpoints.down('401')]: {
				padding: '8px',
				// marginTop: -10,
			},
		},
		tabContainer: {
      margin: "0 auto",
			width: "100%",
			// marginBottom: 22,
			// [theme.breakpoints.down(641)]: {
			// 	marginBottom: 20,
			// },
			// [theme.breakpoints.down(481)]: {
			// 	marginBottom: 14,
			// },
			// [theme.breakpoints.down(401)]: {
			// 	marginBottom: 12,
			// },
			// [theme.breakpoints.down(381)]: {
			// 	marginBottom: 10,
			// },
			// [theme.breakpoints.down(321)]: {
			// 	marginBottom: 9,
			// },
			
      "& .MuiTab-root": {
				width: "33.33%",
				borderRight: `1px solid ${borderGrey}`,
        // borderBottomLeftRadius: "4px",
        // borderTopLeftRadius: "4px",
        maxWidth: "660px",
				minHeight: "92px",
        [theme.breakpoints.down(641)]: {
					minHeight: "80px",
        },
        [theme.breakpoints.down(481)]: {
					minHeight: "54px",
				},
				[theme.breakpoints.down(401)]: {
					minHeight: "54px",
        },
        [theme.breakpoints.down(381)]: {
					minHeight: "46px",
        },
        [theme.breakpoints.down(321)]: {
					minHeight: "40px",
        },
      },
      "& .MuiTabs-root": {
        minHeight: "92px",
        [theme.breakpoints.down(641)]: {
          minHeight: "80px",
        },
        [theme.breakpoints.down(481)]: {
          minHeight: "54px",
        },
        [theme.breakpoints.down(381)]: {
          minHeight: "46px",
        },
        [theme.breakpoints.down(321)]: {
          minHeight: "40px",
        },
      },
    },
    tab: {
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textTransform: "none",
      textAlign: "center",
      padding: theme.spacing(1),
      opacity: 1,
      fontSize: "22px",
      lineHeight: "18px",
      color: "#000000",
      width: "33.33%",
      height: "100%",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    selected: {
      background: theme.palette.primary.main,
      color: whiteColor,
    },
    indicator: {
      backgroundColor: "unset",
    },
	lastIndicator: {
      [theme.breakpoints.down(641)]: {
        width: "220px !important",
      },
      [theme.breakpoints.down(491)]: {
        width: "167px !important",
      },
      [theme.breakpoints.down(381)]: {
        width: "168px !important",
      },
      [theme.breakpoints.down(361)]: {
        width: "160px !important",
      },
      [theme.breakpoints.down(321)]: {
        width: "142px !important",
      },
      [theme.breakpoints.down(316)]: {
        width: "auto !important",
      },
    },
    firstIndicator: {
      marginLeft: "4px !important",
      [theme.breakpoints.down(641)]: {
        width: "220px !important",
      },
      [theme.breakpoints.down(491)]: {
        width: "167px !important",
      },
      [theme.breakpoints.down(381)]: {
        width: "168px !important",
      },
      [theme.breakpoints.down(361)]: {
        width: "160px !important",
      },
      [theme.breakpoints.down(321)]: {
        width: "142px !important",
      },
      [theme.breakpoints.down(316)]: {
        width: "auto !important",
      },
	},
	brLTB: {
		"& .MuiTab-root": {
			// width: "33.33%",
			borderRight: `1px solid ${borderGrey}`,
			borderBottomLeftRadius: "4px",
			borderTopLeftRadius: "4px",
		},
	},
	brRTB: {
	"& .MuiTab-root": {
		// width: "33.33%",
		borderRight: `1px solid ${borderGrey}`,
		borderBottomRightRadius: "4px",
		borderTopRightRadius: "4px",
	},
	},
		allwrapper: {
			background: "#f5f5f5",
			height: "calc(100% - 264px)",
			padding: "0px 20px",
			overflowY: "auto",
			overflowX: "hidden",
			[theme.breakpoints.down('641')]: {
				background: "#f5f5f5",
				height: "calc(100% - 230px)",
				padding: "0px 16px",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('481')]: {
				background: "#f5f5f5",
				height: "calc(100% - 162px)",
				padding: "0px 12px",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('421')]: {
				background: "#f5f5f5",
				height: "calc(100% - 146px)",
				padding: "0px 8px",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('401')]: {
				background: "#f5f5f5",
				height: "calc(100% - 146px)",
				padding: "0px 8px",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('381')]: {
				background: "#f5f5f5",
				height: "calc(100% - 127px)",
				padding: "0px 8px",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('321')]: {
				background: "#f5f5f5",
				height: "calc(100% - 112px)",
				padding: "0px 8px",
				overflowY: "auto",
				overflowX: "hidden",
			},
		},
		searchbox: {
			background: whiteColor,
			width: "100%",
			"& .MuiOutlinedInput-input::placeholder": {
				padding: "25.5px 14px",
				fontSize: 22,
				[theme.breakpoints.down('641')]: {
					padding: "22.5px 14px",
					fontSize: 21,
				},
				[theme.breakpoints.down('481')]: {
					padding: "16.5px 2px",
					fontSize: 14,
				},
				[theme.breakpoints.down('421')]: {
					padding: "15.5px 0px",
					fontSize: 14,
				},
				[theme.breakpoints.down('401')]: {
					padding: "15.5px 0px",
					fontSize: 13,
				},
				[theme.breakpoints.down('381')]: {
					padding: "13.5px 0px",
					fontSize: 12,
				},
				[theme.breakpoints.down('321')]: {
					padding: "13.5px 0px",
					fontSize: 10,
				},
			},
			"& .MuiOutlinedInput-input": {
				padding: "25.5px 14px",
				fontSize: 22,
				[theme.breakpoints.down('641')]: {
					padding: "22.5px 14px",
					fontSize: 21,
				},
				[theme.breakpoints.down('481')]: {
					padding: "16.5px 14px",
					fontSize: 14,
				},
				[theme.breakpoints.down('421')]: {
					padding: "15.5px 14px",
					fontSize: 14,
				},
				[theme.breakpoints.down('401')]: {
					padding: "15.5px 14px",
					fontSize: 13,
				},
				[theme.breakpoints.down('381')]: {
					padding: "13.5px 14px",
					fontSize: 12,
				},
				[theme.breakpoints.down('321')]: {
					padding: "13.5px 14px",
					fontSize: 10,
				},
			},
		},
		searchicon: {
			width: "1.6em",
			height: "2em",
			color: "#c2c2c2",
			[theme.breakpoints.down('641')]: {
				width: "1.5em",
				height: "3em",
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('481')]: {
				width: "1em",
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('421')]: {
				width: "1em",
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('401')]: {
				width: "0.9em",
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('381')]: {
				width: "0.8em",
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('321')]: {
				width: "0.8em",
				color: "#c2c2c2",
			},
		},
		brandcontainer: {
			height: 140,
			margin: "22px 0px",
			[theme.breakpoints.down('641')]: {
				height: 125,
				margin: "20px 0px",
			},
			[theme.breakpoints.down('481')]: {
				height: 85,
				margin: "14px 0px",
			},
			[theme.breakpoints.down('421')]: {
				height: 80,
				margin: "12px 0px",
			},
			[theme.breakpoints.down('401')]: {
				height: 77,
				margin: "12px 0px",
			},
			[theme.breakpoints.down('381')]: {
				height: 70,
				margin: "9px 0px",
			},
			[theme.breakpoints.down('321')]: {
				height: 65,
				margin: "8px 0px",
			},
		},
		branddetailcontainer: {
			height: 184,
			padding: "0px 24px",
			[theme.breakpoints.down('641')]: {
				height: 164,
				padding: "0px 24px",
			},
			[theme.breakpoints.down('481')]: {
				height: 112,
				padding: "0px 12px",
			},
			[theme.breakpoints.down('421')]: {
				height: 100,
				padding: "0px 12px",
			},
			[theme.breakpoints.down('401')]: {
				height: 100,
				padding: "0px 12px",
			},
			[theme.breakpoints.down('381')]: {
				height: 90,
				padding: 10,
			},
			[theme.breakpoints.down('321')]: {
				height: 85,
				padding: 10,
			},
		},
		branddetailcontainer1: {
			height: 184,
			padding: "24px",
			[theme.breakpoints.down('641')]: {
				height: 164,
				padding: "24px",
			},
			[theme.breakpoints.down('481')]: {
				height: 112,
				padding: "12px",
			},
			[theme.breakpoints.down('421')]: {
				height: 100,
				padding: "12px",
			},
			[theme.breakpoints.down('401')]: {
				height: 100,
				padding: "12px",
			},
			[theme.breakpoints.down('381')]: {
				height: 90,
				padding: 10,
			},
			[theme.breakpoints.down('321')]: {
				height: 85,
				padding: 10,
			},
		},
		imgwrapper: {
			padding: "8px !important",
			[theme.breakpoints.down('641')]: {
				padding: "8px !important",
			},
			[theme.breakpoints.down('481')]: {
				padding: "6px !important",
			},
			[theme.breakpoints.down('421')]: {
				padding: "5px !important",
			},
			[theme.breakpoints.down('381')]: {
				padding: "4px !important",
			},
		},
		brandimg: {
			height: "100%",
			width: "80%",
			borderRadius: 8,
			[theme.breakpoints.down('641')]: {
				height: "100%",
				width: "83%",
				borderRadius: 7,
			},
			[theme.breakpoints.down('481')]: {
				height: "100%",
				width: "83%",
				borderRadius: 6,
			},
			[theme.breakpoints.down('421')]: {
				height: "100%",
				width: "80%",
				borderRadius: 6,
			},
			[theme.breakpoints.down('401')]: {
				height: "100%",
				width: "85%",
				borderRadius: 6,
			},
			[theme.breakpoints.down('381')]: {
				height: "100%",
				width: "80%",
				borderRadius: 7,
			},
			[theme.breakpoints.down('361')]: {
				height: "100%",
				width: "83%",
				borderRadius: 7,
			},
			[theme.breakpoints.down('321')]: {
				height: "100%",
				width: "90%",
				borderRadius: 7,
			},
		},
		branddetails: {
			fontSize: 22,
			[theme.breakpoints.down('641')]: {
				fontSize: 21,
			},
			[theme.breakpoints.down('481')]: {
				fontSize: 14,
			},
			[theme.breakpoints.down('401')]: {
				fontSize: 13,
			},
			[theme.breakpoints.down('381')]: {
				fontSize: 12,
			},
			[theme.breakpoints.down('361')]: {
				fontSize: 11,
			},
			[theme.breakpoints.down('321')]: {
				fontSize: 10,
			},
		},
		floorwrapper: {
			marginBottom: "24px !important",
			[theme.breakpoints.down('641')]: {
				marginBottom: "20px !important",
			},
			[theme.breakpoints.down('481')]: {
				marginBottom: "16px !important",
			},
			[theme.breakpoints.down('421')]: {
				marginBottom: "15px !important",
			},
			[theme.breakpoints.down('401')]: {
				marginBottom: "14px !important",
			},
			[theme.breakpoints.down('381')]: {
				marginBottom: "14px !important",
			},
			[theme.breakpoints.down('361')]: {
				marginBottom: "12px !important",
			},
			[theme.breakpoints.down('321')]: {
				marginBottom: "10px !important",
			},
		},
		expandicon: {
			fontSize: "2.5rem",
			marginTop: 7,
			[theme.breakpoints.down('641')]: {
				fontSize: "2.4rem",
			},
			[theme.breakpoints.down('481')]: {
				fontSize: "1.7rem",
			},
			[theme.breakpoints.down('421')]: {
				fontSize: "1.6rem",
			},
			[theme.breakpoints.down('401')]: {
				fontSize: "1.5rem",
				marginTop: 5,
			},
			[theme.breakpoints.down('381')]: {
				fontSize: "1.4rem",
				marginTop: 4,
			},
			[theme.breakpoints.down('361')]: {
				fontSize: "1.3rem",
				marginTop: 3,
			},
			[theme.breakpoints.down('321')]: {
				fontSize: "1.3rem",
				marginTop: 3,
			},
		},
		expandedsummery: {
			height: "90px !important",
			minHeight: "90px !important",
			borderBottom: "1px solid #d7d7d7",
			[theme.breakpoints.down('641')]: {
				height: "84px !important",
				minHeight: "84px !important",
				borderBottom: "1px solid #d7d7d7",
			},
			[theme.breakpoints.down('481')]: {
				height: "58px !important",
				minHeight: "58px !important",
				borderBottom: "1px solid #d7d7d7",
			},
			[theme.breakpoints.down('401')]: {
				height: "52px !important",
				minHeight: "52px !important",
				borderBottom: "1px solid #d7d7d7",
			},
			[theme.breakpoints.down('381')]: {
				height: "48px !important",
				minHeight: "48px !important",
				borderBottom: "1px solid #d7d7d7",
			},
			[theme.breakpoints.down('361')]: {
				height: "45px !important",
				minHeight: "45px !important",
				borderBottom: "1px solid #d7d7d7",
			},
			[theme.breakpoints.down('321')]: {
				height: "40px !important",
				minHeight: "40px !important",
				borderBottom: "1px solid #d7d7d7",
			},
		},
		expansionsummery: {
			fontSize: 22,
			height: 90,
			minHeight: 90,
			padding: 28,
			[theme.breakpoints.down('641')]: {
				fontSize: 21,
				height: 84,
				padding: 24,
				minHeight: 84,
			},
			[theme.breakpoints.down('481')]: {
				fontSize: 14,
				height: 58,
				padding: 16,
				minHeight: 58,
			},
			[theme.breakpoints.down('401')]: {
				fontSize: 13,
				height: 52,
				minHeight: 52,
			},
			[theme.breakpoints.down('381')]: {
				fontSize: 12,
				height: 48,
				minHeight: 48,
			},
			[theme.breakpoints.down('361')]: {
				fontSize: 11,
				height: 45,
				minHeight: 45,
			},
			[theme.breakpoints.down('321')]: {
				height: 40,
				minHeight: 40,
				fontSize: 10,
			},
		},
		expdetails: {
			fontSize: 22,
			padding: "0px 28px",
			[theme.breakpoints.down('641')]: {
				padding: "0px 24px",
				fontSize: 21,
			},
			[theme.breakpoints.down('481')]: {
				padding: "0px 16px",
				fontSize: 14,
			},
			[theme.breakpoints.down('401')]: {
				padding: "0px 16px",
				fontSize: 13,
			},
			[theme.breakpoints.down('381')]: {
				padding: "0px 16px",
				fontSize: 12,
			},
			[theme.breakpoints.down('361')]: {
				padding: "0px 16px",
				fontSize: 11,
			},
			[theme.breakpoints.down('321')]: {
				padding: "0px 16px",
				fontSize: 10,
			},
		},
		media: {
			height: 0,
			paddingTop: "47% !important",
			borderTop: "1px solid #d7d7d7",
			backgroundSize: "contain !important",
			margin: "0px 28px",
			[theme.breakpoints.down('641')]: {
				margin: "0px 24px",
			},
			[theme.breakpoints.down('481')]: {
				margin: "0px 16px",
			},
			[theme.breakpoints.down('401')]: {
				padding: "0px 16px",
			},
			[theme.breakpoints.down('381')]: {
				margin: "0px 16px",
			},
			[theme.breakpoints.down('361')]: {
				margin: "0px 16px",
			},
			[theme.breakpoints.down('321')]: {
				margin: "0px 16px",
			},
		},
		floorata: {
			padding: "14px 0px",
			fontSize: 22,
			[theme.breakpoints.down('641')]: {
				padding: "14px 0px",
				fontSize: 21,
			},
			[theme.breakpoints.down('481')]: {
				padding: "8px 0px",
				fontSize: 14,
			},
			[theme.breakpoints.down('401')]: {
				padding: "8px 0px",
				fontSize: 13,
			},
			[theme.breakpoints.down('381')]: {
				padding: "8px 0px",
				fontSize: 12,
			},
			[theme.breakpoints.down('361')]: {
				padding: "8px 0px",
				fontSize: 11,
			},
			[theme.breakpoints.down('321')]: {
				padding: "8px 0px",
				fontSize: 10,
			},
		},
		arrowbtn: {
			minWidth: 114,
			height: "98%",
			background: primaryOrange,
			color: whiteColor,
			"&:hover": {
				backgroundColor: primaryOrange,
				color: whiteColor,
			},
			"&:disabled": {
				opacity: "0.4 !important",
				backgroundColor: `${primaryOrange} !important`,
				color: whiteColor,
			},
			[theme.breakpoints.down('641')]: {
				minWidth: 89,
				height: "98%",
				background: primaryOrange,
				color: whiteColor,
			},
			[theme.breakpoints.down('481')]: {
				minWidth: 63,
				height: "98%",
				background: primaryOrange,
				color: whiteColor,
			},
			[theme.breakpoints.down('421')]: {
				minWidth: 58,
				height: "98%",
				background: primaryOrange,
				color: whiteColor,
			},
			[theme.breakpoints.down('401')]: {
				minWidth: 56,
				height: "98%",
				background: primaryOrange,
				color: whiteColor,
			},
			[theme.breakpoints.down('381')]: {
				minWidth: 52,
				height: "98%",
				background: primaryOrange,
				color: whiteColor,
			},
			[theme.breakpoints.down('361')]: {
				minWidth: 50,
				height: "98%",
				background: primaryOrange,
				color: whiteColor,
			},
			[theme.breakpoints.down('321')]: {
				minWidth: 42,
				height: "98%",
				background: primaryOrange,
				color: whiteColor,
			},
		},
		arrowicon: {
			fontSize: "2.7rem",
			[theme.breakpoints.down('641')]: {
				fontSize: "2.5rem",
			},
			[theme.breakpoints.down('481')]: {
				fontSize: "1.5rem",
			},
		},
		malllayout: {
			objectFit: "fill",
			height: 360,
			width: "100%",
			margin: "16px 0px",
			[theme.breakpoints.down('641')]: {
				objectFit: "fill",
				height: 324,
				width: "100%",
				margin: "12px 0px"
			},
			[theme.breakpoints.down('481')]: {
				objectFit: "fill",
				height: 223,
				width: "100%",
				margin: "8px 0px"
			},
			[theme.breakpoints.down('421')]: {
				objectFit: "fill",
				height: 223,
				width: "100%",
				margin: "8px 0px"
			},
			[theme.breakpoints.down('401')]: {
				objectFit: "fill",
				height: 203,
				width: "100%",
				margin: "8px 0px"
			},
			[theme.breakpoints.down('381')]: {
				objectFit: "fill",
				height: 203,
				width: "100%",
				margin: "8px 0px"
			},
			[theme.breakpoints.down('361')]: {
				objectFit: "fill",
				height: 194,
				width: "100%",
				margin: "8px 0px"
			},
			[theme.breakpoints.down('321')]: {
				objectFit: "fill",
				height: 172,
				width: "100%",
				margin: "8px 0px"
			},
		},
		mallwrapper: {
			height: "calc(100% - 228px)",
			overflowY: "auto",
			overflowX: "hidden",
			[theme.breakpoints.down('641')]: {
				height: "calc(100% - 196px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('481')]: {
				height: "calc(100% - 136px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('421')]: {
				height: "calc(100% - 132px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('401')]: {
				height: "calc(100% - 128px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('381')]: {
				height: "calc(100% - 112px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('361')]: {
				height: "calc(100% - 112px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('321')]: {
				height: "calc(100% - 100px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
		},
		mallpadding: {
			padding: "14px 18px",
			[theme.breakpoints.down('641')]: {
				padding: "14px 18px",
			},
			[theme.breakpoints.down('481')]: {
				padding: "10px 12px",
			},
			[theme.breakpoints.down('421')]: {
				padding: "10px 13px",
			},
			[theme.breakpoints.down('401')]: {
				padding: "10px 14px",
			},
			[theme.breakpoints.down('381')]: {
				padding: "10px 14px",
			},
			[theme.breakpoints.down('361')]: {
				padding: "10px 14px",
			},
			[theme.breakpoints.down('321')]: {
				padding: "10px 12px",
			},
		},
		shoptitle: {
			minHeight: 78,
			background: primaryOrange,
			fontWeight: 500,
			color: whiteColor,
			textAlign: "left",
			borderRadius: 4,
			padding: "12px 18px",
			fontSize: 20,
			display: "table",
			width: "100%",
			[theme.breakpoints.down('641')]: {
				minHeight: 68,
				background: primaryOrange,
				fontWeight: 500,
				color: whiteColor,
				textAlign: "left",
				borderRadius: 4,
				padding: "12px 14px",
				fontSize: 19,
				display: "table",
				width: "100%",
			},
			[theme.breakpoints.down('481')]: {
				minHeight: 48,
				background: primaryOrange,
				fontWeight: 500,
				color: whiteColor,
				textAlign: "left",
				borderRadius: 4,
				padding: "8px 12px",
				fontSize: 14,
				display: "table",
				width: "100%",
			},
			[theme.breakpoints.down('421')]: {
				minHeight: 48,
				background: primaryOrange,
				fontWeight: 500,
				color: whiteColor,
				textAlign: "left",
				borderRadius: 4,
				padding: "8px 12px",
				fontSize: 14,
				display: "table",
				width: "100%",
			},
			[theme.breakpoints.down('401')]: {
				minHeight: 45,
				background: primaryOrange,
				fontWeight: 500,
				color: whiteColor,
				textAlign: "left",
				borderRadius: 4,
				padding: "7px 12px",
				fontSize: 13,
				display: "table",
				width: "100%",
			},
			[theme.breakpoints.down('381')]: {
				minHeight: 44,
				background: primaryOrange,
				fontWeight: 500,
				color: whiteColor,
				textAlign: "left",
				borderRadius: 4,
				padding: "6px 12px",
				fontSize: 12,
				display: "table",
				width: "100%",
			},
			[theme.breakpoints.down('361')]: {
				minHeight: 42,
				background: primaryOrange,
				fontWeight: 500,
				color: whiteColor,
				textAlign: "left",
				borderRadius: 4,
				padding: "6px 10px",
				fontSize: 11,
				display: "table",
				width: "100%",
			},
			[theme.breakpoints.down('321')]: {
				minHeight: 35,
				background: primaryOrange,
				fontWeight: 500,
				color: whiteColor,
				textAlign: "left",
				borderRadius: 4,
				padding: "5px 10px",
				fontSize: 10,
				display: "table",
				width: "100%",
			},
		},
		itemwrapper: {
			margin: "6px 0px 6px -11px",
			display: "flex",
			[theme.breakpoints.down('641')]: {
				margin: "6px 0px 6px -10px",
				display: "flex",
			},
			[theme.breakpoints.down('481')]: {
				margin: "4px 0px 4px -9px",
				display: "flex",
			},
			[theme.breakpoints.down('421')]: {
				margin: "4px 0px 4px -8px",
				display: "flex",
			},
			[theme.breakpoints.down('401')]: {
				margin: "3px 0px 3px -5px",
				display: "flex",
			},
			[theme.breakpoints.down('381')]: {
				margin: "2px 0px 2px -5px",
				display: "flex",
			},
			[theme.breakpoints.down('361')]: {
				margin: "2px 0px 2px -5px",
				display: "flex",
			},
			[theme.breakpoints.down('321')]: {
				margin: "2px 0px 2px -5px",
				display: "flex",
			},
		},
		itemiconwrapper: {
			width: "10%",
			color: primaryOrange,
			height: 31,
			[theme.breakpoints.down('641')]: {
				width: "10%",
				color: primaryOrange,
				height: 19,
			},
			[theme.breakpoints.down('481')]: {
				width: "10%",
				color: primaryOrange,
				height: 19,
			},
			[theme.breakpoints.down('421')]: {
				width: "10%",
				color: primaryOrange,
				height: 19,
			},
			[theme.breakpoints.down('401')]: {
				width: "10%",
				color: primaryOrange,
				height: 19,
			},
			[theme.breakpoints.down('381')]: {
				width: "10%",
				color: primaryOrange,
				height: 16,
			},
			[theme.breakpoints.down('361')]: {
				width: "10%",
				color: primaryOrange,
				height: 16,
			},
			[theme.breakpoints.down('321')]: {
				width: "10%",
				color: primaryOrange,
				height: 15,
			},
		},
		itemiconwrapperclock: {
			width: "10%",
			color: primaryOrange,
			height: 31,
			marginLeft: -11,
			[theme.breakpoints.down('641')]: {
				width: "10%",
				color: primaryOrange,
				height: 19,
				marginLeft: -9,
			},
			[theme.breakpoints.down('481')]: {
				width: "10%",
				color: primaryOrange,
				height: 19,
				marginLeft: -6,
			},
			[theme.breakpoints.down('421')]: {
				width: "10%",
				color: primaryOrange,
				height: 19,
				marginLeft: -8,
			},
			[theme.breakpoints.down('401')]: {
				width: "10%",
				color: primaryOrange,
				height: 19,
				marginLeft: -7,
			},
			[theme.breakpoints.down('381')]: {
				width: "10%",
				color: primaryOrange,
				height: 16,
				marginLeft: -6,
			},
			[theme.breakpoints.down('361')]: {
				width: "10%",
				color: primaryOrange,
				height: 16,
				marginLeft: -6,
			},
			[theme.breakpoints.down('321')]: {
				width: "10%",
				color: primaryOrange,
				height: 15,
				marginLeft: -6,
			},
		},
		itemicon: {
			fontSize: "2.3rem",
			[theme.breakpoints.down('641')]: {
				fontSize: "2.0rem",
			},
			[theme.breakpoints.down('481')]: {
				fontSize: "1.6rem",
			},
			[theme.breakpoints.down('421')]: {
				fontSize: "1.5rem",
			},
			[theme.breakpoints.down('401')]: {
				fontSize: "1.4rem",
			},
			[theme.breakpoints.down('381')]: {
				fontSize: "1.3rem",
			},
			[theme.breakpoints.down('361')]: {
				fontSize: "1.2rem",
			},
			[theme.breakpoints.down('321')]: {
				fontSize: "1.1rem",
			},
		},
		itemcontent: {
			margin: "4px 0px 0px 8px",
			width: "90%",
			fontSize: 21,
			[theme.breakpoints.down('641')]: {
				margin: "2px 0px 0px 8px",
				width: "90%",
				fontSize: 19,
			},
			[theme.breakpoints.down('481')]: {
				margin: "3px 0px 0px 5px",
				width: "90%",
				fontSize: 14,
			},
			[theme.breakpoints.down('421')]: {
				margin: "3px 0px 0px 5px",
				width: "90%",
				fontSize: 13,
			},
			[theme.breakpoints.down('401')]: {
				margin: "2px 0px 0px 4px",
				width: "90%",
				fontSize: 13,
			},
			[theme.breakpoints.down('381')]: {
				margin: "2px 0px 0px 4px",
				width: "90%",
				fontSize: 12,
			},
			[theme.breakpoints.down('361')]: {
				margin: "1px 0px 0px 4px",
				width: "90%",
				fontSize: 11,
			},
			[theme.breakpoints.down('321')]: {
				margin: "1px 0px 0px 4px",
				width: "90%",
				fontSize: 10,
			},
		},
		itemcontentclock: {
			margin: "4px 0px 0px 8px",
			width: "90%",
			fontSize: 21,
			[theme.breakpoints.down('641')]: {
				margin: "2px 0px 0px 8px",
				width: "90%",
				fontSize: 19,
			},
			[theme.breakpoints.down('481')]: {
				margin: "3px 0px 0px 6px",
				width: "90%",
				fontSize: 14,
			},
			[theme.breakpoints.down('421')]: {
				margin: "3px 0px 0px 6px",
				width: "90%",
				fontSize: 13,
			},
			[theme.breakpoints.down('401')]: {
				margin: "2px 0px 0px 6px",
				width: "90%",
				fontSize: 13,
			},
			[theme.breakpoints.down('381')]: {
				margin: "2px 0px 0px 6px",
				width: "90%",
				fontSize: 12,
			},
			[theme.breakpoints.down('361')]: {
				margin: "1px 0px 0px 6px",
				width: "90%",
				fontSize: 11,
			},
			[theme.breakpoints.down('321')]: {
				margin: "1px 0px 0px 5px",
				width: "90%",
				fontSize: 10,
			},
		},
		rightarrowicon: {
			fontSize: "2.2rem",
			marginRight: 8,
			marginTop: 20,
			color: "#c2c2c2",
			[theme.breakpoints.down('641')]: {
				fontSize: "2rem",
				marginRight: 6,
				marginTop: 20,
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('481')]: {
				fontSize: "1.5rem",
				marginRight: 6,
				marginTop: 15,
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('421')]: {
				fontSize: "1.4rem",
				marginRight: 6,
				marginTop: 15,
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('401')]: {
				fontSize: "1.3rem",
				marginRight: 6,
				marginTop: 13,
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('381')]: {
				fontSize: "1.3rem",
				marginRight: 6,
				marginTop: 10,
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('361')]: {
				fontSize: "1.2rem",
				marginRight: 4,
				marginTop: 10,
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('321')]: {
				fontSize: "1.1rem",
				marginRight: 4,
				marginTop: 10,
				color: "#c2c2c2",
			},
		},
		rightarrowicon1: {
			fontSize: "2.2rem",
			marginTop: 8,
			color: "#c2c2c2",
			[theme.breakpoints.down('641')]: {
				fontSize: "2rem",
				marginTop: 9,
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('481')]: {
				fontSize: "1.5rem",
				marginTop: 6,
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('421')]: {
				fontSize: "1.4rem",
				marginTop: 6,
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('401')]: {
				fontSize: "1.3rem",
				marginTop: 5,
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('381')]: {
				fontSize: "1.2rem",
				marginTop: 5,
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('361')]: {
				fontSize: "1.2rem",
				marginTop: 5,
				color: "#c2c2c2",
			},
			[theme.breakpoints.down('321')]: {
				fontSize: "1.1rem",
				marginTop: 4,
				color: "#c2c2c2",
			},
		},
		branddetail: {
			fontSize: 22,
			padding: "0px 28px",
			margin: "24px 20px 5px",
			[theme.breakpoints.down('641')]: {
				padding: "0px 24px",
				fontSize: 21,
				margin: "20px 17px 5px",
			},
			[theme.breakpoints.down('481')]: {
				padding: "0px 16px",
				fontSize: 14,
				margin: "18px 13px 5px",
			},
			[theme.breakpoints.down('421')]: {
				padding: "0px 16px",
				fontSize: 14,
				margin: "13px 10px 5px",
			},
			[theme.breakpoints.down('401')]: {
				padding: "0px 16px",
				fontSize: 13,
				margin: "13px 10px 5px",
			},
			[theme.breakpoints.down('381')]: {
				padding: "0px 16px",
				fontSize: 12,
				margin: "11px 10px 3px",
			},
			[theme.breakpoints.down('361')]: {
				padding: "0px 16px",
				fontSize: 11,
				margin: "11px 10px 3px",
			},
			[theme.breakpoints.down('321')]: {
				margin: "9px 9px 2px",
				padding: "0px 16px",
				fontSize: 10,
			},
		},
		branddetailicons: {
			height: 28,
			width: 28,
			margin: "2px 0px 0px 2px",
			[theme.breakpoints.down('641')]: {
				height: 26,
				width: 26,
				margin: "2px 0px 0px 2px",
			},
			[theme.breakpoints.down('481')]: {
				height: 21,
				width: 21,
				margin: "2px 0px 0px 2px",
			},
			[theme.breakpoints.down('421')]: {
				height: 19,
				width: 19,
				margin: "2px 0px 0px 2px",
			},
			[theme.breakpoints.down('401')]: {
				height: 18,
				width: 18,
				margin: "2px 0px 0px 2px",
			},
			[theme.breakpoints.down('381')]: {
				height: 16,
				width: 16,
				margin: "2px 0px 0px 2px",
			},
			[theme.breakpoints.down('361')]: {
				height: 16,
				width: 16,
				margin: "1px 0px 0px 1px",
			},
			[theme.breakpoints.down('321')]: {
				height: 14,
				width: 14,
				margin: "1px 0px 0px 1px",
			},
		},
		branddetailicons1: {
			height: 23,
			width: 30,
			margin: "6px 0px 0px 2px",
			[theme.breakpoints.down('641')]: {
				height: 21,
				width: 26,
				margin: "4px 0px 0px 2px",
			},
			[theme.breakpoints.down('481')]: {
				height: 16,
				width: 21,
				margin: "5px 0px 0px 2px",
			},
			[theme.breakpoints.down('421')]: {
				height: 15,
				width: 19,
				margin: "4px 0px 0px 2px",
			},
			[theme.breakpoints.down('401')]: {
				height: 14,
				width: 18,
				margin: "4px 0px 0px 2px",
			},
			[theme.breakpoints.down('381')]: {
				height: 12,
				width: 16,
				margin: "4px 0px 0px 2px",
			},
			[theme.breakpoints.down('361')]: {
				height: 12,
				width: 16,
				margin: "3px 0px 0px 1px",
			},
			[theme.breakpoints.down('321')]: {
				height: 11,
				width: 15,
				margin: "3px 0px 0px 1px",
			},
		},
		branddetailwrapper: {
			background: "#f5f5f5",
			height: "calc(100% - 544px)",
			overflowY: "auto",
			overflowX: "hidden",
			[theme.breakpoints.down('641')]: {
				background: "#f5f5f5",
				height: "calc(100% - 490px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('481')]: {
				background: "#f5f5f5",
				height: "calc(100% - 335px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('421')]: {
				background: "#f5f5f5",
				height: "calc(100% - 323px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('401')]: {
				background: "#f5f5f5",
				height: "calc(100% - 303px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('381')]: {
				background: "#f5f5f5",
				height: "calc(100% - 293px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('361')]: {
				background: "#f5f5f5",
				height: "calc(100% - 284px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('321')]: {
				background: "#f5f5f5",
				height: "calc(100% - 256px)",
				overflowY: "auto",
				overflowX: "hidden",
			},
		},
		textcontent: {
			fontSize: "22px",
			lineHeight: "1.6",
			textAlign: "justify",
			[theme.breakpoints.down(641)]: {
				fontSize: "21px",
				lineHeight: "1.6",
				textAlign: "justify",
			},
			[theme.breakpoints.down(481)]: {
				fontSize: "14px",
				lineHeight: "1.6",
				textAlign: "justify",
			},
			[theme.breakpoints.down(421)]: {
				fontSize: "14px",
				lineHeight: "1.6",
				textAlign: "justify",
			},
			[theme.breakpoints.down(401)]: {
				fontSize: "13px",
				lineHeight: "1.6",
				textAlign: "justify",
			},
			[theme.breakpoints.down(381)]: {
				fontSize: "12px",
					lineHeight: "1.5",
					textAlign: "justify",
			},
			[theme.breakpoints.down(361)]: {
				fontSize: "11px",
				lineHeight: "1.5",
				textAlign: "justify",
			},
			[theme.breakpoints.down(321)]: {
					fontSize: "10px",
					lineHeight: "1.4",
					textAlign: "justify",
			},
		},
		fontWeight: {
			fontWeight: 600,
			color: "#211f20 !important",
		},
		brandwrapper: {
			background: "#f5f5f5",
			height: "calc(100% - 338px)",
			padding: "0px 20px",
			overflowY: "auto",
			overflowX: "hidden",
			[theme.breakpoints.down('641')]: {
				background: "#f5f5f5",
				height: "calc(100% - 295px)",
				padding: "0px 16px",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('481')]: {
				background: "#f5f5f5",
				height: "calc(100% - 205px)",
				padding: "0px 12px",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('421')]: {
				background: "#f5f5f5",
				height: "calc(100% - 190px)",
				padding: "0px 8px",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('401')]: {
				background: "#f5f5f5",
				height: "calc(100% - 187px)",
				padding: "0px 8px",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('381')]: {
				background: "#f5f5f5",
				height: "calc(100% - 166px)",
				padding: "0px 8px",
				overflowY: "auto",
				overflowX: "hidden",
			},
			[theme.breakpoints.down('321')]: {
				background: "#f5f5f5",
				height: "calc(100% - 147px)",
				padding: "0px 8px",
				overflowY: "auto",
				overflowX: "hidden",
			},
		},
		height100: {
			height: "100% !important",
		},
		dialogModal: {
			'& .MuiDialog-paperWidthMd': {
				width: '100%'
			},
			'& .MuiDialog-paper': {
				background: "rgba(0,0,0,0.8)",
				backdropFilter: "blur(3px)",
				height: "100%",
				margin: 0,
				maxHeight: "100%",
			}
		},
	};
};
