import deleteIcon from "../../assets/icons/submit_bill/delete_btn.svg";
import closeIcon from "../../assets/icons/submit_bill/close_btn.svg";
import pdfIcon from "../../assets/icons/submit_bill/pdf.svg";

export const styles = (theme) => {
  return {
    billatt: {
      display: "flex",
      width: "100%",
      marginBottom: "18px",
      padding: "5px",
      height: "146px",
      backgroundColor: theme.palette.cards.backgroundColor,
      boxShadow: "0px 0px 4px #c3c3c3",
      borderRadius: "4px",
      [theme.breakpoints.down(641)]: {
        height: "131px",
        marginBottom: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "98px",
        marginBottom: "12px",
      },
      [theme.breakpoints.down(381)]: {
        height: "74px",
        marginBottom: "10px",
      },
      [theme.breakpoints.down(321)]: {
        height: "66px",
        marginBottom: "8px",
      },
      "& $img_container": {
        width: "25%",
        height: "126px",
        backgroundColor: theme.palette.img.backgroundColor,
        marginRight: "12px",
        overflow: "hidden",

        [theme.breakpoints.down(641)]: {
          height: "113px",
        },
        [theme.breakpoints.down(481)]: {
          height: "85px",
        },
        [theme.breakpoints.down(381)]: {
          height: "64px",
        },
        [theme.breakpoints.down(321)]: {
          height: "57px",
        },
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "contain",
          objectPosition: "center",
        },
      },
      "& $cnt": {
        width: `calc(75% - ${theme.spacing(3)}px)`,
        display: "flex",
        alignItems: "center",
      },
      "& $content": {
        width: `calc(90% - 10px)`,
        // verticalAlign:
      },
      "& $icon_container": {
        // width: "15%",
        position: "relative",
        "& $iconBtnDel": {
          position: "absolute",
          top: "calc(50% - 30px)",
          [theme.breakpoints.down("sm")]: {
            top: "calc(50% - 26px)",
          },
          [theme.breakpoints.down("xs")]: {
            top: "calc(50% - 22px)",
          },
          "& $deleteIcon": {
            width: "30px",
            height: "30px",
            "--size ": "70px",
            display: "inline-block",
            transition: ".12s",
            "-webkit-mask-size": "cover",
            "mask-size ": "cover",
            [theme.breakpoints.down(641)]: {
              width: "27px",
              height: "27px",
            },
            [theme.breakpoints.down(481)]: {
              width: "21px",
              height: "21px",
            },
            [theme.breakpoints.down(381)]: {
              width: "16px",
              height: "16px",
            },
            [theme.breakpoints.down(321)]: {
              width: "14px",
              height: "14px",
            },
          },
        },
        "& $iconBtnClose": {
          position: "absolute",
          top: "calc(50% - 24px)",
          [theme.breakpoints.down("sm")]: {
            top: "calc(50% - 20px)",
          },
          [theme.breakpoints.down("xs")]: {
            top: "calc(50% - 16px)",
          },
          "& $closeIcon": {
            width: "28px",
            height: "28px",
            "--size ": "70px",
            display: "inline-block",
            transition: ".12s",
            "-webkit-mask-size": "cover",
            "mask-size ": "cover",
            [theme.breakpoints.down(641)]: {
              width: "25px",
              height: "25px",
            },
            [theme.breakpoints.down(481)]: {
              width: "19px",
              height: "19px",
            },
            [theme.breakpoints.down(381)]: {
              width: "14px",
              height: "14px",
            },
            [theme.breakpoints.down(321)]: {
              width: "12px",
              height: "12px",
            },
          },
        },
      },
    },
    bill_name: {
      marginTop: theme.spacing(2),
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left",
      color: theme.palette.typography.texts.color,
      fontWeight: "500",
      fontSize: "20px",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        marginTop: theme.spacing(1),
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
      },
    },
    bill_size: {
      marginTop: "4px",
      textAlign: "left",
      color: "#7e7e7e",
      fontSize: "18px",
      [theme.breakpoints.down(641)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
      },
    },
    img_container: {},
    cnt: {},
    content: {},
    icon_container: {},
    iconBtnDel: {},
    iconBtnClose: {},
    deleteIcon: {},
    closeIcon: {},
    large_progress: {},
    range: {},
    icondeleteCss: {
      backgroundColor: '#cecece',
      " -webkit-mask-image": `url(.${deleteIcon})`,
      "mask-image": `url(.${deleteIcon})`,
      mask: `url(.${deleteIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${deleteIcon}) no-repeat center / contain`,
    },
    closeIconCss: {
      backgroundColor: '#cecece',
      " -webkit-mask-image": `url(.${closeIcon})`,
      "mask-image": `url(.${closeIcon})`,
      mask: `url(.${closeIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${closeIcon}) no-repeat center / contain`,
    },
    pdfIconCss: {
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(.${pdfIcon})`,
      "mask-image": `url(.${pdfIcon})`,
      mask: `url(.${pdfIcon}) no-repeat center / contain`,
      "-webkit-mask": `url(.${pdfIcon}) no-repeat center / contain`,
    },
    pdfCss: {
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      position: "relative",
      top: "28%",
      height: "55px",
        width: "55px",
      [theme.breakpoints.down(641)]: {
        height: "45px",
        width: "45px",
      },
      [theme.breakpoints.down(481)]: {
        height: "40px",
        width: "40px",
      },
      [theme.breakpoints.down(421)]: {
        height: "35px",
        width: "35px",
      },
      [theme.breakpoints.down(381)]: {
        height: "30px",
        width: "30px",
      },
      [theme.breakpoints.down(361)]: {
        height: "25px",
        width: "25px",
      },
      [theme.breakpoints.down(321)]: {
        height: "20px",
        width: "20px",
      },
    },
    icon: {
      objectFit: "contain",
      objectPosition: "center",
      backgroundColor: theme.palette.img.backgroundColor,
      width: "100%",
      height: "146px",
      [theme.breakpoints.down(641)]: {
        height: "131px",
      },
      [theme.breakpoints.down(481)]: {
        height: "99px",
      },
      [theme.breakpoints.down(381)]: {
        height: "74px",
      },
      [theme.breakpoints.down(321)]: {
        height: "66px",
      },
    },
  };
};
