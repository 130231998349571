import { lightGrey } from '../../../../components/UI/Theme';

export const styles = (theme) => {
	return {
		startwrapper: {
			objectFit: "contain",
			backgroundColor: theme.palette.cards.backgroundColor,
			maxWidth: "660px",
			padding: "30px",
			margin: "0 auto",
			textAlign: "center",
			[theme.breakpoints.down(641)]: {
			  padding: "28px",
			  // maxWidth: "588px",
			},
			[theme.breakpoints.down(481)]: {
			  padding: "21px",
			  // maxWidth: "440px",
			},
			[theme.breakpoints.down(381)]: {
			  padding: "15px",
			  // maxWidth: "330px",
			},
			[theme.breakpoints.down(321)]: {
			  padding: "13px",
			  // maxWidth: "294px",
			},
		},
		heading: {
				fontSize: "30px",
				fontWeight: "bold",
				fontStretch: "normal",
				fontStyle: "normal",
				lineHeight: "0.94",
				letterSpacing: "normal",
				textAlign: "center",
				color: theme.palette.typography.texts.color,
				marginTop: "0px",
				[theme.breakpoints.down(641)]: {
					fontSize: "28px",
					lineHeight: "0.96",
					marginTop: "0px",
				},
				[theme.breakpoints.down(481)]: {
					fontSize: "18px",
					lineHeight: "0.91",
					marginTop: "0px",
				},
				[theme.breakpoints.down(421)]: {
					fontSize: "17.5px",
				},
				[theme.breakpoints.down(401)]: {
					fontSize: "17px",
				},
				[theme.breakpoints.down(381)]: {
					fontSize: "16.5px",
					lineHeight: "0.83",
					marginTop: "0px",
				},
				[theme.breakpoints.down(361)]: {
					fontSize: "16px",
				},
				[theme.breakpoints.down(321)]: {
					fontSize: "14px",
					lineHeight: "0.84",
					marginTop: "00px",
				},
		},
    	subHeading: {
			height: "53px",
			width: "100%",
			fontSize: "18px",
			fontWeight: "normal",
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: "1.64",
			letterSpacing: "1px",
			textAlign: "center",
			color: lightGrey,
			padding: "0px 40px",
			margin: "48px 0px 0px 0px",
			[theme.breakpoints.down(641)]: {
					padding: "0px 60px",
					height: "54px",
					lineHeight: "1.64",
					fontSize: "16px",
					letterSpacing: "1px",
					margin: "40px 0px 0px 0px",
			},
			[theme.breakpoints.down(481)]: {
				padding: "0px 22px",
					height: "41px",
					lineHeight: "1.5",
					fontSize: "14px",
					letterSpacing: "normal",
					margin: "30px 0px 0px 0px",
			},
			[theme.breakpoints.down(421)]: {
				padding: "0px 17px",
					fontSize: "13px",
					letterSpacing: "normal",
					margin: "27px 0px 0px 0px",
			},
			[theme.breakpoints.down(401)]: {
				padding: "0px 18px",
					fontSize: "12px",
					letterSpacing: "normal",
					margin: "20px 0px 0px 0px",
			},
			[theme.breakpoints.down(381)]: {
				padding: "0px 17px",
					height: "31px",
					lineHeight: "1.5",
					fontSize: "12px",
					margin: "30px 0px 0px 0px",
					letterSpacing: "normal",
			},
			[theme.breakpoints.down(361)]: {
				padding: "0px 22px",
					fontSize: "11px",
					letterSpacing: "normal",
					margin: "20px 0px 0px 0px",
			},
			[theme.breakpoints.down(321)]: {
				padding: "0px 17px",
					height: "27px",
					fontSize: "10px",
					lineHeight: "1.6",
					margin: "20px 0px 0px 0px",
					letterSpacing: "normal",
			},
		},
		subheading1: {
			padding: "0px 33px",
			fontSize: 18,
			letterSpacing: "1px",
			[theme.breakpoints.down(641)]: {
					padding: "0px 33px",
					fontSize: "16px",
					letterSpacing: "1px",
			},
			[theme.breakpoints.down(481)]: {
				padding: "0px 11px",
				fontSize: "14px",
				letterSpacing: "normal",
			},
			[theme.breakpoints.down(421)]: {
				padding: "0px 11px",
					fontSize: "13px",
					letterSpacing: "normal",
			},
			[theme.breakpoints.down(401)]: {
				padding: "0px 12px",
					fontSize: "12px",
					letterSpacing: "normal",
			},
			[theme.breakpoints.down(381)]: {
				padding: "0px 12px",
					fontSize: "12px",
					letterSpacing: "normal",
			},
			[theme.breakpoints.down(361)]: {
				padding: "0px 13px",
					fontSize: "11px",
					letterSpacing: "normal",
			},
			[theme.breakpoints.down(321)]: {
				padding: "0px 10px",
				fontSize: "10px",
				letterSpacing: "normal",
			},
		},
		subheading2: {
			padding: "0px 21px",
			fontSize: 18,
			letterSpacing: "1px",
			[theme.breakpoints.down(641)]: {
					padding: "0px 31px",
					fontSize: "16px",
					letterSpacing: "1px",
			},
			[theme.breakpoints.down(481)]: {
				padding: "0px 8px",
				fontSize: "14px",
				letterSpacing: "normal",
			},
			[theme.breakpoints.down(421)]: {
				padding: "0px 4px",
					fontSize: "13px",
					letterSpacing: "normal",
			},
			[theme.breakpoints.down(401)]: {
				padding: "0px 7px",
					fontSize: "12px",
					letterSpacing: "normal",
			},
			[theme.breakpoints.down(381)]: {
				padding: "0px 6px",
					fontSize: "12px",
					letterSpacing: "normal",
			},
			[theme.breakpoints.down(361)]: {
				padding: "0px 11px",
					fontSize: "11px",
					letterSpacing: "normal",
			},
			[theme.breakpoints.down(321)]: {
				padding: "0px 8px",
				fontSize: "10px",
				letterSpacing: "normal",
			},
		},
		icons: {
			width: "260px",
			height: "130px",
			marginBottom: 55,
			"mask-size ": "cover",
			objectFit: "contain",
			[theme.breakpoints.down(641)]: {
				width: "260px",
				height: "130px",
				marginBottom: 50,
			},
			[theme.breakpoints.down(481)]: {
			  width: "190px",
				height: "80px",
				marginBottom: 35,
			},
			[theme.breakpoints.down(421)]: {
				width: "180px",
				height: "80px",
				marginBottom: 35,
			},
			[theme.breakpoints.down(401)]: {
				width: "170px",
				height: "80px",
				marginBottom: 32,
			},
			[theme.breakpoints.down(381)]: {
				width: "160px",
				height: "78px",
				marginBottom: 32,
			},
			[theme.breakpoints.down(361)]: {
				width: "150px",
				height: "68px",
				marginBottom: 30,
			},
			[theme.breakpoints.down(321)]: {
			  width: "130px",
				height: "58px",
				marginBottom: 25,
			},
		},
		icons1: {
			width: "270px",
			height: "150px",
			marginRight: 60,
			"mask-size ": "cover",
			objectFit: "contain",
			[theme.breakpoints.down(641)]: {
				width: "260px",
				height: "150px",
				marginRight: 50,
			},
			[theme.breakpoints.down(481)]: {
			  width: "190px",
				height: "120px",
				marginRight: 40,
			},
			[theme.breakpoints.down(421)]: {
				width: "180px",
				height: "120px",
				marginRight: 40,
			},
			[theme.breakpoints.down(401)]: {
				width: "175px",
				height: "110px",
				marginRight: 35,
			},
			[theme.breakpoints.down(381)]: {
				width: "165px",
				height: "90px",
				marginRight: 35,
			},
			[theme.breakpoints.down(361)]: {
				width: "150px",
				height: "80px",
				marginRight: 32,
			},
			[theme.breakpoints.down(321)]: {
			  width: "140px",
				height: "70px",
				marginRight: 30,
				
			},
		},
		heading1: {
			fontSize: "22px",
			[theme.breakpoints.down(641)]: {
				fontSize: "19px",
			},
			[theme.breakpoints.down(481)]: {
				fontSize: "13px",
			},
			[theme.breakpoints.down(421)]: {
				fontSize: "12px",
			},
			[theme.breakpoints.down(401)]: {
				fontSize: "12px",
			},
			[theme.breakpoints.down(381)]: {
				fontSize: "11px",
			},
			[theme.breakpoints.down(361)]: {
				fontSize: "11px",
			},
			[theme.breakpoints.down(321)]: {
				fontSize: "10px",
			},
		},
		completeHere: {
				...theme.palette.typography.doneButtons,
				width: "100%",
				height: "92px",
				fontSize: "22px",
				lineHeight: "18px",
				marginTop: "30px",
				marginBottom: "0px",
				[theme.breakpoints.down(641)]: {
					height: "80px",
					fontSize: "16px",
					lineHeight: "16px",
					marginTop: "24px",
				},
				[theme.breakpoints.down(481)]: {
					height: "54px",
					fontSize: "14px",
					lineHeight: "12px",
					marginTop: "20px",
				},
				[theme.breakpoints.down(421)]: {
					fontSize: "13.5px",
				},
				[theme.breakpoints.down(401)]: {
					fontSize: "13px",
				},
				[theme.breakpoints.down(381)]: {
					height: "46px",
					fontSize: "12.5px",
					lineHeight: "9px",
					marginTop: "14px",
				},
				[theme.breakpoints.down(361)]: {
					fontSize: "12px",
				},
				[theme.breakpoints.down(321)]: {
					height: "40px",
					fontSize: "10px",
					lineHeight: "8px",
					marginTop: "12px",
				},
		},
		buttonContainer: {
			position: "fixed",
			bottom: 25,
			width: "92%",
			margin: "0 29px",
			[theme.breakpoints.down(641)]: {
				margin: "0 22px",
				width: "92%",
				bottom: 22,
			},
			[theme.breakpoints.down(481)]: {
				margin: "0 15px",
				width: "93%",
				bottom: 15,
			},
			[theme.breakpoints.down(421)]: {
				margin: "0 15px",
				width: "92%",
				bottom: 15,
			},
			[theme.breakpoints.down(401)]: {
				margin: "0 15px",
				width: "92%",
				bottom: 15,
			},
			[theme.breakpoints.down(381)]: {
				margin: "0 14px",
				width: "92%",
				bottom: 14,
			},
			[theme.breakpoints.down(361)]: {
				margin: "0 13px",
				width: "92%",
				bottom: 14,
			},
			[theme.breakpoints.down(321)]: {
				margin: "0 13px",
				width: "92%",
				bottom: 12,
			},
		}
	};
};
