// import otpicon from "../../../../assets/icons/login/mobileicon.svg";

const styles = (theme) => {
  return {
    wrapper: {
      objectFit: "contain",
      textAlign: "center",
      maxWidth: "660px",
      margin: "0 auto",
      padding: "30px",
      [theme.breakpoints.down(641)]: {
        padding: "28px",
      },
      [theme.breakpoints.down(481)]: {
        padding: "21px",
      },
      [theme.breakpoints.down(381)]: {
        padding: "15px",
      },
      [theme.breakpoints.down(321)]: {
        padding: "13px",
      },
    },
    heading: {
      fontSize: "32px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "0.94",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.typography.texts.color,
      marginTop: "120px",
      [theme.breakpoints.down(641)]: {
        fontSize: "28px",
        lineHeight: "0.96",
        marginTop: "100px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "18px",
        lineHeight: "0.91",
        marginTop: "80px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "17.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "16.5px",
        lineHeight: "0.83",
        marginTop: "60px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "14px",
        lineHeight: "0.84",
        marginTop: "50px",
      },
    },
    subHeading: {
      height: "53px",
      width: "100%",
      fontSize: "18px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.64",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.typography.texts.subHeading,
      margin: "24px 0px 120px 0px",
      [theme.breakpoints.down(641)]: {
        height: "54px",
        lineHeight: "1.64",
        fontSize: "16px",
        margin: "20px 0px 100px 0px",
      },
      [theme.breakpoints.down(481)]: {
        height: "41px",
        lineHeight: "1.5",
        fontSize: "14px",
        margin: "14px 0px 80px 0px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        height: "31px",
        lineHeight: "1.5",
        fontSize: "12.5px",
        margin: "12px 0px 60px 0px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        height: "27px",
        fontSize: "10px",
        lineHeight: "1.6",
        margin: "10px 0px 50px 0px",
      },
    },
    icons: {
      width: "100%",
      height: "122px",
      "--size ": "70px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      // marginLeft: "30px",
      objectFit: "contain",
      [theme.breakpoints.down("sm")]: {
        // width: "100px",
        height: "122px",
      },
      [theme.breakpoints.down(641)]: {
        // width: "90px",
        height: "109px",
      },
      [theme.breakpoints.down(481)]: {
        // width: "87px",
        height: "78px",
      },
      [theme.breakpoints.down(381)]: {
        // width: "75px",
        height: "62px",
        // marginLeft: "16px",
      },
      [theme.breakpoints.down(321)]: {
        // width: "62px",
        height: "53px",
      },
    },
    verifyotp: {
      ...theme.palette.typography.doneButtons,
      width: "100%",
      height: "92px",
      fontSize: "22px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "0.82",
      letterSpacing: "normal",
      textAlign: "center",
      marginTop: "25px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "16px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "14px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
        fontSize: "12.5px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    codeSpanEdit: {
      color: theme.palette.primary.main,
      marginLeft: "7px",
    },
    code: {
      fontSize: "22px",
      margin: "0px auto",
      fontFamily: "Montserrat",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.64",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.typography.texts.subHeading,
      [theme.breakpoints.down(641)]: {
        lineHeight: "1.64",
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        lineHeight: "1.5",
        fontSize: "14px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.6",
      },
    },
    codeSpan: {
      color: theme.palette.primary.main,
    },
    otpBox: {
      marginRight: "10px",
    },
    otpBoxInput: {
      width: "100%",
      margin: "0 auto 25px auto",
      display: "block",
      textAlign: "center",
      marginRight: "0px",
      "& div": {
        width: "100%",
        margin: "0px",
        "& div": {
          width: "24%",
          display: "block !important",
          float: "left",
          marginRight: "20px",
        },
        [theme.breakpoints.down(641)]: {
          "& div": {
            marginRight: "18px",
          },
        },
        [theme.breakpoints.down(481)]: {
          "& div": {
            marginRight: "13px",
          },
        },
        [theme.breakpoints.down(381)]: {
          "& div": {
            marginRight: "10px",
          },
        },
        [theme.breakpoints.down(321)]: {
          "& div": {
            marginRight: "9px",
          },
        },
        "& div:last-child": {
          marginRight: "0px",
        },
      },
      "&& input": {
        width: "23% !important",
        height: "92px",
        // margin: "0px 6px",
        textAlign: "center",
        "-webkit-appearance": 'none',
        // "-moz-user-select": "none",
        // "-webkit-user-select": "none",
        // "-ms-user-select": "none",
        // userSelect: "none",
        fontSize: "24px",
        borderRadius: "10px",
        border: "1px solid #e4e4e4",
        boxShadow: "none !important",
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        appearance: "none",
      },
      [theme.breakpoints.down(641)]: {
        "&& input": {
          width: "23% !important",
          fontSize: "20px",
          height: "80px",
          lineHeight: "1.2",
          borderRadius: "8.9px",
        },
      },
      [theme.breakpoints.down(481)]: {
        "&& input": {
          width: "23% !important",
          fontSize: "18px",
          height: "54px",
          lineHeight: "1.11",
          borderRadius: "6.7px",
        },
      },
      [theme.breakpoints.down(421)]: {
        "&& input": {
          width: "23% !important",
          fontSize: "17.5px",
        },
      },
      [theme.breakpoints.down(401)]: {
        "&& input": {
          width: "23% !important",
          fontSize: "17px",
        },
      },
      [theme.breakpoints.down(381)]: {
        "&& input": {
          width: "23% !important",
          fontSize: "16.5px",
          height: "46px",
          lineHeight: "1.07",
          borderRadius: "5px",
        },
      },
      [theme.breakpoints.down(361)]: {
        "&& input": {
          width: "23% !important",
          fontSize: "16px",
          height: "46px",
          lineHeight: "1.07",
          borderRadius: "5px",
        },
      },
      [theme.breakpoints.down(321)]: {
        "&& input": {
          width: "23% !important",
          fontSize: "14px",
          height: "40px",
          lineHeight: "13.5px",
          borderRadius: "4.4px",
        },
      },
      "&& input:focus": {
        outline: "transparent",
      },
    },
    linkCls: {
      color: theme.palette.primary.main,
      marginTop: "20px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontSize: "22px",
      fontStyle: "normal",
      lineHeight: "18px",
      letterSpacing: "normal",
      textAlign: "center",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "13px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "8px",
      },
      cursor:'pointer',
    },
    disableLinkCls: {
      color: theme.palette.primary.main,
      opacity: "0.4",
      marginTop: "20px",
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.64",
      letterSpacing: "normal",
      textAlign: "center",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "8px",
      },
      cursor: "not-allowed",
    },
    errorCode: {
      color: theme.palette.typography.texts.error,
      fontSize: "22px",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
      },
    },
    incorrectError: {
      marginBottom: "70px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "70px",
      },
      [theme.breakpoints.down(641)]: {
        marginBottom: "43px",
      },
      [theme.breakpoints.down(481)]: {
        marginBottom: "43px",
      },
      [theme.breakpoints.down(381)]: {
        marginBottom: "30px",
      },
      [theme.breakpoints.down(321)]: {
        marginBottom: "30px",
      },
    },
    // otpIconCss: {
    //   backgroundColor: theme.palette.primary.main,
    //   " -webkit-mask-image": `url(.${otpicon})`,
    //   "mask-image": `url(.${otpicon})`,
    //   mask: `url(.${otpicon}) no-repeat center / contain`,
    //   "-webkit-mask": `url(.${otpicon}) no-repeat center / contain`,
    // },
    otpContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    main_root: {
      display: "flex",
      alignItems: "center", 
      minHeight: "100%",
      backgroundColor: theme.palette.cards.backgroundColor,
    }
  };
};

export default styles;
