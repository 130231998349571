export const styles = (theme) => {
  return {
    reedemDetailscnt: {
      margin: "0px auto",
      padding: "0px",
      display: "block",
      width: "660px",
      position: "relative",
      backgroundColor: theme.palette.common.globalBackground,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    txExpires: {
      margin: "5px 0px 0px 0px",
      padding: "0px 10px 0 0",
      fontWeight: "normal",
      color: "#000",
      width: "100%",
      textAlign: "right",
      display: "block",
      lineHeight: "1.5",
      fontFamily: "Montserrat",
      fontSize: "16px",
      paddingRight: "0.625rem",
      [theme.breakpoints.down(641)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
      },
    },
    reedemCntwrapper: {
      padding: "0px 30px",
      [theme.breakpoints.down("641")]: {
        padding: "0px 28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "0px 21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "0px 15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "0px 13px",
      },
      display: "block",
      width: "100%",
      position: "relative",
    },
    brandName: {
      fontFamily: "Montserrat",
      fontSize: "22px",
      lineHeight: "1.25",
      userSelect: "none",
      width: "92%",
      fontWeight: "500",
      overflow: "hidden",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      [theme.breakpoints.down(641)]: {
        fontSize: "20px",
        lineHeight: "1.4",
        // height: "21px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "18px",
        // width: "175px",
        lineHeight: "1.3",
        // height: "19px",
      },
      [theme.breakpoints.down(430)]: {
        fontSize: "16px",
        // height: "17px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "15.5px",
        // height: "15px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "15px",
        // height: "15px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "14.5px",
        // height: "15px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "14px",
        // height: "15px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "12px",
        // height: "13px",
        lineHeight: "1.23",
      },
    },
    productName: {
      fontFamily: "Montserrat",
      fontSize: "24px",
      height: "60px",
      userSelect: "none",
      lineHeight: "1.4",
      width: "92%",
      marginTop: "10px",
      fontWeight: "600",
      overflow: "hidden",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      [theme.breakpoints.down(641)]: {
        fontSize: "22px",
        lineHeight: "1.3",
        height: "53px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "20px",
        marginTop: "5px",
        height: "48px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(430)]: {
        fontSize: "18px",
        height: "43px",
        // width: "145px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "17.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "16.5px",
        height: "40px",
        marginTop: "4px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "14px",
        lineHeight: "1.3",
        height: "37px",
      },
    },
    txBrandcnt: {
      margin: "0px 0px 10px 0px",
      padding: "0px",
      display: "block",
      fontSize: "18px",
      fontWeight: "400",
      color: "#7e7e7e",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
    priceRow: {
      margin: "24px 0px 25px 0px",
      padding: "0px",
      display: "block",
      width: "100%",
      position: "relative",
      [theme.breakpoints.down(641)]: {
        margin: "15px 0px 25px 0px",
      },
      [theme.breakpoints.down(481)]: {
        margin: "8px 0px 17px 0px",
      },
      [theme.breakpoints.down(381)]: {
        margin: "5px 0px 14px 0px",
      },
      [theme.breakpoints.down(321)]: {
        margin: "5px 0px 12px 0px",
      },
    },
    specialPoints: {
      // textTransform: 'uppercase',
      marginRight: "1rem",
      fontFamily: "Montserrat",
      fontSize: "22px",
      lineHeight: "1.5",
      fontWeight: "bold",
      color: theme.palette.typography.texts.specialPointsColor,
      [theme.breakpoints.down(641)]: {
        fontSize: "20px",
        lineHeight: "1.56",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "18px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(430)]: {
        fontSize: "16px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "17.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "16.5px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "16px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "14px",
        lineHeight: "1.2",
      },
    },
    sellingPoints: {
      // textTransform: 'uppercase',
      textDecorationLine: "line-through",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontWeight: "normal",
      lineHeight: "1.56",
      color: theme.palette.typography.texts.sellingPriceColor,
      [theme.breakpoints.down(641)]: {
        fontSize: "18px",
        lineHeight: "1.56",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "16px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "12px",
        lineHeight: "1.2",
      },
    },
    smPaddeing: {
      [theme.breakpoints.down("xs")]: {
        paddingBottom: "0 !important",
      },
    },
    formBlock: {
      margin: "20px auto",
      padding: "0px",
      display: "block",
      width: "100%",
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        margin: "15px auto",
      },
      "& .Mui-disabled": {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      "& .MuiPaper-elevation1": {
        border: "1px solid #e4e4e4",
        borderRadius: "6px",
        height: "92px",
        padding: " 0px 28px",
        "& .disabled": {
          opacity: "0.5",
          cursor: "not-allowed",
        },
        boxShadow: "0px 0px 0px transparent",
        [theme.breakpoints.down(641)]: {
          height: "80px",
        },
        [theme.breakpoints.down(481)]: {
          height: "54px",
        },
        [theme.breakpoints.down(381)]: {
          height: "46px",
        },
        [theme.breakpoints.down(321)]: {
          height: "40px",
        },
        "& .MuiFormControl-root": {
          width: "100%",
          height: "100%",
          [theme.breakpoints.down("xs")]: {
            height: "45%",
          },
          "& .MuiFormLabel-root": {
            fontSize: "22px",
            color: "#aaaaaa",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "400",
            marginTop: "8px",
            [theme.breakpoints.down("xs")]: {
              fontSize: "20px",
            },
          },
          "& .MuiInputBase-root": {
            lineHeight: "unset",
          },
        },
        "& .MuiSelect-select:focus": {
          backgroundColor: "transparent",
        },
        "& .MuiInput-root": {
          height: "64%",
          [theme.breakpoints.down("xs")]: {
            height: "95%",
          },
          "& .MuiSelect-icon": {
            [theme.breakpoints.down("xs")]: {
              padding: "0px 5px 10px 5px",
            },
          },
        },
        "& .MuiSelect-select.MuiSelect-select": {
          fontSize: "20px",
          color: theme.palette.typography.texts.color,
          padding: "0 10px",
          marginLeft: "-15px",
          lineHeight: "1.25",
          [theme.breakpoints.down(641)]: {
            fontSize: "16px",
            lineHeight: "1.22",
          },
          [theme.breakpoints.down(481)]: {
            fontSize: "14px",
            lineHeight: "1.21",
          },
          [theme.breakpoints.down(421)]: {
            fontSize: "13.5px",
          },
          [theme.breakpoints.down(401)]: {
            fontSize: "13px",
          },
          [theme.breakpoints.down(381)]: {
            fontSize: "12.5px",
            lineHeight: "1.21",
          },
          [theme.breakpoints.down(361)]: {
            fontSize: "12px",
          },
          [theme.breakpoints.down(321)]: {
            fontSize: "10px",
            lineHeight: "1.23",
          },
        },
      },
      "& .MuiInput-underline:before": {
        borderBottom: "0px",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "0px",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):focus, .MuiFormLabel-root.Mui-focused": {
        borderBottom: "0px",
      },
    },
    clearBoth: {
      clear: "both",
    },
    descriptionWrapper: {
      margin: "0px",
      padding: "0px",
      display: "block",
      width: "100%",
      fontSize: "24px",
      lineHeight: "2",
      fontWeight: "400",
      color: theme.palette.typography.texts.color,
      "& span": {
        padding: "0px",
        display: "block",
        width: "100%",
        fontSize: "20px",
        fontWeight: "500",
        color: theme.palette.typography.texts.color,
        lineHeight: "2",
        [theme.breakpoints.down(641)]: {
          // margin: "10px 0",
          fontSize: "18px",
          lineHeight: "2",
        },
        [theme.breakpoints.down(481)]: {
          // margin: "10px 0",
          fontSize: "16px",
          lineHeight: "1.11",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "15.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "15px",
        },
        [theme.breakpoints.down(381)]: {
          fontSize: "14.5px",
          lineHeight: "1.8",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down(321)]: {
          // margin: "10px 0",
          fontSize: "12px",
          lineHeight: "1.6",
        },
        // [theme.breakpoints.down("xs")]: {
        //   margin: "0px 0px 5px 0px",
        //   fontSize: "0.80rem",
        //   lineHeight: "10px",
        // },
      },
      "& ul": {
        marginTop: 0,
      },
    },
    descriptionClass: {
      fontSize: "18px",
      lineHeight: "1.5",
      textAlign: "justify",
      margin: "5px 0px 0px 0px",
      width: "100%",
      paddingBottom:'10px',
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "1.56",
        // margin: "5px 0px 0px 0px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "1.5",
        // margin: "5px 0px 0px 0px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        lineHeight: "1.5",
        // margin: "8px 0px 0px 0px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.33",
        // margin: "12px 0px 5px 0px",
      },
      "& p": {
        marginTop: 0,
      },
      "& ol": {
        marginTop: 0,
      },
    },

    checkGroup: {
      border: "20px",
      height: "153px",
      padding: "30px",
      position: "fixed",
      bottom: "1px",
      fontSize: "10px",
    },
    dialogTitle: {
      textAlign: "center"
    },
    dialogActions: {
      justifyContent: 'center'
    },
    dialogButton: {
      width: '28%',
      height: '30px'
    },
    okBtn: {
      ...theme.palette.typography.doneButtons,
      marginBottom: 0,
      width: '30%',
      fontSize: "22px",
      height: "50px",
      lineHeight: "18px",
      [theme.breakpoints.down(641)]: {
        height: "80px",
        fontSize: "18px",
        lineHeight: "16px",
      },
      [theme.breakpoints.down(481)]: {
        height: "54px",
        fontSize: "16px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(421)]: {
        height: "54px",
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(381)]: {
        height: "46px",
        fontSize: "13px",
        lineHeight: "12px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
        lineHeight: "9px",
      },
      [theme.breakpoints.down(321)]: {
        height: "40px",
        fontSize: "10px",
        lineHeight: "8px",
      },
    },
    checkBoxContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent:'flex-start',
      alignItems:"center",
      padding: "1px",
    },
    checkbxContent:{
      marginRight:"10px",
    },
    
    checkBoxText: {
      textAlign: "center",
      marginLeft: '3px',
      justifyContent: 'center',
      fontWeight: "800",
      color: theme.palette.typography.texts.color,
      position: "relative",
      fontSize: "22px",
      [theme.breakpoints.down(641)]: {
        fontSize: "22px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
        lineHeight: "1.23",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "11px",
      },
    },
    termsCondText: {
      width:'15%',
      height:'20%',
      // padding:'10px',
      color: theme.palette.typography.doneButtons.backgroundColor,
    },
    buttonGroup: {
      backgroundColor:"white",
      width: "660px",
      margin: "0 auto",
      padding: "5px 30px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("641")]: {
        padding: "5px 28px",
        height: "140px",
        bottom: "82px",
      },
      [theme.breakpoints.down("610")]: {
        height: "136px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "5px 21px",
        height: "70px",
        bottom: "60px",
      },
      [theme.breakpoints.down("421")]: {
        bottom: "55px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "5px 15px",
        height: "60px",
        bottom: "50px",
      },
      [theme.breakpoints.down("361")]: {
        bottom: "50px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "5px 13px",
        height: "50px",
        bottom: "45px",
      },
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      width: "100%",
      height: "152px",
      padding: "30px",
      position: "fixed",
      bottom: "86px",
      textAlign: "center",
      "& button": {
        color: "#ffffff",
        height: "92px",
        // margin: "0px 10px",
        padding: "0px 0px",
        backgroundColor: theme.palette.typography.doneButtons.backgroundColor,
        fontSize: "22px",
        lineHeight: "0.82",
        fontWeight: "400",
        width: "50%",
        boxShadow: "inherit",
        [theme.breakpoints.down(641)]: {
          height: "80px",
          fontSize: "16px",
          lineHeight: "16px",
        },
        [theme.breakpoints.down(481)]: {
          height: "54px",
          fontSize: "14px",
          lineHeight: "12px",
        },
        [theme.breakpoints.down(421)]: {
          fontSize: "13.5px",
        },
        [theme.breakpoints.down(401)]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down(381)]: {
          height: "46px",
          fontSize: "12.5px",
          lineHeight: "9px",
        },
        [theme.breakpoints.down(361)]: {
          fontSize: "12px",
        },
        [theme.breakpoints.down(321)]: {
          height: "40px",
          fontSize: "10px",
          lineHeight: "8px",
        },
        "&.MuiButton-containedPrimary:hover": {
          backgroundColor: "#fd8306",
          boxShadow: "inherit",
        },
      },
    },
    optionSelect: {
      margin: "0",
    },
    labelSelect: {
      fontWeight: "400",
      color: "#aaaaaa",
      margin: "0",
      position: "relative",
      top: "8px",
      paddingBottom: "6px",
      marginLeft: "-7px",
      fontSize: "16px",
      lineHeight: "1.67",
      [theme.breakpoints.down(641)]: {
        fontSize: "14px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
        lineHeight: "1.23",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "10.5px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
        lineHeight: "1.25",
      },
    },
    buttonAdd: {
      backgroundColor:"grey",
      ...theme.palette.typography.doneButtons,
      marginRight: "10px",
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        marginRight: "5px",
        marginBottom: 0,
      },
    },
    buttonBuy: {
      ...theme.palette.typography.doneButtons,
      marginLeft: "10px",
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "5px",
        marginBottom: 0,
      },
    },
    mainContainer: {
      backgroundColor: theme.palette.common.globalBackground,
      width: "100%",
      height: "100%",
      display: "block",
      overflow: "hidden",
      margin: "0 auto",
    },
    gridContainer: {
      overflow: "hidden",
      overflowY: "auto",
      // height: "calc(100% - 105px)",
      // [theme.breakpoints.down("641")]: {
      //   height: "calc(100% - 105px)",
      // },
      // [theme.breakpoints.down("481")]: {
      //   height: "calc(100% - 64px)",
      // },
      // [theme.breakpoints.down("381")]: {
      //   height: "calc(100% - 57px)",
      // },
      // [theme.breakpoints.down("361")]: {
      //   height: "calc(100% - 61px)",
      // },
      // [theme.breakpoints.down("321")]: {
      //   height: "calc(100% - 49px)",
      // },
      paddingBottom: "130px",
      [theme.breakpoints.down("641")]: {
        paddingBottom: "120px",
      },
      [theme.breakpoints.down("610")]: {
        paddingBottom: "110px",
      },
      [theme.breakpoints.down("481")]: {
        paddingBottom: "72px",
      },
      [theme.breakpoints.down("381")]: {
        paddingBottom: "60px",
      },
      [theme.breakpoints.down("321")]: {
        paddingBottom: "45px",
      },
    },
    down_icon: {
      position: "absolute",
      pointerEvents: "none",
      right: "10px",
      top: "calc(50% - 26px)",
      // [theme.breakpoints.down(641)]: {
      //   top: " calc(50% - 18px)",
      // },
      [theme.breakpoints.down(481)]: {
        top: " calc(50% - 9px)",
      },
      [theme.breakpoints.down(381)]: {
        top: " calc(50% - 8px)",
      },
      [theme.breakpoints.down(321)]: {
        top: " calc(50% - 7px)",
      },
    },
  };
};
