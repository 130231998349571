import { styles } from "./TransactionsLogStyles";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { withStyles } from "@material-ui/styles";
import { stockAPI } from "../../../../../common/axiosInstance";
import LoyaltyInfo from "../LoyaltyInfo";
import { getStorageItem } from "../../../../../common/localStorage";
import { apiData } from "../../../../../common/common-types";
import { openAlertBox } from "../../../../../common/AlertMessage/AlertMessage";
import {
  Tab,
  Paper,
  Tabs,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import PointsTransaction from "../../../../../components/TransactionTabs/PointsTransaction/PointsTransaction";
import Vouchers from "../../../../../components/TransactionTabs/Vouchers/Vouchers";
import SubmittedBillsTransaction from "../../../../../components/TransactionTabs/SubmittedBillsTransaction/SubmittedBillsTransaction";
import ComingSoon from "../../../../../components/ComingSoon/ComingSoon";
import { green, maroonRed } from "../../../../../components/UI/Theme";

class TransactionsLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      all: {},
      earned: {},
      redeemed: {},
      expiring: {},
      expired: {},
      logData: {},
      points: {},
      isLogDetailsOpen: false,
      dialogData: null,
      ptShortName: "",
      user: null,
      pointsSelection: 0,

      billStageSelection: 0,
      allBills: {},
      processingBills: {},
      rejectedBills: {},
      processedBills: {},
      cancelledBills: {},
      pointsForBills: {},

      archived: {},
      archivedTotalPointsRedeem: 0,
      archivedTotalPointsEarned: 0,

      allVouchers: {},
      voucherFilter_type: 1,
      voucherSelection: 1,
      noRecordFound: false,
    };
  }

  async componentDidMount() {
    this.props.openLoader(true);

    var user;
    var ptShortName;

    if (getStorageItem("user")) {
      await this.getArchivedData();
      user = JSON.parse(getStorageItem("user"));
      ptShortName = getStorageItem("pointShortName")
        ? getStorageItem("pointShortName")
        : "P";

      const data = new FormData();
      data.append("customer_id", user.id);
      data.append("platform", apiData.platform);
      data.append("appVersion", apiData.appVersion);
      data.append("merchant_id", apiData.merchant_id);

      const dataBills = new FormData();
      dataBills.append("customer_id", user.id);
      dataBills.append("platform", apiData.platform);
      dataBills.append("merchant_id", apiData.merchant_id);

      const voucherPayload = new FormData();
      voucherPayload.append("customer_id", user.id);
      voucherPayload.append("platform", apiData.platform);
      voucherPayload.append("merchant_id", apiData.merchant_id);
      voucherPayload.append("filter_type", this.state.voucherFilter_type);

      var logs = await stockAPI(
        data,
        "POST",
        "/getEarnRedeemPointDetailsMonthWise"
      );

      const billLogs = await stockAPI(
        dataBills,
        "POST",
        "/getSubmitBillDetailsMonthwise"
      );

      const vouchers = await stockAPI(
        voucherPayload,
        "POST",
        "/getVouchersMonthWise"
      );

      if (logs && billLogs) {
        let logList = logs.transactionDetails ? logs.transactionDetails : {};
        let earnedObj = {};
        let redeemedObj = {};
        Object.keys(logList).length > 0 &&
          Object.keys(logList).map((item, index) => {
            let earnedArr = [];
            let redeemedArr = [];
            logList[item].map((log, number) => {
              if (log.earned_redeemed_flag === "1") {
                earnedArr.push(log);
              } else {
                redeemedArr.push(log);
              }
            });
            if (earnedArr.length > 0) {
              Object.assign(earnedObj, { [item]: earnedArr });
            }
            if (redeemedArr.length > 0) {
              Object.assign(redeemedObj, { [item]: redeemedArr });
            }
          });

        let billLogList = billLogs.transactionDetails
          ? billLogs.transactionDetails
          : {};
        let processedBillObj = {};
        let processingBillObj = {};
        let rejectedBillObj = {};
        let cancelBillObj = {};
        Object.keys(billLogList).length > 0 &&
          Object.keys(billLogList).map((item) => {
            let processdArr = [];
            let processingArr = [];
            let rejectedArr = [];
            let cancelArr = [];

            billLogList[item].map((bill, number) => {
              if (bill.bill_status && bill.bill_status === "Rejected") {
                rejectedArr.push(bill);
              } else if (bill.bill_status && bill.bill_status === "Processed") {
                processdArr.push(bill);
              } else if (bill.bill_status && bill.bill_status === "Cancelled") {
                cancelArr.push(bill);
              } else if (
                bill.bill_status &&
                bill.bill_status === "Processing"
              ) {
                processingArr.push(bill);
              }
            });

            if (processdArr.length > 0) {
              Object.assign(processedBillObj, { [item]: processdArr });
            }

            if (processingArr.length > 0) {
              Object.assign(processingBillObj, { [item]: processingArr });
            }

            if (rejectedArr.length > 0) {
              Object.assign(rejectedBillObj, { [item]: rejectedArr });
            }

            if (cancelArr.length > 0) {
              Object.assign(cancelBillObj, { [item]: cancelArr });
            }
          });

        this.setState(
          {
            all: logs.transactionDetails ? logs.transactionDetails : {},
            logData: logs.transactionDetails ? logs.transactionDetails : {},
            earned: earnedObj,
            redeemed: redeemedObj,
            expiring: logs.expiringPoints ? logs.expiringPoints : {},
            expired: logs.expiredPoints ? logs.expiredPoints : {},
            points: logs.pointsLog,
            ptShortName,
            allBills: billLogs.transactionDetails
              ? billLogs.transactionDetails
              : {},
            processingBills: processingBillObj,
            rejectedBills: rejectedBillObj,
            processedBills: processedBillObj,
            cancelledBills: cancelBillObj,
            pointsForBills: billLogs.pointsLog ? billLogs.pointsLog : {},
            allVouchers: vouchers["e-voucher"] ? vouchers["e-voucher"] : {},
          },
          () =>
            this.setState({
              noRecordFound: this.props.logData ? true : false,
            })
        );
        this.props.changeLogData(
          Object.keys(this.props.logData).length !== 0
            ? this.props.logData
            : logs.transactionDetails
            ? logs.transactionDetails
            : {}
        );
        this.props.openLoader(false);
      } else {
        this.props.openLoader(false);
        // openAlertBox("Oops something went wrong!", "error");
      }
    } else {
      this.props.openLoader(false);
      this.props.logout();
    }
  }

  getArchivedData = async () => {
    const data = new FormData();
    data.append("customer_id", JSON.parse(getStorageItem("user")).id);
    data.append("platform", apiData.platform);
    data.append("appVersion", apiData.appVersion);
    data.append("merchant_id", apiData.merchant_id);
    const archivedLogs = await stockAPI(
      data,
      "POST",
      "/getCustomerArchiveData"
    );
    if (archivedLogs) {
      let logList = archivedLogs.transactionDetails
        ? archivedLogs.transactionDetails
        : {};
      let archivedTotalPointsEarned = archivedLogs.total_points_earned
        ? archivedLogs.total_points_earned
        : 0;
      let archivedTotalPointsRedeem = archivedLogs.total_redeem_points
        ? archivedLogs.total_redeem_points
        : 0;
      let archivedObj = {};

      Object.keys(logList).length > 0 &&
        Object.keys(logList).map((item, index) => {
          let archivedArr = [];
          logList[item].map((log, number) => {
            archivedArr.push(log);
          });
          if (archivedArr.length > 0) {
            Object.assign(archivedObj, { [item]: archivedArr });
          }
        });

      this.setState({
        archived: archivedObj,
        archivedTotalPointsEarned,
        archivedTotalPointsRedeem,
      });
    }
  };

  roundOff = (number) => {
    return parseFloat(number) % 1 !== 0
      ? parseFloat(number).toFixed(2)
      : Math.floor(number);
  };

  handleVoucherFilterChange = (e) => {
    this.props.openLoader(true);
    this.setState(
      {
        voucherSelection: e.target.value,
        voucherFilter_type: e.target.value,
      },
      async () => {
        const user = JSON.parse(getStorageItem("user"));
        const voucherPayload = new FormData();

        voucherPayload.append("customer_id", user.id);
        voucherPayload.append("platform", apiData.platform);
        voucherPayload.append("merchant_id", apiData.merchant_id);
        voucherPayload.append("filter_type", this.state.voucherFilter_type);

        const vouchers = await stockAPI(
          voucherPayload,
          "POST",
          "/getVouchersMonthWise"
        );

        await this.setState({
          allVouchers: vouchers["e-voucher"] ? vouchers["e-voucher"] : {},
        });
        this.props.openLoader(false);
      }
    );
  };

  handlePointsDropdownChange = (e) => {
    const value = e.target.value;

    this.props.changePointsSelection(value);
    switch (value) {
      case 0: {
        this.props.changeLogData(this.state.all);
        this.setState({
          pointsSelection: value,
          logData: this.state.all,
        });
        break;
      }
      case 1: {
        this.props.changeLogData(this.state.earned);
        this.setState({
          pointsSelection: value,
          logData: this.state.earned,
        });
        break;
      }
      case 2: {
        this.props.changeLogData(this.state.redeemed);
        this.setState({
          pointsSelection: value,
          logData: this.state.redeemed,
        });
        break;
      }
      case 3: {
        this.props.changeLogData(this.state.expiring);
        this.setState({
          pointsSelection: value,
          logData: this.state.expiring,
        });
        break;
      }
      case 4: {
        this.props.changeLogData(this.state.expired);
        this.setState({
          pointsSelection: value,
          logData: this.state.expired,
        });
        break;
      }
      case 5: {
        this.props.changeLogData(this.state.archived);
        this.setState({
          pointsSelection: value,
          logData: this.state.archived,
        });
        break;
      }

      default:
        break;
    }
  };

  handleBillsDropdownChange = (e) => {
    const value = e.target.value;
    this.props.changeBillStageSelection(value);
    switch (value) {
      case 0:
        this.props.changeLogData(this.state.allBills);
        this.setState({
          billStageSelection: value,
          logData: this.state.allBills,
        });
        break;

      case 1:
        this.props.changeLogData(this.state.processingBills);
        this.setState({
          billStageSelection: value,
          logData: this.state.processingBills,
        });
        break;

      case 2:
        this.props.changeLogData(this.state.rejectedBills);
        this.setState({
          billStageSelection: value,
          logData: this.state.rejectedBills,
        });
        break;

      case 3:
        this.props.changeLogData(this.state.processedBills);
        this.setState({
          billStageSelection: value,
          logData: this.state.processedBills,
        });
        break;

      case 4:
        this.props.changeLogData(this.state.cancelledBills);
        this.setState({
          billStageSelection: value,
          logData: this.state.cancelledBills,
        });
        break;

      default:
        break;
    }
  };

  handleTabChange = (e, tabNumber) => {
    this.props.changeTransactionTab(tabNumber);
    this.props.changeBillStageSelection(0);
    this.props.changePointsSelection(0);
    switch (tabNumber) {
      case 0: {
        this.props.changeLogData(this.state.all);
        this.setState({
          activeTab: tabNumber,
          logData: this.state.all,
          pointsSelection: 0,
          billStageSelection: 0,
        });
        break;
      }
      case 1: {
        this.props.changeLogData(this.state.allBills);
        this.setState({
          activeTab: tabNumber,
          logData: this.state.allBills,
          pointsSelection: 0,
          billStageSelection: 0,
        });
        break;
      }
      case 2: {
        this.props.changeLogData(this.state.redeemed);
        this.setState({
          activeTab: tabNumber,
          logData: this.state.redeemed,
          pointsSelection: 0,
          billStageSelection: 0,
        });
        break;
      }
      case 3: {
        this.props.changeLogData(this.state.allVouchers);
        this.setState({
          activeTab: tabNumber,
          logData: this.state.allVouchers,
          pointsSelection: 0,
          billStageSelection: 0,
        });
        break;
      }
      default:
        break;
    }
  };

  render() {
    const {
      classes,
      activeTransactionTab,
      billStageSelection,
      pointsSelection,
      logData,
    } = this.props;

    const {
      points,
      ptShortName,
      pointsForBills,
      noRecordFound,
      allVouchers,
      voucherSelection,
    } = this.state;
// console.log("LogData", logData, "Expired- > ", this.state.expired)
    const tabContainer = (
      <Paper
        className={classnames(classes.tabContainer, {
          [classes.brLTB]: activeTransactionTab === 0,
          [classes.brRTB]: activeTransactionTab === 3,
        })}
      >
        <Tabs
          classes={{
            indicator: classnames(classes.indicator, {
              [classes.firstIndicator]: activeTransactionTab === 0,
              [classes.lastIndicator]: activeTransactionTab === 3,
            }),
          }}
          value={activeTransactionTab}
          onChange={this.handleTabChange}
          centered
          scrollButtons="on"
        >
          <Tab
            label="Points"
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
          />
          <Tab
            label={
              <span>
                Submitted
                <br />
                Bills
              </span>
            }
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
          />
          <Tab
            label="Redemptions"
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
          />
          <Tab
            label="Vouchers"
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
          />
        </Tabs>
      </Paper>
    );

    return (
      <Grid className={classes.root}>
        <Grid>
          <LoyaltyInfo />
        </Grid>
        <Grid className={classes.container}>
          <Grid>
            <Grid>{tabContainer}</Grid>
            <Grid>
              {(activeTransactionTab === 0 || activeTransactionTab === 2) && (
                <PointsTransaction
                  logData={logData}
                  noRecordFound={noRecordFound}
                  pointsSelection={pointsSelection}
                  handlePointsChange={this.handlePointsDropdownChange}
                  classObj={classes}
                  ptShortName={ptShortName}
                  roundOff={this.roundOff}
                  activeTab={activeTransactionTab}
                />
              )}
              {activeTransactionTab === 1 && (
                <SubmittedBillsTransaction
                  logData={logData}
                  billStageSelection={billStageSelection}
                  handleBillsChange={this.handleBillsDropdownChange}
                  classObj={classes}
                  ptShortName={ptShortName}
                  roundOff={this.roundOff}
                />
              )}
              {activeTransactionTab === 3 && (
                <Vouchers
                  logData={allVouchers}
                  noRecordFound={noRecordFound}
                  voucherSelection={voucherSelection}
                  handleVoucherFilterChange={this.handleVoucherFilterChange}
                  classObj={classes}
                  ptShortName={ptShortName}
                  roundOff={this.roundOff}
                  activeTab={activeTransactionTab}
                />
              )}
              {/* <ComingSoon message="Shop away!" /> */}
            </Grid>
          </Grid>
          {Object.keys(logData).length > 0 && (
            <Grid>
              <Paper className={classes.paperContainer}>
                <List
                  dense={true}
                  classes={{ padding: classes.pointsListContainer }}
                >
                  {activeTransactionTab === 0 && pointsSelection === 0 ? (
                    <div>
                      <ListItem>
                        <ListItemText
                          primary="Total"
                          classes={{ primary: classes.pointsText }}
                        />
                        <ListItemSecondaryAction
                          className={classes.listPointsContainer}
                        >
                          <Typography
                            className={classes.listPoints}
                            style={{ color: green, textAlign: "left" }}
                          >
                            {points.total_points_earned
                              ? "+ " + this.roundOff(points.total_points_earned)
                              : 0}{" "}
                            {ptShortName}
                          </Typography>
                          <Typography
                            className={classes.listPoints}
                            style={{
                              color: maroonRed,
                              float: "right",
                              textAlign: "right",
                            }}
                          >
                            {points.total_redeem_points
                              ? "- " + this.roundOff(points.total_redeem_points)
                              : 0}{" "}
                            {ptShortName}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Total Points Expired"
                          classes={{ primary: classes.pointsText }}
                        />
                        <ListItemSecondaryAction
                          className={classes.listPointsContainer}
                        >
                          <Typography
                            className={classes.listPoints}
                            style={{
                              width: "100%",
                              color: maroonRed,
                              float: "right",
                              textAlign: "right",
                            }}
                          >
                            {points.total_expiry_points
                              ? "- " + this.roundOff(points.total_expiry_points)
                              : 0}{" "}
                            {ptShortName}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Balance as on Today"
                          classes={{ primary: classes.pointsText }}
                        />
                        <ListItemSecondaryAction
                          className={classes.listPointsContainer}
                        >
                          <Typography
                            className={classes.listPoints}
                            style={{
                              width: "100%",
                              float: "right",
                              textAlign: "right",
                            }}
                          >
                            {points.total_available_points
                              ? this.roundOff(points.total_available_points)
                              : 0}{" "}
                            {ptShortName}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      {/* <ListItem className={classes.grandTotalItem}>
											<ListItemText
												primary="Grand Total"
												classes={{ primary: classes.pointsText }}
											/>
											<ListItemSecondaryAction
												className={classes.listPointsContainer}
											>
												<Typography className={classes.listPoints}>
												{points.grand_total_earn_points
													? this.roundOff(points.grand_total_earn_points)
													: 0}{" "}
												{ptShortName}
												</Typography>
												<Typography
												className={classes.listPoints}
												style={{ fontWeight: "bold", float: "right" }}
												>
												{points.grand_total_redeem_expire_available
													? this.roundOff(
														points.grand_total_redeem_expire_available
													)
													: 0}{" "}
												{ptShortName}
												</Typography>
											</ListItemSecondaryAction>
											</ListItem> */}
                    </div>
                  ) : activeTransactionTab === 0 && pointsSelection === 1 ? (
                    <ListItem>
                      <ListItemText
                        primary={"Total Points Earned"}
                        classes={{ primary: classes.pointsText }}
                      />
                      <ListItemSecondaryAction
                        className={classes.listPointsContainer}
                      >
                        <Typography
                          className={classes.listPoints}
                          style={{
                            width: "100%",
                            float: "right",
                            textAlign: "right",
                          }}
                        >
                          {points.total_points_earned
                            ? "+ " + this.roundOff(points.total_points_earned)
                            : 0}{" "}
                          {ptShortName}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ) : activeTransactionTab === 0 && pointsSelection === 2 ? (
                    <ListItem>
                      <ListItemText
                        primary={"Total Points Redeemed"}
                        classes={{ primary: classes.pointsText }}
                      />
                      <ListItemSecondaryAction
                        className={classes.listPointsContainer}
                      >
                        <Typography
                          className={classes.listPoints}
                          style={{
                            width: "100%",
                            float: "right",
                            textAlign: "right",
                          }}
                        >
                          {points.total_redeem_points
                            ? "- " + this.roundOff(points.total_redeem_points)
                            : 0}{" "}
                          {ptShortName}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ) : activeTransactionTab === 0 && pointsSelection === 3 ? (
                    <ListItem>
                      <ListItemText
                        primary={"Total Points Expiring"}
                        classes={{ primary: classes.pointsText }}
                      />
                      <ListItemSecondaryAction
                        className={classes.listPointsContainer}
                      >
                        <Typography
                          className={classes.listPoints}
                          style={{
                            width: "100%",
                            float: "right",
                            textAlign: "right",
                          }}
                        >
                          {points.total_expiry_points_in_next_three_month
                            ? this.roundOff(
                                points.total_expiry_points_in_next_three_month
                              )
                            : 0}{" "}
                          {ptShortName}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ) : activeTransactionTab === 0 && pointsSelection === 4 ? (
                     <ListItem>
                      <ListItemText
                        primary={"Total Points Expired"}
                        classes={{ primary: classes.pointsText }}
                      />
                      <ListItemSecondaryAction
                        className={classes.listPointsContainer}
                      >
                        <Typography
                          className={classes.listPoints}
                          style={{
                            width: "100%",
                            float: "right",
                            textAlign: "right",
                          }}
                        >
                          {points.total_expiry_points
                            ? this.roundOff(
                                points.total_expiry_points
                              )
                            : 0}{" "}
                          {ptShortName}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    
                  ) : activeTransactionTab === 2 ? (
                    <ListItem>
                      <ListItemText
                        primary={"Total Points Redeemed"}
                        classes={{ primary: classes.pointsText }}
                      />
                      <ListItemSecondaryAction
                        className={classes.listPointsContainer}
                      >
                        <Typography
                          className={classes.listPoints}
                          style={{
                            width: "100%",
                            float: "right",
                            textAlign: "right",
                          }}
                        >
                          {points.total_redeem_points
                            ? "- " + this.roundOff(points.total_redeem_points)
                            : 0}{" "}
                          {ptShortName}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ) : activeTransactionTab === 1 ? (
                    <div>
                      <ListItem>
                        <ListItemText
                          primary={"Total Earned"}
                          classes={{ primary: classes.pointsText }}
                        />
                        <ListItemSecondaryAction
                          className={classes.listPointsContainer}
                        >
                          <Typography
                            className={classes.listPoints}
                            style={{
                              width: "100%",
                              float: "right",
                              color: green,
                              textAlign: "right",
                            }}
                          >
                            {pointsForBills.total_points_earned
                              ? "+ " +
                                this.roundOff(
                                  pointsForBills.total_points_earned
                                )
                              : 0}{" "}
                            {ptShortName}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Balance as on Today"
                          classes={{ primary: classes.pointsText }}
                        />
                        <ListItemSecondaryAction
                          className={classes.listPointsContainer}
                        >
                          <Typography
                            className={classes.listPoints}
                            style={{
                              width: "100%",
                              float: "right",
                              textAlign: "right",
                            }}
                          >
                            {pointsForBills.total_available_points
                              ? this.roundOff(
                                  pointsForBills.total_available_points
                                )
                              : 0}{" "}
                            {ptShortName}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </div>
                  ) : null}
                </List>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(TransactionsLog)
);
