import React, { Fragment, useState } from "react";
import { styles } from "./OffersCardStyle.js";
import { withStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import BillImage from "../../components/BillImage/BillImage";
import default_product from "../../assets/images/product/default_product.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { openAlertBox } from "../../common/AlertMessage/AlertMessage.js";

const OffersCard = (props) => {
  const { classes, viewOfferDetails, key, offer, convertDate, id } = props;
  const [singleBillImagePopup, setsingleBillImagePopup] = useState(false);
  const [singleBillImageSelected, setsingleBillImageSelected] = useState(null);
  const formatDate = (date) => {
    return moment(date).format("Do MMM YYYY");
  };
  const formatStartDate = (date) => {
    return moment(date).format("Do MMM");
  };

  const handleBillImageClickedClosed = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setsingleBillImagePopup(false);
    setsingleBillImageSelected(null);
  };

  //clicked on the image to view the details of single click bill image uploaded
  const handleBillImageClicked = (e, imageSrc) => {
    e.preventDefault();
    e.stopPropagation();
    setsingleBillImageSelected(imageSrc);
    setsingleBillImagePopup(true);
  };
  return (
    <div key={key} id={id} className={classes.root + " " + classes.noSelect}>
      <div
        className={classes.rootChild + " " + classes.noSelect}
        onClick={() => viewOfferDetails(offer)}
      >
        <div
          className={classes.imageContainer + " " + classes.noSelect}
          onClick={(e) =>
            handleBillImageClicked(e, offer.offer_image[0] || default_product)
          }
        >
          <img alt="offer" src={offer.offer_image[0] || default_product} />
        </div>
        <div className={classes.details}>
          <div className={classes.main_content}>
            <Typography className={classes.txbrand}>
              {offer.offer_brand ? offer.offer_brand : props.merchant_name}
            </Typography>
            <Typography className={classes.txapparel}>
              {offer.offer_title}
            </Typography>
          </div>
          {offer.location_count && offer.location_count > 0 && (
            <div className={classes.txtlocation}>
              <Typography
                style={{
                  textAlign: "left",
                  verticalAlign: "top",
                  lineHeight: "normal",
                }}
              >
                <span className={classes.location}>
                  {`${offer.location_count} ${
                    offer.location_count > 1 ? "Locations" : "Location"
                  } `}
                </span>
              </Typography>
            </div>
          )}
          <div className={classes.related_expiry_box}>
            {offer.offer_expire_text ? (
              <span className={classes.textExpires}>
                {offer.offer_expire_text}
              </span>
            ) : (
              <Fragment>
                {offer.display_custom_text_or_date === "1" && (
                  <Fragment>
                    <span className={classes.textExpires}>
                      {formatStartDate(offer.offer_start_date)} -{" "}
                      {formatDate(offer.offer_end_date)}
                    </span>
                  </Fragment>
                )}

                {offer.display_custom_text_or_date === "2" && (
                  <span className={classes.textExpires}>
                    {convertDate(offer.offer_start_date) !== "Invalid date"
                      ? `Starts ${convertDate(offer.offer_start_date)}`
                      : ""}
                  </span>
                )}

                {offer.display_custom_text_or_date === "3" && (
                  <Fragment>
                    <span className={classes.textExpires}>
                      {convertDate(offer.offer_end_date) !== "Invalid date"
                        ? `Expires ${convertDate(offer.offer_end_date)}`
                        : ""}
                    </span>
                  </Fragment>
                )}

                {offer.display_custom_text_or_date === "4" && (
                  <Fragment>
                    <span className={classes.textExpires}>
                      {offer.custom_text}
                    </span>
                  </Fragment>
                )}
              </Fragment>
            )}
            {offer.coupon_applicable && (
              <div
                className={classes.coupons_box + " " + classes.noSelect}
                onClick={(e) => e.stopPropagation()}
              >
                <CopyToClipboard
                  text={offer.coupon_applicable}
                  onCopy={() =>
                    openAlertBox("Coupon copied to clipboard", "success")
                  }
                >
                  <Typography className={classes.coupons}>
                    {offer.coupon_applicable}
                  </Typography>
                </CopyToClipboard>
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={singleBillImagePopup}
        keepMounted
        onClose={handleBillImageClickedClosed}
        maxWidth="md"
        className={classes.dialogModal}
      >
        <BillImage
          multi={false}
          open={singleBillImagePopup}
          imgSrc={singleBillImageSelected}
          okBtnHandler={handleBillImageClickedClosed}
        />
      </Dialog>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(OffersCard);
