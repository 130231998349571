import React, { Component, Fragment } from "react";
import AppBar from "@material-ui/core/AppBar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import back from "../../../assets/icons/header/back_icon.svg";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { apiData } from "../../../../src/common/common-types";
import { getStorageItem } from "../../../common/localStorage";

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const styles = (theme) => {
  return {
    toolbarMargin: {
      minHeight: "86px",
			[theme.breakpoints.down("641")]: {
			  minHeight: "82px",
			},
			[theme.breakpoints.down("481")]: {
			  minHeight: "64px",
			},
			[theme.breakpoints.down("421")]: {
			  minHeight: "54px",
			},
			[theme.breakpoints.down("381")]: {
			  minHeight: "50px",
			},
			[theme.breakpoints.down("361")]: {
			  minHeight: '48px'
			},
			[theme.breakpoints.down("321")]: {
			  minHeight: '43px'
			},
    },
    appbar: {
      height: "auto",
      backgroundColor: theme.palette.primary.main
    },
    backBtn: {
      width: "19px",
      height: "35px",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      fontSize: "6px",
      [theme.breakpoints.down("641")]: {
        width: "17px",
        height: "30px",
      },
      [theme.breakpoints.down("481")]: {
        width: "12px",
        height: "22px",
      },
      [theme.breakpoints.down("381")]: {
        width: "9px",
        height: "16px",
      },
      [theme.breakpoints.down("321")]: {
        width: "8px",
        height: "15px",
      },
    },
    mainHeader: {
      color: theme.palette.typography.texts.headerTextColor,
      fontSize: "20px",
      marginLeft: "15%",
      [theme.breakpoints.down("641")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
    },
    yourBalance: {
      color: theme.palette.typography.texts.headerTextColor,
      fontSize: "18px",
      [theme.breakpoints.down("481")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    balancePoints: {
      // textTransform:'uppercase',
      fontWeight: "600",
      float: "right",
      color: theme.palette.typography.texts.headerTextColor,
      fontSize: "24px",
      [theme.breakpoints.down("641")]: {
        fontSize: "22px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "15.5px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
    },
    toolbar: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: 0,
      "& .MuiIconButton-root": {
        padding: 0,
      },
    },
    backBtnIconCss: {
      backgroundColor: theme.palette.header.backIconColor,
      " -webkit-mask-image": `url(.${back})`,
      "mask-image": `url(.${back})`,
      mask: `url(.${back}) no-repeat center / contain`,
      "-webkit-mask": `url(.${back}) no-repeat center / contain`,
    },
    regular: {
			minHeight: "86px",
			padding: "11px 30px 11px 30px",
			[theme.breakpoints.down("641")]: {
			  padding: "9px 26px 9px 26px",
			  minHeight: "82px",
			},
			[theme.breakpoints.down("481")]: {
			  padding: "7px 20px 7px 20px",
			  minHeight: "64px",
			},
			[theme.breakpoints.down("421")]: {
			  minHeight: "54px",
			},
			[theme.breakpoints.down("381")]: {
			  padding: "0px 15px 0px 15px",
			  minHeight: "50px",
			},
			[theme.breakpoints.down("361")]: {
			  minHeight: '48px'
			},
			[theme.breakpoints.down("321")]: {
			  padding: "0px 13px 0px 13px",
			  minHeight: '43px'
			},
		}
  };
};

class HeaderCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverAnchorEl: null,
    };
  }

  componentDidMount = async () => {
    if (getStorageItem("user")) {
      const formData = new URLSearchParams();
      formData.append("userId", JSON.parse(getStorageItem("user")).id);
      formData.append("merchant_id", apiData.merchant_id);
      formData.append("platform", apiData.platform);
      formData.append("customer_id",getStorageItem("user") && JSON.parse(getStorageItem("user")).id);

      await this.props.merchantDetails(formData);
    }
  };

  pushToCart = (props) => {
    this.props.history.push("/myCart");
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <ElevationScroll>
          <AppBar position="fixed" className={classes.appbar}>
            <Grid>
              <Toolbar disableGutters className={classes.toolbar} classes={{regular: classes.regular}}>
                <IconButton
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  <span
                    className={classes.backBtnIconCss + " " + classes.backBtn}
                  />
                </IconButton>
                <Typography variant="h3" className={classes.mainHeader}>
                  My Cart (
                  {this.props.cartItemCount && this.props.cartItemCount.count
                    ? this.props.cartItemCount.count
                    : 0}
                  )
                </Typography>
                <Grid>
                  <Grid className={classes.yourBalance}>Your Balance</Grid>
                  <Grid className={classes.balancePoints}>
                    {this.props.merchantDetailsResponse && 
                    (parseFloat(this.props.merchantDetailsResponse.current_points_balance) % 1 !== 0
                    ? parseFloat(this.props.merchantDetailsResponse.current_points_balance).toFixed(2)
                    : Math.floor(this.props.merchantDetailsResponse.current_points_balance))}{" "}
                    {this.props.merchantDetailsResponse &&
                      this.props.merchantDetailsResponse.points_unit_short_name}
                  </Grid>
                </Grid>
              </Toolbar>
            </Grid>
          </AppBar>
        </ElevationScroll>
        <div className={classes.toolbarMargin} />
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(HeaderCart));
