export const styles = (theme) => {
  return {
    root: {
      width: "70px",
      height: "70px",
      // borderRadius: '50%',
      //   padding: "10px",
      [theme.breakpoints.down("481")]: {
        width: "50px",
        height: "50px",
      },
      [theme.breakpoints.down("421")]: {
        width: "45px",
        height: "45px",
      },
      [theme.breakpoints.down("321")]: {
        width: "40px",
        height: "40px",
      },

      "& img": {
        height: "100%",
        // borderRadius: "4px",
        width: "100%",
        objectPosition: "center",
        objectFit: "contain",
      },
    },
  };
};
