import { red } from "../UI/Theme";

export const styles = (theme) => {
  return {
    root: {
      width: "100%",
      display: "flex",
      borderBottom: "solid 1px #e4e4e4",
      borderTop: "solid 1px #e4e4e4",
      cursor: "pointer",
      padding: "20px 30px",
      lineHeight: 1.2,
      [theme.breakpoints.down("610")]: {
        padding: "15px 28px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "12px 21px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "9px 15px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "6px 13px",
      },
    },
    notication_icon: {
      marginRight: "15px",
      margin: "auto",
    },
    notication_content: {
      width: "calc(100% - 60px)",
      [theme.breakpoints.down("481")]: {
        width: "calc(100% - 50px)",
      },
      [theme.breakpoints.down("421")]: {
        width: "calc(100% - 45px)",
      },
      [theme.breakpoints.down("321")]: {
        width: "calc(100% - 40px)",
      },
    },
    common_font_styling: {
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.67,
      letterSpacing: "normal",
    },
    notification_label: {
      color: "#211f20",
      overflow: "hidden",
      textOverflow: "ellipsis",
      userSelect: "none",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      fontWeight: "bold",
      fontSize: "24px",
      [theme.breakpoints.down("641")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "11px",
      },
    },
    notification_content: {
      overflow: "hidden",
      userSelect: "none",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      lineHeight: 1.2,
      fontSize: "22px",
      [theme.breakpoints.down("641")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "10px",
      },
    },
    notification_time: {
      color: "#7e7e7e",
      fontSize: "18px",
      [theme.breakpoints.down("641")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "9px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "8px",
      },
    },
    deleteBtnIcon: {
      width: "34px",
      objectFit: "contain",
      objectPosition: "center",
      [theme.breakpoints.down("641")]: {
        width: "30px",
      },
      [theme.breakpoints.down("481")]: {
        width: "22px",
      },
      [theme.breakpoints.down("421")]: {
        width: "19px",
      },
      [theme.breakpoints.down("381")]: {
        width: "17px",
      },
      [theme.breakpoints.down("361")]: {
        width: "16px",
      },
      [theme.breakpoints.down("321")]: {
        width: "15px",
      },
    },
  };
};
