import React, { Fragment } from 'react';
import { Card, CardContent, Grid, Drawer, Typography, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { styles } from './RedeemSubHeaderStyles';
import { withStyles } from '@material-ui/styles';
import { connect } from "react-redux";

const RedeemSubHeader = (props) => {
	const {
		isSubHeader,
		classes,
		isListView,
		isBottomDrawer,
		sortProducts,
		merchantDetailsResponse,
		openListView,
		openFilterDrawer,
		openBottomDrawer
	} = props;
	const filterList = (
		<div className={classes.sortList}>
			<Typography
				key="sort-by"
				variant="h5"
				style={{
					textAlign: 'center',
					margin: '1.5rem 0px ',
					color: '#211f20'
				}}
			>
				Sort By
			</Typography>
			<Divider className={classes.dividerCss} />
			<List>
				{[
					{ text: 'Newest' },
					{ text: 'Popularity' },
					{ text: 'Oldest' },
					{ text: 'Expiring' }
				].map((item, index) => (
					<Fragment key={item.text}>
						<ListItem button onClick={() => sortProducts(item.text)}>
							<ListItemText primary={item.text} />
						</ListItem>
						{index === 3 ? '' : <Divider />}
					</Fragment>
				))}
			</List>
		</div>
	);
	return (
		<Fragment>
			{isSubHeader && (
				<Card className={classes.subHeader}>
					<CardContent>
						<Grid container>
							<Grid item xs={6} style={{ marginTop: '-3px' }}>
								<Grid className={classes.yourBal}>YOUR BALANCE</Grid>
								<Grid className={classes.yourPoints}>
									{merchantDetailsResponse && 
										(parseFloat(merchantDetailsResponse.current_points_balance) % 1 !== 0
										? parseFloat(merchantDetailsResponse.current_points_balance).toFixed(2)
										: Math.floor(merchantDetailsResponse.current_points_balance))
										}
									{' '}
									<span>{merchantDetailsResponse && merchantDetailsResponse.points_unit_name}</span>
								</Grid>
							</Grid>
							<Grid item xs={6} className={classes.icon_container}>
								<span
									className={classes.sortIconCss + ' ' + classes.cardIcons}
									onClick={openBottomDrawer}
								/>
								{
									props.filterApplied ?
									<p onClick={openFilterDrawer} className={classes.filter_icon_container}>
										<span className={classes.filter_dot} />
										<span  className={classes.cardIcons + ' ' +classes.filterIconCss} />
									</p>
									:
									<span
										className={classes.filterIconCss + ' ' + classes.cardIcons}
										onClick={openFilterDrawer}
									/>
								}
								<Drawer
									anchor={'bottom'}
									variant="temporary"
									open={isBottomDrawer}
									onClose={openBottomDrawer}
								>
									{filterList}
								</Drawer>
								{isListView ? (
									<span
										className={classes.gridIconCss + ' ' + classes.cardIcons}
										onClick={openListView}
									/>
								) : (
									<span
										className={classes.listViewIconCss + ' ' + classes.cardIcons}
										onClick={openListView}
									/>
								)}
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			)}
		</Fragment>
	);
};

const mapStateToProps = state => {
	return {
		filterApplied: state.reducerFilter.filterApplied
	}
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(RedeemSubHeader));