import * as actionType from "./actionTypes";

const initialState = {
  isAuthenticated: false,
  isOtpVerified: false,
  isOtpTime: false,
  isExceedTIme: false,
  resendCount: 0,
  wrongOtpCount: 0,
  isUserVisited: false,
  isReferalEnable: false,
};

export default function (state = initialState, action) {
  if (action.type === actionType.AUTH_USER) {
    return {
      ...state,
      isAuthenticated: action.payload,
    };
  }
  if (action.type === actionType.IS_OTP_VERIFIED) {
    return {
      ...state,
      isOtpVerified: action.payload,
    };
  }
  if (action.type === actionType.IS_OTP_TIME_STARTED) {
    return {
      ...state,
      isOtpTime: action.payload,
    };
  }
  if (action.type === actionType.IS_EXCEED_OTP_TIME_STARTED) {
    return {
      ...state,
      isExceedTIme: action.payload,
    };
  }
  if (action.type === actionType.SET_RESEND_COUNT) {
    return {
      ...state,
      resendCount: state.resendCount + 1,
    };
  }
  if (action.type === actionType.SET_TRY_COUNT) {
    return {
      ...state,
      wrongOtpCount: state.wrongOtpCount + 1,
    };
  }
  if (action.type === actionType.RESET_RESEND_COUNT) {
    return {
      ...state,
      resendCount: 0,
    };
  }
  if (action.type === actionType.RESET_TRY_COUNT) {
    return {
      ...state,
      wrongOtpCount: 0,
    };
  }
  if (action.type === actionType.SET_USER_VISITED) {
    return {
      ...state,
      isUserVisited: action.payload,
    };
  }
  if (action.type === actionType.RESET_O_VERIFICATION) {
    return {
      ...state,
      isAuthenticated: false,
      isOtpVerified: false,
      isOtpTime: false,
      isExceedTIme: false,
      resendCount: 0,
      wrongOtpCount: 0,
      isUserVisited: false,
    };
  }

  if (action.type === actionType.IS_REFERAL_ENABLE) {
    return {
      ...state,
      isReferalEnable: action.payload
    }
  }

  return state;
}
