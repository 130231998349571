import { color, grey } from '../../../components/UI/Theme';
import circle_tick from '../../../assets/icons/submit_bill/green_tick.svg';
export const styles = (theme) => {
	return {
		pageContainer: {
			height: 'auto',
			// overflowY: 'auto',
			position: 'relative',
			backgroundColor: theme.palette.common.globalBackground
		},
		root: {
			maxWidth: '660px',
			margin: '0 auto',
			height: '100%',
			padding: '30px',
			[theme.breakpoints.down('610')]: {
				padding: '28px'
			},
			[theme.breakpoints.down('481')]: {
				padding: '21px'
			},
			[theme.breakpoints.down('381')]: {
				padding: '15px'
			},
			[theme.breakpoints.down('321')]: {
				padding: '13px'
			}
		},
		itemContainer: {
			display: 'inline-block',
			width: '50%',
			verticalAlign: 'top',
			position: 'relative'
		},
		padd_right: {
			paddingRight: '15px',
			[theme.breakpoints.down(641)]: {
				paddingRight: '14px'
			},
			[theme.breakpoints.down(481)]: {
				paddingRight: '10.5px'
			},
			[theme.breakpoints.down(381)]: {
				paddingRight: '7.5px'
			},
			[theme.breakpoints.down(321)]: {
				paddingRight: '6.5px'
			}
		},
		padd_left: {
			paddingLeft: '15px',
			[theme.breakpoints.down(641)]: {
				paddingLeft: '14px'
			},
			[theme.breakpoints.down(481)]: {
				paddingLeft: '10.5px'
			},
			[theme.breakpoints.down(381)]: {
				paddingLeft: '7.5px'
			},
			[theme.breakpoints.down(321)]: {
				paddingLeft: '6.5px'
			}
		},
		fieldSet: {
			borderRadius: '5px',
			border: 'solid 1px #e4e4e4',
			margin: '0px auto 30px auto',
			display: 'block',
			width: '100%',
			padding: '10px 10px 0 11px',
			backgroundColor: '#fff',
			'&& input': {
				fontSize: '20px',
				lineHeight: '13.5px',
				color: color
			},
			[theme.breakpoints.down(641)]: {
				margin: '0px auto 20px auto',
				'&& input': {
					fontSize: '16px',
					lineHeight: '1.2'
				}
			},
			[theme.breakpoints.down(481)]: {
				margin: '0px auto 16px auto',
				'&& input': {
					fontSize: '14px',
					lineHeight: '1.11'
				}
			},
			[theme.breakpoints.down(421)]: {
				'&& input': {
					fontSize: '13.5px'
				}
			},
			[theme.breakpoints.down(401)]: {
				'&& input': {
					fontSize: '13px'
				}
			},
			[theme.breakpoints.down(381)]: {
				margin: '0px auto 12px auto',
				'&& input': {
					fontSize: '12.5px',
					lineHeight: '1.07'
				}
			},
			[theme.breakpoints.down(361)]: {
				'&& input': {
					fontSize: '12px'
				}
			},
			[theme.breakpoints.down(321)]: {
				margin: '0px auto 12px auto',
				'&& input': {
					fontSize: '10px',
					lineHeight: '13.5px'
				}
			},
			'& label': {
				paddingLeft: '14px'
			},
			'& div': {
				margin: '0px',
				height: '100%',
				width: '100%',
				padding: '0px'
			},
			'&& p': {
				marginTop: '1px'
			},
			'&& div:before': {
				borderBottom: 'none'
			},
			'&& div:hover': {
				borderBottom: 'none'
			}
		},
		submitBtn: {
			...theme.palette.typography.doneButtons,
			width: '100%',
			fontSize: '22px',
			lineHeight: '18px',
			margin: '0 !important',
			height: '92px',
			[theme.breakpoints.down(641)]: {
				height: '80px',
				fontSize: '16px',
				lineHeight: '16px'
			},
			[theme.breakpoints.down(481)]: {
				height: '54px',
				fontSize: '14px',
				lineHeight: '12px'
			},
			[theme.breakpoints.down(421)]: {
				fontSize: '13.5px'
			},
			[theme.breakpoints.down(401)]: {
				fontSize: '13px'
			},
			[theme.breakpoints.down(381)]: {
				height: '46px',
				fontSize: '12.5px',
				lineHeight: '9px'
			},
			[theme.breakpoints.down(361)]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down(321)]: {
				height: '40px',
				fontSize: '10px',
				lineHeight: '8px'
			}
		},
		cancelBtn: {
			backgroundColor: `${theme.palette.typography.cancelButtons.backgroundColor}`,
			color: color,
			width: '100%',
			height: '92px',
			fontSize: '22px',
			lineHeight: '18px',
			[theme.breakpoints.down(641)]: {
				height: '80px',
				fontSize: '16px',
				lineHeight: '16px'
			},
			[theme.breakpoints.down(481)]: {
				height: '54px',
				fontSize: '14px',
				lineHeight: '12px'
			},
			[theme.breakpoints.down(421)]: {
				fontSize: '13.5px'
			},
			[theme.breakpoints.down(401)]: {
				fontSize: '13px'
			},
			[theme.breakpoints.down(381)]: {
				height: '46px',
				fontSize: '12.5px',
				lineHeight: '9px'
			},
			[theme.breakpoints.down(361)]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down(321)]: {
				height: '40px',
				fontSize: '10px',
				lineHeight: '8px'
			}
		},
		selectListUl: {
			margin: '0px',
			padding: '0px',
			display: 'block',
			listStyle: 'none',
			position: 'absolute',
			top: '45px',
			background: '#fffefe',
			border: '1px solid #ccc',
			height: '120px',
			overflowY: 'scroll',
			zIndex: '99',
			width: '100%'
		},
		less: {
			height: '66%',
			overflow: 'hidden',
			[theme.breakpoints.down(421)]: {
				height: '55%'
			},
			[theme.breakpoints.down(361)]: {
				height: '66%'
			},
			[theme.breakpoints.down(321)]: {
				height: '67%'
			}
		},
		selectListLi: {
			width: '100%',
			height: 'auto',
			margin: '0px',
			padding: '10px 10px',
			display: 'block',
			fontSize: '0.875rem',
			textAlign: 'left',
			borderBottom: '1px solid #ccc',
			cursor: 'pointer',
			[theme.breakpoints.between(360, 420)]: {
				padding: '12px'
			}
		},
		cssLabel: {
			color: `${grey} !important`,
			'&.focused': {
				color: grey
			},
			'&.shrink': {
				transform: 'translate(1px, 12px) scale(0.75)',
				[theme.breakpoints.down('481')]: {
					transform: 'translate(1px, 16px) scale(0.75)'
				},
				[theme.breakpoints.down('381')]: {
					transform: 'translate(1px, 14px) scale(0.75)'
				},
				[theme.breakpoints.down('321')]: {
					transform: 'translate(1px, 18px) scale(0.75)'
				}
			}
		},
		cssUnderline: {
			'&:after': {
				borderBottom: 'none'
			},
			'&&&&:before': {
				borderBottom: 'none'
			}
		},
		input: {
			'&:-webkit-autofill': {
				WebkitBoxShadow: '0 0 0 1000px white inset'
			}
		},
		formControl: {
			// minHeight: 'calc(100% - 92px)',
			height: 'auto',
			// overflowY: 'scroll',
			// marginBottom: '92px',
			'& .MuiTextField-root': {
				height: '92px'
			},
			'& .MuiInputLabel-root': {
				marginTop: '5px',
				fontSize: '20px'
			},
			'& .MuiFormHelperText-root': {
				fontSize: '0.623rem'
			},
			[theme.breakpoints.down(641)]: {
				// minHeight: 'calc(100% - 80px)',
				// marginBottom: '80px',
				'& .MuiTextField-root': {
					height: '80px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '16px',
					marginTop: '1px',
					fontFamily: 'Montserrat'
				}
			},
			[theme.breakpoints.down(481)]: {
				// minHeight: 'calc(100% - 56px)',
				// marginBottom: '56px',
				'& .MuiTextField-root': {
					height: '54px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '14px',
					marginTop: '-7px'
				}
			},
			[theme.breakpoints.down(421)]: {
				'& .MuiInputLabel-root': {
					fontSize: '13.5px'
				}
			},
			[theme.breakpoints.down(401)]: {
				'& .MuiInputLabel-root': {
					fontSize: '13px'
				}
			},
			[theme.breakpoints.down(381)]: {
				// minHeight: 'calc(100% - 46px)',
				// marginBottom: '46px',
				'& .MuiTextField-root': {
					height: '46px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '12.5px',
					marginTop: '-8px'
				}
			},
			[theme.breakpoints.down(361)]: {
				'& .MuiInputLabel-root': {
					fontSize: '12px'
				}
			},
			[theme.breakpoints.down(321)]: {
				// minHeight: 'calc(100% - 40px)',
				// marginBottom: '40px',
				'& .MuiTextField-root': {
					height: '40px'
				},
				'& .MuiInputLabel-root': {
					marginTop: '-11px',
					fontSize: '10px'
				}
			}
		},
		formControl1: {
			height: 'auto',
			// marginBottom: '92px',
			// [theme.breakpoints.down(641)]: {
			// 	marginBottom: '80px'
			// },
			// [theme.breakpoints.down(481)]: {
			// 	marginBottom: '56px'
			// },
			// [theme.breakpoints.down(381)]: {
			// 	marginBottom: '46px'
			// },
			// [theme.breakpoints.down(321)]: {
			// 	marginBottom: '40px',
			// }
		},
		formContent: {
			minHeight: 'calc(100% - 92px)',
			'& .MuiTextField-root': {
				height: '92px'
			},
			'& .MuiInputLabel-root': {
				marginTop: '5px',
				fontSize: '20px'
			},
			'& .MuiFormHelperText-root': {
				fontSize: '0.623rem'
			},
			[theme.breakpoints.down(641)]: {
				minHeight: 'calc(100% - 80px)',
				'& .MuiTextField-root': {
					height: '80px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '16px',
					marginTop: '1px',
					fontFamily: 'Montserrat'
				}
			},
			[theme.breakpoints.down(481)]: {
				minHeight: 'calc(100% - 56px)',
				'& .MuiTextField-root': {
					height: '54px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '14px',
					marginTop: '-7px'
				}
			},
			[theme.breakpoints.down(421)]: {
				'& .MuiInputLabel-root': {
					fontSize: '13.5px'
				}
			},
			[theme.breakpoints.down(401)]: {
				'& .MuiInputLabel-root': {
					fontSize: '13px'
				}
			},
			[theme.breakpoints.down(381)]: {
				minHeight: 'calc(100% - 46px)',
				'& .MuiTextField-root': {
					height: '46px'
				},
				'& .MuiInputLabel-root': {
					fontSize: '12.5px',
					marginTop: '-8px'
				}
			},
			[theme.breakpoints.down(361)]: {
				'& .MuiInputLabel-root': {
					fontSize: '12px'
				}
			},
			[theme.breakpoints.down(321)]: {
				minHeight: 'calc(100% - 40px)',
				'& .MuiTextField-root': {
					height: '40px'
				},
				'& .MuiInputLabel-root': {
					marginTop: '-11px',
					fontSize: '10px'
				}
			}
		},
		menuItem: {
			fontSize: '18px',
			[theme.breakpoints.down('641')]: {
				fontSize: '16px'
			},
			[theme.breakpoints.down('481')]: {
				fontSize: '14px'
			},
			[theme.breakpoints.down('421')]: {
				fontSize: '13.5px'
			},
			[theme.breakpoints.down('401')]: {
				fontSize: '13px'
			},
			[theme.breakpoints.down('381')]: {
				fontSize: '12.5px'
			},
			[theme.breakpoints.down('361')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down('321')]: {
				fontSize: '10px'
			}
		},
		down_icon: {
			position: 'absolute',
			pointerEvents: 'none',
			right: '0px',
			top: 'calc(50% - 10px)'
		},
		selectInput: {
			overflow: 'hidden',
			position: 'relative',
			borderRadius: '5px',
			border: 'solid 1px #e4e4e4',
			backgroundColor: '#fff',
			margin: '0px auto 30px auto',
			padding: '15px 10px 38.25px 11px',
			[theme.breakpoints.down('641')]: {
				padding: '15px 10px 31px 11px',
				margin: '0px auto 28px auto'
			},
			[theme.breakpoints.down('481')]: {
				margin: '0px auto 21px auto',
				padding: '15px 10px 7.39px 11px'
			},
			[theme.breakpoints.down('381')]: {
				margin: '0px auto 15px auto',
				padding: '15px 10px 1.75px 11px'
			},
			[theme.breakpoints.down('321')]: {
				padding: '13.14px 10px 0px 11px',
				margin: '0px auto 13px auto'
			},
			'& .MuiInputBase-input:focus': {
				backgroundColor: 'unset'
			}
		},
		disabledField: {
			// backgroundColor: '#f1f1f1'
		},
		btnContainer: {
			maxWidth: '600px',
			width: 'calc(100% - 60px)',
			paddingBottom: '30px',
			position: 'fixed',
			bottom: 0,
			[theme.breakpoints.down('610')]: {
				width: 'calc(100% - 56px)',
				paddingBottom: '28px'
			},
			[theme.breakpoints.down('481')]: {
				width: 'calc(100% - 42px)',
				paddingBottom: '21px'
			},
			[theme.breakpoints.down('381')]: {
				width: 'calc(100% - 30px)',
				paddingBottom: '15px'
			},
			[theme.breakpoints.down('321')]: {
				width: 'calc(100% - 26px)',
				paddingBottom: '13px'
			}
		},
		greenTickCss: {
			backgroundColor: theme.palette.tick.green,
			' -webkit-mask-image': `url(.${circle_tick})`,
			'mask-image': `url(.${circle_tick})`,
			mask: `url(.${circle_tick}) no-repeat center / contain`,
			'-webkit-mask': `url(.${circle_tick}) no-repeat center / contain`
		},
		tick: {
			position: 'absolute',
			display: 'inline-block',
			transition: '.12s',
			'-webkit-mask-size': 'cover',
			'mask-size ': 'cover',
			height: '28px',
			width: '28px',
			bottom: '25px',
			right: '20px',
			[theme.breakpoints.down(481)]: {
				height: '16px',
				width: '16px',
				//   top: "5px",
				bottom: '19px',
				right: '16px'
			},
			[theme.breakpoints.down(420)]: {
				bottom: '19px',
				right: '16px'
			},
			[theme.breakpoints.down(380)]: {
				bottom: '16px',
				right: '12px'
			},
			[theme.breakpoints.down(361)]: {
				height: '14px',
				width: '14px',
				bottom: '15px'
			},
			[theme.breakpoints.down(321)]: {
				bottom: '13px'
			}
		},
		email_verifyBtn_container: {
			display: 'flex',
			alignItems: 'flex-start',
			overflow: 'hidden',
			justifyContent: 'space-between'
		},
		emailFieldContainer: {
			display: 'inline-block',
			width: '72%'
		},
		verifyBtnContainer: {
			width: '24%',
			display: 'inline-block'
		},
		verifyBtn: {
			...theme.palette.typography.doneButtons,
			width: '100%',
			fontSize: '22px',
			lineHeight: '18px',
			margin: '0 !important',
			[theme.breakpoints.down(641)]: {
				fontSize: '16px',
				lineHeight: '16px'
			},
			[theme.breakpoints.down(481)]: {
				fontSize: '14px',
				lineHeight: '12px'
			},
			[theme.breakpoints.down(421)]: {
				fontSize: '13.5px'
			},
			[theme.breakpoints.down(401)]: {
				fontSize: '13px'
			},
			[theme.breakpoints.down(381)]: {
				fontSize: '12.5px',
				lineHeight: '9px'
			},
			[theme.breakpoints.down(361)]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down(321)]: {
				fontSize: '10px',
				lineHeight: '8px'
			}
		},
		active: {
			visibility: 'visible'
		},
		hidden: {
			visibility: 'hidden',
		}
	};
};
