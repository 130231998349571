export const AUTH_USER = "AUTH_USER";
export const IS_OTP_VERIFIED = "IS_OTP_VERIFIED";
export const IS_OTP_TIME_STARTED = "IS_OTP_TIME_STARTED";
export const IS_EXCEED_OTP_TIME_STARTED = "IS_EXCEED_OTP_TIME_STARTED";
export const SET_RESEND_COUNT = "SET_RESEND_COUNT";
export const SET_TRY_COUNT = "SET_TRY_COUNT";
export const RESET_RESEND_COUNT = "RESET_RESEND_COUNT";
export const RESET_TRY_COUNT = "RESET_TRY_COUNT";
export const SET_USER_VISITED = "SET_USER_VISITED";
export const RESET_O_VERIFICATION = "RESET_O_VERIFICATION";
export const IS_REFERAL_ENABLE="IS_REFERAL_ENABLE";