import { stockAPI } from "../../../../common/axiosInstance";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import * as types from "./types";

export const getBillUploadGuidelines = (params) => {
  return (dispatch) => {
    stockAPI(params, "POST", "/getBillUploadGuidelines").then((response) => {
      if (response) {
        dispatch({
          type: types.GET_BILL_UPLOAD_GUIDELINES_SUCCESS,
          guideLines: response.bill_upload_guidelines,
        });
      } else {
        dispatch({
          type: types.GET_BILL_UPLOAD_GUIDELINES_FAIL,
          guideLines: [],
        });
        // openAlertBox('Oops something went wrong!', 'error');
      }
    });
  };
};
