import { stockAPI } from '../../../../common/axiosInstance';
import * as actionTypes from './actionTypes';
import { openAlertBox } from '../../../../common/AlertMessage/AlertMessage';
import { store } from '../../../../store';
import { getStorageItem } from '../../../../common/localStorage';
import { apiData } from '../../../../common/common-types';
import { logoutUser } from '../../../../App/redux/action';

const genericBillFailedMessage = 'Bill submit failed.';
const uploadImages = () => {
	return async (dispatch) => {
		const { imagesToUpload } = store.getState().reducerBillUploadProgress;

		for (let i = 0; i < imagesToUpload.length; i++) {
			if (imagesToUpload[i].status && imagesToUpload[i].percent > 0) {
				continue;
			} else {
				const file = imagesToUpload[i].file;

				if (getStorageItem('user')) {
					let data = new FormData();
					// data.append('mobile_number', JSON.parse(getStorageItem('user')).mobile_number);
					data.append('customer_id', JSON.parse(getStorageItem('user')).id);
					data.append('merchant_id', apiData.merchant_id);
					data.append('platform', apiData.platform);
					data.append('bill_upload_type', 1);
					data.append('bill_image[]', file);

					const onUploadProgress = async (progressEvent) => {
						const { loaded, total } = progressEvent;
						let percent = Math.floor(loaded * 100 / total);
						if (percent < 71) {
							const imagesToUploadCopy = [ ...imagesToUpload ];

							imagesToUploadCopy[i] = {
								...imagesToUploadCopy[i],
								percent: percent
							};
							await dispatch({
								type: actionTypes.UPDATING_FILE_PERCENTAGE,
								file: imagesToUploadCopy[i]
							});
							await dispatch({ type: actionTypes.UPDATE_UPLOAD_PERCENTAGE });
						}
					};

					stockAPI(data, 'POST', '/submitBill', null, onUploadProgress, null, true)
						.then(async (res) => {
							if (res.statusCode === 100 && res.response) {
								//success case
								if (res.response && res.response.is_manual_process === '0') {
									//got proper response from ocr
									const imagesToUploadCopy = [ ...imagesToUpload ];
									imagesToUploadCopy[i] = {
										...imagesToUploadCopy[i],
										percent: 100,
										status: 'complete',
										pointsEarned: res.response.points_earned,
										...res.response
									};
									await dispatch({
										type: actionTypes.UPDATING_FILE_PERCENTAGE,
										file: imagesToUploadCopy[i]
									});
									await dispatch({ type: actionTypes.UPDATE_UPLOAD_PERCENTAGE });
								} else if (res.response && res.response.is_manual_process === '1') {
									//bill submitted for manual process
									const imagesToUploadCopy = [ ...imagesToUpload ];
									imagesToUploadCopy[i] = {
										...imagesToUploadCopy[i],
										percent: 100,
										status: 'manual',
										pointsEarned: res.response.points_earned,
										...res.response
									};
									await dispatch({
										type: actionTypes.UPDATING_FILE_PERCENTAGE,
										file: imagesToUploadCopy[i]
									});
									await dispatch({ type: actionTypes.UPDATE_UPLOAD_PERCENTAGE });
								}
							} else if (res.statusCode === 110 && res.response && res.response !== '') {
								//duplicate bill case
								openAlertBox(res.response, 'error');
								const imagesToUploadCopy = [ ...imagesToUpload ];
								imagesToUploadCopy[i] = {
									...imagesToUploadCopy[i],
									percent: 100,
									status: 'errorCode',
									duplicate: true
								};
								await dispatch({
									type: actionTypes.UPDATING_FILE_PERCENTAGE,
									file: imagesToUploadCopy[i]
								});
								await dispatch({ type: actionTypes.UPDATE_UPLOAD_PERCENTAGE });
							} else {
								//other errors
								if (res && res.statusDescription) {
									openAlertBox(res && res.statusDescription, 'error');
								} else if (res && res.message) {
									openAlertBox(res && res.message, 'error');
								} else {
									openAlertBox(genericBillFailedMessage, 'error');
								}
								const imagesToUploadCopy = [ ...imagesToUpload ];
								imagesToUploadCopy[i] = {
									...imagesToUploadCopy[i],
									percent: 100,
									status: 'errorCode'
								};
								await dispatch({
									type: actionTypes.UPDATING_FILE_PERCENTAGE,
									file: imagesToUploadCopy[i]
								});
								await dispatch({ type: actionTypes.UPDATE_UPLOAD_PERCENTAGE });
							}
						})
						.catch(async (err) => {
							openAlertBox(err && err.message, 'error');
							const imagesToUploadCopy = [ ...imagesToUpload ];
							imagesToUploadCopy[i] = {
								...imagesToUploadCopy[i],
								percent: 100,
								status: 'errorCode'
							};
							await dispatch({
								type: actionTypes.UPDATING_FILE_PERCENTAGE,
								file: imagesToUploadCopy[i]
							});
							await dispatch({ type: actionTypes.UPDATE_UPLOAD_PERCENTAGE });
						});
				} else {
					logoutUser();
				}
			}
		}
	};
};

const updateImagesToUpload = (images) => {
	return (dispatch) => {
		dispatch({ type: actionTypes.UPDATE_IMAGES_TO_UPLOAD, payLoad: images });
	};
};

const showProgress = () => {
	return (dispatch) => {
		dispatch({ type: actionTypes.SHOW_PROGESS_BAR });
	};
};

const hideProgress = () => {
	return (dispatch) => {
		const { uploadPercentage } = store.getState().reducerBillUploadProgress;
		if (uploadPercentage === 100) {
			dispatch({ type: actionTypes.RESET_BILL_UPLOAD });
		}
		dispatch({ type: actionTypes.HIDE_PROGESS_BAR });
	};
};

const deleteBillHandler = (bill, id) => {
	return (dispatch) => {
		const deleteParams = new FormData();
		deleteParams.append('bill_id', bill.last_bill_id);
		stockAPI(deleteParams, 'POST', '/deleteSubmitBill', null, null, null, true).then((response) => {
			if (response.statusCode === 100) {
				dispatch({ type: actionTypes.DELETE_BILL, payLoad: id });

				const { imagesToUpload } = store.getState().reducerBillUploadProgress;
				if (imagesToUpload.length === 0) {
					dispatch({ type: actionTypes.HIDE_PROGESS_BAR });
				}

				if (response.statusDescription && response.statusDescription !== '') {
					openAlertBox('Bill deleted successfully.', 'success');
				}
			} else {
				openAlertBox('Oops something went wrong!', 'error');
			}
		});
	};
};

export { uploadImages, updateImagesToUpload, showProgress, deleteBillHandler, hideProgress };
