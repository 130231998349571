import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import classnames from "classnames";
import moment from "moment";
import {
  withStyles,
  Card,
  Grid,
  Tabs,
  Paper,
  Tab,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Typography,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { styles } from "./OfferDetailsStyle";
import LocationComponent from "./LocationComponent";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import default_product from "../../../../assets/images/product/default_product.svg";
import Carousal from "../../Redeem/components/Carousal";
import NoRecordFound from "../../../../components/NoRecordFound/NoRecordFound";

class OfferDetails extends Component {
  state = {
    tabValue: 0,
  };

  handleTabValueChange = (event, newValue) => {
    this.setState({
      tabValue: newValue,
    });
  };

  render() {
    const { classes, offerDetails } = this.props;
    const { tabValue } = this.state;
    const formatDate = (date) => {
      return moment(date).format("Do MMM' YYYY");
    };
    const tabContainer = (
      <Paper
        className={classnames(classes.tabContainer, {
          [classes.brLTB]: tabValue === 0,
          [classes.brRTB]: tabValue === 1,
        })}
      >
        <Tabs
          classes={{
            indicator: classes.indicator,
          }}
          value={tabValue}
          onChange={this.handleTabValueChange}
          centered
          className={classes.mainTabs}
        >
          <Tab
            label="Key Highlights"
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
          />
          <Tab
            label={
              offerDetails &&
              offerDetails.locations &&
              Object.keys(offerDetails.locations).length > 0
                ? `Locations (${
                    offerDetails &&
                    offerDetails.locations &&
                    offerDetails.locations.length
                  })`
                : "Locations"
            }
            className={classes.tab}
            classes={{ selected: classes.selected }}
            disableRipple
            disabled={
              offerDetails &&
              offerDetails.locations &&
              Object.keys(offerDetails.locations).length <= 0
            }
          />
        </Tabs>
      </Paper>
    );
    const keyHeighlightList = (
      <List className={classes.listItem}>
        {offerDetails.key_highlight1 && (
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText primary={offerDetails.key_highlight1} />
          </ListItem>
        )}
        {offerDetails.key_highlight2 && (
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText primary={offerDetails.key_highlight2} />
          </ListItem>
        )}
        {offerDetails.key_highlight3 && (
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText primary={offerDetails.key_highlight3} />
          </ListItem>
        )}
        {offerDetails.key_highlight4 && (
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText primary={offerDetails.key_highlight4} />
          </ListItem>
        )}
        {offerDetails.key_highlight5 && (
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText primary={offerDetails.key_highlight5} />
          </ListItem>
        )}
      </List>
    );

    return (
      <div className={classes.root}>
        {offerDetails && Object.keys(offerDetails).length > 0 && (
          <Fragment>
            <Carousal
              imagesArray={
                (offerDetails &&
                  offerDetails.offer_image &&
                  offerDetails.offer_image) || [default_product]
              }
              alt="offers"
              DotsBottom="38px"
            />
            <div className={classes.content + " " + classes.common_padding}>
              <Card
                className={
                  classes.offer_details_container + " " + classes.common_padding
                }
              >
                <div className={classes.reedemCntwrapper}>
                  <div className={classes.brand}>
                    {offerDetails.offer_brand
                      ? offerDetails.offer_brand
                      : offerDetails.merchant_name}
                  </div>
                  {offerDetails.offer_title && (
                    <div className={classes.sub_title}>
                      {offerDetails.offer_title}
                    </div>
                  )}

                  {offerDetails.offer_sub_title && (
                    <div
                      className={classnames(classes.title, classes.mB, {
                        [classes.mB_6]:
                          offerDetails &&
                          offerDetails.offer_sub_title &&
                          offerDetails.offer_sub_title.length > 35,
                        [classes.mB_10]:
                          offerDetails &&
                          offerDetails.offer_sub_title &&
                          offerDetails.offer_sub_title.length < 35,
                      })}
                    >
                      {offerDetails.offer_sub_title}
                    </div>
                  )}
                  {offerDetails.coupon_applicable && (
                    <div
                      className={classes.coupons_box + " " + classes.noSelect}
                    >
                      <CopyToClipboard
                        text={offerDetails.coupon_applicable}
                        onCopy={() =>
                          openAlertBox("Copied to clipboard", "success")
                        }
                      >
                        <Typography className={classes.coupons}>
                          {offerDetails.coupon_applicable}
                        </Typography>
                      </CopyToClipboard>
                    </div>
                  )}
                  <div
                    className={classnames(classes.date, {
                      [classes.mT_20]:
                        offerDetails.display_custom_text_or_date !== "1",
                    })}
                  >
                    {offerDetails.display_custom_text_or_date === "1" ? (
                      <Fragment>
                        <span
                          className={
                            classes.date_title + " " + classes.common_fontSize
                          }
                        >
                          Start Date:{" "}
                          <span
                            className={
                              classes.date_value + " " + classes.common_fontSize
                            }
                          >
                            {formatDate(offerDetails.offer_start_date)}
                          </span>
                        </span>
                        <span
                          className={
                            classes.date_title + " " + classes.common_fontSize
                          }
                        >
                          End Date:{" "}
                          <span
                            className={
                              classes.date_value + " " + classes.common_fontSize
                            }
                          >
                            {formatDate(offerDetails.offer_end_date)}
                          </span>
                        </span>
                      </Fragment>
                    ) : offerDetails.display_custom_text_or_date === "2" ? (
                      <Fragment>
                        <span
                          className={
                            classes.date_title + " " + classes.common_fontSize
                          }
                        >
                          Start Date:{" "}
                          <span
                            className={
                              classes.date_value + " " + classes.common_fontSize
                            }
                          >
                            {formatDate(offerDetails.offer_start_date)}
                          </span>
                        </span>
                      </Fragment>
                    ) : offerDetails.display_custom_text_or_date === "3" ? (
                      <Fragment>
                        <span
                          className={
                            classes.date_title + " " + classes.common_fontSize
                          }
                        >
                          End Date:{" "}
                          <span
                            className={
                              classes.date_value + " " + classes.common_fontSize
                            }
                          >
                            {formatDate(offerDetails.offer_end_date)}
                          </span>
                        </span>
                      </Fragment>
                    ) : (
                      <span
                        className={
                          classes.date_value + " " + classes.common_fontSize
                        }
                      >
                        {offerDetails.custom_text}
                      </span>
                    )}
                  </div>
                </div>
              </Card>
            </div>
            <div
              className={classes.tab_container + " " + classes.common_padding}
            >
              <Grid container>
                <Grid item className={classes.pageItem}>
                  {tabContainer}
                </Grid>
                <Grid item className={classes.pageItem}>
                  {tabValue === 0 ? (
                    !offerDetails.key_highlight1 &&
                    !offerDetails.key_highlight2 &&
                    !offerDetails.key_highlight3 &&
                    !offerDetails.key_highlight4 &&
                    !offerDetails.key_highlight5 ? (
                      <NoRecordFound
                        message="No Data Found"
                        height={160}
                        shrinkImgSize={true}
                      />
                    ) : (
                      <Card> {keyHeighlightList}</Card>
                    )
                  ) : offerDetails.locations &&
                    offerDetails.locations.length > 0 ? (
                    <Card>
                      {offerDetails.locations.map((item) => (
                        <LocationComponent
                          {...item}
                          merchant_name={offerDetails.merchant_name}
                        />
                      ))}
                    </Card>
                  ) : (
                    <NoRecordFound
                      message="No Location Available"
                      height={160}
                      shrinkImgSize={true}
                    />
                  )}
                </Grid>
              </Grid>

              {offerDetails.long_description ? (
                <Card
                  className={
                    classes.descriptionWrapper + " " + classes.common_padding
                  }
                >
                  <span>
                    <strong>Description:</strong>
                  </span>
                  {/* <p className={classes.descriptionClass}>
                    {offerDetails.long_description}
                  </p> */}
                  <div
                    className={classes.descriptionClass}
                    dangerouslySetInnerHTML={{
                      __html: offerDetails.long_description
                        ? offerDetails.long_description
                        : " <div style='text-align:center;'>No data available</div>",
                    }}
                  ></div>
                </Card>
              ) : (
                ""
              )}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}
export default withRouter(
  withStyles(styles, { withTheme: true })(OfferDetails)
);
