import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import BillImage from "../../../../components/BillImage/BillImage";
import { primaryOrange, whiteColor } from "../../../../components/UI/Theme";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = (theme) => {
  return {
    dots: {
      position: "absolute",
      bottom: "20px",
      right: "30px",
      [theme.breakpoints.down("xs")]: {
        // bottom: "10px",
        right: "15px",
      },
    },
    topImageDiv: {
      margin: "0",
      padding: "0",
      display: "block",
      height: "431px",
      textAlign: "center",
      position: "relative",
      overflow: "hidden",
      [theme.breakpoints.down(641)]: {
        height: "324px",
      },
      [theme.breakpoints.down(481)]: {
        height: "223px",
      },
      [theme.breakpoints.down(400)]: {
        height: "203px",
      },
      [theme.breakpoints.down(370)]: {
        height: "194px",
      },
      [theme.breakpoints.down(321)]: {
        height: "172px",
      },
    },
    img: {
      width: "100%",
      height: "360px",
      objectFit: "contain",
      objectPosition: "center",
      [theme.breakpoints.down(641)]: {
        height: "324px",
      },
      [theme.breakpoints.down(481)]: {
        height: "223px",
      },
      [theme.breakpoints.down(400)]: {
        height: "203px",
      },
      [theme.breakpoints.down(370)]: {
        height: "194px",
      },
      [theme.breakpoints.down(321)]: {
        height: "172px",
      },
      "& img": {
        maxWidth: "100%",
        height: "100%",
      },
    },
    imgFullWidth: {
      width: "100%",
      height: "360px",
      objectFit: "cover",
      objectPosition: "center",
      [theme.breakpoints.down(641)]: {
        height: "324px",
      },
      [theme.breakpoints.down(481)]: {
        height: "223px",
      },
      [theme.breakpoints.down(400)]: {
        height: "203px",
      },
      [theme.breakpoints.down(370)]: {
        height: "194px",
      },
      [theme.breakpoints.down(321)]: {
        height: "172px",
      },
      "& img": {
        maxWidth: "100%",
        height: "100%",
      },
    },
    dialogModal: {
      "& .MuiDialog-paperWidthMd": {
        width: "100%",
      },
      "& .MuiDialog-paper": {
        margin: "15px",
      },
    },
  };
};

class ImageCarousal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeImageIndex: 0,
      interval: 4000,
      open: false,
      imageSrc: "",
    };
  }

  next = (next) => {
    this.setState({
      activeImageIndex: next,
    });
  };

  render() {
    const { activeImageIndex } = this.state;
    const {
      classes,
      alt,
      className,
      dotsCss,
      fullWidth,
      isRedirect,
      DotsBottom,
    } = this.props;

    return (
      <div className="col-md-12">
        <div className={className ? className : classes.topImageDiv}>
          <AutoPlaySwipeableViews
            index={activeImageIndex}
            onChangeIndex={this.next}
            enableMouseEvents
          >
            {
              // this.props.imagesArray &&
              // this.props.imagesArray.length > 0 &&
              isRedirect
                ? this.props.imagesArray.map((step, index) => (
                    <div key={step.label}>
                      {Math.abs(activeImageIndex - index) <= 2 ? (
                        <img
                          className={
                            !className
                              ? fullWidth
                                ? classes.imgFullWidth
                                : classes.img
                              : className
                          }
                          src={step.banner_images}
                          key={step.label}
                          alt={step.banner_title}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            step.banner_redirect_url && window.open(step.banner_redirect_url);
                            !step.banner_redirect_url &&
                              this.setState({
                                imageSrc: step.banner_images,
                                open: true,
                              });
                          }}
                        />
                      ) : null}
                    </div>
                  ))
                : this.props.imagesArray.map((step, index) => (
                    <div key={step.label + "-" + index}>
                      {Math.abs(activeImageIndex - index) <= 2 ? (
                        <img
                          className={
                            !className
                              ? fullWidth
                                ? classes.imgFullWidth
                                : classes.img
                              : className
                          }
                          src={step}
                          alt={alt}
                          key={step.label}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState({
                              imageSrc: step,
                              open: true,
                            });
                          }}
                        />
                      ) : null}
                    </div>
                  ))
            }
          </AutoPlaySwipeableViews>
          <div
            className={dotsCss ? dotsCss : classes.dots}
            style={{ bottom: DotsBottom }}
          >
            {
              // this.props.imagesArray &&
              // this.props.imagesArray.length > 0 &&
              this.props.imagesArray.length > 1 &&
                this.props.imagesArray.map((item, i) => {
                  return (
                    <FiberManualRecordIcon
                      key={i}
                      style={{
                        fontSize: 10,
                        color:
                          activeImageIndex === i ? primaryOrange : whiteColor,
                      }}
                    />
                  );
                })
            }
          </div>
        </div>
        <Dialog
          open={this.state.open}
          keepMounted
          onClose={() => {
            this.setState({
              imageSrc: "",
              open: false,
            });
          }}
          maxWidth="md"
          className={classes.dialogModal}
        >
          <BillImage
            multi={false}
            open={this.state.open}
            imgSrc={this.state.imageSrc}
            okBtnHandler={() => {
              this.setState({
                imageSrc: "",
                open: false,
              });
            }}
          />
        </Dialog>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(ImageCarousal)
);
ImageCarousal.defaultProps = {
  imagesArray: [],
  offerScreen: false,
};

ImageCarousal.propTypes = {
  imagesArray: PropTypes.array,
  offerScreen: PropTypes.bool,
};
