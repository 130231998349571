import * as types from './types';

const initialState = {
    guideLines: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_BILL_UPLOAD_GUIDELINES_SUCCESS:
        case types.GET_BILL_UPLOAD_GUIDELINES_FAIL:
            return {
                ...state,
                guideLines: action.guideLines
            }
    
        default:
            return state;
    }
}

export default reducer;